import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { defaultEmptyDisplayConfig } from '@modules/gallery/configs';
import { GalleryService } from '@modules/gallery/gallery.service';
import { PatientNamePipe } from '@modules/patient/patient-name.pipe';
import { FlattenedTelemonitoring, Patient } from '@pixacare/pxc-ts-core';
import { TelemonitoringHttpService } from '@services/http/telemonitoring.http.service';
import { GallerySourceType } from '@shared/models/enums/gallery-source-type.enum';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TelemonitoringService {

  constructor(
    private sanitizer: DomSanitizer,
    private readonly patientNamePipe: PatientNamePipe,
    private readonly galleryService: GalleryService,
    private readonly telemonitoringService: TelemonitoringHttpService,
  ) {}

  getTelemonitoringName(telemonitoring: FlattenedTelemonitoring): string {
    return telemonitoring.telemonitoring.name
      || this.getTelemonitoringDefaultName(telemonitoring.patient);
  }

  getTelemonitoringDefaultName(patient: Patient): string {
    return `Télésuivi de ${this.patientNamePipe.transform(patient)}`;
  }

  openQrCodeInGallery(tmId: number): void {
    this.getQrCode(tmId)
      .subscribe((safeSvg: SafeHtml) => {
        this.galleryService.open(
          {
            items: [{ innerHtml: safeSvg }],
            config: {
              ...defaultEmptyDisplayConfig,
              sourceType: GallerySourceType.INNER_HTML,
            },
          },
        ).subscribe();
      });
  }

  getQrCode(tmId: number): Observable<SafeHtml> {
    return this.telemonitoringService.getQrCode(tmId).pipe(
      map((svg: string) => this.sanitizer.bypassSecurityTrustHtml(svg)),
    );
  }

}
