import { inject, Injectable } from '@angular/core';
import { ActionsService } from '../shared/models/helpers/actions-service';
import { MenuAction } from '../shared/models/menu-actions/menu-action';
import { MenuActionId } from '../shared/models/menu-actions/menu-action-id.enum';
import { ContactUsService } from './contact-us.service';

@Injectable()
export class HelpActionsService extends ActionsService {

  actions: MenuAction<unknown>[] = [
    {
      id: MenuActionId.HELP_MANUAL,
      label: 'Manuel utilisateur',
      icon: '@tui.file-text',
      execute: (): void => {
        window.open('https://cdn.pixacare.com/assets/IFU/IFU_Global.pdf', '_blank');
      },
    },
    {
      id: MenuActionId.HELP_CONTACT,
      label: 'Demander de l\'aide',
      icon: '@tui.mail',
      execute: (): void => {
        this.contactUsService.openPixacareSupportForm();
      },
    },
    {
      id: MenuActionId.HELP_TUTORIALS,
      label: 'Tutoriels',
      icon: '@tui.youtube',
      execute: (): void => {
        window.open('https://www.youtube.com/watch?v=oYsCyv1M-RE&list=PL0aJ1xttMZCAeDKJ9mskZOpYU-LeJruEP', '_blank');
      },
    },
  ];

  private readonly contactUsService = inject(ContactUsService);

}
