<div class="max-md flex divide-slate-200 max-md:flex-col max-md:divide-y md:divide-x">
  <div
    class="flex flex-1 flex-col gap-2 max-md:pb-3"
    [class.pr-3]="displayPatientsList"
  >
    @if (patientEditForm?.errors | keyvalue; as errors) {
      <tui-notification
        size="m"
        icon="@tui.triangle-alert"
        appearance="error"
        data-testid="error-alert"
      >
        {{ errors[0].value }}
      </tui-notification>
    } @else {
      @if ((selectIsGamEnabled$ | async) === true) {
        @if (patientEditForm.value.isGamLinked) {
          <tui-notification
            size="m"
            icon="@tui.link"
            appearance="success"
            data-testid="gam-linked-alert"
          >
            Le patient est rattaché à votre établissement.
          </tui-notification>
        } @else {
          @if (!isPatientAnonymous) {
            <tui-notification
              size="m"
              icon="@tui.unlink"
              appearance="warning"
              data-testid="gam-unlinked-alert"
            >
              Le patient n'est pas rattaché à votre établissement.
            </tui-notification>
          }
        }
      }
    }

    <pxc-patient-edit-form
      [form]="patientEditForm"
      data-testid="patient-edit-form"
    ></pxc-patient-edit-form>
  </div>

  @if (displayPatientsList) {
    <pxc-patient-search-list
      class="md:w-80"
      [patients]="searchedPatients$ | async"
      (selectPatient)="selectPatient($event)"
      data-testid="patient-searched-list"
    ></pxc-patient-search-list>
  }
</div>

@if (displayActionButtons) {
  <div class="mt-4 flex flex-wrap justify-between gap-2">
    <button
      tuiButton
      appearance="outline"
      type="button"
      (click)="closeEdition()"
      data-testid="patient-cancel"
      class="max-sm:w-full"
    >
      Annuler
    </button>
    <button
      tuiButton
      type="button"
      *tuiLet="isCheckingFormValidity$ | async as isCheckingFormValidity"
      [disabled]="!patientEditForm.valid || isCheckingFormValidity"
      [loading]="isCheckingFormValidity"
      (click)="save()"
      data-testid="patient-confirm"
      class="max-sm:order-first max-sm:w-full"
    >
      Enregistrer
    </button>
  </div>
}
