import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit, inject,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { TuiLet } from '@taiga-ui/cdk';
import { TuiButton, TuiError } from '@taiga-ui/core';
import { TuiFieldErrorPipe } from '@taiga-ui/kit';
import { TuiInputModule } from '@taiga-ui/legacy';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { DialogFormInput } from './dialog-form-input';

@Component({
  selector: 'pxc-dialog-form',
  templateUrl: './dialog-form.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TuiLet,
    TuiButton,
    TuiInputModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TuiError,
    TuiFieldErrorPipe,
  ],
})
export class DialogFormComponent implements OnInit {

  formGroup: UntypedFormGroup;

  context = inject(POLYMORPHEUS_CONTEXT);

  entity = this.context.data.entity;
  inputs: DialogFormInput[] = this.context.data.inputs;
  cancelLabel: string = this.context.data.cancelLabel || 'Annuler';
  confirmLabel: string = this.context.data.confirmLabel || 'Enregistrer';

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({});
    this.inputs.forEach((input: DialogFormInput) => {
      this.formGroup.addControl(
        input.property,
        new UntypedFormControl(
          this.entity?.[input.property],
          input.validators,
        ),
      );
    });
  }

  cancel(): void {
    if (this.context) {
      this.context.completeWith();
    }
  }

  confirm(): void {
    if (this.context && this.formGroup.valid) {
      this.context.completeWith(this.formGroup.value);
    }
  }

}
