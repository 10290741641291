import { Pipe, PipeTransform } from '@angular/core';
import { SadmEntity, SadmType, SadmTypeLabel } from '@pixacare/pxc-ts-core';

@Pipe({
  name: 'sadmEntityType',
  pure: true,
  standalone: true,
})
export class SadmEntityTypePipe implements PipeTransform {

  transform(value: SadmEntity, sadmType: SadmType, index?: number): string {
    return `${SadmTypeLabel[sadmType]} n°${index ?? value.patientEntityIndex}`;
  }

}
