import { HomeStatistics } from './home-statistics';

export const homeStatistics: HomeStatistics[] = [
  {
    label: 'Mes séquences créées',
    icon: '@tui.camera',
    value: 'sequenceCount',
    color: 'pixablue',
    link: '/dashboard/sequences',
  },
  {
    label: 'Mes photographies médicales',
    icon: '@tui.image',
    value: 'mediaCount',
    color: 'pixapurple',
    link: '/dashboard/sequences',
  },
  {
    label: 'Mes patients traités',
    icon: '@tui.user',
    value:'treatedPatientCount',
    color: 'pixared',
    link: '/dashboard/patients',
  },
];
