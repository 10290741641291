<article
  class="h-24 w-24 snap-center overflow-hidden rounded"
  #thumbnail
>
  <img
    class="h-full w-full cursor-pointer transition"
    [ngClass]="{ 'brightness-50 hover:brightness-90': !active }"
    [src]="item.thumbnailSrc"
    alt="Image thumbnail"
    (click)="onThumbnailClicked()"
    [hidden]="!loaded"
    (load)="loaded = true"
    data-testid="image"
  />

  @if (!loaded) {
    <pxc-loader
      class="h-full w-full bg-base-alt"
      [dark]="true"
      data-testid="loading"
    ></pxc-loader>
  }
</article>
