import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TuiButton, TuiDropdown } from '@taiga-ui/core';

@Component({
  selector: 'pxc-filter-dropdown-button',
  templateUrl: './filter-dropdown-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiButton,
    TuiDropdown,
  ],
})
export class FilterDropdownButtonComponent {

  @Input() isActive: boolean;
  @Input() buttonText: string;
  @Input() icon: string;

  open = false;

}
