import { Pipe, inject, type PipeTransform } from '@angular/core';
import { Channel } from '@pixacare/pxc-ts-core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Pipe({
  name: 'channelSubtitle',
  standalone: true,
})
export class ChannelSubtitlePipe implements PipeTransform {

  private authService = inject(AuthenticationService);

  transform(channel: Channel): unknown {
    if (!channel.lastMessage) {
      return '';
    }

    const isLastMessageSenderCurrentUser = this.isCurrentUser(channel.lastMessage.senderId);

    if (channel.lastMessage.special?.shares) {
      return this.formatShareMessage(isLastMessageSenderCurrentUser);
    }

    if (channel.lastMessage.special?.telemonitoringId) {
      return channel.lastMessage.text;
    }

    if (channel.lastMessage.text) {
      return this.formatTextMessage(channel, isLastMessageSenderCurrentUser);
    }

    return '';
  }

  private isCurrentUser(senderId: number): boolean {
    return senderId === this.authService.currentUser.id;
  }

  private formatTextMessage(channel: Channel, isLastMessageSenderCurrentUser: boolean): string {
    if (channel.users.length < 3) {
      return channel.lastMessage.text;
    }

    const lastMessageSenderName = isLastMessageSenderCurrentUser ? 'Vous' : channel.lastMessage.senderName;
    return `${lastMessageSenderName}: ${channel.lastMessage.text}`;
  }

  private formatShareMessage(isLastMessageSenderCurrentUser: boolean): string {
    const shareVerb = isLastMessageSenderCurrentUser ? 'partagé' : 'reçu';
    return `Vous avez ${shareVerb} une séquence`;
  }

}
