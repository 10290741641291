import { Inject, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PatientMergeService } from '@modules/patient/patient-merge/patient-merge.service';
import { Store } from '@ngrx/store';
import { FilterOperator, PaginationQuery, Patient } from '@pixacare/pxc-ts-core';
import { PatientHttpService } from '@services/http/patient.http.service';
import { patientsActions } from '@shared/store/patients/patients.actions';
import { TuiAlertService } from '@taiga-ui/core';
import { Observable, catchError, iif, map, of, switchMap, throwError } from 'rxjs';

@Injectable()
export class PatientEditService {

  constructor(
    private readonly store: Store,
    private readonly patientMergeService: PatientMergeService,
    private readonly router: Router,
    private readonly patientHttpService: PatientHttpService,
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService,
  ) { }

  // Return `true` if the two patients are the same.
  static compare(oldPatient: Patient, newPatient: Patient): boolean {
    return oldPatient?.firstName === newPatient?.firstName
        && oldPatient?.lastName === newPatient?.lastName
        && oldPatient?.birthName === newPatient?.birthName
        && oldPatient?.birthDate?.toLocaleDateString() === newPatient?.birthDate?.toLocaleDateString();
  }

  updatePatient(
    basePatient: Patient, patientEditForm: UntypedFormGroup,
  ): Observable<Error> {
    const patient = patientEditForm.getRawValue();

    return iif(() => !patient.businessIdentifier,
      this.findPatientById(patient.clientCode, patient.id),
      this.findByBusinessIdentifier(patient.clientCode, patient.businessIdentifier),
    ).pipe(
      switchMap((targetPatient) => {
        if (
          patient.businessIdentifier
          && basePatient.businessIdentifier !== patient.businessIdentifier
          && targetPatient && targetPatient.id
        ) {
          this.mergePatient(basePatient, targetPatient);
        } else {
          this.editPatient(basePatient.id, patient);
        }

        return of(null);
      }),
      catchError((e) => {
        this.handleError(e.statusText, e);
        return of(e);
      }));
  }

  private handleError(message: string, error: Error) {
    console.error('Patient Edit Service error', error);
    this.alertService.open(message, {
      label: 'Echec de la mise à jour du patient',
      appearance: 'error',
    }).subscribe();
  }

  private findPatientById(clientCode: string, patientId: number): Observable<Patient> {

    if (!patientId) {
      return throwError(() => new Error('Patient ID is required'));
    }

    return this.patientHttpService.getPatients(clientCode, {
      query: new PaginationQuery({
        filter: [
          {
            prop: 'id',
            op: FilterOperator.EQUAL,
            val: patientId.toString(),
          },
        ],
      }),
    }).pipe(map((response) => response?.data[0]));
  }

  private findByBusinessIdentifier(clientCode: string, businessIdentifier: string): Observable<Patient> {

    return this.patientHttpService.search({ clientCode, businessIdentifier })
      .pipe(map(([patient]) => patient));
  }

  private mergePatient(basePatient: Patient, targetPatient: Patient) {

    this.patientMergeService.mergePatient(basePatient, targetPatient, {
      confirmAction: () => {
        this.router.navigate(['/dashboard/patients'], { queryParamsHandling: 'merge' });
      },
      additionalMessage: 'Un patient possédant le même identifiant existe déjà.',
    });

  }

  private editPatient(patientId: number, patient: Patient) {
    this.store.dispatch(patientsActions.updatePatient({
      clientCode: patient.clientCode,
      patientId,
      patient: {
        ...patient,
        id: patientId,
        birthDate: new Date(patient.birthDate),
      },
    }));
  }

}
