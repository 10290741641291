import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DepartmentResponse } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DepartmentHttpService {

  constructor(
    private readonly http: HttpClient,
  ) {}

  getDepartments(
    clientCode: string,
  ): Observable<DepartmentResponse[]> {
    return this.http
      .get<DepartmentResponse[]>(
      `${environment.apiBaseUrl}/Department/?cc=${clientCode}`,
    );
  }

  updateCollaboration(
    clientCode: string,
    departmentId: number,
    isOpen: boolean,
  ): Observable<DepartmentResponse> {
    return this.http
      .patch<DepartmentResponse>(
      `${environment.apiBaseUrl}/Department/${departmentId}`,
      { clientCode, isOpen },
    );
  }

  delete(
    clientCode: string,
    departmentId: number,
  ): Observable<void> {
    return this.http
      .delete<void>(
      `${environment.apiBaseUrl}/Department/${departmentId}?cc=${clientCode}`,
    );
  }

  create(
    clientCode: string,
    name: string,
  ): Observable<DepartmentResponse> {
    return this.http
      .post<DepartmentResponse>(
      `${environment.apiBaseUrl}/Department/?cc=${clientCode}`,
      { name },
    );
  }

  updateUser(
    clientCode: string,
    departmentId: number,
    departmentUserId: number,
    isAdmin: boolean,
    disabledOn: Date,
  ): Observable<object> {
    return this.http
      .patch(
        `${environment.apiBaseUrl}/department/${departmentId}/user/${departmentUserId}`,
        { isAdmin, disabledOn: disabledOn?.toISOString() },
        {
          params: {
            cc: clientCode,
          },
        },
      );
  }

  removeUser(
    userId: number,
    clientCode: string,
    departmentId: number,
  ): Observable<HttpResponse<unknown>> {
    return this.http.delete(
      `${environment.apiBaseUrl}/department/${departmentId}/User/${userId}?cc=${clientCode}`,
      { observe: 'response' },
    );
  }

}
