<div
  class="rounded-lg !shadow-none transition active:bg-neutral-1"
  [ngClass]="{ 'ring-2 ring-accent-1': isSelected }"
>
  @if (sadmEntity.previewMedia && !imageError) {
    <img
      class="h-48 w-full rounded-xl object-cover"
      data-testid="image"
      [src]="sadmEntity.previewMedia.thumbnailUri"
      [alt]="sadmEntity | sadmEntityName: sadmType | titlecase"
      (error)="onImageLoadingError()"
    />
  } @else {
    <pxc-image-broken class="block aspect-square h-48 w-full overflow-hidden rounded-xl"></pxc-image-broken>
  }
  <p class="mt-2 leading-tight">
    {{ sadmEntity | sadmEntityName | titlecase }}
  </p>
  <p class="mt-1 text-sm text-secondary">
    {{ sadmEntity | sadmEntityType: sadmType | firstLetterUppercase }}
  </p>
  <p
    class="text-sm text-tertiary"
    data-testid="date"
  >
    {{ createdOn | date }}
  </p>
</div>
