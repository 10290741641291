import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Client } from '@pixacare/pxc-ts-core';
import { Observable, combineLatest, first, switchMap } from 'rxjs';
import { Department } from 'src/app/shared/models/base/department';
import { selectClient } from 'src/app/shared/store/clients/clients.selectors';
import { departmentsActions } from 'src/app/shared/store/departments/departments.actions';
import { selectClientDepartment } from 'src/app/shared/store/departments/departments.selectors';
import { selectClientCode, selectDepartmentId } from 'src/app/shared/store/router/router.selectors';
import { DepartmentAdminParams } from '../../../shared/models/department-admin-params';

@Injectable()
export class DepartmentAdminService {

  params$: Observable<DepartmentAdminParams>;

  private department$: Observable<Department>;
  private client$: Observable<Client>;

  private readonly store = inject(Store);

  constructor() {
    this.params$ = combineLatest({
      clientCode: this.store.select(selectClientCode),
      departmentId: this.store.select(selectDepartmentId),
    });
  }

  loadClient$(): Observable<Client> {
    if (this.client$) {
      return this.client$;
    }

    this.client$ = this.params$.pipe(
      switchMap(({ clientCode }) => this.store.select(selectClient(clientCode))),
    );
    return this.client$;
  }

  loadDepartment$(): Observable<Department> {
    if (this.department$) {
      return this.department$;
    }

    this.department$ = this.params$.pipe(
      switchMap(({ clientCode, departmentId }) =>
        this.store.select(selectClientDepartment(clientCode, departmentId)),
      ),
    );
    return this.department$;
  }

  updateCollaboration(isOpen: boolean): void {
    this.params$.pipe(first()).subscribe(({ clientCode, departmentId }) => {
      this.store.dispatch(
        departmentsActions.updateCollaboration({
          clientCode,
          departmentId,
          isOpen,
        }),
      );
    });
  }

}
