import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FlattenedLicense, License } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LicenseHttpService {

  constructor(
    private readonly http: HttpClient,
  ) { }

  getLicenses(): Observable<FlattenedLicense[]> {
    return this.http.get<FlattenedLicense[]>(`${environment.apiBaseUrl}/License?flattened=true`);
  }

  answerInvitation(
    licenseId: number,
    accepted: boolean,
  ): Observable<License> {
    return this.http.patch<License>(
      `${environment.apiBaseUrl}/License/${licenseId}/AnswerInvitation?accepted=${accepted}`,
      null);
  }

}
