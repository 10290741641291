import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PatientContact } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PatientContactHttpService {

  constructor(
    private readonly httpClient: HttpClient,
  ) { }

  getPatientContacts(patientId: number, clientCode: string): Observable<PatientContact[]> {
    return this.httpClient.get<PatientContact[]>(
      `${environment.apiBaseUrl}/PatientContact/${clientCode}/${patientId}`);
  }

  createPatientContact(patientContact: Omit<PatientContact, 'id'>, clientCode: string):
  Observable<PatientContact> {
    return this.httpClient.post<PatientContact>(
      `${environment.apiBaseUrl}/PatientContact/${clientCode}`, patientContact);
  }

}
