import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Patient } from '@pixacare/pxc-ts-core';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';

@Component({
  selector: 'pxc-patient-card',
  templateUrl: './patient-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientCardComponent {

  @Input() patient: Patient;
  @Input() actions: MenuAction<Patient>[];
  @Input() isFocusable = false;
  @Input() displayLastActivity = false;

}
