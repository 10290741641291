import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TuiAlertService } from '@taiga-ui/core';
import { BehaviorSubject } from 'rxjs';
import { ResetPasswordHttpService } from '../../services/reset-password.http.service';

@Component({
  templateUrl: './reset-password-request.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordRequestComponent implements OnInit {

  passwordResetRequestForm: UntypedFormGroup;

  actionOnGoing$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  passwordResetRequestSent$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly resetPasswordService: ResetPasswordHttpService,
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService,
  ) { }

  get emailInput(): AbstractControl {
    return this.passwordResetRequestForm.get('emailInput');
  }

  ngOnInit(): void {
    this.passwordResetRequestForm = this.formBuilder.group({
      emailInput: new UntypedFormControl(null, [
        Validators.required,
        Validators.email,
        Validators.maxLength(256),
      ]),
    });
  }

  requestPasswordReset(): void {
    if (!this.passwordResetRequestForm.valid) {
      this.alertService.open('Merci de résoudre les erreurs de saisie.', {
        label: 'Erreur de validation',
        appearance: 'error',
      }).subscribe();

      return;
    }

    this.actionOnGoing$.next(true);
    this.resetPasswordService
      .sendPasswordRequest(this.emailInput.value)
      .subscribe(() => {
        this.actionOnGoing$.next(false);
        this.passwordResetRequestSent$.next(true);
      });
  }

}
