<pxc-filter-dropdown-button
  [isActive]="isActive"
  [buttonText]="buttonText"
  icon="@tui.calendar"
>
  <div
    class="flex flex-col gap-4 p-3"
    data-testid="dropdown-wrapper"
  >
    <div class="grid grid-cols-2 gap-4">
      <tui-input-date
        toNativeDate
        [ngModel]="dates.min"
        (ngModelChange)="updateDates({ min: $event, max: dates.max })"
        class="w-full"
        tuiTextfieldCleaner="true"
        [min]="toTuiDay(template.min) ?? MIN_DATE"
        [max]="toTuiDay(!!dates.max ? dates.max : template.max) ?? MAX_DATE"
        data-testid="from-input"
        ngDefaultControl
      >
        depuis le
      </tui-input-date>

      <tui-input-date
        toNativeDate
        [ngModel]="dates.max"
        (ngModelChange)="updateDates({ min: dates.min, max: $event })"
        class="w-full"
        tuiTextfieldCleaner="true"
        [min]="toTuiDay(!!dates.min ? dates.min : template.min) ?? MIN_DATE"
        [max]="toTuiDay(template.max) ?? MAX_DATE"
        data-testid="to-input"
        ngDefaultControl
      >
        jusqu'au
      </tui-input-date>
    </div>

    @if (template.min && template.max) {
      <tui-range
        [ngModel]="[lerpDate(dates.min || template.min), lerpDate(dates.max || template.max)]"
        (ngModelChange)="slider$.next($event)"
        [min]="0"
        [max]="1"
        [step]="0.01"
        data-testid="range-slider"
        ngDefaultControl
      ></tui-range>
    }

    @if (enableShortcuts) {
      <div
        class="flex flex-wrap justify-center gap-2"
        data-testid="shortcuts"
      >
        @for (shortcut of shortcuts; track shortcut; let index = $index) {
          <button
            tuiButton
            [appearance]="index === activeShortcutIndex ? 'primary' : 'outline'"
            size="s"
            [attr.data-testid]="shortcut.displayName"
            (click)="applyShortcut(shortcut)"
          >
            {{ shortcut.displayName }}
          </button>
        }
      </div>
    }

    <button
      tuiButton
      [disabled]="!isActive"
      (click)="clearFilters()"
      class="w-full"
      size="s"
      data-testid="reset-button"
      appearance="destructive"
    >
      Réinitialiser
    </button>
  </div>
</pxc-filter-dropdown-button>
