<tui-loader
  *tuiLet="items$ | async as items"
  [showLoader]="!items"
>
  @if (!!items?.length && !addMode) {
    <tui-select
      [ngModel]="value$ | async"
      (ngModelChange)="onValueChange($event)"
      ngDefaultControl
      [tuiTextfieldLabelOutside]="true"
      [disabled]="disabled"
      [tuiTextfieldCleaner]="true"
      data-testid="select"
      [valueContent]="itemTemplate"
      ngDefaultControl
    >
      {{ selectPlaceholder }}
      <tui-data-list *tuiDataList>
        <tui-opt-group label="Numéros enregistrés">
          @for (item of items; track item.id) {
            <button
              tuiOption
              [value]="item"
            >
              <ng-content *ngTemplateOutlet="itemTemplate; context: { $implicit: item, hideFlag: true }"></ng-content>
            </button>
          }
        </tui-opt-group>
        <tui-opt-group>
          <button
            tuiOption
            [value]="null"
            iconStart="@tui.plus"
            data-testid="add-number-button"
          >
            Ajouter un numéro
          </button>
        </tui-opt-group>
      </tui-data-list>
    </tui-select>

    <ng-template
      #itemTemplate
      let-value
      let-hideFlag="hideFlag"
    >
      @if (!hideFlag) {
        <img
          [attr.alt]="countryIsoCode"
          [src]="countryIsoCode | tuiFlag"
          class="mr-3 aspect-square h-7 rounded-full"
        />
      }
      {{ value.phoneNumber | maskito: mask }}
    </ng-template>
  } @else {
    <div class="flex items-center gap-4">
      <tui-input-phone-international
        [disabled]="!items"
        [countrySearch]="true"
        [countries]="(countries | tuiSortCountries | async) || []"
        [(countryIsoCode)]="countryIsoCode"
        [ngModel]="input"
        (ngModelChange)="onInput($event)"
        class="flex-1"
        data-testid="input"
        [tuiAutoFocus]="addMode"
        ngDefaultControl
      >
        {{ inputPlaceholder }}
      </tui-input-phone-international>
      @if (!!items?.length) {
        <button
          tuiButton
          appearance="outline"
          (click)="backToList()"
          size="l"
          data-testid="saved-numbers-button"
        >
          Numéros enregistrés
        </button>
      }
    </div>
  }
</tui-loader>
