import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import {
  ResetPasswordRequestComponent,
} from './components/reset-password-request/reset-password-request.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ValidateComponent } from './components/validate/validate.component';

const appRoutes: Routes = [
  {
    path: 'user',
    component: AuthLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'validate', component: ValidateComponent },
      { path: 'changePassword', component: ChangePasswordComponent },
      {
        path: 'reset-password-request',
        component: ResetPasswordRequestComponent,
      },
      { path: 'reset-password', component: ResetPasswordComponent },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
