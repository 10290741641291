import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { DataExport } from '@pixacare/pxc-ts-core';
import { catchError, concat, concatMap, mergeMap, Observable, switchMap } from 'rxjs';
import { ExportDataHttpService } from 'src/app/modules/profile/export-data.http.service';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { exportsActions } from './exports.actions';

@Injectable()
export class ExportsEffects {

  getExports$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(exportsActions.getExports),
      mergeMap(() =>
        this.exportService.getDataExports()
          .pipe(
            switchMap((dataExports: DataExport[]) => [
              exportsActions.getExportsSuccess({
                dataExports,
              }),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  createExport$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(exportsActions.createExport),
      mergeMap(() =>
        this.exportService.createNewExport()
          .pipe(
            switchMap((newExport: DataExport) => [
              exportsActions.createExportSuccess({
                newExport,
              }),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  deleteExport$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(exportsActions.deleteExport),
      concatMap(({ exportId }) => concat(
        this.exportService.delete(exportId)
          .pipe(
            switchMap(() => [
              exportsActions.deleteExportSuccess(),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly exportService: ExportDataHttpService,
  ) {}

}
