import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'normalize',
  standalone: true,
})
export class NormalizePipe implements PipeTransform {

  transform(value: number | unknown): any {
    if (typeof value === 'number') {
      return value.toFixed(1).replace(/[.,]0*$/, '');
    }
    return value;
  }

}
