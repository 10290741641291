import { formatDate } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DashboardReportConfig, FlattenedTelemonitoring, Form, PagedResponse, PaginationQuery, Telemonitoring,
} from '@pixacare/pxc-ts-core';
import { CreateTelemonitoringRequest } from '@shared/models/telemonitoring/create-telemonitoring-request';
import { match } from '@shared/utils/utils';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TelemonitoringHttpService {

  constructor(
    private http: HttpClient,
  ) { }

  getTelemonitorings({
    query = new PaginationQuery({ orderBy: ['last_activity|desc'] }),
  } = {}): Observable<PagedResponse<FlattenedTelemonitoring>> {
    return this.http.get<PagedResponse<FlattenedTelemonitoring>>(`${environment.apiBaseUrl}/telemonitoring`,
      {
        headers: { 'X-version': '2.0' },
        params: {
          includeSequences: false,
          paginationOptions: String(query.encodedQuery || query.encode() || ''),
        },
      });
  }

  getTelemonitoring(telemonitoringId: number): Observable<FlattenedTelemonitoring> {
    return this.http.get<FlattenedTelemonitoring>(
      `${environment.apiBaseUrl}/telemonitoring/${telemonitoringId}`,
      {
        params: { flattened: 'true' },
      });
  }

  createTelemonitoring(payload: CreateTelemonitoringRequest): Observable<HttpResponse<Telemonitoring>> {
    return this.http.post<Telemonitoring>(
      `${environment.apiBaseUrl}/telemonitoring/`,
      payload,
      { observe: 'response' },
    );
  }

  patchTelemonitoring(
    telemonitoringId: number,
    params: {
      name?: string;
      message?: string;
      isActive?: boolean;
    },
  ): Observable<Telemonitoring> {

    return this.http.patch<Telemonitoring>(
      `${environment.apiBaseUrl}/telemonitoring/${telemonitoringId}`,
      params,
    );
  }

  deleteTelemonitoring(telemonitoringId: number): Observable<HttpResponse<unknown>> {
    return this.http.delete(
      `${environment.apiBaseUrl}/telemonitoring/${telemonitoringId}`,
      { observe: 'response' },
    );
  }

  getTutorial(
    tmId: number,
  ): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(
      `${environment.apiBaseUrl}/telemonitoring/${tmId}/Tutorial`,
      { observe: 'response', responseType: 'blob' as 'json' },
    );
  }

  getQrCode(
    tmId: number,
  ): Observable<string> {
    return this.http.get(
      `${environment.apiBaseUrl}/telemonitoring/${tmId}/qrcode`,
      { responseType: 'text' },
    );
  }

  sendTutorial(tmId: number, mailAddresses: string[]): Observable<HttpResponse<unknown>> {
    const body = { mailAddresses };
    return this.http.post(`${environment.apiBaseUrl}/telemonitoring/${tmId}/SendTutorial`,
      body,
      { observe: 'response' });
  }

  exportTelemonitoring(
    tmId: number,
    sequenceIds: number[],
    config: DashboardReportConfig = {},
  ): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(
      `${environment.apiBaseUrl}/telemonitoring/${tmId}/export`,
      {
        params: {
          sequenceIds,
          ...config,
        },
        observe: 'response',
        responseType: 'blob' as 'json',
      },
    );
  }

  unlinkSequence(tmId: number, clientCode: string, seqId: number): Observable<HttpResponse<unknown>> {
    return this.http.delete(
      `${environment.apiBaseUrl}/telemonitoring/${clientCode}/${tmId}/sequences/${seqId}`,
      { observe: 'response' },
    );
  }

  linkSequences(
    telemonitoringId: number,
    clientCode: string,
    sequenceIds: number[],
  ): Observable<HttpResponse<unknown>> {
    return this.http.post(
      `${environment.apiBaseUrl}/telemonitoring/${clientCode}/${telemonitoringId}/sequences`,
      { sequenceIds },
      { observe: 'response' },
    );
  }

  filter(telemonitoring: FlattenedTelemonitoring, value: string): boolean {
    const tags = [
      telemonitoring.patient?.firstName,
      telemonitoring.patient?.lastName,
      telemonitoring.patient?.businessIdentifier,
      formatDate(telemonitoring.telemonitoring.createdOn, 'short', 'fr'),
    ];
    return match(value, tags);
  }

  getPatientForm(telemonitoringId: number): Observable<Form> {
    return this.http.get<Form>(
      `${environment.apiBaseUrl}/telemonitoring/${telemonitoringId}/patientForm`,
    );
  }

  addTelemonitoringUser(telemonitoringId: number, userId: number): Observable<void> {
    return this.http.put<void>(
      `${environment.apiBaseUrl}/telemonitoring/${telemonitoringId}/users/${userId}`,
      {},
    );
  }

}
