import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { drawerReducer } from './drawer.reducer';
import { DRAWER_FEATURE_KEY } from './drawer.selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(DRAWER_FEATURE_KEY, drawerReducer),
  ],
  declarations: [],
})
export class DrawerStoreModule { }
