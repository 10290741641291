import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { Channel, ChannelMessage, ChannelMessageGroup, ChannelUser } from '@pixacare/pxc-ts-core';
import { TuiLet } from '@taiga-ui/cdk';
import { TuiButton, TuiScrollbar } from '@taiga-ui/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DialogService } from 'src/app/services/dialog.service';
import { SharedModule } from '../../shared/shared.module';
import { MessageBubbleComponent } from '../message-bubble/message-bubble.component';
import { MessageReadersListComponent } from '../message-readers-list/message-readers-list.component';

@Component({
  selector: 'pxc-message-list',
  standalone: true,
  templateUrl: './message-list.component.html',
  imports: [
    CommonModule,
    SharedModule,
    TuiScrollbar,
    MessageBubbleComponent,
    TuiButton,
    TuiLet,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageListComponent implements OnChanges {

  @Input() messages: { [messageId: string]: ChannelMessage };
  @Input() hasUnseenMessages: boolean;
  @Input() messageGroups: ChannelMessageGroup[] = [];
  @Input() channel: Channel;

  @Output() loadNext = new EventEmitter<void>();
  @Output() markMessagesAsSeen = new EventEmitter<void>();

  @ViewChild('scrollBar', { read: ElementRef })
  private readonly scrollBar?: ElementRef<HTMLElement>;

  topThreshold = 700;
  bottomThreshold = 200;
  lastMessageReaders: ChannelUser[] = [];

  readonly authenticationService = inject(AuthenticationService);
  private readonly dialogService = inject(DialogService);

  scrollToBottom(): void {
    setTimeout(() => {
      if (this.scrollBar) {
        this.scrollBar.nativeElement.scrollTop = this.scrollBar.nativeElement.scrollHeight;
        this.bottomReached();
      }
    }, 0);
  }

  offsetFromBottom(): number {
    if (this.scrollBar) {
      return Math.abs(this.scrollBar.nativeElement.scrollTop);
    }
    return 0;
  }

  topReached(): void {
    this.loadNext.emit();
  }

  bottomReached(): void {
    this.markMessagesAsSeen.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.messages) {
      if (this.offsetFromBottom() < this.bottomThreshold) {
        this.bottomReached();
      }
    }

    if (changes.channel) {
      this.lastMessageReaders = this.computeMessageReaders(this.channel, this.channel.lastMessage);
    }
  }

  trackByMessageId(index: number, messageId: string): string {
    return messageId;
  }

  trackByMessageGroup(index: number, messageGroup: ChannelMessageGroup): string {
    return messageGroup.messageIds.join('-');
  }

  computeMessageReaders(channel: Channel, message: ChannelMessage): ChannelUser[] {
    return Object.keys(channel.reads).reduce<ChannelUser[]>((acc, userId) => {
      if (channel.reads[userId] >= message.createdOn && message.senderId !== +userId) {
        const foundUser = channel.users.find((user) => user.id === +userId);
        if (foundUser) {
          acc.push(foundUser);
        }
      }
      return acc;
    }, []);
  }

  openReadersList(message: ChannelMessage): void {
    const readers = this.computeMessageReaders(this.channel, message);
    this.dialogService.openComponent(MessageReadersListComponent, {
      label: 'Lecteurs du message',
      data: {
        readers,
        message,
      },
    }).subscribe();
  }

}
