<!-- If nothing has loaded -->
@if ((galleryModel.items$ | async) === null) {
  <pxc-loader
    [dark]="true"
    class="loader"
    data-testid="items-loading"
  ></pxc-loader>
}

@if (galleryModel.items$ | async; as items) {
  <!-- SLIDER mode -->
  @if (galleryModel.config.mode === gMode.SLIDER) {
    <div class="gallery-slider thumb relative h-screen select-none">
      <div class="flex items-center justify-center overflow-hidden">
        <!-- Simple IMG -->
        @if (galleryModel.config.sourceType === gSourceType.STRING_SRC) {
          @for (item of items; track item; let idx = $index) {
            @if (isInLazyLoadRange(idx, items.length)) {
              <pxc-zoom-container
                [hidden]="galleryModel.displayIdx !== idx || !pictureLoadedIdx[idx]"
                [enableOverflow]="true"
              >
                <img
                  class="pointer-events-none mx-auto h-full object-contain"
                  [src]="item.pictureSrc"
                  [alt]="'image-' + idx"
                  (load)="onPictureLoad(idx)"
                  (error)="onPictureError(idx)"
                  [attr.data-testid]="'image-' + idx"
                />
              </pxc-zoom-container>
            }
          }
          <!-- Image Loader -->
          @if (!pictureLoadedIdx[galleryModel.displayIdx]) {
            <pxc-loader
              [dark]="true"
              data-testid="image-loader"
            ></pxc-loader>
          }
          <!-- InnerHtml element -->
        } @else {
          <div
            class="w-96"
            [innerHTML]="items[galleryModel.displayIdx].innerHtml"
          ></div>
        }
      </div>
      <!-- Thumbnails display -->
      @if (galleryModel.config.thumbnails) {
        <pxc-gallery-thumbnail-list
          [items]="items"
          [activeIdx]="galleryModel.displayIdx"
          (thumbnailClicked)="galleryModel.navTo($event)"
          class="my-2"
          data-testid="thumbnails"
        ></pxc-gallery-thumbnail-list>
      }
    </div>
  }
  <!-- COMPARISON Mode -->
  @if (galleryModel.config.mode === gMode.COMPARISON) {
    <div
      (wheel)="onWheel($event)"
      class="gallery-comparison relative flex h-screen w-screen select-none flex-row items-center overflow-y-auto"
      data-testid="comparison-scroll"
    >
      @for (item of items; track item; let idx = $index) {
        <div class="relative h-full min-w-[33%]">
          <pxc-zoom-container
            class="zoom-container min-w-[33%]"
            [zoomWheelCTRLBinding]="true"
          >
            <img
              class="pointer-events-none h-full max-h-[97vh] object-contain"
              [hidden]="!pictureLoadedIdx[idx]"
              [ngClass]="items.length < 3 ? 'image-' + items.length : 'image-infinite'"
              [src]="item.pictureSrc"
              [alt]="'image-' + idx"
              (load)="onPictureLoad(idx)"
              (error)="onPictureError(idx)"
              [attr.data-testid]="'comparison-' + idx"
            />
            @if (!pictureLoadedIdx[idx]) {
              <pxc-loader
                [dark]="true"
                [attr.data-testid]="'comparison-loading-' + idx"
              ></pxc-loader>
            }
          </pxc-zoom-container>
          <ng-template #insertionAnchors></ng-template>
        </div>
      }
    </div>
  }
}

<!-- Gallery navigation -->
@if (galleryModel.config.nav && galleryModel.config.mode !== gMode.COMPARISON) {
  <button
    tuiIconButton
    class="!absolute left-2 top-[50vh] -translate-y-1/2"
    (click)="galleryModel.navPrev()"
    data-testid="previous"
    appearance="glass"
    iconStart="@tui.chevron-left"
  ></button>
  <button
    tuiIconButton
    class="!absolute right-2 top-[50vh] -translate-y-1/2"
    (click)="galleryModel.navNext()"
    data-testid="next"
    appearance="glass"
    iconStart="@tui.chevron-right"
  ></button>
}

<!-- Action bar -->
<div class="absolute right-3 top-3 flex flex-col gap-1 sm:flex-row-reverse">
  <button
    tuiIconButton
    appearance="glass"
    iconStart="@tui.x"
    (click)="close()"
    data-testid="close-action"
  ></button>

  @for (action of galleryModel.actions; track action) {
    @if (!action.condition || action.condition()) {
      <button
        tuiIconButton
        appearance="glass"
        [iconStart]="action.icon"
        (click)="action.execute()"
        [attr.data-testid]="'action-' + action.id"
      ></button>
    }
  }
</div>

<ng-template #insertionAnchor></ng-template>
