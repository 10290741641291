import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { UserStatistics } from '@pixacare/pxc-ts-core';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { StatisticsHttpService } from 'src/app/services/http/statistics.http.service';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { statisticsActions } from './statistics.actions';

@Injectable()
export class StatisticsEffects {

  loadUserStatistics$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(statisticsActions.load),
      mergeMap(() =>
        this.statisticsService.loadUserStatistics()
          .pipe(
            switchMap((userStatistics: UserStatistics) =>
              of(
                statisticsActions.loadSuccess({
                  userStatistics,
                }),
              ),
            ),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly statisticsService: StatisticsHttpService) {}

}
