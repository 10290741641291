import { Share } from '@pixacare/pxc-ts-core';

export interface SharesState {
  shares: {
    [shareId: number]: Share;
  };
}

export const initialSharesState: SharesState = {
  shares: {},
};
