import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { LicenseState } from '@pixacare/pxc-ts-core';
import { TuiTable } from '@taiga-ui/addon-table';
import { TuiLet } from '@taiga-ui/cdk';
import { TuiScrollbar, TuiSurface } from '@taiga-ui/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MenuActionAdapterService } from 'src/app/services/menu-action-adapter.service';
import { ActionsByUserState } from 'src/app/shared/models/actions-by-user-state';
import { AdministrationUser } from 'src/app/shared/models/administration-user';
import { UserAdminState } from 'src/app/shared/models/enums/user-admin-state.enum';
import { ActionButton } from 'src/app/shared/models/menu-actions/action-buttons';
import { SortPipe } from 'src/app/shared/pipes/sort.pipe';
import { SharedModule } from '../../shared/shared.module';
import { UsersAdminSortService } from './users-admin-sort.service';

@Component({
  selector: 'pxc-users-administration-table',
  standalone: true,
  imports: [
    CommonModule,
    TuiTable,
    SharedModule,
    TuiLet,
    ScrollingModule,
    TuiScrollbar,
    SortPipe,
    TuiSurface,
  ],
  providers: [
    UsersAdminSortService,
    MenuActionAdapterService,
  ],
  templateUrl: './users-administration-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersAdministrationTableComponent implements OnInit {

  @Input() administrationUsers: AdministrationUser[];
  @Input() actionsByUserState: ActionsByUserState;

  @Output() adminChanged = new EventEmitter<AdministrationUser>();

  actionButtonsByState: Record<UserAdminState, ActionButton[]>;

  currentUserId: number;

  columns = ['name', 'state', 'admin', 'actions'];

  TABLE_ITEM_HEIGHT = 61;
  LicenseState = LicenseState;
  UserAdminState = UserAdminState;

  readonly usersAdminSortService = inject(UsersAdminSortService);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly menuActionsAdapter = inject(MenuActionAdapterService);

  ngOnInit(): void {
    this.actionButtonsByState = Object.entries(this.actionsByUserState).reduce((acc, [state, actions]) => ({
      ...acc,
      [state]: this.menuActionsAdapter.menuActionsToActionsButtons(actions),
    }), {} as Record<UserAdminState, ActionButton[]>);

    this.currentUserId = this.authenticationService.currentUser.id;
  }

  adminChange(user: AdministrationUser): void {
    this.adminChanged.emit(user);
  }

  trackByUser(index: number, item: AdministrationUser): string {
    return `${index}-${item.id}-${item.licenseState}-${item.state}-${item.isAdmin}`;
  }

}
