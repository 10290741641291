import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { SadmClient, SadmEntity } from '@pixacare/pxc-ts-core';
import { SadmDashboard } from '../../models/sadms/sadm-dashboard';
import { selectPatientState } from '../patients/patients.selectors';
import { selectSadmSequences } from '../sequences/sequences.selectors';
import { SadmState } from './sadm.state';

export const SADM_FEATURE_KEY = 'Sadm';

export const selectSadmState = createFeatureSelector<SadmState>(
  SADM_FEATURE_KEY,
);

export const selectSadmClient = (sadmClientId: number): MemoizedSelector<SadmState, SadmClient> => createSelector(
  selectSadmState,
  (state) => state.clients[sadmClientId],
);

const selectSadmClientFromEntity = (sadmEntityId: number): MemoizedSelector<SadmState, SadmClient> => createSelector(
  selectSadmState,
  (state) => state.clients[state.entities[sadmEntityId]?.sadmClientId],
);

export const selectSadmEntity = (sadmEntityId: number): MemoizedSelector<SadmState, SadmEntity> => createSelector(
  selectSadmState,
  selectSadmSequences(sadmEntityId),
  selectSadmClientFromEntity(sadmEntityId),
  selectPatientState,
  (state, sequences, sadmClient, patients) => {

    const sadmEntity = state.entities[sadmEntityId];

    if (!sadmEntity) {
      return null;
    }

    if (!sequences.data || !sadmEntity.sequencesIds) {
      return {
        ...sadmEntity,
        sadmClient,
      };
    }

    return {
      ...sadmEntity,
      sequences: sequences.data.filter((seq) => sadmEntity.sequencesIds.includes(seq.sequenceInstance.id)),
      patient: patients.patients?.[sadmEntity.clientCode]?.data[sadmEntity.patientId],
      sadmClient,
    };
  },
);

export const selectSadmClients = createSelector(
  selectSadmState,
  (state) => state.userSadmClients.map((sadmClientId) => state.clients[sadmClientId]),
);

export const selectSadmClientsWithEntities = (
  clientCode: string = null,
  patientId: number = null,
  isArchived: boolean = null,
): MemoizedSelector<SadmState, SadmClient[]> => createSelector(
  selectSadmState,
  selectSadmClients,
  selectPatientState,
  (state, clients, patients) => clients.map((sadmClient) => ({
    ...sadmClient,
    entities: Object.values(state.entities)
      .filter((entity) =>
        entity.sadmClientId === sadmClient.id
        && (!patientId || (entity.patientId === patientId && entity.clientCode === clientCode))
        && (isArchived === null
          || isArchived === (
            entity.isArchived || patients.patients?.[entity.clientCode]?.data[entity.patientId]?.isArchived
          )
        ),
      )
      .sort((sadmEntity1, sadmEntity2) => sadmEntity1.patientEntityIndex <= sadmEntity2.patientEntityIndex ? -1 : 1),
  } as SadmClient)),
);

export const selectSadmClientPatientEntities = (
  sadmClientId: number, clientCode: string, patientId: number,
): MemoizedSelector<SadmState, SadmEntity[]> => createSelector(
  selectSadmState,
  (state) => Object.values(state.entities).filter((entity) =>
    entity.sadmClientId === sadmClientId && entity.clientCode === clientCode && entity.patientId === patientId,
  ));

export const selectSadmDashboard = (sadmEntityId: number): MemoizedSelector<SadmState, SadmDashboard> => createSelector(
  selectSadmState,
  (state) => state.dashboards[sadmEntityId],
);

export const selectIsPatientSadmStateLoaded = (
  clientCode: string, patientId: number,
): MemoizedSelector<SadmState, boolean> => createSelector(
  selectSadmState,
  (state) => state.loadState[clientCode]?.[patientId] || false,
);
