import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ChannelUser } from '@pixacare/pxc-ts-core';
import { TuiAlertService } from '@taiga-ui/core';
import { catchError, of } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ChatHttpService } from '../chat.http.service';
import { ChannelCreateComponent } from './channel-create.component';

@Injectable()
export class ChannelCreateService {

  private readonly dialogService = inject(DialogService);
  private readonly alertService = inject(TuiAlertService);
  private readonly router = inject(Router);
  private readonly chatHttpService = inject(ChatHttpService);
  private readonly currentUser = inject(AuthenticationService).currentUser;

  openCreateChannelDialog(): void {
    this.dialogService.openComponent<ChannelCreateComponent, ChannelUser[]>(ChannelCreateComponent,
      {
        size: 'm',
        label: 'Nouvelle discussion',
      }).subscribe((users) => {
      if (!users) {
        return;
      }
      this.createChannel(users);
    });
  }

  createChannel(users: ChannelUser[]): void {
    this.chatHttpService.createChannel(users, null, this.currentUser).pipe(
      catchError((error: HttpErrorResponse) => {

        if (error.status === HttpStatusCode.Conflict) {
          const existingChannelId = error.error;
          this.redirectToChannel(existingChannelId);

          return of(null);
        }

        console.error('Error while creating channel: ', error);
        this.alertService.open(error.message, {
          appearance: 'error',
          label: 'Erreur de création de la discussion',
        }).subscribe();
        return of(null);
      }),
    ).subscribe(
      (channelId) => {
        if (channelId) {
          this.redirectToChannel(channelId);
        }
      },
    );
  }

  private redirectToChannel(channelId: string): void {
    this.router.navigate(['/dashboard/chat', channelId], {
      queryParamsHandling: 'merge',
    });
  }

}
