import { ClientUser } from '@pixacare/pxc-ts-core';

export interface UsersState {
  users: {
    [clientCode: string]: {
      [userId: number]: ClientUser;
    };
  };
}

export const initialUsersState: UsersState = {
  users: {},
};
