import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormDisplayQuestionComponent } from '@modules/forms/form-display-question/form-display-question.component';
import { AlertReportQuestion, AlertReportQuestionsByQuestionId } from '@pixacare/pxc-ts-core';
import { FirstLetterUppercasePipe } from '@shared/pipes/first-letter-uppercase.pipe';

@Component({
  selector: 'pxc-message-special-alert-indicators',
  standalone: true,
  imports: [
    CommonModule,
    FormDisplayQuestionComponent,
    FirstLetterUppercasePipe,
  ],
  templateUrl: './message-special-alert-indicators.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageSpecialAlertIndicatorsComponent implements OnChanges {

  @Input() alertReportQuestions: AlertReportQuestionsByQuestionId;

  questions: AlertReportQuestion[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.alertReportQuestions) {
      return;
    }

    this.questions = Object.values(this.alertReportQuestions).flat();
  }

}
