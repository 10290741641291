<pxc-sequence-picker
  [clientCode]="clientCode"
  [patientId]="patientId"
  [departmentIds]="departmentIds"
  [telemonitoringIds]="telemonitoringIds"
  [disabledSequenceIds]="disabledSequenceIds"
  [preselectedSequences]="preselectedSequences"
  [singleSelectMode]="singleSelectMode"
  [badgeContent]="badgeContent"
  [showTelemonitorings]="showTelemonitorings"
  [showSadms]="showSadms"
  [showPatient]="showPatient"
  (selectedAllSequencesChange)="selectedAllSequences = $event"
  (selectedSequencesChange)="selectedSequences = $event"
  (selectedSequenceIdsChange)="selectedSequenceIds = $event"
  (selectedSadmsChange)="selectedSadms = $event"
  (selectedTelemonitoringsChange)="selectedTelemonitorings = $event"
  data-testid="sequence-picker"
></pxc-sequence-picker>

<div class="mt-2 flex flex-wrap items-center justify-between gap-2">
  <button
    tuiButton
    type="button"
    appearance="outline"
    (click)="close()"
    class="max-md:w-full"
    data-testid="cancel"
  >
    Annuler
  </button>
  <button
    tuiButton
    type="button"
    class="max-md:order-first max-md:w-full"
    (click)="save()"
    data-testid="confirm"
  >
    {{ saveLabel }}
  </button>
</div>
