<div class="flex items-center gap-3">
  <pxc-filter-bar
    searchPlaceholder="Rechercher un groupe par nom, nombre de participants ..."
    data-testid="filter-bar"
  ></pxc-filter-bar>
  <button
    slot="actions"
    tuiButton
    iconStart="@tui.plus"
    (click)="createGroup()"
  >
    Ajouter
  </button>
</div>

@if (clientDepartments$ | async; as clientDepartments) {
  <table
    tuiSurface="outline"
    class="mt-3 w-full overflow-hidden rounded-xl"
    tuiTable
    [columns]="columns"
  >
    @if (clientDepartments.length > 0) {
      <thead>
        <tr tuiThGroup>
          <th
            *tuiHead="'name'"
            tuiTh
          >
            Nom du groupe
          </th>
          <th
            *tuiHead="'membersCount'"
            tuiTh
            [sorter]="clientDepartmentsAdminService.sortByMembersCount"
          >
            Membres
          </th>
          <th
            *tuiHead="'actions'"
            tuiTh
            [sorter]="null"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody tuiTbody>
        @for (department of clientDepartments | tuiTableSort; track department) {
          <tr tuiTr>
            <td
              class="min-w-72 max-w-72"
              tuiTd
              *tuiCell="'name'"
            >
              <p>{{ department.name }}</p>
            </td>
            <td
              class="min-w-48 max-w-48"
              tuiTd
              *tuiCell="'membersCount'"
            >
              <p>{{ department.membersCount }}</p>
            </td>
            <td
              class="min-w-48 max-w-48"
              tuiTd
              *tuiCell="'actions'"
            >
              <button
                tuiIconButton
                iconStart="@tui.trash"
                appearance="destructive"
                [disabled]="true"
                tuiHint="La suppression de groupe est désactivée. Veuillez contacter le support."
                tuiHintAppearance="error"
              ></button>
            </td>
          </tr>
        }
      </tbody>
    } @else {
      <p class="my-16 w-full text-center text-secondary">Aucun groupe trouvé.</p>
    }
  </table>
} @else {
  <pxc-loader
    data-testid="loading"
    class="my-5"
  >
    Chargement des groupes ...
  </pxc-loader>
}
