import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { PatientHttpService } from '@services/http/patient.http.service';
import { catchError, map, of } from 'rxjs';

export const redirectToPatientGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {

  const router = inject(Router);
  const patientService = inject(PatientHttpService);

  const { cc: clientCode, ipp: businessIdentifier } = route.queryParams;
  if (!clientCode || !businessIdentifier) {
    return of(true);
  }

  return patientService.search({ clientCode, businessIdentifier }).pipe(
    map((patients) => {
      const patientId = patients.length ? [patients[0].id] : [];
      const routeSegments = [
        '/dashboard/patients',
        ...patientId,
      ];
      router.navigate(routeSegments, {
        queryParams: { cc: clientCode, ipp: null },
        queryParamsHandling: 'merge',
      });
      return false;
    }),
    catchError((err) => {
      console.error(err);
      router.navigate(['/']);
      return of(false);
    }),
  );

};
