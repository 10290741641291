import { StudioCanvas } from '../canvas/studio-canvas';
import { Tool } from '../config/studio-enums';
import { studioTools } from '../config/studio-tools';
import { EventBus } from '../event-bus/event-bus';
import { EventSubject, EventTopic, Receiver } from '../event-bus/event-bus.interfaces';

export class GlobalKeystrokesHandler implements Receiver {

  eventBus: EventBus;
  canvas: StudioCanvas;

  constructor(eventBus: EventBus, canvas: StudioCanvas) {
    this.eventBus = eventBus;
    this.canvas = canvas;
    this.eventBus.subscribe(EventTopic.KEY_PRESSED, this);
  }

  receive(topic: string, subject: EventSubject): void {

    studioTools.forEach((tool) => {
      if (subject.keyPressed === tool.shortcut) {
        this.eventBus.publish(EventTopic.SWITCH_TOOL, { toolSwitchName: tool.commandName });
      }
    });

    switch (subject.keyPressed) {

      case 'Delete': {
        this.canvas.destroySelectedShapes();
        break;
      }

      case 'Enter': {
        if (subject.toolSwitchName === Tool.PREPARE_CROP) {
          this.eventBus.publish(EventTopic.EXECUTE_CROP);
        }
        break;
      }

      case 'Escape': {
        if (subject.toolSwitchName === Tool.PREPARE_CROP) {
          this.eventBus.publish(EventTopic.SWITCH_TOOL, { toolSwitchName: Tool.DEFAULT_TOOL });
        } else {
          this.eventBus.publish(EventTopic.CLOSE);
        }
        break;
      }

      default: {
        break;
      }
    }
  }

}
