import { Injectable } from '@angular/core';
import { FlattenedSequence } from '@pixacare/pxc-ts-core';
import { of } from 'rxjs';
import { SequenceGalleryService } from './sequence-gallery.service';

@Injectable({
  providedIn: 'root',
})
export class SharedSequenceGalleryService {

  constructor(
    private readonly sequenceGalleryService: SequenceGalleryService,
  ) { }

  openGallery(sequence: FlattenedSequence, openIdx: number): void {
    this.sequenceGalleryService.open(
      of(sequence),
      openIdx,
      () => {},
      { disableEditing: true },
    );
  }

}
