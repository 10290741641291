import { TitleCasePipe, UpperCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Patient } from '@pixacare/pxc-ts-core';

@Pipe({
  name: 'patientName',
  standalone: true,
})
export class PatientNamePipe implements PipeTransform {

  constructor(
    private readonly titleCasePipe: TitleCasePipe,
    private readonly upperCasePipe: UpperCasePipe,
  ) { }

  transform(patient: Patient): string {

    if (!patient) {
      return 'Anonyme';
    }

    return this.upperCasePipe.transform(patient.lastName) + ' ' + this.titleCasePipe.transform(patient.firstName);
  }

}
