<div
  class="group relative ml-4 h-full"
  [attr.tabindex]="isFocusable ? '0' : null"
>
  <pxc-card-details class="block h-full">
    <div class="ml-5 flex flex-col gap-1">
      <pxc-patient-data
        [patient]="patient"
        data-testid="patient-details"
        size="small"
      >
        <ng-content></ng-content>
        <ng-container slot="additional-data">
          <ng-content select="[slot=details]"></ng-content>
        </ng-container>
        <ng-content></ng-content>
        <ng-container additional-data>
          <ng-content select="[details]"></ng-content>
        </ng-container>
      </pxc-patient-data>
      @if (displayLastActivity) {
        <pxc-last-update-badge [lastUpdate]="patient.lastActivity"></pxc-last-update-badge>
      }
    </div>
    @if (actions) {
      <pxc-action-buttons
        dropdown-menu
        *showIfUserAuthorizedForPatient="patient"
        [entity]="patient"
        [actions]="actions"
        data-testid="menu-actions"
      ></pxc-action-buttons>
    }
  </pxc-card-details>

  <pxc-patient-avatar
    class="absolute -left-4 top-4"
    [patient]="patient"
    size="small"
    data-testid="patient-avatar"
  ></pxc-patient-avatar>
</div>
