import { moveItemInArray } from '@angular/cdk/drag-drop';
import { createReducer, on } from '@ngrx/store';
import { DrawerOpeningState } from '../../models/enums/drawer-state.enum';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { drawerActions } from './drawer.actions';
import { DrawerState, initialDrawerState } from './drawer.state';

export const drawerReducer = createReducer(
  initialDrawerState,

  on(lifeCycleActions.resetState, () => initialDrawerState),

  on(drawerActions.updateState, (state: DrawerState, { drawerState }) => ({
    ...state,
    drawer: {
      ...state.drawer,
      state: drawerState,
    },
  })),

  on(drawerActions.updateIsUserDragging, (state: DrawerState, { isUserDragging }) => ({
    ...state,
    isUserDragging,
  })),

  on(drawerActions.insertMedia, (state: DrawerState, { insertIdx, newMedia }) => {
    const medias = [...state.drawer.medias];
    medias.splice(insertIdx, 0, newMedia);

    return {
      ...state,
      drawer: {
        ...state.drawer,
        state: state.drawer.state === DrawerOpeningState.TINY ? DrawerOpeningState.COMPACT : state.drawer.state,
        medias,
      },
    };
  }),

  on(drawerActions.moveMedia, (state: DrawerState, { previousIdx, newIdx }) => {
    const medias = [...state.drawer.medias];
    moveItemInArray(medias, previousIdx, newIdx);

    return {
      ...state,
      drawer: {
        ...state.drawer,
        medias,
      },
    };
  }),

  on(drawerActions.deleteMedia, (state: DrawerState, { idx }) => {
    const medias = [...state.drawer.medias];
    medias.splice(idx, 1);

    return {
      ...state,
      drawer: {
        ...state.drawer,
        medias,
      },
    };
  }),

  on(drawerActions.deleteMedias, (state: DrawerState) => ({
    ...state,
    drawer: {
      ...state.drawer,
      state: state.drawer.state === DrawerOpeningState.COMPACT ? DrawerOpeningState.TINY : state.drawer.state,
      medias: [],
    },
  })),
);
