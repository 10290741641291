import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
  pure: true,
  standalone: true,
})
export class SortPipe implements PipeTransform {

  transform<T>(value: T[], sortFunction: (a: T, b: T) => number): T[] {
    return value.sort(sortFunction);
  }

}
