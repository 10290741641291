import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { FormDisplayComponent } from '@modules/forms/form-display/form-display.component';
import { FormSection } from '@modules/forms/form-display/form-section';
import { computeAlerts, CreatorType, FlattenedSequence, getReport } from '@pixacare/pxc-ts-core';
import { DialogService } from '@services/dialog.service';
import { SequenceHttpService } from '@services/http/sequence.http.service';
import { TelemonitoringHttpService } from '@services/http/telemonitoring.http.service';
import { combineLatest, map, switchMap } from 'rxjs';
import { SequenceContext } from 'src/app/shared/models/enums/sequence-context.enum';
import { MediaClickedOutput } from 'src/app/shared/models/media-clicked-output';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';

@Component({
  selector: 'pxc-sequence-list',
  templateUrl: './sequence-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequenceListComponent {

  @Input() sequences: FlattenedSequence[];
  @Input() actions: MenuAction<FlattenedSequence>[] = null;
  @Input() context: SequenceContext = SequenceContext.SEQUENCE;
  @Input() isNextPageLoading = false;
  @Input() isPreviousPageLoading = false;
  @Input() showPatient = true;
  @Input() patientRedirect = true;

  @Output() loadPrevious: EventEmitter<null> = new EventEmitter();
  @Output() loadNext: EventEmitter<null> = new EventEmitter();
  @Output() sequenceMediaClicked: EventEmitter<MediaClickedOutput> = new EventEmitter();

  SequenceContext = SequenceContext;
  CreatorType = CreatorType;

  private dialogService = inject(DialogService);
  private sequenceService = inject(SequenceHttpService);
  private telemonitoringService = inject(TelemonitoringHttpService);

  mediaClicked($event: MediaClickedOutput): void {
    this.sequenceMediaClicked.emit($event);
  }

  trackBySequenceId(index: number, sequence: FlattenedSequence): number {
    return sequence.sequenceInstance.id * sequence.sequenceInstance.createdOn.getTime();
  }

  openPatientFormAnswer(sequence: FlattenedSequence): void {
    combineLatest([
      this.telemonitoringService.getPatientForm(
        sequence.sequenceInstance.telemonitoringId,
      ),
      this.sequenceService.getPatientFormAnswer({
        clientCode: sequence.clientCode, sequenceId:
        sequence.sequenceInstance.id },
      ),
    ]).pipe(
      map(([patientForm, formAnswers]) => ({
        sections: getReport(patientForm, formAnswers).sections as FormSection[],
        alerts: computeAlerts(formAnswers, patientForm),
      })),
      switchMap((data) =>
        this.dialogService.openComponentWithCloseConfirmation(FormDisplayComponent, {
          label: 'Questionnaire patient',
          size: 'm',
          data,
        }),
      ),
    ).subscribe();
  }

}
