import { createActionGroup, props } from '@ngrx/store';
import { NotifyErrorPayload, NotifyPayload, NotifyStatusPayload } from './notifications.payload';

const NOTIFICATION_KEY = 'Notification';

export const notificationsActions = createActionGroup({
  source: NOTIFICATION_KEY,
  events: {
    Success: props<NotifyStatusPayload>(),
    Info: props<NotifyStatusPayload>(),
    Error: props<NotifyErrorPayload>(),
    Notify: props<NotifyPayload>(),
  },
});
