import { Department } from '../../models/base/department';

export interface DepartmentsState {
  departments: {
    [clientCode: string]: {
      [departmentId: number]: Department;
    };
  };
}

export const initialDepartmentsState: DepartmentsState = {
  departments: {},
};
