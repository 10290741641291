import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { FlattenedSequence, PagedResponse, Share } from '@pixacare/pxc-ts-core';
import { concat, Observable, of } from 'rxjs';
import { catchError, concatMap, mergeMap, switchMap } from 'rxjs/operators';
import { ShareHttpService } from 'src/app/services/http/share.http.service';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { sequencesActions } from '../sequences/sequences.actions';
import { sharesActions } from './shares.actions';

@Injectable()
export class SharesEffects {

  getSharesToCurrentUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sharesActions.getSharesToCurrentUser),
      concatMap(() =>
        concat(
          of(sequencesActions.resetSequencesState()),
          this.shareService.getSharesToCurrentUser()
            .pipe(
              switchMap((shares: Share[]) => [
                sharesActions.getSharesToCurrentUserSuccess({ shares }),
              ]),
              catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
            ),
        ),
      ),
    ),
  );

  getSharedSequences$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sharesActions.getSharedSequences),
      mergeMap(({ shares }) =>
        this.shareService.getSharedSequences(shares)
          .pipe(
            switchMap((sequences) => {
              const payload = {
                clientCode: null,
                collection: {
                  data: sequences,
                } as unknown as PagedResponse<FlattenedSequence>,
              };
              return [
                sequencesActions.loadSequencesEntities(payload),
                sequencesActions.getSequencesSuccess(payload),
              ];
            }),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly shareService: ShareHttpService) {}

}
