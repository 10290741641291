import { EventBusInterface, EventSubject, Receiver } from './event-bus.interfaces';

export class EventBus implements EventBusInterface {

  private receivers: {
    [key: string]: Receiver[];
  } = {};

  async publish(topic: string, subject: EventSubject = {}): Promise<void> {
    const receivers = this.getTopicReceivers(topic);

    receivers.forEach(
      (receiver) => Promise.resolve(receiver.receive(topic, subject)),
    );
  }

  subscribe(topic: string, receiver: Receiver): void {
    if (!this.receivers[topic]) {
      this.receivers[topic] = [];
    }
    this.receivers[topic].push(receiver);
  }

  unsubscribe(topic: string, receiver: Receiver): void {
    if (!this.receivers[topic]) {
      return;
    }

    this.receivers[topic] = this.receivers[topic].filter((item) => item !== receiver);
  }

  getTopicReceivers(topic: string): Receiver[] {
    if (!this.receivers[topic]) {
      return [];
    }
    return this.receivers[topic];
  }

}
