import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharesEffects } from './shares.effects';
import { sharesReducer } from './shares.reducer';
import { SHARES_FEATURE_KEY } from './shares.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(SHARES_FEATURE_KEY, sharesReducer),
    EffectsModule.forFeature([SharesEffects]),
  ],
  declarations: [],
})
export class SharesStoreModule { }
