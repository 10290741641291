import { createReducer, on } from '@ngrx/store';
import { ClientUser } from '@pixacare/pxc-ts-core';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { usersActions } from './users.actions';
import { initialUsersState } from './users.state';

const reduceClientUsersById = (clientUsers: ClientUser[]) => clientUsers.reduce((acc, clientUser) => ({
  ...acc,
  [clientUser.id]: clientUser,
}), {});

export const usersReducer = createReducer(
  initialUsersState,

  on(lifeCycleActions.resetState, () => initialUsersState),

  on(usersActions.getUsersSuccess, (state, { clientCode, clientUsers }) => ({
    ...state,
    users: {
      ...state.users,
      [clientCode]: reduceClientUsersById(clientUsers),
    },
  })),

  on(
    usersActions.addUsersSuccess,
    (state, { clientCode, clientUsers }) => ({
      ...state,
      users: {
        ...state.users,
        [clientCode]: {
          ...state.users[clientCode],
          ...reduceClientUsersById(clientUsers),
        },
      },
    }),
  ),

  on(
    usersActions.updateUsers,
    (state, { clientCode, users }) => ({
      ...state,
      users: {
        ...state.users,
        [clientCode]: {
          ...state.users[clientCode],
          ...users.reduce((acc, user) => ({
            ...acc,
            [user.id]: {
              ...(state.users[clientCode][user.id] || {
                isInvited: false,
                mailAddress: '',
                licenseState: null,
                departmentIds: [],
              }),
              ...user,
            },
          }), {}),
        },
      },
    }),
  ),

  on(
    usersActions.updateUserLicenseStateSuccess,
    (state, { clientCode, userId, licenseState }) => ({
      ...state,
      users: {
        ...state.users,
        [clientCode]: {
          ...state.users[clientCode],
          [userId]: {
            ...state.users[clientCode][userId],
            licenseState,
          },
        },
      },
    }),
  ),

);
