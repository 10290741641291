<div
  class="mb-0.5 px-4 py-1.5 transition hover:rounded"
  [ngClass]="{
    'hover:bg-neutral-1/50': indicatorLevel !== IndicatorLevel.MODERATE && indicatorLevel !== IndicatorLevel.SEVERE,
    'bg-status-warning-pale hover:bg-status-warning-pale-hover': indicatorLevel === IndicatorLevel.MODERATE,
    'bg-status-negative-pale hover:bg-status-negative-pale-hover': indicatorLevel === IndicatorLevel.SEVERE,
  }"
>
  <pxc-tile>
    @if (showAlerts) {
      <pxc-alert-indicator
        leading
        [level]="indicatorLevel"
      ></pxc-alert-indicator>
    }
    <p subtitle>
      {{ label }}
    </p>
    @if (!shouldWrap) {
      <p
        trailing
        class="mr-2 min-w-24 text-right"
      >
        <ng-container *ngTemplateOutlet="answer"></ng-container>
      </p>
    }
  </pxc-tile>
  @if (shouldWrap) {
    <div
      class="mt-0.5"
      [ngClass]="{ 'ml-8': showAlerts }"
    >
      <ng-container *ngTemplateOutlet="answer"></ng-container>
    </div>
  }
</div>

<ng-template #answer>
  <span
    class="whitespace-pre-line"
    [ngClass]="{
      'text-status-warning': indicatorLevel === IndicatorLevel.MODERATE,
      'text-status-negative': indicatorLevel === IndicatorLevel.SEVERE,
    }"
  >
    {{ value }}
  </span>
</ng-template>
