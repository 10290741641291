import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TelemonitoringsEffects } from './telemonitorings.effects';
import { telemonitoringsReducer } from './telemonitorings.reducer';
import { TELEMONITORINGS_FEATURE_KEY } from './telemonitorings.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(TELEMONITORINGS_FEATURE_KEY, telemonitoringsReducer),
    EffectsModule.forFeature([TelemonitoringsEffects]),
  ],
  declarations: [],
})
export class TelemonitoringsStoreModule { }
