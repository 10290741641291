import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

export const isValidUserGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authenticationService = inject(AuthenticationService);
  const router = inject(Router);

  if (!!authenticationService.currentUser && authenticationService.currentUser.isValid) {
    return true;
  }

  router.navigate(['/user/validate'], { queryParams: { intentUrl: state.url } });
  return false;
};
