import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetMediaRequest, Media, PictureAnnotations } from '@pixacare/pxc-ts-core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MediaHttpService {

  constructor(private readonly http: HttpClient) {}

  getMedia(clientCode: string, mediaId: number, {
    original = false,
    fillFullSizeUri = false,
    fillThumbnailUri = false,
    annotations = false,
  }: GetMediaRequest): Observable<Media> {
    return this.http
      .get<Media>(
      `${environment.apiBaseUrl}/Media/${clientCode}/${mediaId}`,
      {
        params: {
          original: String(original),
          fillFullSizeUri: String(fillFullSizeUri),
          fillThumbnailUri: String(fillThumbnailUri),
          annotations: String(annotations),
        },
      },
    );
  }

  annotateMedia(clientCode: string, mediaId: number, filename: string, annotations: PictureAnnotations,
    blob: Blob, duplicate: boolean = false): Observable<Media> {

    const data = new FormData();

    data.append('annotedImage', blob, filename);

    data.append('annotations', JSON.stringify({
      ...annotations,
      mediaGuid: duplicate ? null : filename.split('.')[0],
    }),
    );

    return this.http.put<Media>(
      `${environment.apiBaseUrl}/Media/${clientCode}/${mediaId}/Annotate`,
      data,
      {
        params: {
          duplicate: String(duplicate),
        },
      },
    )
      .pipe(
        catchError((error) => throwError(() => error.status),
        ),
      );
  }

  readBlobAsB64Async(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result.toString());
      };

      reader.onerror = reject;

      reader.readAsDataURL(blob);
    });
  }

}
