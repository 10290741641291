import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';
import { Patient } from '@pixacare/pxc-ts-core';
import { HandleDirection } from 'src/app/shared/models/enums/handle-direction.enum';

@Component({
  selector: 'pxc-sequence-preview-drawer',
  templateUrl: './sequence-preview-drawer.component.html',
  styles: [`
  :host {
      position: absolute;
      top: 15px;
      left: 15px;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequencePreviewDrawerComponent {

  @Input() patient: Patient;
  @Input() date: Date;

  HandleDirection = HandleDirection;

  isOpen = true;

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

}
