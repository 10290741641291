export enum FreeDrawMode {
  BRUSH = 'source-over',
  ERASER = 'destination-out',
}

export enum Tool {
  DEFAULT_TOOL = 'shape-selection',
  SHAPE_SELECTION = 'shape-selection',
  FREE_DRAW = 'free-draw',
  ERASER = 'eraser',
  TEXT = 'text',
  DRAW_RECTANGLE = 'draw-rectangle',
  DRAW_ELLIPSE = 'draw-ellipse',
  DRAW_LINE = 'draw-line',
  DRAW_ARROW = 'draw-arrow',
  ROTATE = 'rotate',
  FLIP = 'flip',
  PREPARE_CROP = 'prepare-crop',
  VALIDATE_CROP = 'validate-crop',
  DOWNLOAD = 'download',
  SET_BACKGROUND = 'set-background',
  SAVE_PICTURE = 'save-picture',
  IMPORT_ANNOTATIONS = 'import-annotations',
}

export enum StudioShape {
  SHAPE_NAME = 'shape',
}
