<tui-select
  (ngModelChange)="onSelectChange($event)"
  [ngModel]="value"
  [tuiTextfieldLabelOutside]="true"
  [disabled]="disabled"
  [valueContent]="preview"
  data-testid="form-select"
  ngDefaultControl
>
  {{ placeholder }}
  <tui-data-list-wrapper
    *tuiDataList
    [itemContent]="listItem"
    [items]="items$ | async"
  ></tui-data-list-wrapper>
</tui-select>

<ng-template
  #preview
  let-item
>
  <span>{{ item.form?.title }}</span>
</ng-template>

<ng-template
  #listItem
  let-item
>
  <div>
    <p>{{ item.form?.title }}</p>
    @if (item.description) {
      <p class="text-sm text-secondary">{{ item?.description }}</p>
    }
  </div>
</ng-template>
