<div class="flex flex-col gap-2">
  @if (showFavoriteLabels && (favoriteLabels$ | async); as favoriteLabels) {
    <div
      class="mt-2 flex gap-2"
      data-testid="favorite-labels"
    >
      @for (favoriteLabel of favoriteLabels; track favoriteLabel) {
        <pxc-label
          class="label"
          [label]="favoriteLabel.value"
          [clickable]="true"
          (click)="addLabel(favoriteLabel.display)"
          [attr.data-testid]="'label-' + favoriteLabel.display"
        ></pxc-label>
      }
    </div>
  }

  <tui-input-tag
    [tuiTextfieldLabelOutside]="true"
    [ngModel]="value"
    (ngModelChange)="onLabelChange($event)"
    (searchChange)="onSearchChange($event)"
    [maxLength]="255"
    uniqueTags="true"
    data-testid="label-input"
    ngDefaultControl
  >
    Ajoutez des mots clés
    @if (autocomplete) {
      <tui-data-list-wrapper
        *tuiDataList
        [items]="autoCompleteLabels$ | async"
        [emptyContent]="emptyState"
      ></tui-data-list-wrapper>
    }
  </tui-input-tag>

  <ng-template #emptyState>
    <p class="font-bold text-secondary">
      Aucun résultat.
      <span class="font-normal text-tertiary">Appuyez sur entrée pour ajouter le mot clé.</span>
    </p>
  </ng-template>
</div>
