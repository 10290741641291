<div class="flex h-screen w-screen items-center justify-center bg-blue-50">
  <div
    class="absolute h-full max-h-80 w-64 -translate-x-40 -translate-y-48 rounded-full bg-pixablue-2/30 blur-3xl"
  ></div>
  <div class="absolute h-full max-h-80 w-64 translate-x-48 rounded-full bg-pixapurple-2/30 blur-3xl"></div>
  <div class="absolute h-64 w-full max-w-sm translate-y-40 rounded-full bg-pixared-2/20 blur-3xl"></div>

  <div class="container z-10 flex justify-center">
    <div
      tuiCardLarge
      tuiSurface="outline"
      class="!bg-base/90 backdrop-blur-xl animate-in fade-in slide-in-from-top-1 md:w-full md:max-w-lg"
    >
      <div class="flex flex-col gap-2 p-3">
        <img
          src="https://cdn.pixacare.com/logo/left-transparent.svg"
          data-testid="logo"
          class="w-48"
          alt="Pixacare Logo"
        />
        <router-outlet data-testid="content"></router-outlet>
      </div>
    </div>
  </div>
</div>
