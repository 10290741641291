@if ((passwordResetRequestSent$ | async) === false) {
  <div>
    <div class="flex flex-col gap-2 py-2">
      <h2 tuiTitle="m">
        Demande de réinitialisation
        <p
          tuiSubtitle
          class="max-w-prose"
        >
          Merci de renseigner votre adresse e-mail, nous vous enverrons un message contenant les instructions pour
          réinitialiser votre mot de passe.
        </p>
      </h2>
    </div>
    <form
      [formGroup]="passwordResetRequestForm"
      (ngSubmit)="requestPasswordReset()"
      data-testid="reset-password-request-form"
      class="mt-6 flex flex-col gap-6"
    >
      <div>
        <tui-input
          autofocus
          formControlName="emailInput"
          [disabled]="(actionOnGoing$ | async) ? '' : null"
          tuiTextfieldIconLeft="@tui.mail"
        >
          Adresse E-Mail
        </tui-input>
        <tui-error
          formControlName="emailInput"
          [error]="[] | tuiFieldError | async"
        />
      </div>
      <div class="flex flex-col gap-2">
        <button
          tuiButton
          class="w-full"
          type="submit"
          [disabled]="actionOnGoing$ | async"
          appearance="primary"
        >
          Envoyer un lien
        </button>
        <button
          tuiButton
          appearance="outline"
          class="w-full"
          routerLink="../login"
        >
          Revenir à la connexion
        </button>
      </div>
    </form>
  </div>
} @else {
  <p class="mx-auto max-w-prose text-center">
    Si cette adresse e-mail est associée à un de nos utilisateurs, nous enverrons une procédure de réinitialisation du
    mot de passe.
  </p>
  <button
    tuiButton
    appearance="primary"
    class="mt-3 w-full"
    routerLink="../login"
  >
    Revenir à la connexion
  </button>
}
