import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'pxc-client-image',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './client-image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientImageComponent {

  @Input() imagePath: string;

  cdnClientBaseUrl: string = environment.cdn.clientBaseUrl;
  defaultClientImagePath = 'default.png';

}
