<tui-elastic-container *tuiLet="channels.length > 0 || users.length > 0 || mailAddresses.length > 0 as hasReceivers">
  <tui-stepper
    [(activeItemIndex)]="currentPage"
    class="mb-6 justify-start"
  >
    <button
      tuiStep
      [stepState]="
        currentPage !== SharePickerPage.SELECT_RECEIVERS && !hasReceivers ? 'error' : !hasReceivers ? 'normal' : 'pass'
      "
    >
      Destinataires
    </button>
    <button tuiStep>Message</button>
  </tui-stepper>

  @if (currentPage === SharePickerPage.SELECT_RECEIVERS) {
    <pxc-receivers-picker
      [(selectedChannels)]="channels"
      [(selectedUsers)]="users"
      [(selectedMailAddresses)]="mailAddresses"
    ></pxc-receivers-picker>
  }

  @if (currentPage === SharePickerPage.MESSAGE) {
    <div
      class="flex flex-col gap-8 pb-8"
      data-testid="summary"
    >
      <div>
        <p class="mb-2">Vérifiez les informations qui seront partagées</p>
        <ng-content></ng-content>
      </div>
      <div>
        <p class="mb-2 text-sm">Destinataires</p>
        @if (hasReceivers) {
          <pxc-receiver-list
            [channels]="channels"
            [users]="users"
            [mailAddresses]="mailAddresses"
            [removable]="false"
          ></pxc-receiver-list>
        } @else {
          <pxc-status
            [state]="Status.BASIC"
            icon="@tui.user-x"
            [showTitle]="false"
            description="Vous devez sélectionner au moins un destinataire !"
          ></pxc-status>
        }
      </div>
      <label tuiLabel>
        Ajouter un message
        <tui-textarea
          class="flex-1"
          [formControl]="shareText"
          [tuiTextfieldLabelOutside]="true"
        >
          Entrez un message
        </tui-textarea>
      </label>
    </div>
  }

  <div class="flex justify-between gap-2">
    <button
      tuiButton
      appearance="outline"
      (click)="currentPage === 0 ? close() : previous()"
      data-testid="cancel-page-button"
    >
      {{ currentPage === 0 ? 'Annuler' : 'Précédent' }}
    </button>
    <button
      tuiButton
      (click)="next()"
      [disabled]="!hasReceivers"
      data-testid="next-page-button"
    >
      {{ currentPage === SharePickerPage.MESSAGE ? 'Confirmer' : 'Suivant' }}
    </button>
  </div>
</tui-elastic-container>
