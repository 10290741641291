import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'CountByKey',
  pure: true,
  standalone: true,
})
export class CountByKeyPipe implements PipeTransform {

  transform<T>(array: T[], key: string): Record<string, number> {
    return array.reduce((acc, elem) => (elem[key] !== undefined ? {
      ...acc,
      [elem[key]]: (acc[elem[key]] || 0) + 1,
    } : { ...acc }), {});
  }

}
