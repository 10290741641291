import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetterUppercase',
  pure: true,
  standalone: true,
})
export class FirstLetterUppercasePipe implements PipeTransform {

  transform(value: string): unknown {
    if (!value) {
      return value;
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

}
