<p class="text-sm text-secondary">Emplacement</p>
<div [formGroup]="form">
  <pxc-department-picker
    [isClientChoiceDisabled]="true"
    [clientCode]="clientCode"
    [departmentIds]="departmentIds"
    (departmentIdsChange)="setDepartmentIds($event)"
    data-testid="department-picker"
  >
    <tui-error
      formControlName="departmentIds"
      [error]="[] | tuiFieldError | async"
    ></tui-error>
  </pxc-department-picker>
</div>
<p class="mt-4 text-sm text-secondary">
  Les membres des groupes sélectionnés pourront consulter le télésuivi et rejoindre la conversation à tout moment.
</p>
