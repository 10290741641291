import { Pipe, type PipeTransform } from '@angular/core';
import { ChannelUser } from '@pixacare/pxc-ts-core';

@Pipe({
  name: 'listMessageReaders',
  standalone: true,
})
export class ListMessageReadersPipe implements PipeTransform {

  MAX_READERS = 3;

  transform(readers: ChannelUser[], channelUsersCount: number, currentUserId: number): string {
    if (readers.length === 0) {
      return 'Envoyé';
    }
    if (readers.length === 1 && (readers[0].id === currentUserId || channelUsersCount === 2)) {
      return  'Lu';
    }
    if (readers.length === channelUsersCount - 1) {
      return 'Lu par tous';
    }
    const readerNames = readers
      .filter((reader) => reader.id !== currentUserId)
      .slice(0, this.MAX_READERS)
      .map((reader) => reader.firstName).join(', ');
    const remainingReadersCount = Math.max(readers.length - this.MAX_READERS, 0);

    if (remainingReadersCount === 0) {
      return `Lu par ${readerNames}`;
    }

    return `Lu par ${readerNames} et ${remainingReadersCount} autres`;
  }

}
