import { Pipe, type PipeTransform } from '@angular/core';
import { Channel } from '@pixacare/pxc-ts-core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Pipe({
  name: 'channelName',
  standalone: true,
})
export class ChannelNamePipe implements PipeTransform {

  constructor(
    private authenticationService: AuthenticationService,
  ) { }

  transform(channel: Channel): string {

    if (!channel) {
      return '';
    }

    if (channel.name) {
      return channel.name;
    }

    const users = channel.users?.filter((user) => user.id !== this.authenticationService.currentUser.id);

    if (users.length === 1) {
      return users[0].firstName + ' ' + users[0].lastName.toUpperCase();
    }

    return users.map(
      (user) => user.firstName.charAt(0).toUpperCase() + '. ' + user.lastName.toUpperCase(),
    ).join(', ');
  }

}
