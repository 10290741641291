<div
  class="z-20 flex h-14 items-center gap-1 px-2 max-md:border-b max-md:border-b-slate-200 max-md:bg-base/90 max-md:backdrop-blur md:pointer-events-none"
>
  <button
    type="button"
    tuiIconButton
    iconStart="@tui.menu"
    appearance="flat"
    (click)="showSidebar()"
    class="pointer-events-auto md:!hidden"
  ></button>

  <button
    type="button"
    tuiIconButton
    iconStart="@tui.menu"
    appearance="flat"
    (click)="toggleSidebarCollapsed()"
    class="pointer-events-auto max-md:!hidden"
  ></button>

  <a
    tuiIconButton
    appearance="flat"
    routerLink="/dashboard/home"
    queryParamsHandling="merge"
    class="pointer-events-auto md:!hidden"
  >
    <img
      src="https://cdn.pixacare.com/logo/mire.svg"
      class="p-1"
    />
  </a>

  <button
    tuiIconButton
    iconStart="@tui.circle-help"
    appearance="flat"
    tuiDropdownOpen
    [tuiDropdown]="helpMenuActions"
    class="pointer-events-auto ml-auto"
  ></button>

  <ng-template #helpMenuActions>
    <pxc-menu-actions [actions]="helpActions"></pxc-menu-actions>
  </ng-template>
</div>
