import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IndicatorLevel } from '@pixacare/pxc-ts-core';

@Component({
  selector: 'pxc-alert-indicator',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './alert-indicator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertIndicatorComponent {

  @Input() level?: IndicatorLevel;

  IndicatorLevel = IndicatorLevel;

}
