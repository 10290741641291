<tui-elastic-container
  *tuiLet="{
    hasPatient:
      selectedPatient?.businessIdentifier ||
      selectedPatient?.firstName ||
      selectedPatient?.lastName ||
      selectedPatient?.birthDate,
    isSecondStepDisabled: !telemonitoringId && departmentIds.length === 0 && clientCode !== 'common',
    isThirdStepDisabled: (isPatientEditFormValid$ | async) === false,
  } as data"
>
  <pxc-paywall-alert></pxc-paywall-alert>

  <div
    class="flex flex-col gap-6"
    *showIfStorageSpaceAvailable="null"
  >
    <tui-stepper
      [(activeItemIndex)]="pageIndex"
      class="mb-2 justify-between"
    >
      <button tuiStep>Informations</button>
      <button
        tuiStep
        [disabled]="data.isSecondStepDisabled"
      >
        Patient
      </button>
      <button
        tuiStep
        [disabled]="data.isSecondStepDisabled || data.isThirdStepDisabled"
      >
        Photographies
      </button>
    </tui-stepper>
    @if (pageIndex === SequenceCreateStep.INFORMATION) {
      <div class="flex flex-col gap-6">
        <label tuiLabel>
          Date
          <tui-input-date-time
            [(ngModel)]="sequenceDataDate"
            data-testid="input-date"
            class="flex-1"
            name="sequenceDate"
            ngDefaultControl
          >
            Entrez une date et une heure
            <input
              tuiTextfieldLegacy
              placeholder="18/04/2019 14:22"
            />
          </tui-input-date-time>
        </label>
        <div>
          <label tuiTitle="s">Emplacement</label>
          @if (!telemonitoringId) {
            <pxc-department-picker
              [(clientCode)]="clientCode"
              [(departmentIds)]="departmentIds"
              data-testid="department-picker"
            >
              @if (!telemonitoringId && departmentIds.length === 0 && clientCode !== 'common') {
                <p
                  class="text-center text-status-negative max-md:order-last"
                  data-testid="no-department-selected-message"
                  error
                >
                  Veuillez sélectionner au moins un groupe
                </p>
              }
            </pxc-department-picker>
          } @else {
            <tui-notification
              size="m"
              appearance="info"
              icon="@tui.info"
            >
              <div>
                <p class="font-bold">Vous créez une séquence rattachée à un télésuivi.</p>
                <p>Elle sera partagée avec les mêmes groupes que son télésuivi.</p>
              </div>
            </tui-notification>
          }
        </div>
        <div class="mt-2 flex gap-2 max-md:flex-col-reverse md:items-center">
          <button
            tuiButton
            appearance="outline"
            (click)="cancel()"
            class="md:mr-auto"
          >
            Annuler
          </button>
          <button
            tuiButton
            (click)="next()"
            data-testid="next-page"
            [disabled]="data.isSecondStepDisabled"
          >
            Suivant
          </button>
        </div>
      </div>
    }
    @if (pageIndex === SequenceCreateStep.PATIENT) {
      <section class="flex flex-col gap-2">
        <h6 class="font-medium">Informations patient</h6>
        <pxc-patient-edit
          [(patient)]="selectedPatient"
          [clientCode]="clientCode"
          [displayActionButtons]="false"
          (formValidityUpdate)="isPatientEditFormValid$.next($event)"
          data-testid="input-patient"
          class="px-px"
        ></pxc-patient-edit>
        <div class="mt-2 flex justify-between gap-2 max-md:flex-col-reverse">
          <button
            tuiButton
            appearance="outline"
            (click)="previous()"
          >
            Précédent
          </button>
          <button
            tuiButton
            [disabled]="data.isSecondStepDisabled || data.isThirdStepDisabled"
            data-testid="next-page"
            (click)="next()"
          >
            {{ data.hasPatient ? 'Suivant' : 'Ignorer' }}
          </button>
        </div>
      </section>
    }
    @if (pageIndex === SequenceCreateStep.PICTURES) {
      <div
        class="flex flex-col gap-6"
        data-testid="step-pictures"
      >
        <section class="flex flex-col gap-2">
          <h6 class="font-medium">Photographies</h6>
          <pxc-image-input
            [(ngModel)]="sequenceMedias"
            [accept]="dropZoneAcceptValidator"
            data-testid="input-medias"
            ngDefaultControl
          ></pxc-image-input>
        </section>
        @if (data.hasPatient && (displayAnalysis$ | async)) {
          <section class="flex flex-col gap-2">
            <h6 class="font-medium">Questionnaire</h6>
            <pxc-sadm-form-input
              [clientCode]="clientCode"
              [patientId]="selectedPatient.id"
              [(sadmEntityId)]="selectedSadmEntityId"
              [(sadmEntity)]="createdSadmEntity"
              [(analysisFormAnswers)]="createdAnalysisFormAnswers"
              [(protocolFormAnswers)]="createdProtocolFormAnswers"
            ></pxc-sadm-form-input>
          </section>
        }
        <section class="flex flex-col gap-2">
          <h6 class="font-medium">Mots clés</h6>
          <pxc-select-label
            [(selectedLabels)]="selectedLabels"
            [showFavoriteLabels]="true"
            data-testid="input-labels"
          ></pxc-select-label>
        </section>
        <label tuiLabel>
          Description
          <tui-textarea
            [(ngModel)]="description"
            [expandable]="true"
            maxlength="65535"
            data-testid="input-description"
            name="sequenceDescription"
            ngDefaultControl
          >
            Ajoutez une description
          </tui-textarea>
        </label>
        <div class="mt-2 flex justify-between gap-2 max-md:flex-col-reverse">
          <button
            tuiButton
            appearance="outline"
            (click)="previous()"
          >
            Précédent
          </button>
          <button
            tuiButton
            (click)="create()"
            data-testid="create-sequence"
          >
            Ajouter
          </button>
        </div>
      </div>
    }
  </div>
</tui-elastic-container>
