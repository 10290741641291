import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Client } from '@pixacare/pxc-ts-core';
import { TuiButton, TuiIcon } from '@taiga-ui/core';
import { TuiTabs } from '@taiga-ui/kit';
import { Observable } from 'rxjs';
import { MenuEntry } from 'src/app/shared/models/base/menu-entry';
import { ClientAdministrationTabs } from 'src/app/shared/models/enums/client-administration-tabs.enum';
import { ClientImageComponent } from '../../shared/client-image/client-image.component';
import { SharedModule } from '../../shared/shared.module';
import { ClientTitleHeaderComponent } from '../client-title-header/client-title-header.component';
import { ClientAdminService } from './client-admin.service';

@UntilDestroy()
@Component({
  selector: 'pxc-client-administration',
  templateUrl: './client-admin.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TuiButton,
    RouterModule,
    SharedModule,
    TuiIcon,
    TuiTabs,
    ClientImageComponent,
    ClientTitleHeaderComponent,
  ],
  providers:[
    ClientAdminService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientAdminComponent implements OnInit {

  client$: Observable<Client>;

  tabs: MenuEntry[] = [
    {
      title: 'Utilisateurs',
      icon: '@tui.user',
      link: ClientAdministrationTabs.USERS,
    },
    {
      title: 'Groupes',
      icon: '@tui.users',
      link: ClientAdministrationTabs.DEPARTMENTS,
    },
  ];

  private readonly clientAdminService = inject(ClientAdminService);

  ngOnInit(): void {
    this.client$ = this.clientAdminService.loadClient$();
  }

}
