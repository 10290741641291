import { untilDestroyed } from '@ngneat/until-destroy';
import { PagedResponse, PaginationQuery } from '@pixacare/pxc-ts-core';
import { BehaviorSubject, Observable, first, map } from 'rxjs';
import { BasePagination } from './base-pagination';
import { defaultPagedCollection } from './models/pagination/default-paged-collection.config';
import { PagedCollection } from './models/pagination/paged-collection';
import { defaultPaginationContext } from './models/pagination/pagination-context.config';

export abstract class Pagination<T> extends BasePagination<T> {

  isCollectionLoaded$: Observable<boolean> = null;

  protected isCollectionLoaded = new BehaviorSubject<boolean>(false);
  protected collection = new BehaviorSubject<PagedCollection<{ [id: number]: T }>>(
    {
      ...defaultPagedCollection,
      context: {
        ...defaultPagedCollection.context,
        isNextPageLoading: true,
      },
    } as PagedCollection<{ [id: number]: T }>,
  );

  constructor() {
    super();
    this.isCollectionLoaded$ = this.isCollectionLoaded.asObservable();
  }

  load(clientCode: string, {
    query = new PaginationQuery({ orderBy: ['last_activity|desc'], size: defaultPaginationContext.countPerPage }),
    reset = false,
  }: {
    reset?: boolean;
    query?: PaginationQuery;
  } = {}): void {

    if (reset) {
      this.isCollectionLoaded.next(false);
    }

    this.setIsNextPageLoading(true);

    this.getNextData(clientCode, { query }).subscribe({
      next: (data) => {

        this.collection.next(this.getNextCollection({
          currentCollection: this.collection.getValue(),
          nextCollection: data,
          reset,
        }));

        if (reset) {
          this.isCollectionLoaded.next(true);
        }
      },
      error: (error) => {
        this.collection.error(error);
      },
    });
  }

  select(): Observable<PagedCollection<T[]>> {
    this.collection$ = this.collection.pipe(
      untilDestroyed(this),
      map((collection) => ({
        ...collection,
        data: collection.sorted.map((id) => collection.data[id]),
      })),
    );
    return this.collection$;
  }

  private setIsNextPageLoading(isNextPageLoading: boolean) {

    this.collection.pipe(first()).subscribe((currentCollection) => {
      this.collection.next({
        ...currentCollection,
        context: {
          ...currentCollection.context,
          isNextPageLoading,
        },
      });
    });
  }

  protected abstract getNextData(
    clientCode: string, { query }: {
      query: PaginationQuery;
    }
  ): Observable<PagedResponse<T>>;

  protected abstract getNextCollection({ currentCollection, nextCollection, reset }: {
    currentCollection: PagedCollection<{ [id: number]: T }>;
    nextCollection: PagedResponse<T>;
    reset: boolean;
  }): PagedCollection<{ [id: number]: T }>;

}
