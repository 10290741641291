import { GalleryMode } from 'src/app/shared/models/enums/gallery-mode.enum';
import { GallerySourceType } from 'src/app/shared/models/enums/gallery-source-type.enum';
import { GalleryConfig } from 'src/app/shared/models/gallery-config';

export const defaultSliderConfig: GalleryConfig = {
  mode: GalleryMode.SLIDER,
  keyboardShortcuts: true,
  nav: true,
  thumbnails: true,
  zoomAllowed: true,
  sourceType: GallerySourceType.STRING_SRC,
};

export const defaultComparisonConfig: GalleryConfig = {
  mode: GalleryMode.COMPARISON,
  keyboardShortcuts: true,
  nav: false,
  thumbnails: false,
  zoomAllowed: false,
  sourceType: GallerySourceType.STRING_SRC,
};

export const defaultEmptyDisplayConfig: GalleryConfig = {
  ...defaultSliderConfig,
  thumbnails: false,
  nav: false,
};
