import {
  ChangeDetectionStrategy,
  Component, Input,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'pxc-patient-edit-form',
  templateUrl: './patient-edit-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientEditFormComponent {

  @Input() form: UntypedFormGroup;

}
