import { CropConfig } from '@pixacare/pxc-ts-core';
import Konva from 'konva';
import { Command } from 'src/app/shared/models/helpers/command';
import { StudioCanvas } from '../canvas/studio-canvas';
import { EventBus } from '../event-bus/event-bus';
import { EventTopic } from '../event-bus/event-bus.interfaces';

export class PrepareCropCommand implements Command {

  receiver: StudioCanvas;
  eventBus: EventBus;

  cropSelection: Konva.Rect;
  cropBackground: Konva.Shape;
  cropConfig: CropConfig;

  constructor(receiver: StudioCanvas, currentCropConfig: CropConfig, eventBus: EventBus) {
    this.receiver = receiver;
    this.cropConfig = currentCropConfig;
    this.eventBus = eventBus;
  }

  execute(): void {
    this.receiver.destroyCropSelection();

    // reset to full picture display
    this.eventBus.publish(EventTopic.REVERT_CROP, {
      cropConfig: {
        x: null, y: null, width: null, height: null,
      },
    });

    if (!this.cropConfig.width || !this.cropConfig.height) {
      this.setDefaultCropConfig();
      if (this.receiver.isVertical()) {
        this.setDefaultCropConfig();
        [this.cropConfig.width, this.cropConfig.height] = [this.cropConfig.height, this.cropConfig.width];
      }
    }

    // Selection rectangle for cropping
    this.cropSelection = new Konva.Rect({
      width: this.cropConfig.width,
      height: this.cropConfig.height,
      x: this.cropConfig.x,
      y: this.cropConfig.y,
      strokeWidth: 3,
      dash: [10, 5],
      draggable: true,
      stroke: '#147efb',
      strokeScaleEnabled: false,
      name: 'crop',
      id: 'crop-selection',
    });

    // Dark background outside the cropping rectangle
    this.cropBackground = new Konva.Shape({
      width: this.receiver.stage.width() / this.receiver.layer.scaleX(),
      height: this.receiver.stage.height() / this.receiver.layer.scaleX(),
      fill: 'rgba(240, 240, 240, 0.6)',
      name: 'crop',
    });

    if (this.receiver.isVertical()) {
      [this.cropBackground.attrs.width,
        this.cropBackground.attrs.height] = [this.cropBackground.attrs.height, this.cropBackground.attrs.width];
    }

    this.cropBackground.setAttrs({
      offsetX: this.receiver.stage.width() / 2,
      offsetY: this.receiver.stage.height() / 2,
      x: this.receiver.stage.width() / 2,
      y: this.receiver.stage.height() / 2,
    });

    this.receiver.addTempShape(this.cropBackground);
    this.receiver.addTempShape(this.cropSelection);
    this.updateCropBackground();
    this.receiver.croppingTransformer.nodes([this.cropSelection]);

    // Avoid cropSelection going offscreen
    this.cropSelection.on('dragmove', () => {
      this.updateCropBackground();
      this.updateCropConfig();
      let stageWidth = this.receiver.stage.width() / this.receiver.layer.scaleX();
      let stageHeihgt = this.receiver.stage.height() / this.receiver.layer.scaleY();
      const cropWidth = this.cropSelection.width() * this.cropSelection.scaleX();
      const cropHeight = this.cropSelection.height() * this.cropSelection.scaleY();

      if (this.receiver.isVertical()) {
        [stageWidth, stageHeihgt] = [stageHeihgt, stageWidth];
      }

      this.cropSelection.x(Math.max(0, this.cropSelection.x()));
      this.cropSelection.x(Math.min(this.cropSelection.x(), stageWidth - cropWidth));
      this.cropSelection.y(Math.max(0, this.cropSelection.y()));
      this.cropSelection.y(Math.min(this.cropSelection.y(), stageHeihgt - cropHeight));
    });

    this.cropSelection.on('transform', () => {
      this.updateCropBackground();
      this.updateCropConfig();
    });
  }

  setDefaultCropConfig(): void {
    Object.assign(this.cropConfig, {
      width: this.receiver.stage.width() / this.receiver.layer.scaleX(),
      height: this.receiver.stage.height() / this.receiver.layer.scaleX(),
      x: 0,
      y: 0,
    });
  }

  updateCropConfig(): void {
    Object.assign(this.cropConfig, {
      x: this.cropSelection.x(),
      y: this.cropSelection.y(),
      width: this.cropSelection.width() * this.cropSelection.scaleX(),
      height: this.cropSelection.height() * this.cropSelection.scaleY(),
    });
  }

  updateCropBackground(): void {
    const cropWidth = this.cropSelection.width() * this.cropSelection.scaleX();
    const cropHeight = this.cropSelection.height() * this.cropSelection.scaleY();
    this.cropBackground.sceneFunc((ctx, shape) => {
      // Full top line
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(shape.width(), 0);
      ctx.lineTo(shape.width(), this.cropSelection.y());
      ctx.lineTo(0, this.cropSelection.y());
      ctx.lineTo(0, 0);

      // Left
      ctx.moveTo(0, this.cropSelection.y());
      ctx.lineTo(0, shape.height());
      ctx.lineTo(this.cropSelection.x(), shape.height());
      ctx.lineTo(this.cropSelection.x(), this.cropSelection.y());
      ctx.lineTo(0, this.cropSelection.y());

      // Bottom
      ctx.moveTo(this.cropSelection.x(), this.cropSelection.y() + cropHeight);
      ctx.lineTo(shape.width(), this.cropSelection.y() + cropHeight);
      ctx.lineTo(shape.width(), shape.height());
      ctx.lineTo(this.cropSelection.x(), shape.height());
      ctx.lineTo(this.cropSelection.x(), this.cropSelection.y() + cropHeight);

      // Right
      ctx.moveTo(cropWidth + this.cropSelection.x(), this.cropSelection.y());
      ctx.lineTo(shape.width(), this.cropSelection.y());
      ctx.lineTo(shape.width(), this.cropSelection.y() + this.cropSelection.height() * this.cropSelection.scaleY());
      ctx.lineTo(cropWidth + this.cropSelection.x(), this.cropSelection.y() + cropHeight);
      ctx.lineTo(cropWidth + this.cropSelection.x(), this.cropSelection.y());
      ctx.closePath();

      ctx.fillStrokeShape(shape);
    });
    this.cropSelection.moveToTop();
  }

}
