import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ImageBrokenComponent } from '@modules/shared/image-broken/image-broken.component';
import { SadmEntity, SadmType } from '@pixacare/pxc-ts-core';
import { FirstLetterUppercasePipe } from '@shared/pipes/first-letter-uppercase.pipe';
import { SadmEntityNamePipe } from '@shared/pipes/sadm-entity-name.pipe';
import { SadmEntityTypePipe } from '@shared/pipes/sadm-entity-type.pipe';

@Component({
  selector: 'pxc-sadm-entity-preview',
  templateUrl: './sadm-entity-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SadmEntityNamePipe,
    SadmEntityTypePipe,
    FirstLetterUppercasePipe,
    ImageBrokenComponent,
  ],
  providers: [
    SadmEntityTypePipe,
    SadmEntityNamePipe,
  ],
})
export class SadmEntityPreviewComponent implements OnInit {

  @Input() sadmEntity: SadmEntity = null;
  @Input() sadmType: SadmType = null;
  @Input() isSelected = false;

  createdOn: Date;
  imageError = false;

  ngOnInit(): void {
    this.createdOn = new Date(this.sadmEntity?.createdOn);
  }

  onImageLoadingError(): void {
    this.imageError = true;
  }

}
