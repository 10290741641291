<form
  [formGroup]="formGroup"
  (ngSubmit)="confirm()"
  class="flex flex-col gap-3"
  data-testid="form"
>
  @for (input of inputs; track input) {
    <tui-input
      [id]="input.property"
      [formControlName]="input.property"
      data-testid="input"
      tuiTextfieldLabelOutside="true"
      ngDefaultControl
      [pseudoInvalid]="formGroup.controls[input.property].invalid"
    >
      {{ input.label }}
    </tui-input>
    <tui-error
      formControlName="input.property"
      [error]="[] | tuiFieldError | async"
    />
  }
  <div class="flex justify-between gap-2">
    <button
      tuiButton
      type="button"
      appearance="outline"
      class="max-sm:w-full"
      data-testid="cancel"
      (click)="cancel()"
    >
      {{ cancelLabel }}
    </button>
    <button
      tuiButton
      type="submit"
      appearance="primary"
      class="max-sm:w-full"
      data-testid="save"
      [disabled]="!formGroup.valid"
    >
      {{ confirmLabel }}
    </button>
  </div>
</form>
