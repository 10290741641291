import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SadmEntity, SadmType, SadmTypeLabel } from '@pixacare/pxc-ts-core';

@Component({
  selector: 'pxc-sadm-entity-list',
  templateUrl: './sadm-entity-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SadmEntityListComponent {

  @Input() sadmsEntities: SadmEntity[] = null;
  @Input() sadmType: SadmType;

  SadmTypeLabel = SadmTypeLabel;

}
