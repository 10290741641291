import Konva from 'konva';
import { Command } from 'src/app/shared/models/helpers/command';
import { StudioCanvas } from '../canvas/studio-canvas';
import { DrawConfig } from '../config/studio-interfaces';
import { EventBus } from '../event-bus/event-bus';
import { EventTopic } from '../event-bus/event-bus.interfaces';
import { StudioService } from '../studio/studio.service';

export class DrawLineCommand implements Command {

  receiver: StudioCanvas;
  eventBus: EventBus;
  drawConfig: DrawConfig;
  constructor(
    receiver: StudioCanvas,
    eventBus: EventBus,
    drawConfig: DrawConfig,
    private studioService: StudioService) {
    this.receiver = receiver;
    this.eventBus = eventBus;
    this.drawConfig = drawConfig;

  }

  execute(): void {
    let isUserDrawing = false;

    const drawingLine = new Konva.Line({
      stroke: this.drawConfig.color.code,
      strokeWidth: this.drawConfig.width,
      visible: false,
      strokeScaleEnabled: false,
      lineCap: 'butt',
    });
    this.receiver.addTempShape(drawingLine);

    let x1: number;
    let y1: number;
    let x2: number;
    let y2: number;

    this.receiver.stage.on('mousedown touchstart', (e) => {
      drawingLine.stroke(this.drawConfig.color.code);
      drawingLine.strokeWidth(this.drawConfig.width);
      if (e.currentTarget !== this.receiver.stage) {
        return;
      }
      x1 = this.receiver.layer.getRelativePointerPosition().x;
      y1 = this.receiver.layer.getRelativePointerPosition().y;
      isUserDrawing = true;
    });

    this.receiver.stage.on('mousemove touchmove', (e) => {
      if (!isUserDrawing) {
        return;
      }
      if (!drawingLine.isVisible()) {
        drawingLine.visible(true);
      }
      x2 = this.receiver.layer.getRelativePointerPosition().x;
      y2 = this.receiver.layer.getRelativePointerPosition().y;

      drawingLine.points([x1, y1, x2, y2]);
      if (e.evt.shiftKey) {
        drawingLine.points(this.studioService.getLineSnappedPositions(x1, y1, x2, y2));
      }

    });

    this.receiver.stage.on('mouseup touchend', () => {
      isUserDrawing = false;
      if (!drawingLine.visible()) {
        return;
      }
      drawingLine.visible(false);

      // Draw line
      const line = new Konva.Line({
        stroke: this.drawConfig.color.code,
        strokeWidth: this.drawConfig.width,
        points: drawingLine.points(),
        lineCap: 'butt',
      });

      this.receiver.addShape(line);
      drawingLine.destroy();
      this.eventBus.publish(EventTopic.AUTO_SELECT, { shapes: [line] });
    });

  }

}
