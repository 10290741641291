import { EncounterStatus } from '@pixacare/pxc-ts-core';

type EncounterConfig = {
  appearance: string;
  label: string;
};

export const encounterStatusConfig: { [encounter in EncounterStatus]:  EncounterConfig } = {
  [EncounterStatus.FINISHED]: {
    appearance: 'warning',
    label: 'Terminé',
  },
  [EncounterStatus.IN_PROGRESS]: {
    appearance: 'success',
    label: 'En cours',
  },
  [EncounterStatus.PLANNED]: {
    appearance: 'info',
    label: 'Planifié',
  },
};

export const unknownEncouterStatusConfig: EncounterConfig = {
  appearance: 'error',
  label: 'Statut inconnu',
};
