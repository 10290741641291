import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InteractiveDrawer } from '../../models/interactive-drawer';
import { DrawerState } from './drawer.state';

export const DRAWER_FEATURE_KEY = 'Drawer';

export const selectFeature = createFeatureSelector<DrawerState>(
  DRAWER_FEATURE_KEY,
);

export const selectInteractiveDrawer = createSelector(
  selectFeature,
  (state: DrawerState): InteractiveDrawer => ({
    ...state.drawer,
    isUserDragging: state.isUserDragging,
  }),
);
