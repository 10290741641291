import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormDisplayComponent } from '@modules/forms/form-display/form-display.component';
import { PatientModule } from '@modules/patient/patient.module';
import { SharedModule } from '@modules/shared/shared.module';
import { FormAnswers, FormReport, SadmClient, SadmEntity } from '@pixacare/pxc-ts-core';
import { SadmEntityNamePipe } from '@shared/pipes/sadm-entity-name.pipe';
import { SadmEntityTypePipe } from '@shared/pipes/sadm-entity-type.pipe';
import { TuiLet } from '@taiga-ui/cdk';
import { TuiButton, TuiDialogContext, TuiIcon, TuiScrollbar, TuiTitle } from '@taiga-ui/core';
import { TuiTabs } from '@taiga-ui/kit';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { DialogService } from 'src/app/services/dialog.service';
import { FormComponent } from '../../forms/form/form.component';
import { SadmProtocolsService } from '../sadm-protocols.service';

@Component({
  selector: 'pxc-sadm-entity-protocols',
  templateUrl: './sadm-entity-protocols.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    PatientModule,
    RouterModule,
    TuiButton,
    TuiIcon,
    TuiLet,
    TuiScrollbar,
    TuiTabs,
    SharedModule,
    SadmEntityNamePipe,
    FormDisplayComponent,
    TuiTitle,
  ],
  providers: [
    SadmProtocolsService,
    SadmEntityTypePipe,
    SadmEntityNamePipe,
  ],
})
export class SadmEntityProtocolsComponent {

  @Input() sadmEntity: SadmEntity = this.context.data.sadmEntity;
  @Input() sadmClient: SadmClient = this.context.data.sadmClient;
  @Input() protocolsReports: FormReport[] = this.context.data.protocolsReports;
  @Input() index: number = this.context.data.index || 0;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<void, Partial<SadmEntityProtocolsComponent>>,
    private readonly protocolsService: SadmProtocolsService,
    private readonly dialogService: DialogService,
  ) { }

  close(): void {
    this.context.completeWith(null);
  }

  edit(): void {
    this.dialogService.openComponentWithCloseConfirmation<FormComponent, FormAnswers>(
      FormComponent, {
        label: 'Modifier le protocole',
        data: {
          form: this.sadmClient.protocolCreationForm,
          formAnswers: SadmProtocolsService.getLatestProtocol(this.sadmEntity).protocolCreationFormAnswer.answers,
        },
        dismissible: false,
        size: 'm',
      },
    ).subscribe((answers) => {
      if (answers) {
        this.protocolsService.createProtocol({
          sadmClientId: this.sadmClient.id,
          sadmEntityId: this.sadmEntity.id,
          answers,
        });
        this.close();
      }
    });
  }

}
