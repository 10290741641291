import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@services/authentication.service';
import { LogoutStrategyService } from '@services/logout-strategy.service';
import { decodeToken, isTokenValid } from '@shared/utils/token-utils';

export const isAuthenticatedGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authenticationService = inject(AuthenticationService);
  const logoutStrategyService = inject(LogoutStrategyService);

  if (authenticationService.currentUser
    && isTokenValid(decodeToken(authenticationService.getToken()))) {
    return true;
  }

  logoutStrategyService.logout(state.url);
  return false;
};
