import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SearchedUser } from '@pixacare/pxc-ts-core';
import { AvatarComponent } from 'src/app/shared/components/avatar/avatar.component';
import { TileComponent } from 'src/app/shared/components/tile/tile.component';

@Component({
  selector: 'pxc-channel-participant',
  standalone: true,
  imports: [
    CommonModule,
    TileComponent,
    AvatarComponent,
  ],
  templateUrl: './channel-participant.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelParticipantComponent {

  @Input() participant: SearchedUser;

}
