import { ValidatorFn, Validators } from '@angular/forms';
import { FormQuestion, FormValidator, ValidatorType } from '@pixacare/pxc-ts-core';
import { TuiValidationError } from '@taiga-ui/cdk';

export class FormEngineValidators {

  static VALIDATORS_MAP: {
    [key in ValidatorType]?: (formValidator: FormValidator) => ValidatorFn
  } = {
      [ValidatorType.MIN]: FormEngineValidators.customMinValidator,
      [ValidatorType.MAX]: FormEngineValidators.customMaxValidator,
    };

  static getQuestionValidators(question: FormQuestion): ValidatorFn[] {
    const validators = (question.validators || []).map((validator) =>
      FormEngineValidators.VALIDATORS_MAP[validator.type](validator),
    );

    if (question.parameters.required) {
      validators.push(Validators.required);
    }

    return validators;
  }

  private static customMinValidator(validator: FormValidator): ValidatorFn {
    return FormEngineValidators.customMessageValidator(
      validator.type,
      validator.message,
      Validators.min(+validator.value),
    );
  }

  private static customMaxValidator(validator: FormValidator): ValidatorFn {
    return FormEngineValidators.customMessageValidator(
      validator.type,
      validator.message,
      Validators.max(+validator.value),
    );
  }

  private static customMessageValidator(validatorType: ValidatorType, message: string, validator: ValidatorFn) {
    return function (...args: unknown[]): ValidatorFn {
      const result = validator.apply(this, args);
      return {
        ...result,
        ...(result?.[validatorType] && {
          [validatorType]: new TuiValidationError(message),
        }),
      };

    };
  }

}
