import { Observable, of } from 'rxjs';
import { FilterTemplate } from './filter-template';
import { FilterType } from './filter-type.enum';

export const sequenceFilterTemplates: Observable<FilterTemplate[]> = of([
  {
    type: FilterType.DATE_RANGE,
    property: 'created_on',
    name: 'Date',
  },
]);
