import { Pipe, type PipeTransform } from '@angular/core';
import { Size } from '@shared/models/enums/size.enum';
import { TuiSizeL, TuiSizeXS } from '@taiga-ui/core';

@Pipe({
  name: 'toTuiSize',
  standalone: true,
})
export class ToTuiSizePipe implements PipeTransform {

  transform(value: Size): TuiSizeL | TuiSizeXS {
    switch (value) {
      case Size.MEDIUM:
        return 'm';
      case Size.LARGE:
        return 'l';
      case Size.TINY:
        return 'xs';
      default:
        return 's';
    }
  }

}
