import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FlattenedSequence, PagedResponse, PaginationQuery } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { SequenceHttpService } from 'src/app/services/http/sequence.http.service';
import { getNextPagedCollection } from 'src/app/shared/models/helpers/pagination-helper';
import { PagedCollection } from 'src/app/shared/models/pagination/paged-collection';
import { Pagination } from 'src/app/shared/pagination';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SequencePaginationService extends Pagination<FlattenedSequence> {

  constructor(
    private readonly sequenceService: SequenceHttpService,
  ) {
    super();
  }

  getNextData(clientCode: string, { query }: { query: PaginationQuery }): Observable<PagedResponse<FlattenedSequence>> {
    return this.sequenceService.getSequences(clientCode, { query });
  }

  getNextCollection({ currentCollection, nextCollection, reset }: {
    currentCollection: PagedCollection<{ [id: number]: FlattenedSequence }>;
    nextCollection: PagedResponse<FlattenedSequence>;
    reset: boolean;
  }): PagedCollection<{ [id: number]: FlattenedSequence }> {

    return getNextPagedCollection({ currentCollection, nextCollection }, {
      reset,
      dataIdentifier: 'sequenceInstance.id',
      sortPredicate: (entityId1, entityId2, entities) => (
        entities[entityId2].sequenceInstance.createdOn?.getTime()
        - entities[entityId1].sequenceInstance.createdOn?.getTime()
      ),
    });
  }

}
