@if (channel$ | async; as channel) {
  <div class="flex h-full w-full overflow-hidden">
    <div class="relative flex h-full w-full flex-col">
      <pxc-channel-header
        [channel]="channel"
        class="z-10 w-full"
        (openChannelInfo)="openSettings()"
      ></pxc-channel-header>
      <div
        class="flex flex-1 items-center justify-center overflow-hidden bg-neutral-1"
        [ngClass]="{
          '2xl:mr-96': showSettings,
        }"
        *tuiLet="messages$ | async as messages"
      >
        @if ((isEmpty$ | async) === false) {
          <pxc-message-list
            class="z-0 block h-full w-full"
            [channel]="channel"
            [messages]="messages"
            [messageGroups]="messageGroups$ | async"
            [hasUnseenMessages]="hasUnseenMessages$ | async"
            (loadNext)="loadNext()"
            (markMessagesAsSeen)="markMessagesAsSeen()"
          ></pxc-message-list>
        } @else {
          <p class="text-secondary">Aucun message.</p>
        }
      </div>
      <pxc-message-input
        (sendMessage)="sendMessage($event)"
        class="z-10 w-full"
      ></pxc-message-input>
    </div>
    <pxc-channel-settings
      [channel]="channel"
      (closeClick)="closeSettings()"
      class="absolute right-0 top-0 h-full shrink-0 bg-base-alt transition-all duration-300 max-lg:left-0 lg:w-96 lg:border-l lg:border-border-normal lg:max-2xl:shadow-xl"
      [ngClass]="{
        'z-20 translate-x-0 opacity-100': showSettings,
        'translate-x-full opacity-0': !showSettings,
      }"
    ></pxc-channel-settings>
  </div>
}
