import { Directive, ElementRef, Input, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Channel, ChannelType } from '@pixacare/pxc-ts-core';

@Directive({
  selector: '[chatEntityLink]',
  standalone: true,
})
export class ChatEntityLinkDirective extends RouterLink {

  private readonly elementRef = inject(ElementRef);

  @Input() set chatEntityLink(channel: Channel) {

    this.queryParamsHandling = 'merge';

    switch (channel?.type) {

      case ChannelType.DEPARTMENT:
        if (!channel.topic?.departmentId) {break;}
        this.routerLink = [ '/dashboard', 'department', channel.topic.departmentId ];
        break;
      case ChannelType.TELEMONITORING:
        if (!channel.topic?.telemonitoringId) {break;}
        this.routerLink = [ '/dashboard', 'telemonitorings', channel.topic.telemonitoringId ];
        break;
      default:
        this.elementRef.nativeElement.style.display = 'none';
        break;

    }

  }

}
