@if (signUpConfiguration.enabled || !!signUpInvitationToken) {
  <div>
    @if ((invitationTokenStatus | async) === InvitationTokenStatus.VALID) {
      <div class="flex flex-col gap-2 py-2">
        <h2 tuiTitle="m">
          Inscription
          <span tuiSubtitle>Rejoignez l'environnement sécurisé de photographies médicales.</span>
        </h2>
      </div>
      <form
        [formGroup]="registerForm"
        (ngSubmit)="register()"
        data-testid="register-form"
        class="mt-6 flex flex-col gap-6"
      >
        <div class="grid gap-6 lg:grid-cols-2">
          <div>
            <tui-input
              formControlName="firstName"
              [disabled]="(actionOnGoing$ | async) ? '' : null"
              tuiTextfieldIconLeft="@tui.user"
              [required]="true"
              ngDefaultControl
            >
              Prénom
            </tui-input>
            <tui-error
              formControlName="firstName"
              [error]="[] | tuiFieldError | async"
            />
          </div>
          <div>
            <tui-input
              formControlName="lastName"
              [disabled]="(actionOnGoing$ | async) ? '' : null"
              tuiTextfieldIconLeft="@tui.user"
              [required]="true"
              ngDefaultControl
            >
              Nom
            </tui-input>
            <tui-error
              formControlName="lastName"
              [error]="[] | tuiFieldError | async"
            />
          </div>
        </div>
        <div>
          <tui-input
            formControlName="mailAddress"
            [disabled]="(actionOnGoing$ | async) ? '' : null"
            tuiTextfieldIconLeft="@tui.mail"
            [readOnly]="emailFromInvitationToken | async"
            ngDefaultControl
          >
            Adresse E-Mail
            <input
              tuiTextfieldLegacy
              type="email"
              data-testid="email-input"
            />
          </tui-input>
          <tui-error
            formControlName="mailAddress"
            [error]="[] | tuiFieldError | async"
          />
        </div>
        <div class="grid gap-6 lg:grid-cols-2">
          <div>
            <tui-input-password
              formControlName="password"
              tuiTextfieldIconLeft="@tui.lock"
              [disabled]="(actionOnGoing$ | async) ? '' : null"
              ngDefaultControl
            >
              Mot de passe
            </tui-input-password>
            <tui-error
              formControlName="password"
              [error]="[] | tuiFieldError | async"
            />
          </div>
          <div>
            <tui-input-password
              formControlName="passwordConfirm"
              tuiTextfieldIconLeft="@tui.lock"
              [disabled]="(actionOnGoing$ | async) ? '' : null"
              ngDefaultControl
            >
              Confirmation du mot de passe
            </tui-input-password>
            <tui-error
              formControlName="passwordConfirm"
              [error]="[] | tuiFieldError | async"
            />
          </div>
        </div>
        <div class="grid gap-6 lg:grid-cols-2">
          <div>
            <tui-input
              formControlName="phoneNumber"
              [disabled]="(actionOnGoing$ | async) ? '' : null"
              tuiTextfieldIconLeft="@tui.phone"
              ngDefaultControl
            >
              Numéro de téléphone
              <input
                tuiTextfieldLegacy
                type="tel"
                data-testid="phone-input"
              />
            </tui-input>
            <tui-error
              formControlName="phoneNumber"
              [error]="[] | tuiFieldError | async"
            />
          </div>
          <div>
            <pxc-medical-specialty-input
              formControlName="medicalSpecialtyId"
              ngDefaultControl
            ></pxc-medical-specialty-input>
            <tui-error
              formControlName="medicalSpecialtyId"
              [error]="[] | tuiFieldError | async"
            />
          </div>
        </div>
        <div class="my-2">
          <label tuiLabel>
            <input
              tuiCheckbox
              type="checkbox"
              formControlName="CGVU"
              [disabled]="(actionOnGoing$ | async) ? '' : null"
              ngDefaultControl
            />
            J'accepte les
            <a
              tuiLink
              data-appearance="primary"
              href="https://pixacare.com/cgu"
              target="_blank"
              rel="noopener noreferrer"
              class="inline-block"
            >
              CGVU
              <tui-icon icon="@tui.external-link"></tui-icon>
            </a>
          </label>
          <tui-error
            formControlName="CGVU"
            [error]="[] | tuiFieldError | async"
          />
        </div>
        <div class="mt-2 flex flex-wrap justify-between gap-3">
          <button
            tuiButton
            appearance="outline"
            routerLink="../login"
            [disabled]="actionOnGoing$ | async"
            class="max-sm:w-full"
          >
            J'ai déjà un compte
          </button>
          <button
            tuiButton
            type="submit"
            appearance="primary"
            [loading]="actionOnGoing$ | async"
            class="max-sm:order-first max-sm:w-full"
          >
            Inscription
          </button>
        </div>
      </form>
    } @else {
      @switch (invitationTokenStatus | async) {
        @case (InvitationTokenStatus.INVALID) {
          <ng-container
            [ngTemplateOutlet]="signUpErrorBlock"
            [ngTemplateOutletContext]="{
              title: 'Invitation invalide',
              message:
                'Ce lien d\'invitation est expiré ou erroné, merci de vous rapprocher de la ' +
                'personne qui vous a invitée.',
            }"
          ></ng-container>
        }
        @case (InvitationTokenStatus.CONSUMED) {
          <ng-container
            [ngTemplateOutlet]="signUpErrorBlock"
            [ngTemplateOutletContext]="{
              title: 'Vous avez déjà un compte',
              message:
                'Vous pouvez vous connecter en cliquant sur le bouton ci-dessous. ' +
                'En cas d\'oubli de votre mot de passe, vous pourrez le régénérer.',
            }"
          ></ng-container>
        }
      }
    }
  </div>
} @else {
  <ng-container
    [ngTemplateOutlet]="signUpErrorBlock"
    [ngTemplateOutletContext]="{
      title: 'Inscriptions désactivées',
      message: signUpConfiguration.disabledMessage,
    }"
  ></ng-container>
}

<ng-template
  #signUpErrorBlock
  let-title="title"
  let-message="message"
>
  <div
    class="flex flex-col items-center gap-2 text-center"
    data-testid="message"
  >
    <h3 tuiTitle="m">{{ title }}</h3>
    <p class="max-w-prose text-center">{{ message }}</p>
  </div>
  <button
    tuiButton
    appearance="primary"
    class="w-full"
    routerLink="../login"
  >
    Revenir à la connexion
  </button>
</ng-template>
