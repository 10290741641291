import { Injectable } from '@angular/core';
import { TuiDay, TuiTime, TuiValueTransformer } from '@taiga-ui/cdk';

@Injectable()
export class DateTimeTransformer
implements TuiValueTransformer<[TuiDay | null, TuiTime | null], Date> {

  fromControlValue(controlValue: Date): [TuiDay | null, TuiTime | null] {
    return [TuiDay.fromLocalNativeDate(controlValue), TuiTime.fromLocalNativeDate(controlValue)];
  }

  toControlValue([day, time]: [TuiDay | null, TuiTime | null]): Date {
    const date = day?.toLocalNativeDate() || new Date();
    const milliseconds = time?.toAbsoluteMilliseconds() || 0;

    return new Date(date.getTime() + milliseconds);
  }

}
