import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PatientModule } from '@modules/patient/patient.module';
import { SharedModule } from '@modules/shared/shared.module';
import { SpinnerComponent } from '@modules/shared/spinner/spinner.component';
import { Store } from '@ngrx/store';
import { BaseSadmEntity, SadmClient, SadmTypeLabel } from '@pixacare/pxc-ts-core';
import { SadmEntityNamePipe } from '@shared/pipes/sadm-entity-name.pipe';
import { SadmEntityTypePipe } from '@shared/pipes/sadm-entity-type.pipe';
import { TuiLet } from '@taiga-ui/cdk';
import { TuiButton, TuiDialogContext, TuiLabel } from '@taiga-ui/core';
import { TuiRadio } from '@taiga-ui/kit';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { Observable, first, of } from 'rxjs';
import { sadmActions } from 'src/app/shared/store/sadm/sadm.actions';
import {
  selectIsPatientSadmStateLoaded,
  selectSadmClients,
  selectSadmClientsWithEntities,
} from 'src/app/shared/store/sadm/sadm.selectors';
import { SadmEntityCreationService } from '../sadm-entity-creation.service';

@Component({
  selector: 'pxc-sadm-entity-picker',
  templateUrl: './sadm-entity-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    TuiButton,
    TuiLabel,
    TuiLet,
    PatientModule,
    SpinnerComponent,
    SadmEntityNamePipe,
    TuiRadio,
  ],
  providers: [
    SadmEntityCreationService,
    SadmEntityTypePipe,
    SadmEntityNamePipe,
  ],
})
export class SadmEntityPickerComponent implements OnInit {

  @Input() clientCode: string = this.context.data.clientCode;
  @Input() patientId: number  = this.context.data.patientId;

  sadms$: Observable<SadmClient[]>;
  isDataLoaded$: Observable<boolean> = of(false);

  SadmTypeLabel = SadmTypeLabel;
  selectedSadmIdControl = new UntypedFormControl(undefined, [Validators.required]);

  constructor(
    private readonly store: Store,
    @Optional() @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<{
      sadmEntityId?: number;
      sadmEntity?: BaseSadmEntity;
    }, Partial<SadmEntityPickerComponent>>,
    private readonly sadmEntityCreationService: SadmEntityCreationService,
  ) { }

  ngOnInit(): void {
    this.isDataLoaded$ = this.store.select(selectIsPatientSadmStateLoaded(this.clientCode, this.patientId));

    if (this.patientId) {
      this.store.dispatch(sadmActions.getSadmEntities({
        clientCode: this.clientCode,
        patientId: this.patientId,
        isArchived: false,
      }));
      this.sadms$ = this.store.select(selectSadmClientsWithEntities(this.clientCode, this.patientId, false));
    } else {
      this.sadms$ = this.store.select(selectSadmClients);
    }
  }

  validate(): void {
    if (this.selectedSadmIdControl.valid) {
      this.context.completeWith({
        sadmEntityId: this.selectedSadmIdControl.value,
      });
    }
  }

  close(): void {
    this.context.completeWith(null);
  }

  createEntity(sadmClient: SadmClient): void {
    this.sadmEntityCreationService.openEntityCreation(sadmClient)
      .pipe(first())
      .subscribe((sadmEntity) => {
        if (sadmEntity) {
          this.context.completeWith({
            sadmEntity,
          });
        }
      });
  }

}
