import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TuiAutoFocus } from '@taiga-ui/cdk';
import { TuiButton } from '@taiga-ui/core';
import { TuiInputModule, TuiTextfieldControllerModule } from '@taiga-ui/legacy';
import { BehaviorSubject, first } from 'rxjs';
import { Message } from 'src/app/shared/models/chat/message';
import { ChannelPreviewComponent } from '../channel-preview/channel-preview.component';
import { MessageInputService } from './message-input.service';

@UntilDestroy()
@Component({
  selector: 'pxc-message-input',
  standalone: true,
  imports: [
    CommonModule,
    TuiInputModule,
    TuiTextfieldControllerModule,
    FormsModule,
    ReactiveFormsModule,
    TuiButton,
    ChannelPreviewComponent,
    TuiAutoFocus,
  ],
  providers: [
    MessageInputService,
  ],
  templateUrl: './message-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageInputComponent implements OnInit {

  @Output() sendMessage = new EventEmitter<Message>();

  displayError$ = new BehaviorSubject<boolean>(false);
  newMessageText = new FormControl<string>(null, [Validators.required, Validators.minLength(1)]);

  readonly messageInputService = inject(MessageInputService);

  ngOnInit() {
    this.messageInputService.sendMessage$.pipe(
      untilDestroyed(this),
    ).subscribe(() => this.send());
  }

  send(): void {
    this.messageInputService.attachment$
      .pipe(first())
      .subscribe((attachment) => {
        if (this.newMessageText.valid || attachment) {
          this.sendMessage.emit({
            text: this.newMessageText.value,
            attachment: {
              sequence: attachment?.sequence,
            },
          });
          this.newMessageText.reset();
          this.messageInputService.updateAttachment(null);
        }
      });
  }

  addAttachment(): void {
    this.messageInputService.selectAttachment();
  }

  removeAttachment(): void {
    this.messageInputService.updateAttachment(null);
  }

}
