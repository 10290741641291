import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private readonly injector: Injector) { }

  handleError(error: Error): void {
    if (!environment.production) {
      console.error(error);
    }
    const appInsights = this.injector.get(ApplicationInsights);
    appInsights.trackException({ exception: error });
  }

}
