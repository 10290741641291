import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Patient } from '@pixacare/pxc-ts-core';
import { PatientNamePipe } from 'src/app/modules/patient/patient-name.pipe';
import { DialogService } from 'src/app/services/dialog.service';
import { patientsActions } from 'src/app/shared/store/patients/patients.actions';

@Injectable({
  providedIn: 'root',
})
export class PatientMergeService {

  constructor(
    private readonly store: Store,
    private readonly dialogService: DialogService,
    private readonly patientNamePipe: PatientNamePipe,
  ) { }

  mergePatient(fromPatient: Patient, toPatient: Patient, { confirmAction, cancelAction, additionalMessage }: {
    confirmAction?: () => void;
    cancelAction?: () => void;
    additionalMessage?: string;
  }): void {

    // eslint-disable-next-line max-len
    const content = `Les séquences de ${this.patientNamePipe.transform(fromPatient)} seront associées à ${this.patientNamePipe.transform(toPatient)}. Le patient ${this.patientNamePipe.transform(fromPatient)} sera ensuite supprimé.`;

    this.dialogService.openConfirm(
      'Êtes-vous sûr de vouloir fusionner ces patients ?',
      {
        content: additionalMessage ? `
          
          ${additionalMessage}
          
          ${content} 
          
        ` : content,
        yes: 'Fusionner',
        no: 'Annuler',
      },
    ).subscribe((result) => {
      if (result) {
        this.store.dispatch(patientsActions.mergePatients({
          clientCode: fromPatient.clientCode,
          patientId: fromPatient.id,
          targetPatientId: toPatient.id,
        }));
        if (confirmAction) { confirmAction(); }
      } else {
        if (cancelAction) { cancelAction(); }
      }
    });
  }

}
