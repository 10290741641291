<pxc-image-overlay
  [caption]="file?.name"
  [showDelete]="showDelete"
  (delete)="delete.emit()"
>
  @if (src$ | async; as src) {
    @if ((error$ | async) === null) {
      <img
        class="h-full w-full object-cover"
        [src]="src"
        (error)="error$.next(true)"
        data-testid="image"
      />
    } @else {
      <pxc-image-broken data-testid="error"></pxc-image-broken>
    }
  } @else {
    <pxc-image-loading data-testid="loading"></pxc-image-loading>
  }
</pxc-image-overlay>
