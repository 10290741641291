import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TuiAlertService } from '@taiga-ui/core';
import { map } from 'rxjs/operators';
import { notificationsActions } from './notifications.actions';
import { NotifyErrorPayload, NotifyPayload, NotifyStatusPayload } from './notifications.payload';

@Injectable()
export class NotificationsEffects {

  success$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.success),
      map(({
        title = 'Les modifications ont été apportées.',
        message,
      }: NotifyStatusPayload) =>
        notificationsActions.notify({
          title, message, status: 'success',
        }),
      ),
    ),
  );

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.error),
      map(({ title, message }: NotifyErrorPayload) =>
        notificationsActions.notify({
          title, message, status: 'error',
        }),
      ),
    ),
  );

  info$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationsActions.info),
      map(({
        title,
        message = 'Opération en cours',
      }: NotifyStatusPayload) =>
        notificationsActions.notify({
          title, message, status: 'info',
        }),
      ),
    ),
  );

  notify$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(notificationsActions.notify),
        map(({
          title,
          message,
          status,
        }: NotifyPayload) =>
          this.alertService.open(message, {
            label: title,
            appearance: status,
          }).subscribe(),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService) {}

}
