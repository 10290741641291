import {
  ComponentRef, Injectable, Renderer2, RendererFactory2, ViewContainerRef,
} from '@angular/core';
import { Widget } from 'src/app/shared/models/widget';

@Injectable({
  providedIn: 'root',
})
export class WidgetService {

  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  loadWidget<T>(viewContainerRef: ViewContainerRef, widget: Widget<T>): ComponentRef<T> {
    viewContainerRef.clear();

    // Create the component
    const componentRef = viewContainerRef.createComponent<T>(
      widget.component,
    );

    // Add the component inputs
    Object.assign(componentRef.instance, widget.config?.context);

    // Give custom class to the component
    this.renderer.addClass(componentRef.location.nativeElement, widget.config.dialogClass);

    return componentRef;
  }

}
