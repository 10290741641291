import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'pxc-image-loading',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './image-loading.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [`
    :host {
      width: 100%;
      height: 100%;
    }
  `],
})
export class ImageLoadingComponent { }
