import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChannelType, ChannelUser } from '@pixacare/pxc-ts-core';
import { TuiLet } from '@taiga-ui/cdk';
import { TuiIcon } from '@taiga-ui/core';
import { Size } from 'src/app/shared/models/enums/size.enum';
import { AvatarComponent } from '../../../shared/components/avatar/avatar.component';
import { ChatHideMyselfPipe } from '../pipes/chat-hide-myself.pipe';

@Component({
  selector: 'pxc-channel-avatar',
  standalone: true,
  imports: [
    CommonModule,
    TuiIcon,
    AvatarComponent,
    ChatHideMyselfPipe,
    TuiLet,
  ],
  templateUrl: './channel-avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelAvatarComponent {

  @Input() channelType: ChannelType;
  @Input() channelUsers: ChannelUser[];
  @Input() size = Size.SMALL;

  ChannelType = ChannelType;
  Size = Size;

}
