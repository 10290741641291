import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Share } from '@pixacare/pxc-ts-core';
import { SHARES_FEATURE_KEY } from './shares.selectors';

export const sharesActions = createActionGroup({
  source: SHARES_FEATURE_KEY,
  events: {
    'Get shares to current user': emptyProps(),
    'Get shares to current user success': props<{ shares: Share[] }>(),
    'Get shared sequences': props<{ shares: Share[] }>(),
  },
});
