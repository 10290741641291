import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TuiNotification } from '@taiga-ui/core';

@Component({
  selector: 'pxc-archived-notification',
  standalone: true,
  imports: [
    CommonModule,
    TuiNotification,
  ],
  templateUrl: './archived-notification.component.html',
})
export class ArchivedNotificationComponent {

  @Input() title = 'Cet élément est archivé';
  @Input() description = 'Vous ne pouvez plus modifier ou ajouter de données à cet élément.';

}
