import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SadmEffects } from './sadm.effects';
import { sadmReducer } from './sadm.reducer';
import { SADM_FEATURE_KEY } from './sadm.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(SADM_FEATURE_KEY, sadmReducer),
    EffectsModule.forFeature([SadmEffects]),
  ],
  declarations: [],
})
export class SadmStoreModule { }
