<p>Sélectionnez la lésion pour laquelle vous souhaitez remplir un questionnaire.</p>
@if (sadms$ | async; as sadms) {
  <div class="my-3 flex flex-col gap-6">
    @for (sadm of sadms; track sadm) {
      <div
        class="flex flex-col gap-1"
        [attr.data-testid]="'sadm-' + sadm.id"
      >
        <div class="align-items-center flex justify-between gap-4">
          <p class="font-bold text-secondary">
            {{ SadmTypeLabel[sadm.sadmType] | pluralize | titlecase }}
          </p>
          <button
            tuiButton
            appearance="secondary"
            size="s"
            (click)="createEntity(sadm)"
            iconStart="@tui.plus"
            data-testid="create-entity"
          >
            Ajouter
          </button>
        </div>
        @if (isDataLoaded$ | async) {
          @if (sadm.entities?.length > 0) {
            <div class="flex flex-col gap-4 px-3 py-2">
              @for (sadmEntity of sadm.entities; track sadmEntity) {
                <label tuiLabel>
                  <input
                    tuiRadio
                    type="radio"
                    [formControl]="selectedSadmIdControl"
                    [value]="sadmEntity.id"
                    [attr.data-testid]="'sadm-entity-' + sadmEntity.id"
                    size="m"
                    ngDefaultControl
                  />
                  {{ sadmEntity | sadmEntityName: sadm.sadmType | titlecase }}
                </label>
              }
            </div>
          } @else {
            <p
              class="p-3 text-secondary"
              data-testid="no-sadm"
            >
              Vous n'avez aucune {{ SadmTypeLabel[sadm.sadmType] }} pour ce patient.
            </p>
          }
        } @else {
          <div
            class="flex h-14 items-center justify-center gap-2"
            data-testid="loading-sadms"
          >
            <pxc-spinner></pxc-spinner>
            <p class="text-secondary">Chargement des questionnaires ...</p>
          </div>
        }
      </div>
    }
  </div>
}

<div class="flex justify-between">
  <button
    tuiButton
    appearance="flat"
    (click)="close()"
    data-testid="cancel-button"
  >
    Annuler
  </button>
  <button
    tuiButton
    [disabled]="selectedSadmIdControl.invalid"
    (click)="validate()"
    data-testid="confirm-button"
  >
    Valider
  </button>
</div>
