import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { TuiButton, TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { AutoCompleteLabelModel } from 'src/app/shared/models/helpers/auto-complete-label-model';
import { SelectLabelComponent } from '../select-label/select-label.component';

@Component({
  selector: 'pxc-label-create',
  templateUrl: './label-create.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SelectLabelComponent,
    TuiButton,
    CommonModule,
  ],
})
export class LabelCreateComponent implements OnInit {

  @Input() labels: string[] = this.context.data.labels ?? [];

  labelsToCreate: AutoCompleteLabelModel[];

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<string[], Partial<LabelCreateComponent>>,
  ) {}

  ngOnInit(): void {
    this.labelsToCreate = this.labels.map((label) => ({ display: label, value: label }));
  }

  confirm(): void {
    this.context.completeWith(this.labelsToCreate.map((label) => label.display));
  }

  cancel(): void {
    this.context.completeWith(null);
  }

}
