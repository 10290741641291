<div class="group relative cursor-pointer">
  <ng-content></ng-content>
  @if (isActive) {
    <button
      class="!absolute right-0 top-0 z-30 transition-opacity md:opacity-0 md:group-hover:opacity-100"
      tuiButtonClose
      tuiIconButton
      type="button"
      data-testid="badge"
    >
      Supprimer
    </button>
  }
</div>
