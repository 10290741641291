import { createReducer, on } from '@ngrx/store';
import { lifeCycleActions } from './life-cycle.actions';
import { initialLifeCycleState, LifeCycleState } from './life-cycle.state';

export const lifeCycleReducer = createReducer(
  initialLifeCycleState,

  on(lifeCycleActions.resetState, () => initialLifeCycleState),

  on(lifeCycleActions.loadError, (state: LifeCycleState, { error }) => ({
    ...state,
    error,
  })),
);
