import { CropConfig } from '@pixacare/pxc-ts-core';
import { Command } from 'src/app/shared/models/helpers/command';
import { StudioCanvas } from '../canvas/studio-canvas';
import { EventBus } from '../event-bus/event-bus';
import { EventTopic } from '../event-bus/event-bus.interfaces';

export class ValidateCropCommand implements Command {

  receiver: StudioCanvas;
  cropConfig: CropConfig;
  eventBus: EventBus;
  constructor(receiver: StudioCanvas, cropConfig: CropConfig, eventBus: EventBus) {
    this.receiver = receiver;
    this.cropConfig = cropConfig;
    this.eventBus = eventBus;
  }

  execute(): void {
    const cropSelection = this.receiver.layer.findOne('#crop-selection');
    Object.assign(this.cropConfig, {
      x: cropSelection.x(),
      y: cropSelection.y(),
      width: cropSelection.width() * cropSelection.scaleX(),
      height: cropSelection.height() * cropSelection.scaleY(),
    });
    this.receiver.destroyCropSelection();

    this.eventBus.publish(EventTopic.CROP_FINISHED);
  }

}
