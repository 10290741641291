<pxc-filter-bar
  class="mb-2"
  data-testid="filter-bar"
></pxc-filter-bar>
<pxc-sequence-select-list
  [selectedSequenceIds]="(selectedSequenceIdsChange | async) ?? []"
  (selectSequences)="selectSequences($event)"
  (unselectSequences)="unselectSequences($event)"
  [singleSelectMode]="singleSelectMode"
  [disabledSequenceIds]="disabledSequenceIds"
  [badgeContent]="badgeContent"
  [showSadms]="showSadms"
  [showTelemonitorings]="showTelemonitorings"
  [showPatient]="showPatient"
  (isAllSequencesSelectedChange)="isAllSequencesSelectedChange($event)"
  data-testid="sequence-select-list"
></pxc-sequence-select-list>
