import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ClientUser } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { ClientUserHttpService } from 'src/app/services/http/client-user.http.service';
import { departmentsActions } from '../departments/departments.actions';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { notificationsActions } from '../notifications/notifications.actions';
import { usersActions } from './users.actions';

@Injectable()
export class UsersEffects {

  getUsers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.getUsers),
      mergeMap(({ clientCode }) =>
        this.clientService.getClientUsers(clientCode)
          .pipe(
            switchMap((clientUsers: ClientUser[]) => [
              usersActions.getUsersSuccess({
                clientCode,
                clientUsers,
              }),
              departmentsActions.getDepartments({ clientCode }),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  addUsers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.addUsers),
      mergeMap(({ mailAddresses, clientCode, departmentId }) =>
        this.clientService.addClientUser(clientCode, mailAddresses, departmentId)
          .pipe(
            switchMap((clientUsers: ClientUser[]) =>
              [
                usersActions.addUsersSuccess({
                  clientCode,
                  clientUsers,
                }),
                notificationsActions.success({
                  message: 'Utilisateur ajouté',
                }),
              ],
            ),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  updateUserLicenseState$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.updateUserLicenseState),
      mergeMap(({ clientCode, userId, licenseState }) =>
        this.clientService.updateClientUserLicenseState(clientCode, userId, licenseState)
          .pipe(
            switchMap(() => [
              usersActions.updateUserLicenseStateSuccess({ clientCode, userId, licenseState }),
              notificationsActions.success({
                message: 'Les droits de l\'utilisateur ont bien été modifiés.',
              }),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly clientService: ClientUserHttpService) {}

}
