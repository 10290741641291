<a
  tuiButton
  appearance="outline"
  iconEnd="@tui.external-link"
  [routerLink]="['/dashboard', 'telemonitorings', telemonitoringId]"
  queryParamsHandling="merge"
  class="mt-2"
>
  Voir le télésuivi
</a>
