import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TuiAlertService } from '@taiga-ui/core';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppConfigHttpService } from 'src/app/services/http/app-config.http.service';
import { passwordValidator } from 'src/app/shared/validators/validators';

@Component({
  templateUrl: './change-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent implements OnInit {

  actionOnGoing$: BehaviorSubject<boolean>;

  changePasswordForm: UntypedFormGroup;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly router: Router,
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService,
    private readonly appConfigService: AppConfigHttpService,
    private readonly authenticationService: AuthenticationService,
  ) {
    this.actionOnGoing$ = new BehaviorSubject(false);
  }

  get inputOldPassword(): AbstractControl {
    return this.changePasswordForm.get('inputOldPassword');
  }

  get inputNewPassword(): AbstractControl {
    return this.changePasswordForm.get('inputNewPassword');
  }

  get inputNewPasswordConfirm(): AbstractControl {
    return this.changePasswordForm.get('inputNewPasswordConfirm');
  }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      inputOldPassword: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(256),
      ]),
      inputNewPassword: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(256),
        passwordValidator(
          this.appConfigService.configuration.inputValidation.password.regex,
        ),
      ]),
      inputNewPasswordConfirm: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(256),
        passwordValidator(
          this.appConfigService.configuration.inputValidation.password.regex,
        ),
      ]),
    });
  }

  changePassword(): void {
    if (!this.changePasswordForm.valid) {
      this.alertService.open('Merci de résoudre les erreurs de saisie.', {
        label: 'Erreur de validation',
        appearance: 'error',
      }).subscribe();
      return;
    }

    if (this.inputNewPassword.value !== this.inputNewPasswordConfirm.value) {
      this.alertService.open('Le nouveau mot de passe est mal renseigné.', {
        label: 'Erreur de validation',
        appearance: 'error',
      }).subscribe();
      return;
    }
    this.actionOnGoing$.next(true);
    this.authenticationService.changePassword({
      oldPassword: this.inputOldPassword.value,
      newPassword: this.inputNewPassword.value,
    }).subscribe({
      next: () => {
        this.actionOnGoing$.next(false);
        this.router.navigate(['/']);
      },
      error: (err: HttpErrorResponse) => {
        this.actionOnGoing$.next(false);

        let title = '';
        let message = '';
        if (err.status === 400) {
          title = 'Ancien mot de passe incorrect';
          message = 'Votre ancien mot de passe ne correspond pas.';
        } else {
          console.error(err);
          title = 'Erreur Système';
          message = 'Une erreur s\'est produite, merci de réessayer plus tard.';
        }
        this.alertService.open(message, {
          label: title,
          appearance: 'error',
        }).subscribe();
      },
    });
  }

}
