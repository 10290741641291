import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Patient } from '@pixacare/pxc-ts-core';
import { MenuEntry } from 'src/app/shared/models/base/menu-entry';
import { PatientTab } from 'src/app/shared/models/enums/patient-tabs.enum';
import { Size } from 'src/app/shared/models/enums/size.enum';
import { ActionButton } from 'src/app/shared/models/menu-actions/action-buttons';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';
import { MenuActionId } from 'src/app/shared/models/menu-actions/menu-action-id.enum';
import { PatientActionsService } from '../../patient/patient-actions.service';
import { PatientService } from './patient.service';

@UntilDestroy()
@Component({
  templateUrl: './patient.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    PatientService,
  ],
})
export class PatientComponent {

  PatientTabsId = PatientTab;
  Size = Size;

  patient$ = this.patientPageService.getPatient();
  isPatientStateLoaded$ = this.patientPageService.getIsPatientStateLoaded();

  actionButtonsGroups: ActionButton[] = [
    {
      appearance: 'secondary',
      title: 'Rapport',
      actionIds: [
        MenuActionId.PATIENT_REPORT,
      ],
    },
    {
      appearance: 'outline',
      title: 'Télésurveiller',
      actionIds: [
        MenuActionId.PATIENT_TELEMONITOR,
      ],
    },
  ];

  tabs: MenuEntry[] = [
    {
      title: 'Photos',
      icon: '@tui.image',
      link: PatientTab.SEQUENCES,
      queryParams: {
        is_archived: undefined,
        sequenceId: undefined,
      },
    },
    {
      title: 'Plaies',
      icon: '@tui.chart-column-increasing',
      link: PatientTab.ANALYSES,
      queryParams: {
        is_archived: 'false',
        sequenceId: undefined,
      },
    },
    {
      title: 'Télésuivis',
      icon: 'telemonitoring',
      link: PatientTab.TELEMONITORINGS,
      queryParams: {
        is_archived: undefined,
        sequenceId: undefined,
      },
    },
  ];

  constructor(
    private readonly patientActionsService: PatientActionsService,
    private readonly patientPageService: PatientService,
  ) { }

  get patientActions(): MenuAction<Patient>[] {
    return this.patientActionsService.getActions();
  }

}
