@if (actionOnGoing$ | async) {
  <pxc-loader data-testid="loading">Vérification en cours ...</pxc-loader>
} @else {
  @if (tokenUser; as user) {
    <div>
      <div class="flex flex-col gap-2 py-2">
        <h2 tuiTitle="m">
          Bonjour {{ user.firstName }},
          <p
            tuiSubtitle
            class="max-w-prose"
          >
            Afin de réinitialiser votre mot de passe, veuillez entrer votre nouveau mot de passe et le confirmer.
          </p>
        </h2>
      </div>
      <form
        class="mt-6 flex flex-col gap-4"
        [formGroup]="resetPasswordForm"
        (ngSubmit)="resetPassword()"
        data-testid="form"
      >
        <div>
          <tui-input-password
            formControlName="inputPassword"
            tuiTextfieldIconLeft="@tui.lock"
            [disabled]="(actionOnGoing$ | async) ? '' : null"
            ngDefaultControl
          >
            Mot de passe
            <input
              tuiTextfieldLegacy
              type="password"
              data-testid="password-input"
            />
          </tui-input-password>
          <tui-error
            formControlName="inputPassword"
            [error]="[] | tuiFieldError | async"
          />
        </div>
        <div class="form-group">
          <tui-input-password
            formControlName="inputPasswordConfirm"
            tuiTextfieldIconLeft="@tui.lock"
            [disabled]="(actionOnGoing$ | async) ? '' : null"
            ngDefaultControl
          >
            Confirmation du mot de passe
            <input
              tuiTextfieldLegacy
              type="password"
              data-testid="confirm-password-input"
            />
          </tui-input-password>
          <tui-error
            formControlName="inputPasswordConfirm"
            [error]="[] | tuiFieldError | async"
          />
        </div>
        <button
          tuiButton
          class="w-full"
          type="submit"
          [disabled]="actionOnGoing$ | async"
          appearance="primary"
          data-testid="confirm"
          ngDefaultControl
        >
          Changer le mot de passe
        </button>
      </form>
    </div>
  } @else {
    <p
      class="w-full pb-4 text-center"
      data-testid="token-invalid"
    >
      Votre lien a expiré.
    </p>
    <button
      tuiButton
      appearance="primary"
      class="w-full"
      routerLink="../reset-password-request"
    >
      Demander un nouveau lien
    </button>
  }
}
