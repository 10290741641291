<div
  tuiWrapper
  appearance="textfield"
  [tuiDropdownLimitWidth]="collapsed ? 'auto' : 'fixed'"
  [(tuiDropdownOpen)]="open"
  [tuiDropdown]="dropdownContent"
  class="flex w-full cursor-pointer items-center justify-between rounded-xl bg-base px-3 py-1.5 transition-all"
  [ngClass]="{
    '!ring ring-inset ring-accent-1': open,
  }"
  data-testid="client-picker-toggle"
  [disabled]="disabled"
>
  <div class="flex items-center">
    <ng-container
      [ngTemplateOutlet]="client"
      [ngTemplateOutletContext]="{
        clientCode: selectedClient,
        hideName: collapsed,
      }"
    ></ng-container>
  </div>
  <tui-icon
    class="text-slate-300 !transition-all"
    [ngClass]="{ 'rotate-180': open }"
    icon="@tui.chevron-down"
    [animateFade]="!collapsed"
  ></tui-icon>
</div>

<ng-template #dropdownContent>
  @if (clientCodes?.length > 0) {
    <div class="flex flex-col p-2">
      @for (clientCode of clientCodes; track clientCode) {
        <div
          class="flex cursor-pointer items-center justify-between rounded-xl p-2 transition-colors hover:bg-neutral-1"
          (click)="selectClient(clientCode)"
          [attr.data-testid]="'client-' + clientCode"
        >
          <ng-container
            [ngTemplateOutlet]="client"
            [ngTemplateOutletContext]="{ clientCode: clientCode }"
          ></ng-container>
          @if (clientCode === selectedClient) {
            <tui-icon
              class="text-secondary"
              icon="@tui.check"
            ></tui-icon>
          }
        </div>
      }
    </div>
  } @else {
    <p class="text-center text-sm text-secondary">Vous n'avez aucun autre espace.</p>
  }
</ng-template>

<ng-template
  #client
  let-clientCode="clientCode"
  let-hideName="hideName"
>
  @if (clientCodes?.length > 0) {
    <div class="my-auto flex items-center gap-4">
      <img
        [src]="cdnClientBaseUrl + (clients[clientCode]?.cdnImagePath || defaultClientImagePath)"
        data-testid="client-image"
        [alt]="clientCode + ' client logo'"
        class="rounded-full border border-border-normal"
        [ngClass]="{
          'h-10 w-10': size !== Size.SMALL,
          'h-8 w-8': size === Size.SMALL,
        }"
      />
      <p
        class="line-clamp-2 leading-snug"
        [ngClass]="{ 'text-sm': size === Size.SMALL }"
        [animateFade]="!hideName"
      >
        {{ clients[clientCode]?.fullName }}
      </p>
    </div>
  }
</ng-template>
