import { ValidatorFn } from '@angular/forms';

export const emailsForbiddenValidator = (): ValidatorFn =>
  (control) => {

    if (control.value && control.value.length > 0) {
      return { emailsForbidden: true };
    }

  };
