import { TuiLet } from '@taiga-ui/cdk';
/* eslint-disable @typescript-eslint/member-ordering */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectChannelId } from '@shared/store/router/router.selectors';
import { map } from 'rxjs';
import { IsMobileService } from '../../../services/is-mobile.service';
import { SharedModule } from '../../shared/shared.module';
import { ChannelListComponent } from '../channel-list/channel-list.component';
import { ChatService } from '../chat.service';
import { MessageGroupService } from '../message-group.service';
import { ChannelNamePipe } from '../pipes/channel-name.pipe';

@Component({
  templateUrl: './chat-page.component.html',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ChannelListComponent,
    RouterModule,
    TuiLet,
  ],
  providers: [
    ChatService,
    MessageGroupService,
    ChannelNamePipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatPageComponent {

  isMobile$ = inject(IsMobileService).maxWidth(1024);

  private readonly store = inject(Store);

  displayChannelPane$ = this.store.select(selectChannelId).pipe(
    map((channelId) => !!channelId),
  );

}
