@if (tabs$ | async; as tabs) {
  <tui-tabs [(activeItemIndex)]="activeItemIndex">
    @for (tab of tabs; track trackByTab($index, tab)) {
      <button
        tuiTab
        (click)="onTabChange(tab)"
      >
        {{ tab.display }}
      </button>
    }
  </tui-tabs>
}
