<h1
  tuiTitle="l"
  class="!mb-4"
>
  Partages
</h1>

@if (areSharedSequencesFetched$ | async) {
  <div class="flex flex-col gap-2">
    <pxc-filter-bar data-testid="search"></pxc-filter-bar>
    @if (sharedSequences$ | async; as sharedSequences) {
      <pxc-sequence-list
        [sequences]="sharedSequences"
        [patientRedirect]="false"
        (loadNext)="loadNextEntities()"
        (sequenceMediaClicked)="openGallery($event)"
        data-testid="sequence-list"
      ></pxc-sequence-list>
    }
  </div>
} @else {
  <pxc-loader data-testid="loading">Récupération de vos données en cours ...</pxc-loader>
}
