import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserStatistics } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StatisticsHttpService {

  constructor(
    private readonly http: HttpClient,
  ) { }

  loadUserStatistics(): Observable<UserStatistics> {
    return this.http
      .get<UserStatistics>(`${environment.apiBaseUrl}/Statistics`);
  }

}
