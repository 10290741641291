import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  ServerUnavailableComponent,
} from './modules/shared/server-unavailable/server-unavailable.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard/home',
    pathMatch: 'full',
  }, {
    path: 'server-unavailable',
    component: ServerUnavailableComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
