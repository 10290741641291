<div
  class="flex aspect-square items-center justify-center"
  [ngClass]="{
    'h-10 w-10 !rounded-lg': size === Size.SMALL,
    'h-12 w-12 rounded-lg': size === Size.MEDIUM,
    'h-16 w-16 rounded-xl': size === Size.LARGE,
  }"
  [ngStyle]="{
    'background-color': avatarColors.backgroundColor,
    color: avatarColors.textColor,
  }"
  data-testid="patient-avatar"
>
  @if (patient && patient.firstName && patient.lastName) {
    <p
      class="font-bold text-current"
      [ngClass]="{
        'text-xl': size !== Size.SMALL,
      }"
    >
      {{ patient.lastName[0] | uppercase }}{{ patient.firstName[0] | uppercase }}
    </p>
  } @else {
    <p
      class="font-bold text-current"
      [ngClass]="{
        'text-xl': size !== Size.SMALL,
        'text-lg': size === Size.SMALL,
      }"
    >
      ?
    </p>
  }
</div>
