import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ChannelUser } from '@pixacare/pxc-ts-core';
import { UserInputSelection } from '@shared/models/helpers/user-input-selection';
import { TuiButton, TuiDialogContext, TuiError } from '@taiga-ui/core';
import { TuiElasticContainer, TuiFieldErrorPipe } from '@taiga-ui/kit';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { UserInputComponent } from 'src/app/shared/components/user-input/user-input.component';

@Component({
  selector: 'pxc-channel-create',
  standalone: true,
  imports: [
    CommonModule,
    TuiButton,
    TuiElasticContainer,
    UserInputComponent,
    FormsModule,
    ReactiveFormsModule,
    TuiError,
    TuiFieldErrorPipe,
  ],
  templateUrl: './channel-create.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelCreateComponent {

  form = new FormGroup({
    users: new FormControl<UserInputSelection[]>([], [Validators.required]),
  });

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<ChannelUser[], void>,
  ) {}

  close(): void {
    this.context.completeWith(null);
  }

  create(): void {

    const value = this.form.get('users').value;

    if (!value.length) {
      return;
    }

    const users = value.map(({ user }) => ({
      firstName: user.firstName,
      lastName: user.lastName,
      id: user.id,
    }));

    this.context.completeWith(users);
  }

}
