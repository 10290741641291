import { createReducer, on } from '@ngrx/store';
import { DepartmentResponse } from '@pixacare/pxc-ts-core';
import { Department } from '../../models/base/department';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { departmentsActions } from './departments.actions';
import { DepartmentsState, initialDepartmentsState } from './departments.state';

const composeDepartment = (dpt: DepartmentResponse): Department => {

  const labelPackIds = dpt.labelPacks?.map((labelPack) => labelPack.id);

  const department = { ...dpt };
  delete department.labelPacks;

  return {
    ...department,
    labelPackIds,
    memberships: department.memberships?.reduce((membershipAcc, membership) => ({
      ...membershipAcc,
      [membership.userId]: membership,
    }), {}),
  };
};

export const departmentsReducer = createReducer(
  initialDepartmentsState,

  on(lifeCycleActions.resetState, () => initialDepartmentsState),

  on(departmentsActions.getDepartmentsSuccess,
    (state: DepartmentsState, { clientCode, departments }) => ({
      ...state,
      departments: {
        ...state.departments,
        [clientCode]: departments.reduce((acc, department) => ({
          ...acc,
          [department.id]: composeDepartment(department),
        }), {} as { [departmentId: number]: Department }),
      },
    }),
  ),

  on(departmentsActions.updateCollaborationSuccess,
    ( state: DepartmentsState, { clientCode, departmentId, isOpen }) => ({
      ...state,
      departments: {
        ...state.departments,
        [clientCode]: {
          ...state.departments[clientCode],
          [departmentId]: {
            ...state.departments[clientCode][departmentId],
            isOpen,
          },
        },
      },
    }),
  ),

  on(departmentsActions.createDepartmentSuccess,
    (state: DepartmentsState, { department, clientCode }) => ({
      ...state,
      departments: {
        ...state.departments,
        [clientCode]: {
          ...state.departments[clientCode],
          [department.id]: composeDepartment(department),
        },
      },
    }),
  ),

  on(departmentsActions.updateDepartmentUserSuccess,
    (
      state: DepartmentsState,
      {
        clientCode, departmentId, departmentUserId, membership,
      },
    ) => ({
      ...state,
      departments: {
        ...state.departments,
        [clientCode]: {
          ...state.departments[clientCode],
          [departmentId]: {
            ...state.departments[clientCode][departmentId],
            memberships: {
              ...state.departments[clientCode][departmentId].memberships,
              [departmentUserId]: {
                ...state.departments[clientCode][departmentId].memberships[departmentUserId],
                isAdmin: membership.isAdmin,
                disabledOn: membership.disabledOn,
              },
            },
          },
        },
      },
    }),
  ),

  on(departmentsActions.removeDepartmentUserSuccess,
    (
      state: DepartmentsState,
      {
        clientCode, departmentId, userId,
      },
    ) => {

      const memberships = { ...state.departments[clientCode][departmentId].memberships };
      delete memberships[userId];

      return {
        ...state,
        departments: {
          ...state.departments,
          [clientCode]: {
            ...state.departments[clientCode],
            [departmentId]: {
              ...state.departments[clientCode][departmentId],
              memberships,
            },
          },
        },
      };

    },
  ),

);
