import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Patient } from '@pixacare/pxc-ts-core';
import { Size } from 'src/app/shared/models/enums/size.enum';

@Component({
  selector: 'pxc-patient-data',
  templateUrl: './patient-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientDataComponent {

  @Input() patient: Patient;
  @Input() customClass: string;
  @Input() size: Size = Size.MEDIUM;

  Size = Size;

}
