import { Injectable, inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, first, switchMap } from 'rxjs';
import { DialogService } from 'src/app/services/dialog.service';
import { DialogFormComponent } from 'src/app/shared/components/dialog-form/dialog-form.component';
import { Department } from 'src/app/shared/models/base/department';
import { departmentsActions } from 'src/app/shared/store/departments/departments.actions';
import { selectClientDepartments } from 'src/app/shared/store/departments/departments.selectors';
import { selectClientCode } from 'src/app/shared/store/router/router.selectors';
import { getDepartmentMembershipsCount } from 'src/app/shared/utils/department-utils';

@Injectable()
export class ClientDepartmentsAdminService {

  private clientDepartments$: Observable<Department[]>;

  private readonly store = inject(Store);
  private readonly dialogService = inject(DialogService);

  loadClientDepartments$(): Observable<Department[]> {
    this.clientDepartments$ = this.store.select(selectClientCode).pipe(
      switchMap((clientCode) => this.store.select(selectClientDepartments(clientCode))),
    );
    return this.clientDepartments$;
  }

  openCreateDepartment(): void {
    combineLatest([
      this.store.select(selectClientCode),
      this.dialogService.openComponent<DialogFormComponent, { departmentName: string }>(DialogFormComponent, {
        label: 'Créer un groupe',
        data: {
          inputs: [{
            label: 'Nom du groupe',
            property: 'departmentName',
            validators: [Validators.required, Validators.maxLength(127), Validators.minLength(3)],
          }],
          confirmLabel: 'Créer',
        },
      }),
    ]).pipe(first()).subscribe(([clientCode, { departmentName }]) => {
      if (departmentName && departmentName.length > 0) {
        this.store.dispatch(
          departmentsActions.createDepartment({ clientCode, name: departmentName }),
        );
      }
    });
  }

  sortByMembersCount(departmentA: Department, departmentB: Department): number {
    return getDepartmentMembershipsCount(departmentB) - getDepartmentMembershipsCount(departmentA);
  }

}
