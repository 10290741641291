@if (collection$ | async; as collection) {
  <div class="flex flex-col gap-4">
    <div class="flex flex-col justify-between animate-in fade-in md:flex-row md:items-center">
      <h1 tuiTitle="l">
        {{ mode === ContextualComponentMode.USER ? 'Mes photos' : (departmentName$ | async) }}
        @if (mode === ContextualComponentMode.DEPARTMENT) {
          <span tuiSubtitle>Photos du groupe</span>
        }
      </h1>
      @if (hasLicenseWriteAccess$ | async) {
        <pxc-create-button
          class="flex-none"
          (click)="addSequence()"
          data-testid="add-sequence"
          label="Ajouter une séquence"
        ></pxc-create-button>
      }
    </div>
    <pxc-filter-bar
      searchPlaceholder="Rechercher une séquence par mot clé, date ou patient ..."
      data-testid="filter-bar"
      class="animate-in fade-in"
    ></pxc-filter-bar>
    @if (isCollectionLoaded$ | async) {
      <pxc-sequence-list
        [sequences]="collection.data"
        [actions]="actions"
        [isNextPageLoading]="collection.context.isNextPageLoading"
        [isPreviousPageLoading]="collection.context.isPreviousPageLoading"
        (loadPrevious)="loadPreviousPage()"
        (loadNext)="loadNextPage()"
        (sequenceMediaClicked)="openSequenceMediaGallery($event)"
        data-testid="sequence-list"
      ></pxc-sequence-list>
    } @else {
      <ng-container [ngTemplateOutlet]="loading"></ng-container>
    }
  </div>
} @else {
  <ng-container [ngTemplateOutlet]="loading"></ng-container>
}

<ng-template #loading>
  <pxc-loader
    data-testid="loading"
    class="my-5"
  >
    Récupération de vos séquences en cours ...
  </pxc-loader>
</ng-template>
