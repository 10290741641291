import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { FlattenedLicense, License, writeAccessLicenseStates } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { LicenseHttpService } from 'src/app/services/http/license.http.service';
import { clientsActions } from '../clients/clients.actions';
import { departmentsActions } from '../departments/departments.actions';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { notificationsActions } from '../notifications/notifications.actions';
import { usersActions } from '../users/users.actions';
import { licensesActions } from './licenses.actions';

@Injectable()
export class LicensesEffects {

  loadLicenses$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(licensesActions.loadLicenses),
      mergeMap(() =>
        this.licenseService.getLicenses()
          .pipe(
            switchMap((flattenedLicenses: FlattenedLicense[]) => [
              licensesActions.loadLicensesSuccess({ flattenedLicenses }),
            ]),
            catchError((error: Error) => [
              lifeCycleActions.loadError({ error }),
            ]),
          ),
      ),
    ),
  );

  loadLicensesSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(licensesActions.loadLicensesSuccess),
      switchMap(({ flattenedLicenses }) => {

        const loadClientActions = [];

        flattenedLicenses.forEach(({ client, license }) => {

          if (writeAccessLicenseStates.includes(license.state)) {
            loadClientActions.push(
              usersActions.getUsers({ clientCode: client.code }),
            );
            return;
          }

          loadClientActions.push(
            usersActions.getUsersSuccess({ clientCode: client.code, clientUsers: [] }),
            departmentsActions.getDepartmentsSuccess({ clientCode: client.code, departments: [] }),
          );

        });

        return [
          clientsActions.updateClients({ clients: flattenedLicenses.map((flattenedLicense) => ({
            ...flattenedLicense.client,
            clientDataConsumption: flattenedLicense.clientDataConsumption,
          })) }),
          ...loadClientActions,
        ];
      }),
      catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
    ),
  );

  answerInvitation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(licensesActions.answerInvitation),
      mergeMap(({ licenseId, accepted }) =>
        this.licenseService.answerInvitation(licenseId, accepted)
          .pipe(
            switchMap((license: License) => [
              licensesActions.answerInvitationSuccess({ license }),
              licensesActions.loadLicenses(),
              notificationsActions.success(
                {
                  title: accepted ? 'Invitation Acceptée' : 'Invitation Refusée',
                  message: `Vous avez bien ${accepted ? 'accepté' : 'refusé'} l'invitation`,
                }),
            ],
            ),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly licenseService: LicenseHttpService,
  ) {}

}
