<pxc-tile class="border-b border-border-normal px-3 py-2">
  <pxc-channel-avatar
    leading
    *showIfDesktop="{
      elseTemplate: backButton,
    }"
    [channelType]="channel?.type"
    [channelUsers]="channel?.users"
    data-testid="channel-avatar"
  ></pxc-channel-avatar>

  <p
    title
    class="line-clamp-2 text-lg"
  >
    {{ channel | channelName }}
  </p>

  @if (channel?.users) {
    <button
      subtitle
      type="button"
      class="w-fit text-secondary transition hover:text-secondary"
      (click)="openChannelInfo.emit()"
      data-testid="channel-participants"
    >
      {{ channel.users?.length | i18nPlural: participantsPluralMapping }}
      <tui-icon icon="@tui.chevron-right"></tui-icon>
    </button>
  }
</pxc-tile>

<ng-template #backButton>
  <a
    leading
    tuiIconButton
    appearance="flat"
    iconStart="@tui.arrow-left"
    routerLink=".."
    queryParamsHandling="merge"
  ></a>
</ng-template>
