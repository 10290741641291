import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TuiActiveZone } from '@taiga-ui/cdk';
import { TuiDropdown, TuiIcon } from '@taiga-ui/core';
import { TuiWrapperModule } from '@taiga-ui/legacy';
import { ClientData } from 'src/app/shared/models/base/client-data';
import { Size } from 'src/app/shared/models/enums/size.enum';
import { environment } from 'src/environments/environment';
import { AnimateFadeDirective } from '../../shared/directives/animate-fade.directive';
import { SharedModule } from '../../shared/shared.module';

@Component({
  selector: 'pxc-client-picker',
  templateUrl: './client-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiIcon,
    TuiDropdown,
    TuiActiveZone,
    SharedModule,
    AnimateFadeDirective,
    TuiWrapperModule,
  ],
})
export class ClientPickerComponent implements OnInit {

  @Input() clients: { [clientCode: string]: ClientData };
  @Input() selectedClient: string;
  @Input() collapsed = false;
  @Input() size = Size.MEDIUM;
  @Input() disabled = false;

  @Output() selectedClientChange = new EventEmitter<string>();

  cdnClientBaseUrl: string = environment.cdn.clientBaseUrl;
  defaultClientImagePath = 'default.png';
  clientCodes: string[] = [];
  open = false;
  Size = Size;

  ngOnInit(): void {
    this.clientCodes = Object.keys(this.clients ?? {});
  }

  selectClient(clientCode: string): void {
    this.selectedClientChange.emit(clientCode);
    this.open = false;
  }

}
