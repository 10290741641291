import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'pxc-patient-link-quick-action',
  templateUrl: './patient-link-quick-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientLinkQuickActionComponent {

}
