import { defaultPagedCollection } from '../../models/pagination/default-paged-collection.config';
import { PagedCollection } from '../../models/pagination/paged-collection';
import { StoreSequence } from '../../models/store/store-sequence';

type PagedSequencesCollection = PagedCollection<{ [sequenceId: number]: StoreSequence }>;

export interface SequencesState {
  sequences: PagedCollection<{ [sequenceId: number]: StoreSequence }>;
  synchronizeSequenceOnGoing: boolean;
  isLoaded: boolean;
}

export const initialSequencesState: SequencesState = {
  sequences: {
    ...defaultPagedCollection as PagedSequencesCollection,
  },
  synchronizeSequenceOnGoing: false,
  isLoaded: false,
};
