import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FlattenedSequence, PaginationDirection, PaginationQuery } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { BasePagination } from '../shared/base-pagination';
import { PagedCollection } from '../shared/models/pagination/paged-collection';
import { defaultPaginationContext } from '../shared/models/pagination/pagination-context.config';
import { sequencesActions } from '../shared/store/sequences/sequences.actions';
import { selectSequences } from '../shared/store/sequences/sequences.selectors';

@Injectable({
  providedIn: 'root',
})
export class SequenceStorePaginationService extends BasePagination<FlattenedSequence> {

  constructor(
    private readonly store: Store,
  ) {
    super();
  }

  load(clientCode: string, {
    query = new PaginationQuery({ orderBy: ['created_on|desc'], size: defaultPaginationContext.countPerPage }),
    direction = PaginationDirection.NEXT,
    reset = false,
  } = {}): void {

    if (direction === PaginationDirection.PREVIOUS) {
      this.store.dispatch(sequencesActions.getPreviousUserSequences({ clientCode, options: { query, reset } }));
    } else {
      this.store.dispatch(sequencesActions.getNextUserSequences({ clientCode, options: { query, reset } }));
    }
  }

  select(): Observable<PagedCollection<FlattenedSequence[]>> {
    this.collection$ = this.store.select(selectSequences);
    return this.collection$;
  }

}
