import { TelemonitoringRequestForm } from '@modules/telemonitoring/telemonitoring-request/telemonitoring-request-form';

export const telemonitoringRequestStepsConfig: {
  name: string;
  hidePrevious?: boolean;
  hideNext?: boolean;
  fields?: (keyof TelemonitoringRequestForm)[];
}[] = [
  {
    name: 'Type',
    hideNext: true,
    fields: ['creatorType'],
  },
  {
    name: 'Emplacement',
    fields: ['clientCode', 'departmentIds'],
  },
  {
    name: 'Détails',
    fields: ['name', 'message', 'patientForm', 'patientContact', 'shouldSendTextMessageToPatient'],
  },
  {
    name: 'Photos',
    fields: ['sequences'],
  },
  {
    name: 'Collaboration',
    fields: ['collaborators'],
  },
];
