import { Command } from 'src/app/shared/models/helpers/command';

export class CommandInvoker {

  private commands: Record<string, Command>;

  constructor(commands: Record<string, Command> = {}) {
    this.commands = commands;
  }

  set(commandKey: string, command: Command): void {
    this.commands[commandKey] = command;
  }

  async execute(commandKey: string): Promise<void> {
    await this.commands[commandKey].execute();
  }

}
