import { AppConfigHttpService } from '@services/http/app-config.http.service';
import { PolymorpheusContent } from '@taiga-ui/polymorpheus';
import { Observable } from 'rxjs';

type ValidationFactoryResult = Record<string, PolymorpheusContent<any> | Observable<PolymorpheusContent<any>>>;

export const validationErrorFactory = (configService: AppConfigHttpService): ValidationFactoryResult => {

  const config = configService.configuration.inputValidation;

  const messages = Object.keys(config).reduce((acc, key) => {
    if (config[key].message) {
      acc[key] = config[key].message;
    }
    return acc;
  }, {} as Record<string, string>);

  return {
    ...messages,
    required: 'Ce champ est requis.',
    maxlenght: ({ requiredLength }: { requiredLength: number }) =>
      `Vous ne pouvez pas entrer plus de ${requiredLength} caractères.`,
    minlength: ({ requiredLength }: { requiredLength: number }) =>
      `Vous devez entrer au moins ${requiredLength} caractères.`,
    email: 'Veuillez entrer une adresse email valide.',
    pattern: 'Le format de ce champ est invalide.',
    departmentRequired: 'Veuillez sélectionner au moins un groupe.',
    emailExcluded: 'Vous ne pouvez pas ajouter cette adresse email.',
    emailsForbidden: 'Vous ne pouvez pas ajouter une adresse email, veuillez sélectionner un utilisateur',
  };

};
