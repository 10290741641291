<div class="align-center flex justify-start">
  @for (button of buttons$ | async; track button; let index = $index) {
    <button
      tuiButton
      appearance="custom"
      size="s"
      class="tui-space_right-2 !min-w-20"
      [ngClass]="index === selectedIndex ? '!bg-neutral-2' : '!bg-neutral-1'"
      (click)="onButtonClick(button, index)"
    >
      {{ button.display }}
    </button>
  }
</div>
