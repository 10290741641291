import { Directive, ElementRef, HostListener, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';
import { selectDepartmentId } from 'src/app/shared/store/router/router.selectors';

@Directive({
  selector: '[patientLink]',
  standalone: true,
})
export class PatientLinkDirective {

  patientId: number;
  private readonly elementRef = inject(ElementRef);
  private readonly departmentId$ = inject(Store).select(selectDepartmentId);
  private readonly router = inject(Router);

  @Input() set patientLink(patientId: number) {
    this.patientId = patientId;
    this.elementRef.nativeElement.style.cursor = 'pointer';
  }

  @HostListener('click') onClick(): void {
    this.departmentId$.pipe(first()).subscribe((departmentId) => {
      this.router.navigate(this.getPatientRoute(departmentId), {
        queryParamsHandling: 'merge',
        queryParams: { is_archived: undefined },
      });
    });
  }

  private getPatientRoute(departmentId: number): (string | number)[] {
    if (departmentId) {
      return ['/dashboard/department', departmentId, 'patients', this.patientId];
    }
    return ['/dashboard/patients', this.patientId];
  }

}
