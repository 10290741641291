import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, filter, first, switchMap, take } from 'rxjs';
import { lifeCycleActions } from '../shared/store/life-cycle/life-cycle.actions';
import { selectIsStoreInitialized } from '../shared/store/life-cycle/life-cycle.selectors';

@Injectable({
  providedIn: 'root',
})
export class GuardInitializerService {

  private readonly store = inject(Store);

  executeOrWaitForStoreInitialization$<T>(action$: Observable<T>): Observable<T> {
    return this.store.select(selectIsStoreInitialized).pipe(
      first(),
      switchMap((isStoreInitialized) => {
        if (!isStoreInitialized) {
          this.store.dispatch(lifeCycleActions.initialize());
          return this.store.select(selectIsStoreInitialized).pipe(
            filter((initialized) => initialized),
            take(1),
            switchMap(() => action$),
          );
        } else {
          return action$;
        }
      }),
    );
  }

}
