import { PaginationContext } from '@pixacare/pxc-ts-core';

export const defaultPaginationContext: PaginationContext = {
  currentPages: 3,
  lastPage: -1,
  hasPreviousPage: false,
  hasNextPage: true,
  countPerPage: 10,
  isPreviousPageLoading: false,
  isNextPageLoading: false,
};

export const patientPaginationContext: PaginationContext = {
  ...defaultPaginationContext,
  countPerPage: 20,
};
