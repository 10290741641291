import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FlattenedSequence } from '@pixacare/pxc-ts-core';
import { MediaClickedOutput } from 'src/app/shared/models/media-clicked-output';
import { sequencesPluralMapping } from 'src/app/shared/utils/plural-mappings';

@Component({
  selector: 'pxc-sequence-stacks-preview',
  templateUrl: './sequence-stacks-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequenceStacksPreviewComponent {

  @Input() sequences: FlattenedSequence[];
  @Input() centered = false;
  @Output() mediaClicked: EventEmitter<MediaClickedOutput> = new EventEmitter();

  displayedSequencePreviews = 6;

  sequencesPluralMapping = sequencesPluralMapping;

  getRemaining(index: number): number {
    const offset = index < this.displayedSequencePreviews ? index + 2 : index + 1;

    return this.sequences.length - offset;
  }

  mediaClick(idx: number, sequence: FlattenedSequence): void {
    this.mediaClicked.emit({
      mediaIdx: idx,
      sequenceId: sequence.sequenceInstance.id,
      clientCode: sequence.clientCode,
    });
  }

}
