<div class="flex items-center gap-2 py-1 max-sm:flex-col-reverse">
  <ng-content></ng-content>

  <button
    tuiLink
    appearance="flat"
    type="button"
    (click)="toggleAll()"
    data-testid="checkbox-all"
    [disabled]="disabledItems.length === items?.length"
    class="ml-auto"
  >
    {{ items?.length === selection.length ? 'Tout désélectionner' : 'Tout sélectionner' }}
  </button>
</div>

<tui-scrollbar
  class="w-full flex-1 rounded-xl border border-border-normal bg-base"
  scroll-tracker
  [threshold]="{ bottom: 100 }"
  (bottomThreshold)="loadNextPage.emit()"
  data-testid="scroll-wrapper"
>
  <tui-data-list
    [emptyContent]="emptyContent"
    size="s"
  >
    <tui-opt-group
      tuiMultiSelectGroup
      [ngModel]="selection"
      (ngModelChange)="selectionChange.emit($event)"
      data-testid="opt-group"
      ngDefaultControl
    >
      <tui-opt-group>
        @for (item of items; track trackByValue($index, item)) {
          <button
            tuiOption
            size="m"
            [value]="item.value"
            [disabled]="disabledItems.includes(item.value)"
          >
            <p>{{ item.display }}</p>
          </button>
        }
      </tui-opt-group>
    </tui-opt-group>
  </tui-data-list>
</tui-scrollbar>

<ng-template #emptyContent>
  <p class="mx-auto my-4 max-w-prose text-center text-secondary">{{ emptyMessage }}</p>
</ng-template>
