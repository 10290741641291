import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  BaseSadmEntity,
  FlattenedSequence,
  FormAnswer,
  FormAnswers,
  Media,
  PagedResponse, PaginationOptions, Patient,
  Sequence,
} from '@pixacare/pxc-ts-core';
import { SEQUENCES_FEATURE_KEY } from './sequences.selectors';

export const sequencesActions = createActionGroup({
  source: SEQUENCES_FEATURE_KEY,
  events: {
    'Reset sequences state': emptyProps(),

    'Set synchronize sequence ongoing': props<{ synchronizeSequenceOnGoing: boolean }>(),

    'Get user sequence': props<{ clientCode: string; sequenceId: number }>(),

    'Update flattened sequence success':  props<{ clientCode: string; sequence: FlattenedSequence }>(),

    'Update sequence success': props<{ clientCode: string; updatedSequence: Sequence }>(),

    'Get sequences success': props<{
      clientCode: string;
      reset?: boolean;
      collection: PagedResponse<FlattenedSequence>;
    }>(),

    'Get previous user sequences': props<{
      clientCode: string;
      options?: PaginationOptions;
    }>(),
    'Get previous user sequences success':  props<{
      clientCode: string;
      reset?: boolean;
      collection: PagedResponse<FlattenedSequence>;
    }>(),

    'Get next user sequences': props<{
      clientCode: string;
      options?: PaginationOptions;
    }>(),
    'Get next user sequences success': props<{
      clientCode: string;
      reset?: boolean;
      collection: PagedResponse<FlattenedSequence>;
    }>(),
    'Load sequence entities':  props<{ clientCode: string;sequence: FlattenedSequence }>(),
    'Load sequences entities': props<{
      clientCode: string;
      reset?: boolean;
      collection: PagedResponse<FlattenedSequence>;
    }>(),

    'Load sequences': props<{
      clientCode: string;
      data: FlattenedSequence[];
    }>(),

    'Update sequence patient': props<{
      clientCode: string;
      sequenceId: number;
      patient: Patient;
    }>(),

    'Update sequence labels': props<{
      clientCode: string;
      userId: number;
      sequenceId: number;
      existingLabels: number[];
      createdLabels: string[];
    }>(),

    'Update sequence media': props<{
      clientCode: string;
      sequenceId: number;
      mediaId: number;
    }>(),
    'Update sequence media success': props<{
      sequenceId: number;
      updatedMedia: Media;
    }>(),

    'Synchronize sequence': props<{ data: FormData }>(),
    'Synchronize sequence success': props<{
      clientCode: string;
      sequence: FlattenedSequence;
    }>(),

    'Synchronize sequence error': props<{ error: Error }>(),

    'Add sequence media': props<{
      clientCode: string;
      sequenceId: number;
      mediaId: number;
    }>(),
    'Add sequence media success':  props<{ sequenceId: number;updatedMedia: Media }>(),

    'Patch sequence':  props<{ sequence: Sequence;clientCode: string }>(),

    'Move sequence': props<{
      fromClientCode: string;
      toClientCode: string;
      sequenceId: number;
      overridePatient: boolean;
    }>(),

    'Delete sequence': props<{ clientCode: string;sequenceId: number }>(),
    'Delete sequence success': props<{ sequenceId: number }>(),

    'Delete sequence media': props<{
      clientCode: string;
      sequenceId: number;
      mediaId: number;
    }>(),
    'Delete sequence media success': props<{ sequenceId: number;mediaId: number }>(),

    'Attach sequence medias': props<{ clientCode: string; sequenceId: number; medias: File[] }>(),
    'Attach sequence medias success': props<{ updatedSequence: FlattenedSequence }>(),

    'Add sequence analysis': props<{
      clientCode: string;
      sequenceId: number;
      answers: FormAnswers;
      protocolCreationFormAnswer?: FormAnswer;
      sadmEntity?: BaseSadmEntity;
      sadmEntityId?: number;
    }>(),

    'Edit sequence analysis': props<{
      clientCode: string;
      sequenceId: number;
      answers: FormAnswers;
    }>(),

    'Delete sequence analysis': props<{
      clientCode: string;
      sequenceId: number;
    }>(),

    'Get sequence analysis': props<{
      clientCode: string;
      sequenceId: number;
    }>(),

    'Delete sadm Entity from sequences': props<{ sadmEntityId: number }>(),

  },
});
