<pxc-tile [vertical]="vertical">
  <pxc-channel-avatar
    leading
    [channelType]="channel?.type"
    [channelUsers]="channel?.users"
    [size]="size"
    data-testid="channel-avatar"
  ></pxc-channel-avatar>

  <tui-line-clamp
    title
    [content]="channelName"
    [linesLimit]="1"
    [lineHeight]="20"
    [ngClass]="{ 'max-w-24': vertical }"
  ></tui-line-clamp>

  <ng-template #channelName>
    <span class="text-sm font-medium">
      {{ channel | channelName }}
    </span>
  </ng-template>

  @if (showDetails) {
    <span
      date
      data-testid="date"
      [ngClass]="{
        'font-bold': unreadsCount,
        'text-tertiary': !unreadsCount,
      }"
    >
      {{ date | date: 'shortDate' }}
    </span>
  }

  @if (showDetails) {
    <div
      subtitle
      class="flex"
    >
      <span
        data-testid="subtitle"
        class="mt-1 line-clamp-1 w-full"
        [ngClass]="{
          'font-bold': unreadsCount,
          'text-secondary': !unreadsCount,
        }"
      >
        {{ channel | channelSubtitle }}
      </span>
      @if (unreadsCount) {
        <tui-badge
          class="shrink-0 bg-negative"
          appearance="custom"
          data-testid="counter"
          size="m"
        >
          {{ unreadsCount }}
        </tui-badge>
      }
    </div>
  }
</pxc-tile>
