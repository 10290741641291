import { createReducer, on } from '@ngrx/store';
import { Label } from '@pixacare/pxc-ts-core';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { labelsActions } from './labels.actions';
import { initialLabelsState, LabelsState } from './labels.state';

export const labelsReducer = createReducer(
  initialLabelsState,

  on(lifeCycleActions.resetState, () => initialLabelsState),

  on(labelsActions.getUserLabelsSuccess,
    (state: LabelsState, { flattenedLabels }) => ({
      ...state,
      labels: flattenedLabels.reduce((acc, flattenedLabel) => ({
        ...acc,
        [flattenedLabel.labelInstance.id]: {
          ...flattenedLabel.labelInstance,
          statistics: {
            count: flattenedLabel.userLabelStatsInstance?.count,
            subscribersCount: flattenedLabel.data.subscribersCount,
          },
        },
      }), {} as { [labelId: number]: Label }),
      userLabels: [...new Set(
        flattenedLabels.map((flattenedLabel) => flattenedLabel.labelInstance.id),
      )],
    }),
  ),

  on(labelsActions.addLabels,
    (state, { labels, isUsedByUser = false }) => ({
      ...state,
      labels: {
        ...state.labels,
        ...labels.reduce((acc, label) => ({
          ...acc,
          [label.id]: {
            ...state.labels[label.id],
            ...label,
          },
        }), {}),
      },
      userLabels: [...new Set([
        ...state.userLabels,
        ...(isUsedByUser ? labels.map((label) => label.id) : []),
      ])],
    }),
  ),

  on(labelsActions.unsubscribeFromLabelSuccess,
    (state: LabelsState, { labelId }) => ({
      ...state,
      userLabels: state.userLabels.filter((label) => label !== labelId),
    }),
  ),

  // ---- Label packs ----

  on(labelsActions.addLabelPack,
    (state: LabelsState, { labelPacks }) => ({
      ...state,
      labelPacks: {
        ...state.labelPacks,
        ...Object.assign({}, ...labelPacks.map((labelPack) => {

          const newLabelPack = { ...labelPack };
          const labelIds = newLabelPack.labels.map((label) => label.id);
          delete newLabelPack.labels;

          return {
            [labelPack.id]: {
              ...newLabelPack,
              labelIds,
            },
          };

        })),
      } }),
  ),

  on(labelsActions.createLabelPackLabelsSuccess,
    (state: LabelsState, { labelIds, labelPackId }) => ({
      ...state,
      labelPacks: {
        ...state.labelPacks,
        [labelPackId]: {
          ...state.labelPacks[labelPackId],
          labelIds: [
            ...state.labelPacks[labelPackId].labelIds,
            ...labelIds,
          ],
        },
      },
    }),
  ),

  on(labelsActions.deleteLabelPackLabelsSuccess,
    (state: LabelsState, { labelIds, labelPackId }) => ({
      ...state,
      labelPacks: {
        ...state.labelPacks,
        [labelPackId]: {
          ...state.labelPacks[labelPackId],
          labelIds: state.labelPacks[labelPackId].labelIds.filter((label) => !labelIds.includes(label)),
        },
      },
    }),
  ),

);
