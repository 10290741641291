import { Pipe, type PipeTransform } from '@angular/core';
import { ChannelUser } from '@pixacare/pxc-ts-core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Pipe({
  name: 'chatHideMyself',
  standalone: true,
})
export class ChatHideMyselfPipe implements PipeTransform {

  constructor(
    private authenticationService: AuthenticationService,
  ) { }

  transform(value: ChannelUser[]): ChannelUser[] {
    return value?.filter((user) => user.id !== this.authenticationService.currentUser.id);
  }

}
