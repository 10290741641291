import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PagedResponse, PaginationQuery, Patient } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { PatientHttpService } from 'src/app/services/http/patient.http.service';
import { getNextPagedCollection } from 'src/app/shared/models/helpers/pagination-helper';
import { PagedCollection } from 'src/app/shared/models/pagination/paged-collection';
import { patientPaginationContext } from 'src/app/shared/models/pagination/pagination-context.config';
import { Pagination } from 'src/app/shared/pagination';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class PatientPaginationService extends Pagination<Patient> {

  constructor(
    private readonly patientService: PatientHttpService,
  ) {
    super();
  }

  getNextData(clientCode: string, { query }: { query: PaginationQuery }): Observable<PagedResponse<Patient>> {
    return this.patientService.getPatients(clientCode, { query });
  }

  getNextCollection({ currentCollection, nextCollection, reset }: {
    currentCollection: PagedCollection<{ [id: number]: Patient }>;
    nextCollection: PagedResponse<Patient>;
    reset: boolean;
  }): PagedCollection<{ [id: number]: Patient }> {

    return getNextPagedCollection({ currentCollection, nextCollection }, {
      reset,
      paginationContext: patientPaginationContext,
      sortPredicate: (entityId1, entityId2, entities) => (
        entities[entityId2].lastActivity?.getTime() - entities[entityId1].lastActivity?.getTime()
      ),
    });
  }

}
