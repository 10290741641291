import { StudioColor } from './studio-interfaces';

export const studioColors: StudioColor[] = [
  {
    code: '#ef4444',
    name: 'Red',
  },
  {
    code: '#f97316',
    name: 'Orange',
  },
  {
    code: '#eab308',
    name: 'Yellow',
  },
  {
    code: '#84cc16',
    name: 'Lime Green',
  },
  {
    code: '#10b981',
    name: 'Emerald',
  },
  {
    code: '#14b8a6',
    name: 'Teal',
  },
  {
    code: '#06b6d4',
    name: 'Sky Blue',
  },
  {
    code: '#3b82f6',
    name: 'Cerulean',
  },
  {
    code: '#6366f1',
    name: 'Royal Blue',
  },
  {
    code: '#8b5cf6',
    name: 'Purple',
  },
  {
    code: '#ec4899',
    name: 'Magenta',
  },
  {
    code: '#f43f5e',
    name: 'Pink',
  },
  {
    code: '#FFFFFF',
    name: 'White',
  },
  {
    code: '#000000',
    name: 'Black',
  },
  {
    code: '#64748b',
    name: 'Steel Blue',
  },
];
