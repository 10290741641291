import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LocationService {

  constructor(
    private readonly router: Router,
  ) {}

  updateUrlParams(route: ActivatedRoute, queryParams: Params): void {
    this.router.navigate(['.'], {
      relativeTo: route,
      queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });

  }

}
