<div class="flex flex-col gap-4">
  <h3
    tuiTitle="m"
    data-testid="title"
  >
    Ajouter des collaborateurs
    <span
      tuiSubtitle
      class="!text-sm"
    >
      Les utilisateurs avec lesquels vous choisissez de partager ce télésuivi pourront accéder aux photographies du
      patient et participer à la conversation associée.
    </span>
  </h3>

  <pxc-user-input
    [allowEmails]="true"
    [disabled]="disabled"
    [ngModel]="userInputValue"
    (ngModelChange)="onSelectChange($event)"
    [placeholder]="placeholder"
    [excludedIds]="excludedIds"
    [allowEmails]="false"
    data-testid="input"
    ngDefaultControl
    class="min-h-20"
  ></pxc-user-input>

  <tui-scrollbar class="max-h-96 min-h-24">
    <p class="sticky top-0 z-10 w-full bg-base pb-2 text-sm font-medium text-secondary md:bg-base-alt">
      Collaborateurs
    </p>
    <div
      class="flex flex-col gap-3"
      tuiFade="vertical"
      tuiScrollable
    >
      @for (item of selectedUsers; track item; let idx = $index) {
        <div class="flex items-center gap-2">
          <pxc-user-card
            [user]="item.user"
            [mailAddress]="item.email"
            class="flex-1"
            [attr.data-testid]="'user-card-' + item.user?.id"
          ></pxc-user-card>
          @if (item.user?.id !== currentUserId) {
            <button
              tuiButtonClose
              tuiIconButton
              (click)="removeUser(idx)"
              [attr.data-testid]="'remove-user-' + item.user?.id"
              type="button"
              class="mr-4"
            ></button>
          }
        </div>
      }
    </div>
    @if (suggestedUsers$ | async; as suggestedUsers) {
      @if (shouldShowSuggestions(suggestedUsers)) {
        <div class="h-12"></div>
        <div class="sticky top-0 z-10 flex flex-1 flex-wrap justify-between gap-2 bg-white pb-2 text-sm md:bg-slate-50">
          <p class="text-sm font-medium text-slate-600">Suggestions</p>
          <button
            tuiLink
            (click)="addAllSuggestions(suggestedUsers)"
            type="button"
            data-testid="add-all-suggestions"
            appearance="flat"
          >
            Ajouter tout
          </button>
        </div>
        <div
          class="flex flex-col gap-3"
          tuiFade="vertical"
          tuiScrollable
        >
          @for (user of suggestedUsers; track user.id + user.medicalSpecialtyLabel; let idx = $index) {
            @if (shouldShowUser(user.id)) {
              <div class="flex items-center gap-2">
                <pxc-user-card
                  [user]="user"
                  class="flex-1"
                  [attr.data-testid]="'suggested-user-' + user.id"
                ></pxc-user-card>
                <button
                  tuiButton
                  (click)="addFromSuggestion(user)"
                  appearance="outline"
                  [attr.data-testid]="'add-suggestion-' + user.id"
                  type="button"
                >
                  Ajouter
                </button>
              </div>
            }
          }
        </div>
      }
    }
  </tui-scrollbar>
</div>
