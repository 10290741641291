<div class="flex flex-col gap-4">
  <div class="flex gap-8">
    <div class="h-32 w-32 rounded-xl bg-neutral-1"></div>

    <div class="flex flex-col gap-2">
      <h3
        tuiTitle="m"
        data-testid="sadm-entity-name"
      >
        {{ sadmEntity | sadmEntityName: sadmClient.sadmType | titlecase }}
      </h3>
      <pxc-patient-card
        [patient]="sadmEntity.patient"
        class="w-fit"
        data-testid="patient-card"
      ></pxc-patient-card>
    </div>
  </div>

  <div class="flex items-center justify-between gap-2 border-b border-border-normal py-2">
    <h5 tuiTitle>Protocole de soin</h5>
    <button
      type="button"
      tuiButton
      size="s"
      appearance="outline"
      iconEnd="@tui.pencil"
      (click)="edit()"
      data-testid="edit-protocol"
      [disabled]="sadmEntity.isArchived || (sadmEntity.patient && sadmEntity.patient.isArchived)"
    >
      Modifier
    </button>
  </div>

  <div class="flex h-[30vh] gap-2">
    <div class="flex w-40 flex-col gap-2">
      <p
        class="text-secondary"
        tuiTitle="s"
      >
        Historique
      </p>
      <tui-scrollbar>
        <tui-tabs
          vertical="left"
          [(activeItemIndex)]="index"
        >
          @for (protocol of sadmEntity.protocolsHistory; track protocol) {
            <button
              tuiTab
              [attr.data-testid]="'protocol-' + protocol.id"
            >
              <pxc-date-preview [date]="protocol.createdOn"></pxc-date-preview>
            </button>
          }
        </tui-tabs>
      </tui-scrollbar>
    </div>

    <div
      class="flex-1"
      *tuiLet="sadmEntity.protocolsHistory[index] as protocol"
    >
      <tui-scrollbar class="h-full">
        <pxc-form-display
          [sections]="protocolsReports[index]?.sections"
          formDisplayStyle="columns"
          data-testid="protocol-display"
          [showAlerts]="false"
        ></pxc-form-display>
        <p
          class="mr-4 mt-4 w-full text-right text-secondary"
          data-testid="protocol-date"
        >
          Le {{ protocol.createdOn | date: 'short' }}
          @if (protocol.createdByInstance) {
            par {{ protocol.createdByInstance.firstName | titlecase }}
            {{ protocol.createdByInstance.lastName | titlecase }}
          }
        </p>
      </tui-scrollbar>
    </div>
  </div>

  <button
    type="button"
    tuiButton
    class="place-self-end"
    appearance="outline"
    (click)="close()"
  >
    Fermer
  </button>
</div>
