import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AvatarStyle, PublicUser } from '@pixacare/pxc-ts-core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AvatarStyleService } from 'src/app/services/avatar-style.service';

@Component({
  selector: 'pxc-user-preview',
  templateUrl: './user-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
  ],
})
export class UserPreviewComponent implements OnInit {

  @Input() user: PublicUser;
  @Input() colored = true;

  isCurrentUser: boolean;

  avatarColors: AvatarStyle;

  private readonly avatarStyleService = inject(AvatarStyleService);
  private readonly authenticationService = inject(AuthenticationService);

  ngOnInit(): void {
    this.isCurrentUser = this.authenticationService.currentUser.id === this.user.id;
    this.avatarColors = this.avatarStyleService.getAvatarColors(`${this.user.firstName} ${this.user.lastName}`);
  }

}
