import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject } from 'rxjs';
import { first, map } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})

export class VirtualScrollService<T> {

  entities$: Observable<T[]>;
  filteredEntities$: Observable<T[]>;
  displayedEntities$: Observable<T[]>;

  private displayedEntitiesSubject = new ReplaySubject<T[]>(1);
  private currentPageSubject = new ReplaySubject<number>(1);
  private entitiesCountPerPage;

  init(entities$: Observable<T[]>, { countPerPage = 25, initialPageAmount = 1 } = {}): Observable<T[]> {
    this.entitiesCountPerPage = countPerPage;

    this.entities$ = entities$;
    this.filteredEntities$ = this.entities$;

    this.currentPageSubject
      .pipe(untilDestroyed(this))
      .subscribe((currentPage) => {
        this.filteredEntities$
          .pipe(
            untilDestroyed(this),
            map((fs) => fs?.slice(0, (currentPage + 1) * this.entitiesCountPerPage)),
          )
          .subscribe((entities) =>
            this.displayedEntitiesSubject.next(entities),
          );
      });
    this.currentPageSubject.next(initialPageAmount - 1);
    this.displayedEntities$ = this.displayedEntitiesSubject.asObservable();
    return this.displayedEntities$;
  }

  loadNextPage(): void {
    this.currentPageSubject.pipe(first()).subscribe((currentPage) => this.currentPageSubject.next(currentPage + 1));
  }

  filter = (value: string, filterFn: (entity: T, val: string) => boolean): void => {

    this.filteredEntities$ = this.entities$.pipe(
      first(),
      map((entities) => entities.filter((entity) => filterFn(entity, value))),
    );
    this.reset();
  };

  reset(): void {
    this.currentPageSubject.next(0);
  }

}
