import { Label } from '@pixacare/pxc-ts-core';
import { StoreLabelPack } from '../../models/base/store-label-pack';

export interface LabelsState {
  labels: {
    [labelId: number]: Label;
  };
  userLabels: number[];
  labelPacks: {
    [labelPackId: number]: StoreLabelPack;
  };
}

export const initialLabelsState: LabelsState = {
  labels: {},
  labelPacks: {},
  userLabels: [],
};
