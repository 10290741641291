import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SequencesEffects } from './sequences.effects';
import { sequencesReducer } from './sequences.reducer';
import { SEQUENCES_FEATURE_KEY } from './sequences.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(SEQUENCES_FEATURE_KEY, sequencesReducer),
    EffectsModule.forFeature([SequencesEffects]),
  ],
  declarations: [],
})
export class SequencesStoreModule { }
