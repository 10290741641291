import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FlattenedLabel, Label, LabelPack } from '@pixacare/pxc-ts-core';
import { LABELS_FEATURE_KEY } from './labels.selectors';

export const labelsActions = createActionGroup({
  source: LABELS_FEATURE_KEY,
  events: {
    'Get user labels': emptyProps(),
    'Get user labels success': props<{ flattenedLabels: FlattenedLabel[] }>(),

    'Add label pack': props<{ labelPacks: LabelPack[] }>(),

    'Create labels': props<{ labels: string[] }>(),

    'Unsubscribe from label': props<{ labelId: number;removeFromSequences: boolean }>(),

    'Unsubscribe from label success': props<{ labelId: number }>(),

    'Create label pack labels': props<{
      labelPackId: number;
      clientCode: string;
      departmentId: number;
      labels: string[];
    }>(),

    'Create label pack labels success': props<{
      labelPackId: number;
      clientCode: string;
      departmentId: number;
      labelIds: number[];
    }>(),

    'Delete label pack labels': props<{
      labelPackId: number;
      clientCode: string;
      departmentId: number;
      labels: Label[];
    }>(),

    'Delete label pack labels success': props<{
      labelPackId: number;
      clientCode: string;
      departmentId: number;
      labelIds: number[];
    }>(),

    'Add labels': props<{ labels: Label[]; isUsedByUser?: boolean }>(),
  },
});
