import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, inject } from '@angular/core';
import { ChannelUser, SearchedUser } from '@pixacare/pxc-ts-core';
import { Observable, map, startWith } from 'rxjs';
import { UserHttpService } from 'src/app/services/http/user.http.service';
import { ChannelParticipantComponent } from '../channel-participant/channel-participant.component';

@Component({
  selector: 'pxc-channel-participants',
  standalone: true,
  imports: [
    CommonModule,
    ChannelParticipantComponent,
  ],
  templateUrl: './channel-participants.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelParticipantsComponent implements OnChanges {

  @Input() participants: ChannelUser[];

  users$: Observable<SearchedUser[]>;

  private userHttpService = inject(UserHttpService);

  ngOnChanges(changes: SimpleChanges) {
    if (changes.participants) {
      this.updateParticipants();
    }
  }

  private updateParticipants() {
    this.users$ = this.userHttpService.searchUsers(null, this.participants.map((x) => x.id)).pipe(
      startWith(this.participants as SearchedUser[]),
      map((users) => users.sort((a, b) => `${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1)),
    );
  }

}
