import { CommonModule, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { Label } from '@pixacare/pxc-ts-core';
import { TuiButton } from '@taiga-ui/core';
import { TuiChip } from '@taiga-ui/kit';

@Component({
  selector: 'pxc-label',
  templateUrl: './label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiChip,
    TuiButton,
    UpperCasePipe,
  ],
})
export class LabelComponent {

  @Input() label: Label;
  @Input() clickable = false;
  @Input() isDeletable = false;
  @Output() closeEvent = new EventEmitter<void>();

}
