import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IsMobileService {

  private readonly breakpointObserver = inject(BreakpointObserver);

  maxWidth(pixels: number): Observable<boolean> {
    return this.breakpointObserver.observe(`(max-width: ${pixels}px)`)
      .pipe(map((breakpoint) => breakpoint.matches));
  }

}
