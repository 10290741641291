import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LicensesEffects } from './licenses.effects';
import { licensesReducer } from './licenses.reducer';
import { LICENSES_FEATURE_KEY } from './licenses.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(LICENSES_FEATURE_KEY, licensesReducer),
    EffectsModule.forFeature([LicensesEffects]),
  ],
  declarations: [],
})
export class LicensesStoreModule { }
