<div class="flex items-center justify-between border-b border-border-normal p-3">
  <div class="flex flex-col gap-3 max-md:flex-col">
    <div class="flex items-center gap-3 py-1">
      <h3 tuiTitle>{{ title }}</h3>
      <tui-badge appearance="info">{{ counterLabel }}</tui-badge>
    </div>
    <pxc-user-state-count [userCountByAdminState]="userCountByAdminState"></pxc-user-state-count>
  </div>
  <pxc-action-buttons
    [actions]="actions"
    [actionButtons]="actionButtonsGroups"
  ></pxc-action-buttons>
</div>
