import { ChannelMessagePriority } from '@pixacare/pxc-ts-core';

export type ChannelMessagePriorityConfig = {
  [key: number]: {
    name: string;
  };
};

export const defaultChannelMessagePriorityConfig: ChannelMessagePriorityConfig = {
  [ChannelMessagePriority.INFORMATION]: {
    name: 'Informations',
  },
  [ChannelMessagePriority.MESSAGE]: {
    name: 'Messages',
  },
  [ChannelMessagePriority.WARNING]: {
    name: 'Avertissements',
  },
  [ChannelMessagePriority.CRITICAL]: {
    name: 'Critiques',
  },
};

export const telemonitoringChannelMessagePriorityConfig: ChannelMessagePriorityConfig = {
  [ChannelMessagePriority.INFORMATION]: {
    name: 'Informations',
  },
  [ChannelMessagePriority.MESSAGE]: {
    name: 'Messages',
  },
  [ChannelMessagePriority.CRITICAL]: {
    name: 'Alertes',
  },
};
