<button
  tuiIconButton
  appearance="flat"
  iconStart="@tui.link"
  class="!bg-status-positive-pale !text-positive hover:!bg-status-positive-pale-hover"
  data-testid="patient-quick-action"
  tuiHint="Le patient est rattaché à votre établissement."
  tuiHintDirection="right"
  tuiHintAppearance="dark"
  size="xs"
></button>
