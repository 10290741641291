import { DataConsumptionState } from './data-consumption-state';
import { StorageSpace } from './storage-space.enum';

export const dataConsumptionStateConfig: { [key in StorageSpace]: DataConsumptionState } = {
  [StorageSpace.AVAILABLE]: {
    icon: '@tui.cloud-upload',
    styles: {
      hintAppearance: 'onDark',
      progressClass: 'bg-neutral-2-hover',
      containerClass: 'bg-neutral-2 shadow-neutral-2/20',
    },
    label: 'Espace utilisé',
    title: (remainingStorage) => 'Il vous reste ' + remainingStorage + ' de stockage',
  },
  [StorageSpace.LOW]: {
    icon: '@tui.triangle-alert',
    styles: {
      hintAppearance: 'onDark',
      progressClass: 'bg-orange-200',
      containerClass: 'bg-status-warning-pale shadow-status-warning-page/20',
    },
    label: 'Espace utilisé',
    title: () => 'Vous arrivez bientôt à court d\'espace !',
  },
  [StorageSpace.FULL]: {
    icon: '@tui.triangle-alert',
    styles: {
      hintAppearance: 'error',
      progressClass: 'bg-red-200',
      containerClass: 'bg-status-negative-pale shadow-status-negative-pale/20',
    },
    label: 'Espace saturé',
    title: () => 'Espace de stockage saturé !',
  },
};
