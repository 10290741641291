export enum SectionActivity {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum SectionValidity {
  VALID = 'valid',
  INVALID = 'invalid',
}

type SectionColors = { [key in SectionValidity]: { [validity in SectionActivity]: string } };

export const sectionColors: SectionColors = {
  [SectionValidity.VALID]: {
    [SectionActivity.ACTIVE]: 'var(--tui-background-accent-1)',
    [SectionActivity.INACTIVE]: 'var(--tui-background-accent-1-hover)',
  },
  [SectionValidity.INVALID]: {
    [SectionActivity.ACTIVE]: 'var(--tui-background-accent-2-pressed)',
    [SectionActivity.INACTIVE]: 'var(--tui-background-accent-2)',
  },
};
