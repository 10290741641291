@if (selectedChannels.length > 0 || selectedUsers.length > 0 || selectedMailAddresses.length > 0) {
  <pxc-receiver-list
    [channels]="selectedChannels"
    [users]="selectedUsers"
    [mailAddresses]="selectedMailAddresses"
    (removeChannel)="removeChannel($event)"
    (removeUser)="removeUser($event)"
    (removeMailAddress)="removeMailAddress($event)"
  ></pxc-receiver-list>
}

<tui-input
  class="mt-3"
  [formControl]="searchInput"
  (keydown.enter)="submit()"
  tuiTextfieldIconLeft="@tui.search"
>
  Cherchez par nom, conversation ou ajoutez un email
</tui-input>

<div
  class="my-3 flex max-h-[50vh] min-h-52 w-full flex-col"
  *tuiLet="{ channels: (channels$ | async), users: (users$ | async) } as data"
>
  @if (data.channels || data.users) {
    <tui-scrollbar class="max-h-[53vh] flex-1">
      @if (data.channels?.length > 0 || data.users?.length > 0) {
        <div class="relative flex flex-col gap-3">
          @if (data.channels?.length > 0) {
            <ng-container *ngTemplateOutlet="title; context: { title: 'Conversations' }"></ng-container>
            <div class="flex flex-col">
              @for (channel of data.channels; track channel) {
                <pxc-channel-preview
                  class="z-0 cursor-pointer px-4 py-2 transition hover:bg-neutral-1"
                  [channel]="channel"
                  [showDetails]="false"
                  (click)="selectChannel(channel)"
                ></pxc-channel-preview>
              }
            </div>
          }
          @if (data.users?.length > 0) {
            <ng-container *ngTemplateOutlet="title; context: { title: 'Utilisateurs' }"></ng-container>
            <div class="flex flex-col">
              @for (user of data.users; track user) {
                <pxc-user-card
                  class="cursor-pointer px-4 py-2 transition hover:bg-neutral-1"
                  [user]="user"
                  (click)="selectUser(user)"
                ></pxc-user-card>
              }
            </div>
          }
        </div>
      } @else {
        <pxc-status
          [state]="Status.BASIC"
          [icon]="searchInput.value.includes('@') ? '@tui.at-sign' : searchInput.value ? '@tui.search' : '@tui.meh'"
          [showTitle]="false"
          [description]="
            searchInput.value.includes('@')
              ? 'Entrez un email et appuyez sur Entrée pour ajouter un utilisateur.'
              : searchInput.value
                ? 'Aucune conversation ou utilisateur ne correspond à votre recherche.'
                : 'Vous n\'avez pas encore de conversations ! Cherchez un utilisateur ou entrez un email pour démarrer.'
          "
        ></pxc-status>
      }
    </tui-scrollbar>
  } @else {
    <pxc-loader size="tiny"></pxc-loader>
  }
</div>

<ng-template
  #title
  let-title="title"
>
  <p class="!sticky top-0 z-10 bg-base py-1 text-secondary lg:bg-base-alt">
    {{ title }}
  </p>
</ng-template>
