<div class="space-y-1">
  @for (question of questions; track question) {
    <pxc-form-display-question
      [label]="question.title | firstLetterUppercase"
      [value]="question.value"
      [indicatorLevel]="question.level"
      class="block overflow-hidden rounded-lg"
    ></pxc-form-display-question>
  }
</div>
