import { Injectable, inject } from '@angular/core';
import { LabelCreateComponent } from '@modules/label/label-create/label-create.component';
import { Store } from '@ngrx/store';
import { Label } from '@pixacare/pxc-ts-core';
import { DialogService } from '@services/dialog.service';
import { labelsActions } from '@shared/store/labels/labels.actions';
import { combineLatest, first } from 'rxjs';
import { DepartmentAdminService } from '../department-admin/department-admin.service';

@Injectable()
export class DepartmentLabelsAdminService {

  private readonly store = inject(Store);
  private readonly dialogService = inject(DialogService);
  private readonly departmentAdminService = inject(DepartmentAdminService);

  createLabels(): void {
    combineLatest([
      this.departmentAdminService.loadDepartment$(),
      this.departmentAdminService.params$,
      this.dialogService.openComponentWithCloseConfirmation<LabelCreateComponent, string[]>(
        LabelCreateComponent, {
          label: 'Ajouter des mots clés',
        }),
    ]).pipe(first()).subscribe(([department, params, labels]) => {
      if (labels && labels.length > 0) {
        this.store.dispatch(
          labelsActions.createLabelPackLabels({
            clientCode: params.clientCode,
            departmentId: params.departmentId,
            labelPackId: department.labelPackIds[0],
            labels,
          }),
        );
      }
    });
  }

  deleteLabel(label: Label): void {
    combineLatest([
      this.departmentAdminService.params$,
      this.departmentAdminService.loadDepartment$(),
    ]).pipe(first()).subscribe(([{ clientCode, departmentId }, { labelPackIds }]) => {
      this.store.dispatch(
        labelsActions.deleteLabelPackLabels({
          clientCode,
          departmentId,
          labels: [ label ],
          labelPackId: labelPackIds[0],
        }),
      );
    });
  }

}
