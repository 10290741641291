<div class="flex gap-3">
  <tui-icon
    [icon]="icon"
    data-testid="icon"
    class="h-5 w-5"
  ></tui-icon>
  <div>
    <p class="text-sm">
      Liée à
      <span class="font-medium">
        {{ name }}
      </span>
    </p>
    <a
      tuiLink
      class="!text-sm"
      data-appearance="primary"
      (click)="linkClicked.emit()"
      data-testid="link"
    >
      {{ link }}
    </a>
  </div>
</div>
