import { Filter, FilterOperator } from '@pixacare/pxc-ts-core';
import { FlattenedFilterTemplate } from '@shared/models/filters/flattened-filter-template';
import { AutoCompleteModel } from '@shared/models/helpers/auto-complete-model';
import { lens, match } from './utils';

export type SearchHandler<T> = (entity: T, search: string) => boolean;
export type FilterHandler<T> = (entity: T, filter: Filter, prop: string) => boolean;

export const filterEntities = <T>(
  entities: T[],
  { search, filters }: {
    search: {
      value: string;
      handler: SearchHandler<T>;
    };
    filters: {
      values: Filter[];
      handlers: {
        [prop: string]: FilterHandler<T>;
      };
    };
  },
): T[] => {
  if (!search.value && !filters.values.length) {
    return entities;
  }

  let filteredEntities = entities;

  if (search.value && search.handler) {
    filteredEntities = filteredEntities.filter((entity) =>
      search.handler(entity, search.value));
  }

  if (filters.values && filters.values.length) {
    filteredEntities = filteredEntities.filter((entity) =>
      filters.values.every((filter) => {
        if (filters.handlers[filter.prop]) {
          return filters.handlers[filter.prop](entity, filter, filter.prop);
        }
        return true;
      }));
  }

  return filteredEntities;

};

export const createSearchHandler = <T>(keys: string[]): SearchHandler<T> =>
  (entity: T, search: string): boolean => match(search, keys.map((key) => entity[key]));

export const dateRangeFilterHandler = <T>(entity: T, filter: Filter, prop: string): boolean => {

  const filterDate: number = Date.parse(filter.val as string);
  let entityTime: number;
  const entityValue = lens(entity, prop);
  if (entityValue instanceof Date) {
    entityTime = entityValue.getTime();
  } else  if (typeof entityValue === 'string') {
    entityTime = Date.parse(entityValue);
  }

  if (filter.op === FilterOperator.GREATER_THAN_OR_EQUAL) {
    return entityTime >= filterDate;
  }

  if (filter.op === FilterOperator.LESS_THAN_OR_EQUAL) {
    return entityTime <= filterDate;
  }

  return false;

};

export const makeFilter = (autoCompleteModel: AutoCompleteModel, template: FlattenedFilterTemplate): Filter => ({
  prop: template.property,
  op: FilterOperator.EQUAL,
  val: autoCompleteModel.value,
});
