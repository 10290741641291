<div class="flex items-center gap-3">
  @if (userCountByAdminState[UserState.ENABLED] > 0) {
    <pxc-user-admin-state-badge
      [state]="UserState.ENABLED"
      [count]="userCountByAdminState[UserState.ENABLED]"
    ></pxc-user-admin-state-badge>
  }
  @if (userCountByAdminState[UserState.DISABLED] > 0) {
    <pxc-user-admin-state-badge
      [state]="UserState.DISABLED"
      [count]="userCountByAdminState[UserState.DISABLED]"
    ></pxc-user-admin-state-badge>
  }
  @if (userCountByAdminState[UserState.INVITED] > 0) {
    <pxc-user-admin-state-badge
      [state]="UserState.INVITED"
      [count]="userCountByAdminState[UserState.INVITED]"
    ></pxc-user-admin-state-badge>
  }
</div>
