import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StatisticsState } from './statistics.state';

export const STATISTICS_FEATURE_KEY = 'Statistics';

export const selectStatisticsState = createFeatureSelector<StatisticsState>(
  STATISTICS_FEATURE_KEY,
);

export const selectUserStatistics = createSelector(
  selectStatisticsState,
  (state) => state.statistics,
);
