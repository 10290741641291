import { Injectable, inject } from '@angular/core';
import { ObjectType } from '@pixacare/pxc-ts-core';
import { BehaviorSubject, Observable, catchError, first, map, of, switchMap, tap } from 'rxjs';
import { SequenceHttpService } from 'src/app/services/http/sequence.http.service';
import { ShareHttpService } from 'src/app/services/http/share.http.service';
import { ResolvedShare } from 'src/app/shared/models/resolved-share';

@Injectable()
export class MessageSpecialService {

  shareObjects$: Observable<{ [shareId: number]: ResolvedShare }>;
  private shareObjectsSubject: BehaviorSubject<{ [shareId: number]: ResolvedShare }>
    = new BehaviorSubject({});

  private readonly sequenceService = inject(SequenceHttpService);
  private readonly shareService = inject(ShareHttpService);

  constructor() {
    this.shareObjects$ = this.shareObjectsSubject.asObservable();
  }

  getSequence(shareId: number): Observable<ResolvedShare> {
    return this.shareObjects$.pipe(
      first(),
      map((cachedShareObjects) => cachedShareObjects[shareId]),
      catchError(() => of(null)),
      switchMap((cachedSequence) => {
        if (!cachedSequence) {
          return this.fetchShare(shareId);
        }
        return of(cachedSequence);
      }),
    );
  }

  private fetchShare(shareId: number): Observable<ResolvedShare> {
    return this.shareService.getShareById(shareId).pipe(
      switchMap((share) => {
        if (share.objectType === ObjectType.SEQUENCE) {
          return this.sequenceService.getSequence(share.clientCode, share.objectId, {
            fillFullSizeUri: true,
            fillThumbnailUri: true,
          }).pipe(
            map((sequence): ResolvedShare => ({
              ...share,
              object: sequence,
            })),
          );
        }
        return of({ ...share, object: null });
      }),
      tap((shareObject) => {
        this.updateCache(shareId, shareObject);
      }),
    );
  }

  private updateCache(shareId: number, shareObject: ResolvedShare): void {
    const cachedShareObjects = this.shareObjectsSubject.value;

    this.shareObjectsSubject.next({
      ...cachedShareObjects,
      [shareId]: shareObject,
    });
  }

}
