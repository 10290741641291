import { Drawer } from '../../models/drawer';
import { DrawerOpeningState } from '../../models/enums/drawer-state.enum';

export interface DrawerState {
  drawer: Drawer;
  isUserDragging: boolean;
}

export const initialDrawerState: DrawerState = {
  drawer: {
    state: DrawerOpeningState.TINY,
    medias: [],
  },
  isUserDragging: false,
};
