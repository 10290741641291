@if (collection$ | async; as collection) {
  <div class="flex flex-col gap-3">
    <div class="flex flex-col justify-between animate-in fade-in md:flex-row md:items-center">
      <h1 tuiTitle="l">
        {{ mode === ContextualComponentMode.USER ? 'Mes patients' : (departmentName$ | async) }}
        @if (mode === ContextualComponentMode.DEPARTMENT) {
          <span tuiSubtitle>Patients du groupe</span>
        }
      </h1>
      @if (hasLicenseWriteAccess$ | async) {
        <pxc-create-button
          class="flex-none"
          label="Ajouter un patient"
          icon="@tui.user-plus"
          (click)="createPatient()"
        ></pxc-create-button>
      }
    </div>
    <pxc-filter-tabs class="animate-in fade-in"></pxc-filter-tabs>
    <div class="flex items-center gap-2 animate-in fade-in max-lg:flex-wrap max-lg:justify-end">
      <pxc-filter-bar
        searchPlaceholder="Rechercher un patient par nom, prénom, nip ou date de naissance ..."
        data-testid="filter-bar"
      ></pxc-filter-bar>
      <pxc-dropdown-button
        class="shrink-0"
        icon="@tui.list-filter"
        appearance="flat"
        [actions]="orderService.orderMenuActions"
        [selectedKey]="order$ | async"
      ></pxc-dropdown-button>
    </div>
    <pxc-filter-buttons></pxc-filter-buttons>
    @if ((isDataLoaded$ | async) === true) {
      <pxc-patient-list
        [patients]="collection.data"
        [isNextPageLoading]="collection.context?.isNextPageLoading"
        (loadNext)="loadNextPage()"
        data-testid="patient-list"
      ></pxc-patient-list>
    } @else {
      <ng-container [ngTemplateOutlet]="loading"></ng-container>
    }
  </div>
} @else {
  <ng-container [ngTemplateOutlet]="loading"></ng-container>
}

<ng-template #loading>
  <pxc-loader
    data-testid="loading"
    class="my-5"
  >
    Chargement des patients ...
  </pxc-loader>
</ng-template>
