import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordHttpService {

  constructor(private readonly http: HttpClient) {}

  sendPasswordRequest(mailAddress: string): Observable<HttpResponse<void>> {
    return this.http.post<HttpResponse<void>>(`${environment.apiBaseUrl}/User/ForgotPassword`, {
      mailAddress,
    });
  }

  checkToken(token: string): Observable<User> {
    return this.http.get<User>(
      `${environment.apiBaseUrl}/User/ForgotPasswordCheck/${token}`,
    );
  }

}
