import {
  ChangeDetectionStrategy,
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { Patient } from '@pixacare/pxc-ts-core';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';

@Component({
  selector: 'pxc-patient-list',
  templateUrl: './patient-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [`
    :host {
      display: block;
      height: 100%;
    }
  `],
})
export class PatientListComponent {

  @Input() patients: Patient[];
  @Input() actions: MenuAction<Patient>[] = null;
  @Input() isNextPageLoading = false;

  @Output() loadNext = new EventEmitter<null>();

}
