@if (special?.shares) {
  <pxc-message-special-share
    [special]="special"
    [userId]="userId"
  ></pxc-message-special-share>
}

@if (special?.alertIndicators) {
  <pxc-message-special-alert-indicators
    [alertReportQuestions]="special.alertIndicators"
  ></pxc-message-special-alert-indicators>
}

@if (special?.telemonitoringId) {
  <pxc-message-special-telemonitoring
    [telemonitoringId]="special.telemonitoringId"
    class="flex justify-end"
  ></pxc-message-special-telemonitoring>
}
