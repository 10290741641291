import { ClientData } from '../../models/base/client-data';

export interface ClientsState {
  clients: {
    [clientCode: string]: ClientData;
  };
}

export const initialClientsState: ClientsState = {
  clients: null,
};
