<div class="flex flex-col gap-2">
  <div class="my-2 flex flex-col items-center justify-between gap-2 lg:flex-row">
    <h1 tuiTitle="l">Mes mots clés</h1>
    <button
      tuiButton
      (click)="openCreateLabelDialog()"
      appearance="primary"
      data-testid="add-button"
      iconStart="@tui.plus"
    >
      Ajouter
    </button>
  </div>

  <pxc-label-table
    [labels]="labels$ | async"
    [displayStats]="true"
    [actions]="actions"
  ></pxc-label-table>
</div>

<ng-template
  #removeDialog
  let-observer
>
  <div class="flex flex-col gap-4">
    <p>
      Le mot clé
      <span class="rounded-full bg-neutral-1 px-2 py-1 uppercase">{{ currentLabel.labelInstance.word }}</span>
      ne vous sera plus proposé lors de la sélection de mots clés.
    </p>

    <label tuiLabel>
      <input
        tuiCheckbox
        type="checkbox"
        [(ngModel)]="removeFromSequences"
      />
      Supprimer également ce mot clé de mes séquences déjà créées
    </label>

    <div class="mt-2 flex justify-between gap-2">
      <button
        tuiButton
        appearance="outline"
        (click)="observer.complete()"
      >
        Annuler
      </button>
      <button
        tuiButton
        (click)="removeLabel(observer)"
        appearance="destructive"
        data-testid="confirm-delete-button"
      >
        Ne plus utiliser
      </button>
    </div>
  </div>
</ng-template>
