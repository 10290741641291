import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PatientsEffects } from './patients.effects';
import { patientsReducer } from './patients.reducer';
import { PATIENTS_FEATURE_KEY } from './patients.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(PATIENTS_FEATURE_KEY, patientsReducer),
    EffectsModule.forFeature([PatientsEffects]),
  ],
  declarations: [],
})
export class PatientsStoreModule { }
