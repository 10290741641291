<div class="flex flex-col gap-3">
  <div class="my-2 flex gap-5 max-md:flex-col md:items-center">
    <p class="max-w-[12rem] truncate">{{ fromClientName$ | async }}</p>

    <tui-icon icon="@tui.chevrons-right"></tui-icon>

    <pxc-client-picker
      [(selectedClient)]="toClientCode"
      [clients]="movableClients$ | async"
      data-testid="client-picker"
      class="flex-1 bg-base"
    ></pxc-client-picker>
  </div>

  <div class="flex justify-between gap-2 max-md:flex-col-reverse">
    <button
      tuiButton
      appearance="outline"
      (click)="cancel()"
      data-testid="cancel"
    >
      Annuler
    </button>
    <button
      tuiButton
      (click)="move()"
      data-testid="confirm"
      [disabled]="!toClientCode"
    >
      Déplacer
    </button>
  </div>
</div>
