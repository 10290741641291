<tui-root class="h-full">
  @if (!isBrowserIE) {
    <router-outlet data-testid="content"></router-outlet>
  } @else {
    <div class="flex h-screen items-center justify-center">
      <div class="flex flex-col items-center">
        <img
          class="max-h-24"
          src="https://cdn.pixacare.com/logo/top-transparent.svg"
        />
        <h1>Navigateur Incompatible</h1>
        <p>Vous utilisez Internet Explorer qui n'est pas compatible avec les standards de sécurité de Pixacare.</p>
        <p>Merci de vous connecter avec un autre navigateur (Edge, Chrome, Firefox, Safari, Opéra).</p>
      </div>
    </div>
  }
</tui-root>
