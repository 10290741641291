<ng-container *tuiLet="(displayChannelPane$ | async) === false as displayChannelPane">
  @if ((isMobile$ | async) === false) {
    <div class="flex h-full items-stretch border-t border-border-normal animate-in fade-in">
      <pxc-channel-list
        class="z-20 h-full w-96"
        data-testid="channel-list"
      ></pxc-channel-list>
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
  } @else {
    @if (displayChannelPane) {
      <pxc-channel-list
        class="w-full"
        data-testid="channel-list"
      ></pxc-channel-list>
    } @else {
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    }
  }
</ng-container>

<ng-template #content>
  <div
    class="h-full flex-1"
    data-testid="channel-pane"
  >
    <router-outlet></router-outlet>
  </div>
</ng-template>
