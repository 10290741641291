import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Patient } from '@pixacare/pxc-ts-core';
import { DialogService } from 'src/app/services/dialog.service';
import { patientsActions } from 'src/app/shared/store/patients/patients.actions';
import { PatientCreateComponent } from '../../patient/patient-create/patient-create.component';

@Injectable({
  providedIn: 'root',
})
export class PatientCreationService {

  constructor(
    private readonly dialogService: DialogService,
    private readonly store: Store,
  ) { }

  createPatient(clientCode: string): void {
    this.dialogService.openComponent(PatientCreateComponent, {
      label: 'Créer un patient',
      size: 'l',
      data: {
        clientCode,
      },
    }).subscribe((patient: Patient) => {
      if (!patient) {
        return;
      }

      this.store.dispatch(patientsActions.createPatient({
        clientCode,
        patient,
      }));

    });
  }

}
