/* eslint-disable @typescript-eslint/member-ordering */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlattenedSequence, FlattenedTelemonitoring, SadmEntity } from '@pixacare/pxc-ts-core';
import { BehaviorSubject, combineLatest, distinctUntilChanged, first, map, skipWhile } from 'rxjs';
import { FilterBarComponent } from 'src/app/modules/filters/filter-bar/filter-bar.component';
import { sequenceFilterTemplates } from 'src/app/shared/models/filters/filter-template.config';
import { FILTER_TEMPLATES, FilterBarService } from '../../../filters/filter-bar.service';
import { TelemonitoringPaginationService } from '../../../telemonitoring/telemonitoring-pagination.service';
import { SequencePaginationService } from '../../services/sequence-pagination.service';
import { SequencePickerService } from '../sequence-picker.service';
import { SequenceSelectListComponent } from '../sequence-select-list/sequence-select-list.component';

type Deps = { [id: number]: Set<number> };

@Component({
  selector: 'pxc-sequence-picker',
  standalone: true,
  imports: [
    CommonModule,
    FilterBarComponent,
    SequenceSelectListComponent,
  ],
  providers: [
    SequencePaginationService,
    TelemonitoringPaginationService,
    SequencePickerService,
    FilterBarService,
    {
      provide: FILTER_TEMPLATES,
      useValue: sequenceFilterTemplates,
    },
  ],
  templateUrl: './sequence-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequencePickerComponent implements OnInit {

  @Input() clientCode: string;
  @Input() patientId?: number;
  @Input() departmentIds?: number[];
  @Input() telemonitoringIds?: number[];

  @Input() disabledSequenceIds: number[] = [];
  @Input() preselectedSequences: FlattenedSequence[] = [];
  @Input() singleSelectMode = false;
  @Input() badgeContent?: (sequence: FlattenedSequence) => string | null;

  @Input() showTelemonitorings = false;
  @Input() showSadms = false;

  @Input() showPatient = false;

  private selectedSequences$ = new BehaviorSubject<{ [id: number]: FlattenedSequence }>({});
  private selectedTelemonitorings$ = new BehaviorSubject<{ [id: number]: FlattenedTelemonitoring }>({});
  private selectedSadms$ = new BehaviorSubject<{ [id: number]: SadmEntity }>({});

  telemonitoringsDeps: Deps = {};
  sadmsDeps: Deps = {};

  @Output() selectedSequencesChange = this.selectedSequences$.pipe(
    map((sequences) => Object.values(sequences)),
    skipWhile((sequences) => sequences.length === 0),
    distinctUntilChanged((previous, current) =>
      previous.map((sequence) => sequence.sequenceInstance.id).join()
      === current.map((sequence) => sequence.sequenceInstance.id).join()),
  );

  @Output() selectedSequenceIdsChange = this.selectedSequencesChange.pipe(
    map((sequences) => sequences.map((s) => s.sequenceInstance.id)),
  );

  @Output() selectedTelemonitoringsChange = this.selectedTelemonitorings$.pipe(
    map((telemonitorings) => Object.values(telemonitorings)),
    skipWhile((telemonitorings) => telemonitorings.length === 0),
    distinctUntilChanged((previous, current) =>
      previous.map((tlm) => tlm.telemonitoring.id).join() === current.map((tlm) => tlm.telemonitoring.id).join()),
  );

  @Output() selectedSadmsChange = this.selectedSadms$.pipe(
    map((sadms) => Object.values(sadms)),
    skipWhile((sadms) => sadms.length === 0),
    distinctUntilChanged((previous, current) =>
      previous.map((sequence) => sequence.id).join() === current.map((sequence) => sequence.id).join()),
  );

  @Output() selectedAllSequencesChange = new EventEmitter<boolean>();

  constructor(
    readonly sequencePickerService: SequencePickerService,
  ) { }

  ngOnInit(): void {

    if (this.preselectedSequences && this.preselectedSequences.length > 0) {
      this.selectedSequences$.next(this.preselectedSequences.reduce((acc, sequence) => ({
        ...acc,
        [sequence.sequenceInstance.id]: sequence,
      }), {}));
    }

    this.sequencePickerService.init({
      clientCode: this.clientCode,
      patientId: this.patientId,
      departmentIds: this.departmentIds,
      telemonitoringIds: this.telemonitoringIds,
      showSadms: this.showSadms,
      showTelemonitorings: this.showTelemonitorings,
    });

  }

  selectSequences(sequenceIds: number[]): void {

    if (!sequenceIds || sequenceIds.length === 0) {
      return;
    }

    this.getDisplayedAndSelectedData$().pipe(first()).subscribe(({
      displayedSequences,
      selectedSequences,
      displayedTelemonitorings,
      selectedTelemonitorings,
      displayedSadms,
      selectedSadms,
    }) => {

      const sequences = this.singleSelectMode ? {} : selectedSequences;
      const telemonitorings = this.singleSelectMode ? {} : selectedTelemonitorings;
      const sadms = this.singleSelectMode ? {} : selectedSadms;

      for (const sequenceId of sequenceIds) {

        const sequence = displayedSequences.data?.find((s) => s.sequenceInstance.id === sequenceId);

        if (!sequence) {
          continue;
        }

        sequences[sequenceId] = sequence;

        const tmId = sequence.sequenceInstance.telemonitoringId;
        if (tmId) {

          // keep tracks of the sequences that selected this telemonitoring
          this.addDeps(this.telemonitoringsDeps, tmId, sequenceId);

          const telemonitoring = displayedTelemonitorings.data?.find((t) => t.telemonitoring.id === tmId);

          if (telemonitoring && !telemonitorings[tmId]) {
            telemonitorings[telemonitoring.telemonitoring.id] = telemonitoring;
          }
        }

        const sadmId = sequence.sequenceInstance.sadmEntityId;
        if (sadmId) {

          // keep tracks of the sequences that selected this sadm
          this.addDeps(this.sadmsDeps, sadmId, sequenceId);

          const sadm = displayedSadms.find((s) => s.id === sadmId);
          if (sadm && !sadms[sadmId]) {
            sadms[sadm.id] = sadm;
          }
        }

      }

      this.selectedSequences$.next(sequences);
      this.selectedTelemonitorings$.next(telemonitorings);
      this.selectedSadms$.next(sadms);

    });

  }

  unselectSequences(sequenceIds: number[]): void {

    if (!sequenceIds || sequenceIds.length === 0) {
      return;
    }

    this.getDisplayedAndSelectedData$().pipe(first()).subscribe(({
      selectedSequences,
      selectedTelemonitorings,
      selectedSadms,
    }) => {

      const sequences = { ...selectedSequences };
      const telemonitorings = { ...selectedTelemonitorings };
      const sadms = { ...selectedSadms };

      for (const sequenceId of sequenceIds) {

        const tmId = sequences[sequenceId]?.sequenceInstance.telemonitoringId;
        if (tmId && telemonitorings[tmId]) {

          if (this.telemonitoringsDeps[tmId].size === 1) {
            // we can remove the telemonitoring only if it is not selected by another sequence
            delete telemonitorings[tmId];
          }

          this.removeDeps(this.telemonitoringsDeps, tmId, sequenceId);

        }

        const sadmId = sequences[sequenceId]?.sequenceInstance.sadmEntityId;
        if (sadmId && sadms[sadmId]) {

          if (this.sadmsDeps[sadmId].size === 1) {
            // we can remove the sadm only if it is not selected by another sequence
            delete sadms[sadmId];
          }

          this.removeDeps(this.sadmsDeps, sadmId, sequenceId);
        }

        if (sequences[sequenceId]) {
          delete sequences[sequenceId];
        }

      }

      this.selectedSequences$.next(sequences);
      this.selectedTelemonitorings$.next(telemonitorings);
      this.selectedSadms$.next(sadms);

    });

  }

  isAllSequencesSelectedChange(isAllSelected: boolean): void {

    if (!isAllSelected) {
      this.selectedAllSequencesChange.emit(false);
      return;
    }

    combineLatest([
      this.selectedSequences$,
      this.sequencePickerService.displayedSequences$,
    ])
      .pipe(first()).subscribe(([selectedSequences, sequences]) => {
        this.selectedAllSequencesChange.emit(
          Object.values(selectedSequences).length <= sequences.data?.length,
        );
      });
  }

  private addDeps(deps: Deps, id: number, depId: number): void {
    if (!deps[id]) {
      deps[id] = new Set();
    }
    deps[id].add(depId);
  }

  private removeDeps(deps: Deps, id: number, depId: number): void {
    if (deps[id]) {
      deps[id].delete(depId);
      if (deps[id].size === 0) {
        delete deps[id];
      }
    }
  }

  private getDisplayedAndSelectedData$() {
    return combineLatest({
      displayedSequences: this.sequencePickerService.displayedSequences$,
      selectedSequences: this.selectedSequences$,
      displayedTelemonitorings: this.sequencePickerService.displayedTelemonitorings$,
      selectedTelemonitorings: this.selectedTelemonitorings$,
      displayedSadms: this.sequencePickerService.displayedSadms$,
      selectedSadms: this.selectedSadms$,
    });
  }

}
