import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  FilterDropdownButtonComponent,
} from '@modules/filters/filter-dropdown-button/filter-dropdown-button.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Filter, FilterOperator } from '@pixacare/pxc-ts-core';
import { Bounds } from '@shared/models/bounds.interface';
import { DateFilterShortcut } from '@shared/models/filters/date-filter-shortcut';
import { defaultDateShortcuts } from '@shared/models/filters/date-filter-shortcut.config';
import { FlattenedFilterTemplate } from '@shared/models/filters/flattened-filter-template';
import { formatToIso } from '@shared/utils/utils';
import { TuiDay } from '@taiga-ui/cdk';
import { TuiButton } from '@taiga-ui/core';
import { TuiRange } from '@taiga-ui/kit';
import { TuiInputDateModule, TuiTextfieldControllerModule } from '@taiga-ui/legacy';
import { endOfDay, startOfDay } from 'date-fns';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'pxc-filter-date-range',
  templateUrl: './filter-date-range.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FilterDropdownButtonComponent,
    TuiInputDateModule,
    TuiTextfieldControllerModule,
    TuiRange,
    FormsModule,
    ReactiveFormsModule,
    TuiButton,
  ],
})
export class FilterDateRangeComponent implements OnInit, OnChanges {

  @Input() enableShortcuts = true;
  @Input() dateShortcuts: DateFilterShortcut[];
  @Input() template: FlattenedFilterTemplate;
  @Output() templateChange = new EventEmitter<FlattenedFilterTemplate>();

  dates: Bounds<Date> = { min: null, max: null };
  slider$ = new BehaviorSubject<Bounds<number>>({ min: 0, max: 1 });

  MIN_DATE = TuiDay.fromLocalNativeDate(new Date(0));
  MAX_DATE = TuiDay.fromLocalNativeDate(new Date(2100, 10, 28));

  boundsValidators = {
    min: FilterOperator.GREATER_THAN_OR_EQUAL,
    max: FilterOperator.LESS_THAN_OR_EQUAL,
  };

  buttonText: string;
  shortcuts: DateFilterShortcut[];
  activeShortcutIndex: number = null;

  get isActive(): boolean {
    return this.buttonText !== this.template.name;
  }

  ngOnInit() {

    if (this.template.min && this.template.max) {

      this.slider$.pipe(
        distinctUntilChanged(),
        debounceTime(300),
        untilDestroyed(this),
      ).subscribe(({ min, max }: Bounds<number>) => {
        this.templateChange.emit({
          ...this.template,
          filters: this.makeFilterItems({
            min: min === 0 ? null : this.dateFromLerp(min),
            max: max === 1 ? null : this.dateFromLerp(max),
          }),
        });
      });
    }

    this.shortcuts = this.dateShortcuts || defaultDateShortcuts;
  }

  ngOnChanges({ template }: SimpleChanges): void {
    let min = null;
    let max = null;
    if (template?.currentValue.filters.length > 0) {
      template.currentValue.filters.forEach((filter) =>{
        if (filter.op === this.boundsValidators.min) {
          min = new Date(filter.val);
        } else if (filter.op === this.boundsValidators.max) {
          max = new Date(filter.val);
        }
      });
    } else {
      this.activeShortcutIndex = null;
    }
    this.dates = { min, max };
    this.updateButtonText(this.dates);
  }

  updateDates(updatedDates: Bounds<Date>): void {
    if (this.dates.min !== updatedDates.min || this.dates.max !== updatedDates.max) {
      this.activeShortcutIndex = null;
      this.templateChange.emit({
        ...this.template,
        filters: this.makeFilterItems(updatedDates),
      });
    }
  }

  updateButtonText({ min, max }: Bounds<Date>): void {

    const startString = this.isDateValid(min, this.template.min) ? min.toLocaleDateString() : null;
    const endString = this.isDateValid(max, this.template.max) ? max.toLocaleDateString() : null;

    if (!startString && !endString) {
      this.buttonText = this.template.name;
    } else if (startString && !endString) {
      this.buttonText = 'depuis le ' + startString;
    } else if (!startString && endString) {
      this.buttonText = 'jusqu\'au ' + endString;
    } else if (startString === endString) {
      this.buttonText = 'le ' + startString;
    } else {
      this.buttonText = startString + ' - ' + endString;
    }

  }

  makeFilterItems({ min, max }: Bounds<Date>): Filter[] {
    return [
      ...(this.isDateValid(min, this.template.min) ? [{
        prop: this.template.property,
        op: this.boundsValidators.min,
        val: formatToIso(startOfDay(min)),
      }] : []),
      ...(this.isDateValid(max, this.template.max) ? [{
        prop: this.template.property,
        op: this.boundsValidators.max,
        val: formatToIso(endOfDay(max)),
      }] : []),
    ];
  }

  isDateValid(date: Date, dateFromConfig: Date): boolean {
    return !!date && (!dateFromConfig || (!!dateFromConfig && date.getTime() !== dateFromConfig.getTime()));
  }

  toTuiDay(date: Date): TuiDay {
    return !!date ? TuiDay.fromLocalNativeDate(date) : null;
  }

  lerpDate(date: Date): number {
    return (date.getTime() - this.template.min.getTime()) /
    (this.template.max.getTime() - this.template.min.getTime());
  }

  dateFromLerp(value: number): Date {
    return new Date(this.template.min.getTime() +
    (value * (this.template.max.getTime() - this.template.min.getTime())));
  }

  applyShortcut(shortcut: DateFilterShortcut): void {

    const { min, max } = shortcut.get(new Date(Date.now()));

    this.templateChange.emit({
      ...this.template,
      filters: this.makeFilterItems({
        min: min < (this.template.min || this.MIN_DATE) ? this.template.min : min,
        max: max > (this.template.max || this.MAX_DATE) ? this.template.max : max,
      }),
    });

    this.activeShortcutIndex = this.shortcuts.indexOf(shortcut);

  }

  clearFilters(): void {
    this.templateChange.emit({
      ...this.template,
      filters: this.makeFilterItems({ min: null, max: null }),
    });
    this.activeShortcutIndex = null;
  }

}
