import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { SadmClient, SadmEntity, SadmProtocol } from '@pixacare/pxc-ts-core';
import { SadmProtocolsService } from '../sadm-protocols.service';

@Component({
  selector: 'pxc-sadm-entity-protocol-card',
  templateUrl: './sadm-entity-protocol-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SadmEntityProtocolCardComponent implements OnChanges {

  @Input() sadmEntity: SadmEntity;
  @Input() sadmClient: SadmClient;

  @Output() openClick = new EventEmitter<SadmEntity>();
  @Output() create = new EventEmitter<void>();

  protocol: SadmProtocol = null;
  description: string;

  ngOnChanges(): void {

    this.sadmEntity = {
      ...this.sadmEntity,
      protocolsHistory: [...(this.sadmEntity.protocolsHistory || [])]
        .sort((p1, p2) => p1.createdOn <= p2.createdOn ? 1 : -1),
    };

    this.protocol = SadmProtocolsService.getLatestProtocol(this.sadmEntity);

    this.description = this.protocol
      ? SadmProtocolsService.getProtocolDescription(this.protocol)
        ?? 'Un protocole de soin a été renseigné.'
      : 'Aucun protocole de soin n\'a été défini.';
  }

}
