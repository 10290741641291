import Konva from 'konva';
import { Command } from 'src/app/shared/models/helpers/command';
import { StudioCanvas } from '../canvas/studio-canvas';
import { FreeDrawConfig } from '../config/studio-interfaces';

export class DrawFreeCommand implements Command {

  receiver: StudioCanvas;
  freeDrawConfig: FreeDrawConfig;
  isPaint = false;

  // register only 1 draw point every registerStep
  registerCount = 0;
  registerStep = 2;

  constructor(receiver: StudioCanvas, freeDrawConfig: FreeDrawConfig) {
    this.receiver = receiver;
    this.freeDrawConfig = freeDrawConfig;
  }

  execute(): void {
    let isPaint = false;
    let lastLine;

    this.receiver.stage.on('mousedown touchstart', () => {
      isPaint = true;
      const pos = this.receiver.layer.getRelativePointerPosition();
      lastLine = new Konva.Line({
        stroke: this.freeDrawConfig.drawConfig.color.code,
        strokeWidth: this.freeDrawConfig.drawConfig.width,
        globalCompositeOperation: this.freeDrawConfig.mode,
        points: [pos.x, pos.y, pos.x, pos.y],
        lineCap: 'round',
        lineJoin: 'round',
      });
      this.receiver.addShape(lastLine);
    });

    this.receiver.stage.on('mouseup touchend', () => {
      isPaint = false;
    });

    this.receiver.stage.on('mousemove touchmove', () => {
      if (!isPaint) {
        return;
      }

      if (this.registerCount !== this.registerStep) {
        this.registerCount++;
        return;
      }
      this.registerCount = 0;

      const pos = this.receiver.layer.getRelativePointerPosition();
      const newPoints = lastLine.points().concat([pos.x, pos.y]);
      lastLine.points(newPoints);
    });

  }

}
