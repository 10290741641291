@if (user && !isCurrentUser) {
  <a
    routerLink="/dashboard/chat"
    [queryParams]="{
      userId: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
    }"
    queryParamsHandling="merge"
    class="hover:brightness-80 focus:brightness-70 underline-offset-4 transition-all hover:!underline focus:!underline"
    data-testid="link"
  >
    <ng-container *ngTemplateOutlet="userDisplay"></ng-container>
  </a>
} @else {
  <ng-container *ngTemplateOutlet="userDisplay"></ng-container>
}

<ng-template #userDisplay>
  <span
    [ngStyle]="{ color: colored && avatarColors.textColor }"
    [ngClass]="{ 'text-[inherit]': !colored }"
  >
    {{ user?.firstName }} {{ user?.lastName }}
  </span>
</ng-template>
