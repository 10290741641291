@if (isAppInitialized$ | async) {
  <main
    class="grid min-h-screen overflow-x-hidden transition-all duration-300 animate-in fade-in"
    [ngClass]="{
      'md:grid-cols-sidebar-collapsed': sidebarService.collapsed,
      'md:grid-cols-sidebar': !sidebarService.collapsed,
    }"
  >
    <pxc-sidebar
      class="fixed z-40 border-r border-border-normal transition-all max-md:w-3/4 md:static"
      [ngClass]="{
        'max-md:-translate-x-full': !sidebarService.shown,
        'max-md:translate-x-0': sidebarService.shown,
      }"
      [collapsed]="sidebarService.collapsed"
      data-testid="sidebar"
    ></pxc-sidebar>
    <div class="relative h-screen overflow-y-auto overflow-x-hidden">
      <pxc-header
        class="pointer-events-none sticky top-0 z-30"
        data-testid="navbar"
      ></pxc-header>
      <pxc-paywall-alert></pxc-paywall-alert>
      <router-outlet data-testid="content"></router-outlet>
    </div>
    <!-- This is the background overlay when sidebar is open on mobile -->
    <button
      type="button"
      class="fixed left-0 top-0 z-30 h-screen w-screen bg-accent-opposite/50 transition-all md:hidden"
      (click)="sidebarService.shown = false"
      [ngClass]="{
        'hidden opacity-0': !sidebarService.shown,
        'opacity-100': sidebarService.shown,
      }"
    ></button>
  </main>
} @else {
  <pxc-loader
    [fullscreen]="true"
    [useLogo]="true"
    data-testid="loading"
    class="duration-500 animate-in fade-in zoom-in-90"
  ></pxc-loader>
}
