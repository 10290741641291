import Konva from 'konva';
import { Command } from 'src/app/shared/models/helpers/command';
import { StudioCanvas } from '../canvas/studio-canvas';
import { StudioShape } from '../config/studio-enums';

export class SelectCommand implements Command {

  receiver: StudioCanvas;
  isPaint = false;
  mode = 'brush';
  lastLine;

  constructor(receiver: StudioCanvas) {
    this.receiver = receiver;
  }

  execute(): void {
    this.receiver.stage.on('click tap', (e) => {
      // deselect everything if click is on the background or something else than our shapes
      if (e.target === this.receiver.stage || !e.target.hasName(StudioShape.SHAPE_NAME)) {
        this.receiver.deselectShapes();
        return;
      }

      const clickedShape: Konva.Shape = e.target as Konva.Shape;

      const ctrlPressed = e.evt.ctrlKey || e.evt.metaKey;

      const shapeSelected = this.receiver.transformer.nodes()
        .concat(this.receiver.lineTransformer.getTransformedLine())
        .indexOf(clickedShape) >= 0;

      if (!ctrlPressed && !shapeSelected) {
        this.receiver.setSelectedShapes([clickedShape]);
      } else if (ctrlPressed && shapeSelected) {
        this.receiver.deselectShape(clickedShape);
      } else if (ctrlPressed && !shapeSelected) {
        this.receiver.selectShape(clickedShape);
      }
    });
  }

}
