<tui-elastic-container [formGroup]="form">
  <div class="flex flex-col gap-3">
    <pxc-user-input
      [allowEmails]="false"
      placeholder="Rechercher un utilisateur"
      formControlName="users"
    ></pxc-user-input>

    <tui-error
      formControlName="users"
      [error]="[] | tuiFieldError | async"
    ></tui-error>

    <div class="flex justify-between gap-2 max-lg:flex-col-reverse lg:items-center">
      <button
        type="button"
        tuiButton
        appearance="outline"
        (click)="close()"
      >
        Annuler
      </button>
      <button
        type="button"
        tuiButton
        (click)="create()"
        [disabled]="form.untouched || form.invalid"
      >
        Créer
      </button>
    </div>
  </div>
</tui-elastic-container>
