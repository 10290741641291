@if (templates$ | async; as templates) {
  <div
    tuiSurface="outline"
    class="w-full p-1"
    [ngClass]="{
      'rounded-full': !templates?.length,
      'rounded-xl md:rounded-full': templates?.length,
    }"
  >
    <div class="flex w-full items-stretch max-md:flex-col md:gap-1">
      <div class="flex flex-1 items-stretch gap-1 max-md:flex-col md:items-center">
        <div
          class="flex h-full min-h-10 flex-1 cursor-text items-center gap-2 lg:w-52"
          (click)="focusSearch()"
        >
          <tui-icon
            icon="@tui.search"
            class="ml-2 h-5 text-tertiary"
          ></tui-icon>
          <input
            #searchInput
            type="text"
            [ngModel]="search$ | async"
            (ngModelChange)="updateSearch($event)"
            data-testid="search"
            [placeholder]="searchPlaceholder"
            class="h-full flex-1 placeholder:truncate placeholder:text-sm placeholder:text-tertiary focus:outline-none"
          />
        </div>

        @if (templates?.length > 0) {
          <div
            class="mx-1 flex flex-wrap items-center gap-1 max-lg:flex-nowrap max-md:mb-1 md:max-w-[60vw]"
            data-testid="templates"
          >
            @for (template of templates; track trackByTemplate($index, template)) {
              @switch (template.type) {
                @case (FilterType.DATE_RANGE) {
                  <pxc-filter-date-range
                    [template]="template"
                    (templateChange)="updateTemplate($event)"
                    [attr.data-testid]="template.name"
                  ></pxc-filter-date-range>
                }
                @case (FilterType.SELECTOR) {
                  <pxc-filter-selector
                    [template]="template"
                    (templateChange)="updateTemplate($event)"
                    [attr.data-testid]="template.name"
                  ></pxc-filter-selector>
                }
              }
            }
            <button
              *tuiLet="isClearable$ | async as clearable"
              tuiIconButton
              [iconStart]="'@tui.x'"
              appearance="flat"
              size="s"
              class="ml-auto"
              (click)="clear()"
              [disabled]="!clearable"
              data-testid="clear-button"
            ></button>
          </div>
        }

        @if (actions.length > 0) {
          <button
            tuiIconButton
            iconStart="@tui.more-ver"
            appearance="flat"
            size="s"
            data-testid="action-button"
            tuiDropdownOpen
            [tuiDropdown]="actionsDropdown"
            tuiDropdownAlign="right"
          ></button>
        }

        <ng-template #actionsDropdown>
          <pxc-menu-actions [actions]="actions"></pxc-menu-actions>
        </ng-template>
      </div>
    </div>
  </div>
}
