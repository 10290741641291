<div
  *tuiLet="selectedPatient$ | async as selectedPatient"
  class="flex flex-col gap-2"
>
  <div class="flex items-center gap-2 max-lg:flex-wrap">
    <pxc-filter-bar
      searchPlaceholder="Rechercher une séquence par mot clé, date ou patient ..."
      data-testid="filter-bar"
    ></pxc-filter-bar>

    @if (selectedPatient) {
      @if (hasLicenseWriteAccess$ | async) {
        <pxc-create-button
          [disabled]="selectedPatient.isArchived"
          (click)="addSequence(selectedPatient)"
          data-testid="add-sequence"
          class="mt flex-none max-lg:w-full"
          label="Ajouter une séquence"
        ></pxc-create-button>
      }
    }
  </div>

  @if (sequences$ | async; as sequences) {
    @if (isSequenceStateLoaded$ | async) {
      <pxc-sequence-list
        [sequences]="sequences.data"
        [actions]="sequenceActions"
        [isNextPageLoading]="sequences.context.isNextPageLoading"
        [isPreviousPageLoading]="sequences.context.isPreviousPageLoading"
        (loadPrevious)="loadPreviousPage()"
        (loadNext)="loadNextPage()"
        (sequenceMediaClicked)="openSequenceMediaGallery($event)"
        data-testid="sequence-list"
      ></pxc-sequence-list>
    } @else {
      <ng-container
        [ngTemplateOutlet]="loading"
        ]
      ></ng-container>
    }
  } @else {
    <ng-container
      [ngTemplateOutlet]="loading"
      ]
    ></ng-container>
  }
</div>

<ng-template #loading>
  <div class="mx-auto my-4">
    <pxc-loader data-testid="loading">Récupération des photos en cours ...</pxc-loader>
  </div>
</ng-template>
