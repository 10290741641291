@if (patients) {
  @if (patients.length > 0) {
    <tui-scrollbar class="relative max-h-96">
      <div class="flex flex-col divide-y divide-slate-100">
        @if ((isGamEnabled$ | async) && gamLinkedPatients.length > 0) {
          <ng-container
            [ngTemplateOutlet]="sectionTitle"
            [ngTemplateOutletContext]="{ title: 'Patients existants dans votre établissement' }"
          ></ng-container>
          @for (patient of gamLinkedPatients; track patient) {
            <ng-container
              [ngTemplateOutlet]="patientCard"
              [ngTemplateOutletContext]="{ patient: patient }"
            ></ng-container>
          }
        }
        @if (pixacarePatients.length > 0) {
          <ng-container
            [ngTemplateOutlet]="sectionTitle"
            [ngTemplateOutletContext]="{ title: 'Patients existants dans Pixacare' }"
          ></ng-container>
          @for (patient of pixacarePatients; track patient) {
            <ng-container
              [ngTemplateOutlet]="patientCard"
              [ngTemplateOutletContext]="{ patient: patient }"
            ></ng-container>
          }
        }
      </div>
    </tui-scrollbar>
  } @else {
    <div class="flex h-full w-full items-center justify-center">
      <p
        class="text-center text-tertiary"
        data-testid="no-identified-patient"
      >
        Aucun patient n'a été identifié.
      </p>
    </div>
  }
} @else {
  <pxc-loader
    size="tiny"
    data-testid="loading"
    class="my-auto"
  ></pxc-loader>
}

<ng-template
  #sectionTitle
  let-title="title"
>
  <p
    class="sticky top-0 z-10 border-b border-border-normal bg-base p-2 text-sm font-medium text-secondary lg:bg-base-alt"
  >
    {{ title }}
  </p>
</ng-template>

<ng-template
  #patientCard
  let-patient="patient"
>
  <button
    class="flex w-full gap-3 p-3 hover:bg-neutral-1"
    (click)="onPatientClick(patient)"
    [attr.data-testid]="'select-patient-' + patient.id"
  >
    <pxc-patient-avatar
      size="small"
      [patient]="patient"
    ></pxc-patient-avatar>
    <pxc-patient-data
      [patient]="patient"
      size="small"
    ></pxc-patient-data>
  </button>
</ng-template>
