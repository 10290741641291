<div class="flex flex-col gap-2 py-2">
  <h2 tuiTitle="m">
    Bonjour,
    <p
      tuiSubtitle
      class="max-w-prose text-balance"
    >
      Connectez-vous à votre environnement sécurisé de photographies médicales.
    </p>
  </h2>
</div>

<form
  [formGroup]="loginForm"
  (ngSubmit)="login()"
  data-testid="login-form"
  class="mt-6 flex flex-col gap-4"
>
  <div>
    <tui-input
      formControlName="inputEmail"
      [disabled]="(actionOnGoing$ | async) ? '' : null"
      tuiTextfieldIconLeft="@tui.mail"
      ngDefaultControl
    >
      Adresse E-Mail
      <input
        tuiTextfieldLegacy
        type="email"
        data-testid="email-input"
      />
    </tui-input>
    <tui-error
      formControlName="inputEmail"
      [error]="[] | tuiFieldError | async"
    />
  </div>

  <div>
    <tui-input-password
      formControlName="inputPassword"
      tuiTextfieldIconLeft="@tui.lock"
      [disabled]="(actionOnGoing$ | async) ? '' : null"
      ngDefaultControl
    >
      Mot de passe
      <input
        tuiTextfieldLegacy
        type="password"
        data-testid="password-input"
      />
    </tui-input-password>
    <tui-error
      formControlName="inputPassword"
      [error]="[] | tuiFieldError | async"
    />
  </div>

  <a
    tuiLink
    data-appearance="primary"
    class="!text-sm"
    routerLink="../reset-password-request"
  >
    Mot de passe oublié ?
  </a>

  <label tuiLabel>
    <input
      tuiCheckbox
      type="checkbox"
      formControlName="rememberEmail"
      [disabled]="(actionOnGoing$ | async) ? '' : null"
      data-testid="remember-email-checkbox"
      class="mt-3"
      ngDefaultControl
    />
    Se souvenir de mon e-mail
  </label>

  <div class="mt-4 flex flex-row-reverse flex-wrap justify-between gap-3">
    <button
      tuiButton
      type="submit"
      appearance="primary"
      [disabled]="actionOnGoing$ | async"
      [loading]="actionOnGoing$ | async"
      data-testid="login-button"
      class="max-sm:order-first max-sm:w-full"
    >
      Se connecter
    </button>

    <button
      tuiButton
      appearance="outline"
      routerLink="../register"
      [disabled]="actionOnGoing$ | async"
      class="max-sm:w-full"
    >
      Je n'ai pas de compte
    </button>
  </div>
</form>
