<tui-elastic-container>
  @if (step === 1) {
    <div class="flex flex-col">
      <pxc-sequence-picker
        [patientId]="patientId"
        [clientCode]="clientCode"
        [departmentIds]="departmentIds"
        [telemonitoringIds]="telemonitoringId ? [telemonitoringId] : null"
        [preselectedSequences]="selectedSequences"
        (selectedAllSequencesChange)="isAllSequencesSelected = $event"
        (selectedSequenceIdsChange)="selectedSequenceIds = $event"
        (selectedSequencesChange)="selectedSequences = $event"
        (selectedTelemonitoringIdsChange)="telemonitoringsSelectionChanged($event)"
        (selectedSadmsChange)="sadmEntitiesSelectionChanged($event)"
        [showSadms]="true"
        [showTelemonitorings]="true"
        data-testid="sequence-picker"
      ></pxc-sequence-picker>
    </div>
  }

  @if (step === 2) {
    <div
      data-testid="summary"
      class="flex flex-col gap-6 pb-4"
    >
      <p>Vérifiez les informations qui seront incluses dans le rapport.</p>
      <pxc-patient-card [patient]="patient$ | async"></pxc-patient-card>
      <div class="flex flex-col gap-1">
        <div class="flex flex-col gap-2">
          <pxc-sequence-stacks-preview
            [sequences]="selectedSequences"
            (mediaClicked)="openGallery($event)"
          ></pxc-sequence-stacks-preview>
          <div class="flex flex-col gap-1">
            <ng-container
              [ngTemplateOutlet]="dataLabel"
              [ngTemplateOutletContext]="{
                icon: '@tui.camera',
                label: selectedSequenceIds.length | i18nPlural: sequencesCountPluralMapping,
              }"
            ></ng-container>
            @for (telemonitoring of selectedTelemonitoringNames; track telemonitoring) {
              <ng-container
                [ngTemplateOutlet]="dataLabel"
                [ngTemplateOutletContext]="{
                  icon: 'telemonitoring',
                  label: telemonitoring,
                }"
              ></ng-container>
            }
            @for (sadmEntity of selectedSadmEntityNames; track sadmEntity) {
              <ng-container
                [ngTemplateOutlet]="dataLabel"
                [ngTemplateOutletContext]="{
                  icon: '@tui.chart-column-increasing',
                  label: sadmEntity,
                }"
              ></ng-container>
            }
          </div>
        </div>
      </div>
      @if (selectedSadmEntityNames.length > 0) {
        <div class="flex flex-col gap-2">
          <label
            tuiTitle="s"
            class="text-secondary"
          >
            Personnalisation du rapport
          </label>
          <form
            [formGroup]="customReportForm"
            class="space-y-1"
          >
            <label tuiLabel>
              <input
                tuiCheckbox
                type="checkbox"
                formControlName="includeReports"
              />
              Afficher les réponses aux questionnaires
            </label>
            <label tuiLabel>
              <input
                tuiCheckbox
                type="checkbox"
                formControlName="includeCharts"
              />
              Afficher les graphiques
            </label>
          </form>
        </div>
      }
    </div>
  }
</tui-elastic-container>

<div class="border-t-1 -mx-7 -mb-7 flex flex-col gap-6 rounded-b-2xl border-border-hover bg-base px-8 py-4">
  <div class="flex justify-stretch gap-2 max-md:flex-col md:justify-between">
    <button
      tuiButton
      status="primary"
      type="button"
      appearance="outline"
      (click)="previousStep()"
      data-testid="cancel-selection-button"
    >
      {{ step === 2 ? 'Retour' : 'Annuler' }}
    </button>
    <div class="flex items-stretch gap-4 max-md:order-first max-md:flex-col md:items-center">
      @if (step === 1) {
        <p class="text-center">{{ selectedSequenceIds.length | i18nPlural: sequenceCountPluralMapping }}</p>
      }
      <button
        tuiButton
        type="button"
        [disabled]="selectedSequenceIds.length <= 0"
        (click)="nextStep()"
        data-testid="confirm-selection-button"
      >
        {{ step === 2 ? 'Exporter' : 'Suivant' }}
      </button>
    </div>
  </div>

  <ng-template
    let-icon="icon"
    let-label="label"
    #dataLabel
  >
    @if (label) {
      <div class="flex items-center gap-3">
        <tui-icon [icon]="icon"></tui-icon>
        <p>{{ label }}</p>
      </div>
    }
  </ng-template>
</div>
