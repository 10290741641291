import { inject, Pipe, PipeTransform } from '@angular/core';
import { SadmEntity, SadmType } from '@pixacare/pxc-ts-core';
import { SadmEntityTypePipe } from './sadm-entity-type.pipe';

@Pipe({
  name: 'sadmEntityName',
  pure: true,
  standalone: true,
})
export class SadmEntityNamePipe implements PipeTransform {

  private sadmEntityTypePipe = inject(SadmEntityTypePipe);

  transform(value: SadmEntity, sadmType?: SadmType, index?: number): string {
    if (!sadmType) {
      return value.name;
    }

    return `${this.sadmEntityTypePipe.transform(value, sadmType, index)} - ${value.name}`;
  }

}
