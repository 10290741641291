@if (!isLoading) {
  <div class="flex flex-col gap-4">
    <tui-stepper
      [(activeItemIndex)]="activePageIndex"
      class="pointer-events-none mb-2 justify-between"
      data-testid="stepper"
    >
      @for (step of telemonitoringRequestStepsConfig; track step) {
        <button tuiStep>
          {{ step.name }}
        </button>
      }
    </tui-stepper>
    <tui-elastic-container
      class="min-h-80 [&>*]:block [&>*]:pb-4"
      [formGroup]="form"
    >
      @switch (activePageIndex) {
        @case (TelemonitoringRequestStep.TYPE) {
          <pxc-telemonitoring-request-type
            (creatorTypeChange)="pickCreatorType($event)"
            data-testid="request-type"
          ></pxc-telemonitoring-request-type>
        }
        @case (TelemonitoringRequestStep.DETAILS) {
          <pxc-telemonitoring-request-details
            [form]="form"
            [defaultName]="defaultName"
            data-testid="request-details"
          ></pxc-telemonitoring-request-details>
        }
        @case (TelemonitoringRequestStep.COLLABORATORS) {
          <pxc-telemonitoring-collaborators
            formControlName="collaborators"
            [departmentIds]="form.get('departmentIds').value"
          ></pxc-telemonitoring-collaborators>
        }
        @case (TelemonitoringRequestStep.LOCATION) {
          <pxc-telemonitoring-request-location
            [form]="form"
            data-testid="request-location"
          ></pxc-telemonitoring-request-location>
        }
        @case (TelemonitoringRequestStep.SEQUENCES) {
          <pxc-telemonitoring-request-sequences
            [form]="form"
            data-testid="request-sequences"
          ></pxc-telemonitoring-request-sequences>
        }
      }
    </tui-elastic-container>
    <div class="flex flex-wrap items-center gap-2 md:gap-4">
      @if (!telemonitoringRequestStepsConfig[activePageIndex].hidePrevious) {
        <button
          tuiButton
          appearance="outline"
          (click)="previousPage()"
          class="mr-auto max-md:order-last max-md:w-full"
          data-testid="previous-button"
        >
          {{ activePageIndex < 1 ? 'Annuler' : 'Précédent' }}
        </button>
      }
      @if (!telemonitoringRequestStepsConfig[activePageIndex].hideNext) {
        <button
          tuiButton
          data-testid="next-button"
          (click)="nextPage()"
          [disabled]="!isStepValid(telemonitoringRequestStepsConfig[activePageIndex].fields)"
          class="max-md:w-full"
        >
          {{ activePageIndex === telemonitoringRequestStepsConfig.length - 1 ? 'Créer' : 'Suivant' }}
        </button>
      }
    </div>
  </div>
} @else {
  <pxc-telemonitoring-request-result
    [state]="state$ | async"
    (closeDialog)="closeDialog()"
  ></pxc-telemonitoring-request-result>
}
