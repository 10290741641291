import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { ImageBrokenComponent } from '../image-broken/image-broken.component';
import { ImageLoadingComponent } from '../image-loading/image-loading.component';
import { ImageOverlayComponent } from '../image-overlay/image-overlay.component';
import { SharedModule } from '../shared.module';

@Component({
  selector: 'pxc-file-image-preview',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ImageOverlayComponent,
    ImageBrokenComponent,
    ImageLoadingComponent,
  ],
  templateUrl: './file-image-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileImagePreviewComponent implements OnInit {

  @Input() file: File;

  @Input() showDelete = true;

  @Output() delete = new EventEmitter<void>();

  src$ = new Subject<string>();
  error$ = new Subject<boolean>();

  ngOnInit(): void {

    if (!this.file) {
      this.error$.next(true);
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {

      this.src$.next(e.target.result as string);
    };

    reader.readAsDataURL(this.file);
  }

}
