<div
  class="flex items-center gap-1"
  *tuiLet="'text-secondary text-sm' as subtextStyle"
>
  <div
    class="flex flex-row items-center gap-3"
    data-testid="patient-data"
  >
    <div class="flex flex-col overflow-hidden text-left">
      <p
        [tuiTitle]="(size | toTuiSize) === 'm' ? 'm' : null"
        class="!inline text-lg !font-medium"
        [ngClass]="customClass || 'text-primary'"
        data-testid="title"
      >
        {{ patient | patientName }}
        <ng-content></ng-content>
      </p>
      @if (patient) {
        @if (patient.birthName) {
          <p
            [ngClass]="customClass || subtextStyle"
            data-testid="birth-name"
          >
            Né(e)
            <span [class.text-secondary]="!customClass">{{ patient.birthName }}</span>
            le
            <ng-container *ngTemplateOutlet="birthDate"></ng-container>
          </p>
        } @else {
          <p
            [ngClass]="customClass || subtextStyle"
            data-testid="birth-date"
          >
            Né(e) le
            <ng-container *ngTemplateOutlet="birthDate"></ng-container>
          </p>
        }
        @if (patient.businessIdentifier) {
          <p
            [ngClass]="customClass || subtextStyle"
            data-testid="nip"
          >
            NIP :
            <span [class.text-secondary]="!customClass">{{ patient.businessIdentifier }}</span>
          </p>
        }
        <ng-content select="[additional-data]"></ng-content>
      }
    </div>
  </div>

  <ng-template #birthDate>
    <span [class.text-secondary]="!customClass">{{ patient.birthDate | date: 'shortDate' }}</span>
  </ng-template>
</div>
