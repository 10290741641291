<div class="flex w-full flex-col gap-3 border-t border-border-normal p-3">
  <div class="flex items-center gap-3">
    <button
      tuiIconButton
      appearance="flat"
      iconStart="@tui.plus"
      size="l"
      (click)="addAttachment()"
    ></button>
    <form
      class="flex w-full items-center gap-3"
      (ngSubmit)="send()"
    >
      <tui-input
        class="flex-1"
        [formControl]="newMessageText"
        [tuiTextfieldLabelOutside]="true"
        tuiAutoFocus
      >
        Entrez un message
      </tui-input>
      <button
        tuiIconButton
        type="submit"
        appearance="flat"
        iconStart="@tui.send"
        size="l"
      ></button>
    </form>
  </div>
</div>
