import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DepartmentUserService } from '@modules/department/department-user.service';
import { FILTER_TEMPLATES, FilterBarService } from '@modules/filters/filter-bar.service';
import { SequenceActionsService } from '@modules/sequence/services/sequence-actions.service';
import { UserSequenceGalleryService } from '@modules/sequence/services/user-sequence-gallery.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { FilterOperator, FlattenedSequence, PaginationQuery } from '@pixacare/pxc-ts-core';
import { AuthenticationService } from '@services/authentication.service';
import { SequenceStorePaginationService } from '@services/sequence-store-pagination.service';
import { ContextualComponentMode } from '@shared/models/enums/contextual-component-mode.enum';
import { sequenceFilterTemplates } from '@shared/models/filters/filter-template.config';
import { MediaClickedOutput } from '@shared/models/media-clicked-output';
import { MenuAction } from '@shared/models/menu-actions/menu-action';
import { PagedCollection } from '@shared/models/pagination/paged-collection';
import { defaultPaginationContext } from '@shared/models/pagination/pagination-context.config';
import { selectDepartmentName } from '@shared/store/departments/departments.selectors';
import { selectHasLicenseWriteAccess } from '@shared/store/licenses/licenses.selectors';
import { selectClientCode, selectDepartmentId, selectQueryParams } from '@shared/store/router/router.selectors';
import { sequencesActions } from '@shared/store/sequences/sequences.actions';
import { selectIsSequenceStateLoaded } from '@shared/store/sequences/sequences.selectors';
import { Observable, combineLatest, distinctUntilChanged, map } from 'rxjs';

@UntilDestroy()
@Component({
  templateUrl: './sequences.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    FilterBarService,
    {
      provide: FILTER_TEMPLATES,
      useValue: sequenceFilterTemplates,
    },
    UserSequenceGalleryService,
  ],
})
export class SequencesComponent implements OnInit {

  hasLicenseWriteAccess$: Observable<boolean>;
  ContextualComponentMode = ContextualComponentMode;
  mode: ContextualComponentMode;

  clientCode: string = null;
  departmentId: number = null;
  isAppInitialized$: Observable<boolean>;
  collection$: Observable<PagedCollection<FlattenedSequence[]>>;
  isCollectionLoaded$: Observable<boolean> = null;
  departmentName$: Observable<string>;
  actions: MenuAction<FlattenedSequence>[];

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly sequencePaginationService: SequenceStorePaginationService,
    private readonly sequenceActionsService: SequenceActionsService,
    private readonly departmentUserService: DepartmentUserService,
    private readonly userSequenceGalleryService: UserSequenceGalleryService,
    private readonly filterBarService: FilterBarService,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(sequencesActions.resetSequencesState());

    const params$ = combineLatest({
      clientCode: this.store.select(selectClientCode),
      departmentId: this.store.select(selectDepartmentId),
    }).pipe(
      untilDestroyed(this),
    );

    params$.subscribe(({ clientCode, departmentId }) => {

      this.clientCode = clientCode;
      this.departmentId = departmentId;

      if (this.departmentId) {

        this.departmentUserService.isDepartmentUserDisabled$(
          this.authenticationService.currentUser.id,
          this.clientCode,
          this.departmentId,
        )
          .pipe(untilDestroyed(this))
          .subscribe((isUserDisabled) => {
            if (isUserDisabled) {
              this.router.navigate(['/']);
            }
          });

        this.departmentName$ = this.store.select(selectDepartmentName(this.clientCode, this.departmentId));
      }

      if (this.clientCode) {
        this.mode = this.departmentId ? ContextualComponentMode.DEPARTMENT : ContextualComponentMode.USER;

        this.hasLicenseWriteAccess$ = this.store.select(selectHasLicenseWriteAccess);

        this.collection$ = this.sequencePaginationService.select();

        this.actions = this.sequenceActionsService.getActions();

      } else {
        this.router.navigate(['/']);
      }

      this.store.select(selectQueryParams).pipe(
        map(({ create }) => create),
        distinctUntilChanged(),
        untilDestroyed(this),
      ).subscribe((create) => {
        if (create) {
          this.addSequence();
        }
      });

    });

    combineLatest([this.filterBarService.filteredSearch$, params$])
      .pipe(untilDestroyed(this))
      .subscribe(([{ search, filters }, { clientCode, departmentId }]) => {
        this.sequencePaginationService.load(clientCode, {
          query: new PaginationQuery({
            orderBy: ['created_on|desc'],
            filter: [
              ...filters,
              ...(departmentId
                ? [{ prop: 'department_id', op: FilterOperator.EQUAL, val: departmentId.toString() }]
                : []
              ),
            ],
            search,
            size: defaultPaginationContext.countPerPage,
          }),
          reset: true,
        });
      });

    this.isCollectionLoaded$ = this.store.select(selectIsSequenceStateLoaded);
  }

  async addSequence(): Promise<void> {
    await this.sequenceActionsService.addSequence(this.clientCode, {
      ...(this.departmentId && { departmentIds: [this.departmentId] }),
    });
    this.filterBarService.clear();
  }

  loadNextPage(): void {
    this.sequencePaginationService.loadNextPage(this.clientCode);
  }

  loadPreviousPage(): void {
    this.sequencePaginationService.loadPreviousPage(this.clientCode);
  }

  openSequenceMediaGallery(clickedMedia: MediaClickedOutput): void {
    this.userSequenceGalleryService.openGallery(this.clientCode, clickedMedia.sequenceId, clickedMedia.mediaIdx);
  }

}
