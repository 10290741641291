import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FILTER_TEMPLATES, FilterBarService } from '@modules/filters/filter-bar.service';
import { SequenceActionsService } from '@modules/sequence/services/sequence-actions.service';
import { UserSequenceGalleryService } from '@modules/sequence/services/user-sequence-gallery.service';
import { TelemonitoringActionsService } from '@modules/telemonitoring/telemonitoring-actions.service';
import { TelemonitoringService } from '@modules/telemonitoring/telemonitoring.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FlattenedTelemonitoring } from '@pixacare/pxc-ts-core';
import { SequenceContext } from '@shared/models/enums/sequence-context.enum';
import { sequenceFilterTemplates } from '@shared/models/filters/filter-template.config';
import { MediaClickedOutput } from '@shared/models/media-clicked-output';
import { ActionButton } from '@shared/models/menu-actions/action-buttons';
import { MenuActionId } from '@shared/models/menu-actions/menu-action-id.enum';
import { first, map } from 'rxjs';
import { TelemonitoringPageService } from './telemonitoring-page.service';

@UntilDestroy()
@Component({
  templateUrl: './telemonitoring.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TelemonitoringPageService,
    FilterBarService,
    {
      provide: FILTER_TEMPLATES,
      useValue: sequenceFilterTemplates,
    },
    UserSequenceGalleryService,
  ],
})
export class TelemonitoringComponent {

  telemonitoring$ = this.telemonitoringPageService.getTelemonitoring();
  sequences$ =  this.telemonitoringPageService.getSequences(this.filterBarService.filteredSearch$);
  qrCode$ = this.telemonitoringPageService.getQrCode();
  isTelemonitoringStateLoaded$ = this.telemonitoringPageService.getIsTelemonitoringStateLoaded();
  patientContactPhoneNumber$ = this.telemonitoringPageService.getPatientContactPhoneNumber();
  context: SequenceContext = SequenceContext.TELEMONITORING;
  sequenceActions = this.sequenceActionsService.getActions(this.context);
  tmActions = this.tmActionsService.getActions();
  isArchived$ = this.telemonitoring$.pipe(
    untilDestroyed(this),
    map((telemonitoring: FlattenedTelemonitoring) =>
      !telemonitoring.telemonitoring.isActive || (telemonitoring.patient && telemonitoring.patient.isArchived),
    ),
  );

  actionButtonsGroups: ActionButton[] = [
    {
      appearance: 'secondary',
      title: 'Rapport',
      actionIds: [
        MenuActionId.TELEMONITORING_REPORT,
      ],
    },
    {
      appearance: 'outline',
      title: 'Conversation',
      actionIds: [
        MenuActionId.TELEMONITORING_OPEN_CHAT,
      ],
    },
    {
      appearance: 'outline',
      title: 'Tutoriel',
      actionIds: [
        MenuActionId.TELEMONITORING_TUTORIAL,
        MenuActionId.TELEMONITORING_SEND_TUTORIAL,
      ],
    },
  ];

  constructor(
    private readonly sequenceActionsService: SequenceActionsService,
    private readonly tmActionsService: TelemonitoringActionsService,
    private readonly userSequenceGalleryService: UserSequenceGalleryService,
    private readonly telemonitoringService: TelemonitoringService,
    private readonly filterBarService: FilterBarService,
    private readonly telemonitoringPageService: TelemonitoringPageService,
  ) {}

  openGallery(clickedMedia: MediaClickedOutput): void {
    this.telemonitoringPageService.getParams()
      .pipe(first())
      .subscribe(({ clientCode }) => {
        this.userSequenceGalleryService.openGallery(clientCode, clickedMedia.sequenceId, clickedMedia.mediaIdx);
      });
  }

  openQrCode(tmId: number): void {
    this.telemonitoringService.openQrCodeInGallery(tmId);
  }

  addSequence(): void {
    this.telemonitoring$.pipe(first()).subscribe(async (tlm) => {
      await this.sequenceActionsService.addSequence(tlm.telemonitoring.clientCode, {
        telemonitoringId: tlm.telemonitoring.id,
        patient: tlm.patient,
      });
    });
  }

}
