<div
  class="container relative"
  [ngClass]="{
    'lg:max-[1870px]:!pr-40': drawerOutlet.isActivated,
  }"
>
  <router-outlet data-testid="content"></router-outlet>
</div>
<router-outlet
  name="drawer"
  #drawerOutlet="outlet"
></router-outlet>
