import Konva from 'konva';
import { Group } from 'konva/lib/Group';
import { Layer } from 'konva/lib/Layer';
import { Circle } from 'konva/lib/shapes/Circle';
import { Line } from 'konva/lib/shapes/Line';
import { Stage } from 'konva/lib/Stage';
import { StudioService } from '../studio/studio.service';

export class LineTransformer {

  line: Line;
  anchors: Group;

  private layer: Layer;
  private stage: Stage;

  private anchorConfig = {
    radius: 8,
    fill: 'white',
    stroke: '#00a1ff',
    strokeWidth: 1,
    draggable: true,
    visible: false,
  };

  constructor(layer: Konva.Layer, stage: Konva.Stage, private studioService: StudioService) {

    this.layer = layer;
    this.stage = stage;

    this.anchors = new Group();

    this.anchors.add(new Konva.Circle(this.anchorConfig));
    this.anchors.add(new Konva.Circle(this.anchorConfig));

    this.anchors.getChildren().forEach((anchor) => {
      this.layer.add(anchor);
      this.anchors.add(anchor);
    });

    this.layer.add(this.anchors);

    this.setAnchorMouseOver();
  }

  stopLineTransform(): void {
    this.resetAnchorVisibility();
    if (this.line) {
      this.line.off();
      this.line = null;
    }

    // Restablish default cursor when deselecting by clicking on the anchor
    this.stage.container().style.cursor = 'default';
  }

  destroyTransformedLine(): void {
    if (this.line) {
      this.line.destroy();
    }
  }

  getTransformedLine(): Line {
    return this.line;
  }

  setTransformedLine(line: Konva.Line): void {

    if (!line) {
      return;
    }

    this.resetAnchorVisibility();
    this.setAnchorMouseOver();
    this.anchors.moveToTop();

    this.line = line;

    this.updateAnchors();

    line.on('dragmove', () => {
      this.updateAnchors();
    });

    this.anchors.getChildren().forEach((anchor, idx) => {
      (anchor as Circle).on('dragmove', (e) => {
        if (e.evt.shiftKey && this.line.rotation() === 0) {
          this.updateLineSnap(idx);
        } else {
          this.updateLineFree();
        }
      });
    });

  }

  private updateAnchors(): void {
    this.anchors.setAttrs({
      x: this.line.x(),
      y: this.line.y(),
      rotation: this.line.rotation(),
    });

    this.anchors.getChildren()[0].setAttrs({
      x: (this.line.points()[0] * this.line.scaleX()),
      y: (this.line.points()[1] * this.line.scaleY()),
      visible: true,
    });

    this.anchors.getChildren()[1].setAttrs({
      x: (this.line.points()[2] * this.line.scaleX()),
      y: (this.line.points()[3] * this.line.scaleY()),
      visible: true,
    });
  }

  private updateLineSnap(anchorId: number): void {

    const mirrorPointId = anchorId === 1 ? { x: 0, y: 1 } : { x: 2, y: 3 };

    const snappedPoints = this.studioService.getLineSnappedPositions(
      this.line.x() + (this.line.points()[mirrorPointId.x] * this.line.scaleX()),
      this.line.y() + (this.line.points()[mirrorPointId.y] * this.line.scaleY()),
      this.layer.getRelativePointerPosition().x,
      this.layer.getRelativePointerPosition().y,
    );

    this.anchors.getChildren()[anchorId].x(snappedPoints[2] - this.line.x());
    this.anchors.getChildren()[anchorId].y(snappedPoints[3] - this.line.y());

    this.updateLineFree();
  }

  private updateLineFree(): void {
    this.line.points([
      this.anchors.getChildren()[0].x() / this.line.scaleX(),
      this.anchors.getChildren()[0].y() / this.line.scaleY(),
      this.anchors.getChildren()[1].x() / this.line.scaleX(),
      this.anchors.getChildren()[1].y() / this.line.scaleY(),
    ]);
    this.layer.batchDraw();
  }

  private setAnchorMouseOver(): void {

    this.anchors.getChildren().forEach((anchor: Circle) => {
      anchor.on('mouseenter', () => {
        this.stage.container().style.cursor = 'move';
      });
      anchor.on('mouseleave', () => {
        this.stage.container().style.cursor = 'default';
      });

      anchor.radius(this.anchorConfig.radius / this.layer.scaleX());
      anchor.strokeWidth(this.anchorConfig.strokeWidth / this.layer.scaleX());
    });
  }

  private resetAnchorVisibility(): void {
    this.anchors.getChildren().forEach((anchor) => {
      anchor.off();
      anchor.visible(false);
    });
  }

}
