import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { lifeCycleActions } from '../shared/store/life-cycle/life-cycle.actions';

@Injectable({
  providedIn: 'root',
})
export class LogoutStrategyService {

  constructor(
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly store: Store,
  ) { }

  async logout(forcedPreviousUrl?: string): Promise<void> {
    const previousUrl = forcedPreviousUrl || this.router.url;
    this.authenticationService.logout();

    await this.router.navigate(['/user/login'], {
      queryParams: {
        intentUrl: previousUrl,
      },
      queryParamsHandling: 'merge',
    });
    this.store.dispatch(lifeCycleActions.resetState());
  }

}
