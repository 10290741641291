import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  FlattenedSequence,
  FlattenedTelemonitoring,
  PagedResponse,
  PaginationOptions,
  Telemonitoring,
} from '@pixacare/pxc-ts-core';
import { TELEMONITORINGS_FEATURE_KEY } from './telemonitorings.selectors';

export const telemonitoringsActions = createActionGroup({
  source: TELEMONITORINGS_FEATURE_KEY,
  events: {
    'Reset telemonitorings state': emptyProps(),

    'Get next user telemonitorings': props<{ options?: PaginationOptions }>(),
    'Get next user telemonitorings success': props<{
      reset?: boolean;
      collection: PagedResponse<FlattenedTelemonitoring>;
    }>(),

    'Get previous user telemonitorings': props<{ options?: PaginationOptions }>(),
    'Get previous user telemonitorings success':  props<{
      reset?: boolean;
      collection: PagedResponse<FlattenedTelemonitoring>;
    }>(),

    'Get telemonitoring': props<{ telemonitoringId: number }>(),
    'Get telemonitoring success': props<{ telemonitoring: FlattenedTelemonitoring }>(),

    'Get sequences': props<{ clientCode: string; tmId: number }>(),

    'Add telemonitoring': props<{ telemonitoring: Telemonitoring; sequenceIds: number[] }>(),

    'Delete telemonitoring': props<{ telemonitoringId: number }>(),
    'Delete telemonitoring success': props<{ telemonitoringId: number }>(),

    'Update telemonitoring patient': props<{ patientId: number; targetPatientId: number }>(),
    'Update telemonitoring active state': props<{ telemonitoringId: number; isActive: boolean }>(),
    'Update telemonitoring active state success': props<{ telemonitoringId: number; isActive: boolean }>(),

    'Unlink sequence': props<{
      telemonitoringId: number;
      clientCode: string;
      sequenceId: number;
    }>(),

    'Unlink sequence success': props<{
      telemonitoringId: number;
      sequenceId: number;
    }>(),

    'Link sequences': props<{
      telemonitoringId: number;
      clientCode: string;
      sequenceIds: number[];
    }>(),

    'Link sequences success': props<{
      telemonitoringId: number;
      sequenceIds: number[];
    }>(),

    'Load telemonitoring sequences': props<{
      tmId: number;
      sequences: FlattenedSequence[];
    }>(),

  },
});
