import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientUser, LicenseState } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClientUserHttpService {

  constructor(private readonly http: HttpClient) {}

  getClientUsers(clientCode: string): Observable<ClientUser[]> {
    return this.http.get<ClientUser[]>(
      `${environment.apiBaseUrl}/Client/GetClientUsers?clientCode=${clientCode}`,
    );
  }

  addClientUser(
    clientCode: string,
    mailAddresses: string[],
    departmentId: number,
  ): Observable<ClientUser[]> {
    return this.http
      .put<ClientUser[]>(`${environment.apiBaseUrl}/client/User?cc=${clientCode}`, {
      mailAddress: mailAddresses,
      departmentId,
    });
  }

  updateClientUserLicenseState(
    clientCode: string,
    userId: number,
    licenseState: LicenseState,
  ): Observable<LicenseState> {
    return this.http
      .patch<LicenseState>(
      `${environment.apiBaseUrl}/License/`,
      { clientCode, userId, licenseState },
    );
  }

  resendInvitation(userId: number): Observable<unknown> {
    return this.http.post(
      `${environment.apiBaseUrl}/User/ResendInvitationLink/${userId}`,
      { observe: 'response' },
    );
  }

}
