<div
  [formGroup]="form"
  class="flex flex-col gap-6"
>
  <label tuiLabel>
    Nom du télésuivi
    <tui-input
      formControlName="name"
      [tuiTextfieldLabelOutside]="true"
      data-testid="request-name"
      ngDefaultControl
    >
      {{ defaultName }}
    </tui-input>
    <tui-error
      formControlName="name"
      [error]="[] | tuiFieldError | async"
    ></tui-error>
  </label>

  <label tuiLabel tuiLabelRequired>
    Ajouter un message
    <tui-textarea
      formControlName="message"
      [tuiTextfieldLabelOutside]="true"
      data-testid="request-message"
      ngDefaultControl
    >
      Saisir un message personnalisé (facultatif)
    </tui-textarea>
    <tui-error
      formControlName="message"
      [error]="[] | tuiFieldError | async"
    ></tui-error>
  </label>

  @if (creatorType === CreatorType.BY_PATIENT) {
    <div class="flex flex-col gap-6">
      <label tuiLabel>
        <span class="required">Formulaire à compléter par le patient</span>
        <pxc-form-select
          formControlName="patientForm"
          ngDefaultControl
          data-testid="patient-form"
        ></pxc-form-select>
        <tui-error
          formControlName="patientForm"
          [error]="[] | tuiFieldError | async"
        ></tui-error>
      </label>

      <label tuiLabel>
        <span class="required">Numéro de téléphone du patient</span>
        <pxc-patient-contact-input
          formControlName="patientContact"
          [patientId]="patientId"
          [clientCode]="clientCode"
          data-testid="patient-contact"
          ngDefaultControl
        ></pxc-patient-contact-input>
        <tui-error
          formControlName="patientContact"
          [error]="[] | tuiFieldError | async"
        ></tui-error>
        <p class="mt-2 text-sm text-secondary">Le patient recevra des rappels pour documenter son télésuivi</p>
      </label>

      <div>
        <p class="max-w-prose text-sm">Notifier le patient</p>
        <label tuiLabel>
          <input
            tuiCheckbox
            type="checkbox"
            formControlName="shouldSendTextMessageToPatient"
            data-testid="should-send-text-message-to-patient"
          />
          <p class="text-md text-secondary">Envoyer un SMS au patient à la création du télésuivi</p>
          <tui-error
            formControlName="shouldSendTextMessageToPatient"
            [error]="[] | tuiFieldError | async"
          ></tui-error>
        </label>
      </div>
    </div>
  }
</div>
