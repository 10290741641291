<pxc-tag-input
  [placeholder]="placeholder"
  [validators]="validators"
  [autocompleteModels]="autocompleteModels$ | async"
  [autocompleteUsers]="autocompleteUsers$ | async"
  [tags]="displayValue"
  (tagsChange)="setUsers($event)"
  (searchChange)="inputChange$.next($event)"
  [disabled]="disabled"
  data-testid="tag-input"
  [helpText]="null"
></pxc-tag-input>
