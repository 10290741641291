import {
  endOfMonth,
  endOfToday,
  endOfWeek,
  endOfYesterday,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYesterday,
  subDays,
  subMonths,
} from 'date-fns';
import { Bounds } from '../bounds.interface';
import { DateFilterShortcut } from './date-filter-shortcut';

export const defaultDateShortcuts: DateFilterShortcut[] = [
  {
    displayName: 'Aujourd\'hui',
    get: (): Bounds<Date> => ({
      min: startOfToday(),
      max: endOfToday(),
    }),
  },
  {
    displayName: 'Hier',
    get: (): Bounds<Date> => ({
      min: startOfYesterday(),
      max: endOfYesterday(),
    }),
  },
  {
    displayName: 'Cette semaine',
    get: (now: Date): Bounds<Date> => ({
      min: startOfWeek(now),
      max: endOfWeek(now),
    }),
  },
  {
    displayName: 'La semaine dernière',
    get: (now: Date): Bounds<Date> => ({
      min: subDays(startOfWeek(now), 7),
      max: subDays(endOfWeek(now), 7),
    }),
  },
  {
    displayName: 'Ce mois-ci',
    get: (now: Date): Bounds<Date> => ({
      min: startOfMonth(now),
      max: endOfMonth(now),
    }),
  },
  {
    displayName: 'Le mois dernier',
    get: (now: Date): Bounds<Date> => ({
      min: subMonths(startOfMonth(now), 1),
      max: subMonths(endOfMonth(now), 1),
    }),
  },
];
