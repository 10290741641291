import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { ChannelCreateService } from '../modules/chat/channel-create/channel-create.service';

export const redirectToChannelGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {

  const channelCreateService = inject(ChannelCreateService);

  const { userId, firstName, lastName } = route.queryParams;
  if (userId && firstName && lastName) {
    channelCreateService.createChannel([{ id: +userId, firstName, lastName }]);
    return false;
  } else {
    return true;
  }
};
