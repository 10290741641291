<div class="flex flex-col gap-2">
  <p>Entrez les adresses email auxquelles vous souhaitez envoyer le tutoriel de télésuivi.</p>

  <pxc-tag-input
    [validators]="validators"
    [(tags)]="mailAddresses"
    data-testid="input"
    disableAutocomplete="true"
  ></pxc-tag-input>

  <div class="mt-2 flex justify-between gap-2 max-md:flex-col-reverse">
    <button
      tuiButton
      appearance="outline"
      (click)="close()"
      data-testid="cancel-button"
    >
      Annuler
    </button>
    <button
      tuiButton
      (click)="sendTutorial()"
      data-testid="send-button"
      [disabled]="mailAddresses.length === 0"
    >
      Envoyer
    </button>
  </div>
</div>
