import { Patient } from '@pixacare/pxc-ts-core';
import { SortOrderStrategy } from '../../models/enums/sort-order-strategy.enum';
import { PagedCollection } from '../../models/pagination/paged-collection';

export interface PatientsState {
  patients: {
    [clientCode: string]: PagedCollection<{ [patientId: number]: Patient }>;
  };
  loadState: {
    [clientCode: string]: boolean;
  };
  order?: SortOrderStrategy;
}

export const initialPatientsState: PatientsState = {
  patients: {},
  order: null,
  loadState: {},
};
