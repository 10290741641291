import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { LicenseState, limitedAccessLicenseStates, supportAccessLicenseStates } from '@pixacare/pxc-ts-core';
import { Observable, combineLatest, distinctUntilChanged, filter, map, mergeMap } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ClientData } from 'src/app/shared/models/base/client-data';
import { Department } from 'src/app/shared/models/base/department';
import { selectClients } from 'src/app/shared/store/clients/clients.selectors';
import { selectClientDepartments } from 'src/app/shared/store/departments/departments.selectors';
import {
  selectReadAccessClientCodes,
  selectUserActiveLicenseState,
} from 'src/app/shared/store/licenses/licenses.selectors';
import { selectIsStoreInitialized } from 'src/app/shared/store/life-cycle/life-cycle.selectors';
import { selectClientCode } from 'src/app/shared/store/router/router.selectors';
import { DepartmentUserService } from '../../department/department-user.service';

@UntilDestroy()
@Injectable()
export class SidebarService {

  collapsed = false;
  shown = false;

  clients$: Observable<{ [clientCode: string]: ClientData }> = combineLatest({
    clients: this.store.select(selectClients),
    readAccessClientCodes: this.store.select(selectReadAccessClientCodes),
    isAppInitialized: this.store.select(selectIsStoreInitialized),
  }).pipe(
    filter(({ isAppInitialized }) => isAppInitialized ),
    map(({ clients, readAccessClientCodes }) =>
      readAccessClientCodes.reduce((acc, clientCode) => ({
        ...acc, [clientCode]: clients[clientCode],
      }), {})),
  );

  activeClientCode$: Observable<string> = this.store.select(selectClientCode);

  activeLicenseState$: Observable<LicenseState> = this.store.select(selectUserActiveLicenseState);

  isActiveLicenseStateLimited$: Observable<boolean> = this.activeLicenseState$.pipe(
    map((licenseState) => limitedAccessLicenseStates.includes(licenseState)),
  );

  shouldDisplayDataConsumption$: Observable<boolean> =
    combineLatest([this.activeClientCode$, this.activeLicenseState$])
      .pipe(
        map(([activeClientCode, activeLicenseState]) =>
          activeClientCode === 'common'
      || supportAccessLicenseStates.includes(activeLicenseState)));

  departments$: Observable<Department[]> = this.activeClientCode$.pipe(
    distinctUntilChanged(),
    // Get departments for active client
    mergeMap((clientCode) =>
      this.store.select(selectClientDepartments(clientCode))),
    // Filter departments where user is disabled
    map((departments) => departments.filter((department) =>
      !this.departmentUserService.isDepartmentUserDisabled(this.authService.currentUser.id, department)),
    ),
  );

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private departmentUserService: DepartmentUserService,
    private authService: AuthenticationService,
  ) {

    // dismiss navbar when clicking on a link on mobile.
    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart),
      untilDestroyed(this),
    ).subscribe(() => {
      this.shown = false;
    });

  }

}
