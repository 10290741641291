import { createReducer, on } from '@ngrx/store';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { exportsActions } from './exports.actions';
import { initialExportsState } from './exports.state';

export const exportsReducer = createReducer(
  initialExportsState,

  on(lifeCycleActions.resetState, () => initialExportsState),

  on(exportsActions.getExportsSuccess,
    (state, { dataExports }) => ({
      ...state,
      exports: dataExports,
    }),
  ),

  on(exportsActions.createExportSuccess,
    (state, { newExport }) => ({
      ...state,
      exports: [...state.exports, newExport],
    }),
  ),

  on(exportsActions.deleteExport, (state, { exportId }) => ({
    ...state,
    exports: state.exports.filter((e) => e.id !== exportId),
  })),
);
