import { Injectable, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, Subscription, distinctUntilChanged, filter, map, startWith } from 'rxjs';
import { selectDepartmentId } from 'src/app/shared/store/router/router.selectors';
import { SidebarService } from './sidebar.service';

@UntilDestroy()
@Injectable()
export class SidebarOpenFolderService {

  private readonly activeDepartmentId$ = inject(Store).select(selectDepartmentId);

  private readonly departmentIds$ = inject(SidebarService).departments$.pipe(
    map((entries) => entries.map((entry) => entry.id)),
  );

  private openDepartmentSubject$: Subject<number> = new BehaviorSubject<number>(undefined);

  private subscription: Subscription;

  constructor() {

    this.departmentIds$.pipe(
      untilDestroyed(this),
      distinctUntilChanged(),
    ).subscribe((departmentIds) => {

      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      this.subscription = this.activeDepartmentId$.pipe(
        untilDestroyed(this),
        filter((departmentId) => departmentId !== null),
        distinctUntilChanged(),
        startWith(departmentIds[0]),
      ).subscribe((departmentId) => {
        this.setOpenDepartment(departmentId);
      });

    });

  }

  get openDepartment$(): Observable<number> {
    return this.openDepartmentSubject$.asObservable().pipe(distinctUntilChanged());
  }

  setOpenDepartment(departmentId: number): void {
    this.openDepartmentSubject$.next(departmentId);
  }

}
