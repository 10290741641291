import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Client } from '@pixacare/pxc-ts-core';
import { first, map, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { selectClient } from '../shared/store/clients/clients.selectors';
import { selectClientCode } from '../shared/store/router/router.selectors';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class ContactUsService {

  private readonly authenticationService = inject(AuthenticationService);
  private readonly store = inject(Store);

  openPixacareSupportForm(): void {
    this.store.select(selectClientCode).pipe(
      first((clientCode: string) => !!clientCode),
      switchMap((clientCode: string) => this.store.select(selectClient(clientCode))),
      map((client: Client) => {
        const hubspotFormUrl = environment.hubspot.formUrl;
        const queryParams = {
          email: this.authenticationService.currentUser.mailAddress,
          company: client.code !== 'common' ? client.fullName : '',
        };
        const url = `${hubspotFormUrl}?${new URLSearchParams(queryParams)}`;
        window.open(url, '_blank');
      }),
    ).subscribe();
  }

}
