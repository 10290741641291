import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TuiLet } from '@taiga-ui/cdk';
import { map } from 'rxjs';
import { Message } from 'src/app/shared/models/chat/message';
import { SharedModule } from '../../shared/shared.module';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';
import { ChannelHeaderComponent } from '../channel-header/channel-header.component';
import { ChannelSettingsComponent } from '../channel-settings/channel-settings.component';
import { MessageInputComponent } from '../message-input/message-input.component';
import { MessageListComponent } from '../message-list/message-list.component';
import { ChannelPaneService } from './channel-pane.service';
import { MessageSpecialService } from './message-special.service';

@Component({
  templateUrl: './channel-pane.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    SpinnerComponent,
    ChannelHeaderComponent,
    MessageListComponent,
    MessageInputComponent,
    TuiLet,
    ChannelSettingsComponent,
  ],
  providers: [ChannelPaneService, MessageSpecialService],
})
export class ChannelPaneComponent {

  readonly channelPaneService = inject(ChannelPaneService);

  channel$ = this.channelPaneService.channel$;
  messages$ = this.channelPaneService.messages$;
  messageGroups$ = this.channelPaneService.messageGroups$;
  hasUnseenMessages$ = this.channelPaneService.hasUnseenMessages$;

  showSettings = false;

  isEmpty$ = this.messages$.pipe(
    map((messages) => Object.keys(messages).length === 0),
  );

  sendMessage(newMessage: Message): void {
    this.channelPaneService.sendMessage(newMessage);
  }

  openSettings(): void {
    this.showSettings = true;
  }

  closeSettings(): void {
    this.showSettings = false;
  }

  loadNext(): void {
    this.channelPaneService.loadNext();
  }

  markMessagesAsSeen(): void {
    this.channelPaneService.markMessagesAsSeen();
  }

}
