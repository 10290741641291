<div class="flex flex-col gap-2 pb-4">
  @if (department$ | async; as department) {
    <div class="flex justify-between gap-6 max-lg:flex-col lg:items-center lg:gap-3">
      @if (client$ | async; as client) {
        <pxc-client-title-header
          [title]="department.name"
          subtitle="Administration du groupe"
          [clientCdnImagePath]="client.cdnImagePath"
        ></pxc-client-title-header>
      }
      <pxc-toggle
        data-testid="collaboration-toggle"
        [toggle]="department.isOpen"
        (toggleChange)="updateDepartmentCollaboration($event)"
        hint="Lorsque la collaboration est activée, tous les utilisateurs du groupe peuvent voir les séquences des uns et des autres."
      >
        Collaboration ouverte
      </pxc-toggle>
    </div>
  }

  <nav
    tuiTabs
    class="my-2"
  >
    @for (tab of tabs; track tab) {
      <button
        tuiTab
        [routerLink]="tab.link"
        [routerLinkActive]="['active']"
        queryParamsHandling="merge"
        [attr.data-testid]="tab.link"
      >
        <tui-icon
          [icon]="tab.icon"
          class="mr-1"
        ></tui-icon>
        {{ tab.title }}
      </button>
    }
  </nav>

  <router-outlet></router-outlet>
</div>
