import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FILTER_TEMPLATES, FilterBarService } from '@modules/filters/filter-bar.service';
import { TelemonitoringActionsService } from '@modules/telemonitoring/telemonitoring-actions.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { FlattenedTelemonitoring, PaginationQuery } from '@pixacare/pxc-ts-core';
import { TelemonitoringStorePaginationService } from '@services/telemonitoring-store-pagination.service';
import { VirtualScrollService } from '@services/virtual-scroll.service';
import { FilterType } from '@shared/models/filters/filter-type.enum';
import { MenuAction } from '@shared/models/menu-actions/menu-action';
import { PagedCollection } from '@shared/models/pagination/paged-collection';
import { selectQueryParams } from '@shared/store/router/router.selectors';
import { telemonitoringsActions } from '@shared/store/telemonitorings/telemonitorings.actions';
import { selectIsTelemonitoringStateLoaded } from '@shared/store/telemonitorings/telemonitorings.selectors';
import { stringToBoolean } from '@shared/utils/utils';
import { Observable, combineLatest, map, of, switchMap } from 'rxjs';

@UntilDestroy()
@Component({
  templateUrl: './telemonitorings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    VirtualScrollService,
    FilterBarService,
    {
      provide: FILTER_TEMPLATES,
      useValue: of([
        {
          type: FilterType.TABS,
          property: 'is_active',
          name: 'Telemonitoring Status',
          getValue: () => of([
            {
              value: '1',
              display: 'Actifs',
            },
            {
              value: '0',
              display: 'Archivés',
            },
          ]),
        },
      ]),
    },
  ],
})
export class TelemonitoringsComponent implements OnInit {

  isDataLoaded$: Observable<boolean> = null;
  collection$: Observable<PagedCollection<FlattenedTelemonitoring[]>>;
  actions: MenuAction<FlattenedTelemonitoring>[];
  params$ = combineLatest({
    isActive: this.store.select(selectQueryParams).pipe(map((params) => stringToBoolean(params.is_active))),
  });

  constructor(
    private readonly store: Store,
    private readonly telemonitoringPaginationService: TelemonitoringStorePaginationService,
    private readonly telemonitoringActionsService: TelemonitoringActionsService,
    private readonly filterBarService: FilterBarService,
  ) { }

  ngOnInit(): void {

    this.store.dispatch(telemonitoringsActions.resetTelemonitoringsState());

    this.isDataLoaded$ = this.store.select(selectIsTelemonitoringStateLoaded);
    this.collection$ = this.params$.pipe(
      switchMap((params) => this.telemonitoringPaginationService.select(!params.isActive)),
    );
    this.actions = this.telemonitoringActionsService.getActions();

    this.filterBarService.filteredSearch$
      .pipe(untilDestroyed(this))
      .subscribe(({ search, filters }) => {
        this.telemonitoringPaginationService.load(undefined, {
          query: new PaginationQuery({
            search,
            filter: filters,
          }),
          reset: true,
        });
      });

  }

  loadNextPage(): void {
    this.telemonitoringPaginationService.loadNextPage(undefined);
  }

  loadPreviousPage(): void {
    this.telemonitoringPaginationService.loadPreviousPage(undefined);
  }

}
