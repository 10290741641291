import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LifeCycleEffects } from './life-cycle.effects';
import { lifeCycleReducer } from './life-cycle.reducer';
import { LIFE_CYCLE_FEATURE_KEY } from './life-cycle.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(LIFE_CYCLE_FEATURE_KEY, lifeCycleReducer),
    EffectsModule.forFeature([LifeCycleEffects]),
  ],
  declarations: [],
})
export class LifeCycleStoreModule { }
