import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SharedModule } from '@modules/shared/shared.module';
import { GalleryItem } from '@shared/models/gallery-item';

@Component({
  selector: 'pxc-gallery-thumbnail',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
  ],
  templateUrl: './gallery-thumbnail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryThumbnailComponent implements OnChanges, AfterViewInit {

  @Input() active = false;
  @Input() item: GalleryItem;
  @Output() thumbnailClicked = new EventEmitter<void>();

  @ViewChild('thumbnail') element: ElementRef<HTMLElement>;

  loaded = false;

  ngOnChanges({ active }: SimpleChanges): void {
    if (!active) {
      return;
    }
    this.focusThumbnail();
  }

  onThumbnailClicked(): void {
    this.thumbnailClicked.emit();
  }

  ngAfterViewInit(): void {
    this.focusThumbnail();
  }

  focusThumbnail(): void {
    if (this.active) {
      this.element?.nativeElement?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

}
