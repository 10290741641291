import { License } from '@pixacare/pxc-ts-core';

export interface LicensesState {
  licenses: {
    [licenseId: number]: License;
  };
}

export const initialLicensesState: LicensesState = {
  licenses: {},
};
