<h2 tuiTitle="m">Changement de mot de passe</h2>

<form
  [formGroup]="changePasswordForm"
  (ngSubmit)="changePassword()"
  data-testid="change-password-form"
  class="mt-6 flex flex-col gap-4"
>
  <div>
    <tui-input-password
      formControlName="inputOldPassword"
      tuiTextfieldIconLeft="@tui.lock-open"
      [disabled]="(actionOnGoing$ | async) ? '' : null"
    >
      Ancien mot de passe
    </tui-input-password>
    <tui-error
      formControlName="inputOldPassword"
      [error]="[] | tuiFieldError | async"
    />
  </div>

  <div>
    <tui-input-password
      formControlName="inputNewPassword"
      tuiTextfieldIconLeft="@tui.lock"
      [disabled]="(actionOnGoing$ | async) ? '' : null"
    >
      Nouveau mot de passe
    </tui-input-password>
    <tui-error
      formControlName="inputNewPassword"
      [error]="[] | tuiFieldError | async"
    />
  </div>

  <div>
    <tui-input-password
      formControlName="inputNewPasswordConfirm"
      tuiTextfieldIconLeft="@tui.lock"
      [disabled]="(actionOnGoing$ | async) ? '' : null"
    >
      Confirmation du nouveau mot de passe
    </tui-input-password>
    <tui-error
      formControlName="inputNewPasswordConfirm"
      [error]="[] | tuiFieldError | async"
    />
  </div>

  <div class="mt-2 flex flex-col gap-2">
    <button
      tuiButton
      class="w-full"
      type="submit"
      appearance="primary"
      [loading]="actionOnGoing$ | async"
    >
      Changer mon mot de passe
    </button>

    <button
      tuiButton
      appearance="outline"
      class="w-full"
      routerLink="/"
      [disabled]="actionOnGoing$ | async"
    >
      Retour à l'accueil
    </button>
  </div>
</form>
