<div class="flex max-w-full items-center gap-2">
  <button
    tuiIconButton
    appearance="outline"
    (click)="previous()"
    [disabled]="selectedIndex <= 0"
    data-testid="previous"
    iconStart="@tui.chevron-left"
  ></button>

  <tui-carousel
    class="sadm-carousel"
    [itemsCount]="itemsCount$ | async"
    [(index)]="displayIndex"
  >
    @for (report of reports; track report; let idx = $index) {
      <div
        *tuiItem
        [attr.data-testid]="report.title"
        class="mx-auto flex max-w-[16vh] cursor-pointer flex-col items-center gap-2 rounded-xl border border-transparent p-2 transition-all hover:border-border-normal"
        [ngClass]="{
          '!border-border-normal bg-neutral-1': selectedIndex === idx,
        }"
        (click)="moveTo(idx)"
      >
        <img
          class="h-full w-full rounded-xl"
          (click)="openGallery(idx)"
          [src]="report.media.thumbnailUri"
          data-testid="thumbnail"
          alt="thumbnail"
        />
        <pxc-date-preview
          [date]="report.date"
          [displayTime]="false"
          class="m-1"
        ></pxc-date-preview>
      </div>
    }
  </tui-carousel>

  <button
    tuiIconButton
    appearance="outline"
    (click)="next()"
    [disabled]="selectedIndex >= reports.length - 1"
    data-testid="next"
    iconStart="@tui.chevron-right"
  ></button>
</div>
