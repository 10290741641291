import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FlattenedLabel, Label } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LabelHttpService {

  constructor(
    private readonly http: HttpClient,
  ) { }

  getFlattenedLabels(): Observable<FlattenedLabel[]> {
    return this.http
      .get<FlattenedLabel[]>(`${environment.apiBaseUrl}/Label/Flattened`);
  }

  getFlattenedLabel(
    labelId: number,
  ): Observable<FlattenedLabel> {
    return this.http
      .get<FlattenedLabel>(
      `${environment.apiBaseUrl}/Label/Flattened/${labelId}`,
    );
  }

  createLabel(
    labels: string[],
  ): Observable<Label[]> {
    return this.http
      .post<Label[]>(`${environment.apiBaseUrl}/Label`, { labels });
  }

  unsubscribeFromLabel(
    labelId: number,
    removeFromSequences: boolean,
  ): Observable<unknown> {
    return this.http
      .post(`${environment.apiBaseUrl}/Label/Unsubscribe`, { labelId, removeFromSequences });
  }

  createLabelPackLabels(
    labelPackId: number,
    clientCode: string,
    labels: string[],
  ): Observable<Label[]> {
    return this.http
      .post<Label[]>(`${environment.apiBaseUrl}/LabelPack/${labelPackId}/Labels`,
      { labels },
      {
        params: {
          clientCode,
        },
      });
  }

  deleteLabelPackLabels(
    labelPackId: number,
    clientCode: string,
    labels: string[],
  ): Observable<unknown> {
    return this.http
      .delete(
        `${environment.apiBaseUrl}/LabelPack/${labelPackId}/Labels`,
        {
          body: {
            labels,
          },
          params: {
            clientCode,
          },
        },
      );
  }

}
