import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChannelUser, User } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatHttpService {

  constructor(
    private readonly http: HttpClient,
  ) { }

  updateChannelUserUnreads(channelId: string, userId: number): Observable<void> {
    return this.http.post<void>(
      `${environment.firebaseCloudFunctionsBaseUrl}/channels-updateChannelUserUnreads`,
      {
        channelId,
        userId,
        readOn: new Date(),
      },
    );
  }

  createChannel(users: ChannelUser[], channelName: string, currentUser: User): Observable<string> {
    return this.http.post<string>(
      `${environment.firebaseCloudFunctionsBaseUrl}/channels-createChannel`
      , {
        users,
        channelName,
        createdByUser: {
          id: currentUser.id,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
        },
      },
      {
        responseType: 'text' as any,
      },
    );
  }

}
