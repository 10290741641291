import { Injectable, inject } from '@angular/core';
import { Auth, signInWithCustomToken } from '@angular/fire/auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TuiAlertService } from '@taiga-ui/core';
import { Observable, catchError, from, of, switchMap, tap } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserHttpService } from 'src/app/services/http/user.http.service';
import { isRtpTokenExpired } from 'src/app/shared/utils/token-utils';

@UntilDestroy()
@Injectable()
export class FirebaseAuthenticationService {

  private userHttpService = inject(UserHttpService);
  private authenticationService = inject(AuthenticationService);
  private alertService = inject(TuiAlertService);
  private auth = inject(Auth);

  authenticate(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      if (!this.auth.currentUser) {
        this.signIn().pipe(
          untilDestroyed(this),
        ).subscribe((isAuthenticated) => {
          if (!isAuthenticated) {
            this.onRtpTokenError();
          }
          observer.next(isAuthenticated);
          observer.complete();
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  signIn(): Observable<boolean> {
    return this.getRtpToken().pipe(
      switchMap((rtpToken: string) => from(signInWithCustomToken(this.auth, rtpToken)
        .then(() => true)
        .catch(() => false)),
      ),
      catchError(() => of(false)));
  }

  getRtpToken(): Observable<string> {
    const rtpToken = this.authenticationService.getRtpToken();
    if (rtpToken && !isRtpTokenExpired(rtpToken)) {
      return of(rtpToken);
    } else {
      return this.userHttpService.refreshRtpToken().pipe(
        tap((newRtpToken: string) => this.authenticationService.setRtpToken(newRtpToken)),
      );
    }
  }

  private onRtpTokenError(): void {
    this.alertService.open('Une erreur est survenue, merci de réessayer dans quelques instants', {
      label: 'Erreur du serveur',
      appearance: 'error',
    }).subscribe();
  }

}
