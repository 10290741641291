import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SequencePickerPreviewComponent } from '@modules/sequence/sequence-picker-preview/sequence-picker-preview.component';
import { UserSequenceGalleryService } from '@modules/sequence/services/user-sequence-gallery.service';
import { FlattenedSequence } from '@pixacare/pxc-ts-core';
import { MediaClickedOutput } from '@shared/models/media-clicked-output';
import { TuiError } from '@taiga-ui/core';
import { TuiFieldErrorPipe } from '@taiga-ui/kit';
import { TelemonitoringRequestForm } from '../telemonitoring-request/telemonitoring-request-form';

@Component({
  selector: 'pxc-telemonitoring-request-sequences',
  standalone: true,
  imports: [
    CommonModule,
    SequencePickerPreviewComponent,
    TuiError,
    TuiFieldErrorPipe,
  ],
  providers: [
    UserSequenceGalleryService,
  ],
  templateUrl: './telemonitoring-request-sequences.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelemonitoringRequestSequencesComponent {

  @Input() form: FormGroup<TelemonitoringRequestForm>;

  private readonly galleryService = inject(UserSequenceGalleryService);

  get patientId(): number {
    return this.form.get('patientId').value;
  }

  get clientCode(): string {
    return this.form.get('clientCode').value;
  }

  get sequences(): FlattenedSequence[] {
    return this.form.get('sequences').value ?? [];
  }

  get departmentIds(): number[] {
    return this.form.get('departmentIds').value ?? [];
  }

  setSequence(sequences: FlattenedSequence[]): void {
    this.form.get('sequences').setValue(sequences);
  }

  mediaClicked({ clientCode, mediaIdx, sequenceId }: MediaClickedOutput): void {
    this.galleryService.openGallery(clientCode, sequenceId, mediaIdx);
  }

}
