import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { AutoCompleteModel } from 'src/app/shared/models/helpers/auto-complete-model';

@Component({
  templateUrl: './client-add-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientAddUserComponent {

  userMails: AutoCompleteModel[] = [];
  validators: Validators = [Validators.email];

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) private readonly context: TuiDialogContext<string[], void>,
  ) {}

  close(): void {
    this.context.completeWith([]);
  }

  addUser(): void {
    this.context.completeWith(this.userMails.map((mail) => mail.value) || []);
  }

}
