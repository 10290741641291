import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[animateFade]',
  standalone: true,
})
export class AnimateFadeDirective {

  private element: HTMLElement;

  private initialClasses: string;

  private readonly animationBase = `
  data-[shown=true]:animate-in  data-[shown=true]:fade-in 
  data-[shown=false]:animate-out data-[shown=false]:fade-out
  fill-mode-forwards`;

  private readonly leftAnimation = `
  data-[shown=true]:slide-in-from-left-2
  data-[shown=false]:slide-out-from-left-2
  `;

  private readonly rightAnimation = `
  data-[shown=true]:slide-in-from-right-2
  data-[shown=false]:slide-out-from-right-2
  `;

  constructor(private elementRef: ElementRef) {
    this.element = elementRef.nativeElement;

    if (!this.element) {
      console.error('animateFade directive: element is undefined');
      return;
    }

    this.initialClasses = this.element.className;

    this.element.className += this.animationBase;
  }

  @Input() set animateFade(shown: boolean) {

    if (!this.element) {
      return;
    }

    this.element.setAttribute('data-shown', shown.toString());
  }

  @Input() set animateDirection(direction: 'left' | 'right') {

    if (!this.element) {
      return;
    }

    this.element.className = [
      this.initialClasses,
      this.animationBase,
      direction === 'left' ? this.leftAnimation : this.rightAnimation,
    ].join(' ');
  }

}
