import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormReport } from '@pixacare/pxc-ts-core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SadmGalleryService } from '../sadm-gallery.service';

@UntilDestroy()
@Component({
  selector: 'pxc-sadm-entity-thumbnails-carousel',
  templateUrl: './sadm-entity-thumbnails-carousel.component.html',
  styleUrls: ['./sadm-entity-thumbnails-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SadmEntityThumbnailsCarouselComponent implements OnInit, OnChanges {

  @Input() reports: FormReport[];
  @Input() sadmEntityId: number;
  @Input() clientCode: string;
  @Input() itemsCount = 3;

  @Input() selectedIndex = 0;
  @Output() selectedIndexChange = new EventEmitter<number>();

  displayIndex = 0;

  itemsCount$: Subject<number>;

  breakpoints = {
    medium: '(min-width: 590px)',
  };

  constructor(
    private readonly sadmGalleryService: SadmGalleryService,
    private readonly breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit(): void {

    this.itemsCount$ = new BehaviorSubject<number>(this.itemsCount);

    this.breakpointObserver.observe(Object.values(this.breakpoints))
      .pipe(untilDestroyed(this))
      .subscribe((state) => {
        this.itemsCount$.next((state.breakpoints[this.breakpoints.medium]) ? this.itemsCount : 1);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedIndex) {
      this.displayIndex = Math.min(this.selectedIndex, this.reports.length - this.itemsCount);
    }
  }

  openGallery(openIdx: number): void {
    this.sadmGalleryService.openGallery(this.sadmEntityId, this.clientCode, openIdx);
  }

  previous(): void {
    this.selectedIndexChange.emit(this.selectedIndex - 1);
  }

  next(): void {
    this.selectedIndexChange.emit(this.selectedIndex + 1);
  }

  moveTo(index: number): void {
    this.selectedIndexChange.emit(index);
  }

}
