<ng-template #menuActions>
  <pxc-menu-actions
    [actions]="actions"
    [entity]="user"
  ></pxc-menu-actions>
</ng-template>

<pxc-tile
  class="w-full cursor-pointer rounded-xl py-2 transition hover:bg-neutral-1"
  [ngClass]="collapsed ? 'px-2.5' : 'px-1'"
  [tuiHint]="collapsed ? 'Votre compte' : null"
  tuiHintAppearance="dark"
  tuiHintDirection="right"
  tuiDropdownOpen
  [tuiDropdown]="menuActions"
  [tuiDropdownSided]="true"
>
  <pxc-avatar
    leading
    [name]="user.firstName + ' ' + user.lastName"
    size="tiny"
    class="transition-all"
  ></pxc-avatar>
  <p
    class="line-clamp-1 break-all text-sm"
    title
    [animateFade]="!collapsed"
  >
    {{ user.firstName }} {{ user.lastName }}
  </p>
  <p
    class="line-clamp-1 break-all text-xs text-secondary"
    subtitle
    [animateFade]="!collapsed"
  >
    {{ user.mailAddress }}
  </p>
  <tui-icon
    icon="@tui.ellipsis-vertical"
    class="text-tertiary"
    [animateFade]="!collapsed"
    trailing
  ></tui-icon>
</pxc-tile>
