import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FILTER_TEMPLATES, FilterBarService } from '@modules/filters/filter-bar.service';
import { SequenceActionsService } from '@modules/sequence/services/sequence-actions.service';
import { UserSequenceGalleryService } from '@modules/sequence/services/user-sequence-gallery.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilterOperator, FlattenedSequence, Patient } from '@pixacare/pxc-ts-core';
import { sequenceFilterTemplates } from '@shared/models/filters/filter-template.config';
import { MediaClickedOutput } from '@shared/models/media-clicked-output';
import { MenuAction } from '@shared/models/menu-actions/menu-action';
import { PagedCollection } from '@shared/models/pagination/paged-collection';
import { Observable, first } from 'rxjs';
import { PatientService } from '../patient/patient.service';

@UntilDestroy()
@Component({
  templateUrl: './patient-sequences.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    FilterBarService,
    {
      provide: FILTER_TEMPLATES,
      useValue: sequenceFilterTemplates,
    },
    UserSequenceGalleryService,
  ],
})
export class PatientSequencesComponent implements OnInit {

  sequences$: Observable<PagedCollection<FlattenedSequence[]>>;
  selectedPatient$ = this.patientPageService.getPatient();
  hasLicenseWriteAccess$ = this.patientPageService.getHasLicenseWriteAccess();
  isSequenceStateLoaded$ = this.patientPageService.getIsSequenceStateLoaded();

  constructor(
    private readonly patientPageService: PatientService,
    private readonly filterBarService: FilterBarService,
    private readonly userSequenceGalleryService: UserSequenceGalleryService,
    private readonly sequenceActionsService: SequenceActionsService,
  ) {  }

  get sequenceActions(): MenuAction<FlattenedSequence>[] {
    return this.sequenceActionsService.getActions();
  }

  ngOnInit() {

    this.patientPageService.getParams()
      .pipe(untilDestroyed(this))
      .subscribe(({ patientId }) => {
        this.filterBarService.setPersistentFilters([{
          prop: 'patient_id',
          op: FilterOperator.EQUAL,
          val: patientId.toString(),
        }]);
      });

    this.sequences$ = this.patientPageService.getSequences(this.filterBarService.filteredSearch$);

  }

  async addSequence(patient: Patient): Promise<void> {
    this.patientPageService.getParams().pipe(first())
      .subscribe(({ clientCode }) => {
        this.sequenceActionsService.addSequence(clientCode, { patient }).then(() => {
          this.filterBarService.clear();
        });
      });
  }

  loadNextPage(): void {
    this.patientPageService.loadNextSequences();
  }

  loadPreviousPage(): void {
    this.patientPageService.loadPreviousSequences();
  }

  openSequenceMediaGallery(clickedMedia: MediaClickedOutput): void {
    this.patientPageService.getParams().pipe(first())
      .subscribe(({ clientCode }) => {
        this.userSequenceGalleryService.openGallery(clientCode, clickedMedia.sequenceId, clickedMedia.mediaIdx);
      });
  }

}
