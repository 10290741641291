import { ChartConfiguration } from '../../charts/config/chart.config';

export class Chart {

  config: ChartConfiguration;

  constructor(config?: ChartConfiguration) {
    if (config) {
      this.config = config;
    }
  }

}
