import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Client } from '@pixacare/pxc-ts-core';
import { Observable, switchMap } from 'rxjs';
import { selectClient } from 'src/app/shared/store/clients/clients.selectors';
import { selectClientCode } from 'src/app/shared/store/router/router.selectors';

@Injectable()
export class ClientAdminService {

  clientCode$: Observable<string>;

  private client$: Observable<Client>;

  private readonly store = inject(Store);

  constructor() {
    this.clientCode$ = this.store.select(selectClientCode);
  }

  loadClient$(): Observable<Client> {
    if (this.client$) {
      return this.client$;
    }

    this.client$ = this.clientCode$.pipe(
      switchMap((clientCode) => this.store.select(selectClient(clientCode))),
    );
    return this.client$;
  }

}
