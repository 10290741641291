import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component, Input, OnChanges, OnInit,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { SearchedUser } from '@pixacare/pxc-ts-core';
import { UserInputSelection } from '@shared/models/helpers/user-input-selection';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  scan,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { UserHttpService } from 'src/app/services/http/user.http.service';
import { AutoCompleteModel } from 'src/app/shared/models/helpers/auto-complete-model';
import { TagInputComponent } from '../tag-input/tag-input.component';
import { emailsForbiddenValidator } from './user-input.validators';

type OnChangeFn = (value: UserInputSelection[]) => void;

@Component({
  selector: 'pxc-user-input',
  templateUrl: './user-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TagInputComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UserInputComponent,
    },
  ],
})
export class UserInputComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() excludedIds: number[] = [];
  @Input() excludedEmails: string[] = [];
  @Input() placeholder = 'Entrez un nom ou une adresse email';
  @Input() allowEmails = false;

  @Input() searchUsers: (value: string) => Observable<SearchedUser[]> = this.defaultSearchUsers;

  validators: Validators = [];
  value: UserInputSelection[] = [];
  displayValue: AutoCompleteModel[] = [];
  touched = false;
  disabled = false;

  inputChange$ = new BehaviorSubject<string>('');

  autocompleteModels$: Observable<AutoCompleteModel[]>;
  autocompleteUsers$: Observable<{ [id: number]: SearchedUser }>;

  constructor(private readonly userService: UserHttpService) {}

  onChange: OnChangeFn = () => {};
  onTouched = (): void => {};

  ngOnChanges(changes: SimpleChanges): void {

    if (!changes.allowEmails && !changes.excludedIds && !changes.excludedEmails) {
      return;
    }

    const validators = [];

    if (this.allowEmails) {
      validators.push(Validators.email);
    } else {
      validators.push(emailsForbiddenValidator());
    }

    if (this.excludedEmails.length) {
      validators.push((control) => {
        if (this.excludedEmails.includes(control.value)) {
          return { emailExcluded: true };
        }
        return null;
      });
    }

    this.validators = validators;

  }

  writeValue(obj: UserInputSelection[]): void {
    this.value = obj;
    this.displayValue = this.selectionToDisplayValue(obj);
  }

  registerOnChange(fn: OnChangeFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    const users$: Observable<SearchedUser[]> = this.inputChange$.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      switchMap((value) => {
        if (value?.length) {
          return this.searchUsers(value);
        }
        return of(null);
      }),
      map((users) => users?.filter((user) =>
        !this.getUserIds(this.value).includes(user.id) && !this.excludedIds.includes(user.id)),
      ),
      startWith([]),
    );

    this.autocompleteUsers$ = users$.pipe(
      filter((users) => users?.length > 0),
      map((users) => users.reduce((acc, user) => ({
        ...acc,
        [user.id]: user,
      }), {})),
      scan((acc, users) => (Object.assign({}, acc, users)), {}),
      shareReplay(1),
    );

    this.autocompleteModels$ = users$.pipe(
      map((users) => users?.map((user) => ({
        value: `${user.id}`,
        display: `${user.firstName} ${user.lastName}`,
      }))),
    );

  }

  getUserIds(values: UserInputSelection[]): number[] {
    return values.reduce((acc, { user }) => {
      if (user) {
        acc.push(user.id);
      }
      return acc;
    }, []);
  }

  getUserMails(values: UserInputSelection[]): string[] {
    return values.reduce((acc, { email }) => {
      if (email) {
        acc.push(email);
      }
      return acc;
    }, []);
  }

  selectionToDisplayValue(selection: UserInputSelection[]): AutoCompleteModel[] {
    return selection?.reduce((acc, { user, email }) => {

      if (user) {
        acc.push({
          value: `${user.id}`,
          display: `${user.firstName} ${user.lastName}`,
        });
      } else if (email) {
        acc.push({
          value: email,
          display: email,
        });
      }

      return acc;

    }, [] as AutoCompleteModel[]) ?? [];
  }

  setUsers(values: AutoCompleteModel[]): void {

    this.autocompleteUsers$.pipe(first()).subscribe((autocompleteUsers) => {

      this.value = values.map(({ value }) => {
        if (value.includes('@')) {
          return { email: value };
        }
        return { user: autocompleteUsers[+value] };
      });
      this.displayValue = this.selectionToDisplayValue(this.value);
      this.onChange(this.value);
      this.markAsTouched();

    });

  }

  defaultSearchUsers(value: string): Observable<SearchedUser[]> {
    return this.userService.searchUsers(value).pipe(
      map((users) => users.filter((user) =>
        !this.excludedIds.includes(user.id) && !this.getUserIds(this.value).includes(user.id)),
      ),
    );
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

}
