import { createReducer, on } from '@ngrx/store';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { sharesActions } from './shares.actions';
import { initialSharesState } from './shares.state';

export const sharesReducer = createReducer(
  initialSharesState,

  on(lifeCycleActions.resetState, () => initialSharesState),

  on(sharesActions.getSharesToCurrentUserSuccess,
    (state, { shares }) => ({
      ...state,
      shares: shares.reduce((acc, share) => ({
        ...acc,
        [share.id]: share,
      }), state.shares),
    }),
  ),

);
