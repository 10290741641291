import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { Observable, combineLatest, map, of } from 'rxjs';
import { ActionsByUserState } from 'src/app/shared/models/actions-by-user-state';
import { AdministrationUser } from 'src/app/shared/models/administration-user';
import { Department } from 'src/app/shared/models/base/department';
import { Status } from 'src/app/shared/models/enums/status.enum';
import { UserAdminState } from 'src/app/shared/models/enums/user-admin-state.enum';
import { ActionButton } from 'src/app/shared/models/menu-actions/action-buttons';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';
import { MenuActionId } from 'src/app/shared/models/menu-actions/menu-action-id.enum';
import { CountByKeyPipe } from 'src/app/shared/pipes/count-by-key.pipe';
import { match } from 'src/app/shared/utils/utils';
import {
  AdministrationTableHeaderComponent,
} from '../../administration/administration-table-header/administration-table-header.component';
import {
  UsersAdministrationTableComponent,
} from '../../administration/users-administration-table/users-administration-table.component';
import { FILTER_TEMPLATES, FilterBarService } from '../../filters/filter-bar.service';
import { FilterBarComponent } from '../../filters/filter-bar/filter-bar.component';
import { SharedModule } from '../../shared/shared.module';
import {
  DepartmentAdminService,
} from '../department-admin/department-admin.service';
import { DepartmentUsersAdminService } from './department-users-admin.service';

@Component({
  selector: 'pxc-department-users-admin',
  standalone: true,
  imports: [
    CommonModule,
    UsersAdministrationTableComponent,
    AdministrationTableHeaderComponent,
    FilterBarComponent,
    SharedModule,
    CountByKeyPipe,
  ],
  providers: [
    FilterBarService,
    {
      provide: FILTER_TEMPLATES,
      useValue: of([]),
    },
  ],
  templateUrl: './department-users-admin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartmentUsersAdminComponent implements OnInit {

  administrationUsers$: Observable<AdministrationUser[]>;
  searchedAdministrationUsers$: Observable<AdministrationUser[]>;
  department$: Observable<Department>;

  deleteUserAction: MenuAction =  {
    id: MenuActionId.DEPARTMENT_ADMIN_USER_REMOVE,
    hint: 'Supprimer l\'utilisateur du groupe',
    icon: '@tui.user-x',
    status: Status.ERROR,
    appearance: 'flat-danger',
    execute: (user: AdministrationUser): void => this.removeUser(user),
  };

  departmentUsersActions: ActionsByUserState = {
    [UserAdminState.ENABLED]: [
      {
        id: MenuActionId.DEPARTMENT_ADMIN_USER_DISABLE,
        hint: 'Retirer l\'utilisateur du groupe',
        icon: '@tui.user-minus',
        appearance: 'destructive',
        execute: (user: AdministrationUser): void => this.disableUser(user),
      },
    ],
    [UserAdminState.INVITED]: [
      this.deleteUserAction,
    ],
    [UserAdminState.DISABLED]: [
      {
        id: MenuActionId.DEPARTMENT_ADMIN_USER_ENABLE,
        hint: 'Ajouter l\'utilisateur au groupe',
        icon: '@tui.user-plus',
        appearance: 'destructive',
        execute: (user: AdministrationUser): void => this.enableUser(user),
      },
      this.deleteUserAction,
    ],
  };

  tableHeaderActions: MenuAction[] = [
    {
      id: MenuActionId.CLIENT_ADMIN_USER_INVITE,
      label: 'Inviter',
      icon: '@tui.user-plus',
      appearance: 'outline',
      execute: (): void => this.inviteUsers(),
    },
  ];

  tableHeaderActionButtons: ActionButton[] = [
    {
      appearance: 'primary',
      title: 'Inviter',
      icon: '@tui.user-plus',
      actionIds: [
        MenuActionId.CLIENT_ADMIN_USER_INVITE,
      ],
    },
  ];

  private readonly departmentUsersAdminService = inject(DepartmentUsersAdminService);
  private readonly departmentAdminService = inject(DepartmentAdminService);
  private readonly filterBarService = inject(FilterBarService);

  ngOnInit(): void {
    this.administrationUsers$ = this.departmentUsersAdminService.loadDepartmentAdministrationUsers$();

    this.searchedAdministrationUsers$ = combineLatest([
      this.filterBarService.filteredSearch$,
      this.administrationUsers$,
    ]).pipe(
      map(([{ search }, users]) =>
        users
          .filter((user: AdministrationUser) =>
            match(search, [user.firstName, user.lastName, user.mailAddress]),
          ),
      ),
    );

    this.department$ = this.departmentAdminService.loadDepartment$();
  }

  enableUser(user: AdministrationUser): void {
    this.departmentUsersAdminService.enableUser(user);
  }

  disableUser(user: AdministrationUser): void {
    this.departmentUsersAdminService.disableUser(user);
  }

  removeUser(user: AdministrationUser): void {
    this.departmentUsersAdminService.removeUser(user);
  }

  adminChange(user: AdministrationUser): void {
    this.departmentUsersAdminService.adminChange(user);
  }

  inviteUsers(): void {
    this.departmentUsersAdminService.addUsers();
  }

}
