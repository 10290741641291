import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TuiBadge } from '@taiga-ui/kit';
import { UserAdminState } from 'src/app/shared/models/enums/user-admin-state.enum';
import { UserCountByAdminState } from 'src/app/shared/models/user-count-by-admin-state';
import { UserAdminStateBadgeComponent } from '../../shared/user-state-badge/user-admin-state-badge.component';

@Component({
  selector: 'pxc-user-state-count',
  standalone: true,
  imports: [
    CommonModule,
    TuiBadge,
    UserAdminStateBadgeComponent,
  ],
  templateUrl: './user-state-count.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserStateCountComponent {

  @Input() userCountByAdminState: UserCountByAdminState;

  UserState = UserAdminState;

}
