import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Department } from 'src/app/shared/models/base/department';
import { selectClientDepartment } from 'src/app/shared/store/departments/departments.selectors';
import { isDateFromPast } from 'src/app/shared/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class DepartmentUserService {

  constructor(
    private readonly store: Store,
  ) { }

  isDepartmentUserDisabled$(
    userId: number,
    clientCode: string,
    departmentId: number,
  ): Observable<boolean> {
    return this.store.select(selectClientDepartment(clientCode, departmentId)).pipe(
      map((department) => isDateFromPast(new Date(department?.memberships[userId]?.disabledOn))),
    );
  }

  isDepartmentUserDisabled(
    userId: number,
    department: Department,
  ): boolean {
    return isDateFromPast(new Date(department?.memberships[userId]?.disabledOn));
  }

}
