import { createActionGroup, props } from '@ngrx/store';
import { ClientUser, LicenseState, PublicUser } from '@pixacare/pxc-ts-core';
import { USERS_FEATURE_KEY } from './users.selectors';

export const usersActions = createActionGroup({
  source: USERS_FEATURE_KEY,
  events: {
    'Get users': props<{ clientCode: string }>(),
    'Get users success': props<{ clientCode: string; clientUsers: ClientUser[] }>(),

    'Add users': props<{ mailAddresses: string[]; clientCode: string; departmentId: number }>(),
    'Add users success': props<{ clientCode: string; clientUsers: ClientUser[] }>(),

    'Update user license state': props<{
      clientCode: string;
      userId: number;
      licenseState: LicenseState;
    }>(),

    'Update user license state success': props<{
      clientCode: string;
      userId: number;
      licenseState: LicenseState;
    }>(),

    'Update users': props<{ clientCode: string; users: PublicUser[] }>(),
  },
});
