<ng-container *tuiLet="{ resolvedShare: resolvedShare$ | async, error: resolvedShareError$ | async } as data">
  @if (!data.error) {
    @if (data.resolvedShare; as resolvedShare) {
      @switch (resolvedShare.objectType) {
        @case (ObjectType.SEQUENCE) {
          <pxc-sequence-preview
            class="cursor-auto"
            [sequence]="resolvedShare.object"
            [mobileMode]="true"
            [patientRedirect]="false"
            (mediaClicked)="mediaClicked($event, resolvedShare.object)"
            (click)="$event.stopPropagation()"
          ></pxc-sequence-preview>
        }
      }
    } @else {
      <pxc-spinner></pxc-spinner>
    }
  } @else {
    Une erreur est survenue lors de la récupération du partage
  }
</ng-container>
