import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StatisticsEffects } from './statistics.effects';
import { statisticsReducer } from './statistics.reducer';
import { STATISTICS_FEATURE_KEY } from './statistics.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(STATISTICS_FEATURE_KEY, statisticsReducer),
    EffectsModule.forFeature([StatisticsEffects]),
  ],
  declarations: [],
})
export class StatisticsStoreModule { }
