<button
  tuiButton
  appearance="flat"
  class="tui-group__inherit-item"
  [iconStart]="isActive ? icon : ''"
  iconEnd="@tui.chevron-down"
  size="s"
  data-testid="dropdown-button"
  [(tuiDropdownOpen)]="open"
  [tuiAppearanceState]="open || isActive ? 'hover' : null"
  [tuiDropdownMaxHeight]="500"
  [tuiDropdown]="content"
  tuiDropdownAlign="right"
>
  {{ buttonText }}
</button>

<ng-template #content>
  <div class="h-full w-full max-w-md overflow-hidden">
    <ng-content></ng-content>
  </div>
</ng-template>
