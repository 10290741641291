import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, filter, map, tap } from 'rxjs';
import { selectReadAccessClientCodes } from 'src/app/shared/store/licenses/licenses.selectors';
import { selectIsStoreInitialized } from 'src/app/shared/store/life-cycle/life-cycle.selectors';
import { environment } from 'src/environments/environment';

@Injectable()
export class ClientCodeService {

  ensureClientCode$ = combineLatest({
    initialized: this.store.select(selectIsStoreInitialized).pipe(
      filter((initialized) => initialized),
    ),
    clientCode: this.activatedRoute.queryParamMap.pipe(
      map((params) => params.get('cc')),
    ),
    readAccessClientCodes: this.store.select(selectReadAccessClientCodes),
  }).pipe(
    tap(({ clientCode, readAccessClientCodes }) => {

      if (!readAccessClientCodes?.length) {
        // No client code available
        // TODO : Handle account that has 0 client code
        return;
      }

      if (clientCode && readAccessClientCodes.includes(clientCode)) {
        // Client code is already set and valid
        return;
      }

      const suggestedClientCode = readAccessClientCodes.find((cc) => cc !== environment.commonClientCode);

      this.redirect(suggestedClientCode ?? readAccessClientCodes[0]);

    }),

  );

  constructor(
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {}

  private redirect(cc: string): void {
    this.router.navigate(['/dashboard'], { queryParams: { cc } });
  }

}
