import { Tool } from './studio-enums';
import { StudioTool } from './studio-interfaces';

export const studioTools: StudioTool[] = [
  {
    commandName: Tool.SHAPE_SELECTION, icon: '@tui.navigation', name: 'Sélection', shortcut: 'v',
  },
  {
    commandName: Tool.FREE_DRAW, icon: '@tui.pen-tool', name: 'Dessin', shortcut: 'b',
  },
  {
    commandName: Tool.DRAW_RECTANGLE, icon: '@tui.square', name: 'Rectangle', shortcut: 's',
  },
  {
    commandName: Tool.DRAW_ELLIPSE, icon: '@tui.circle', name: 'Ellipse', shortcut: 'c',
  },
  {
    commandName: Tool.DRAW_LINE, icon: '@tui.minus', name: 'Ligne', shortcut: 'l',
  },
  {
    commandName: Tool.ROTATE, icon: '@tui.refresh-cw', name: 'Rotation', shortcut: 'r',
  },
  {
    commandName: Tool.PREPARE_CROP, icon: '@tui.crop', name: 'Rogner', shortcut: 'p',
  },
];
