import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TuiIcon } from '@taiga-ui/core';
import { sequencesActions } from 'src/app/shared/store/sequences/sequences.actions';
import { ImageBrokenComponent } from '../../shared/image-broken/image-broken.component';
import { ImageLoadingComponent } from '../../shared/image-loading/image-loading.component';
import { MediaRetryStrategy } from './media-retry-strategy/media-retry-strategy';

@Component({
  selector: 'pxc-sequence-media',
  standalone: true,
  imports: [
    CommonModule,
    TuiIcon,
    ImageBrokenComponent,
    ImageLoadingComponent,
  ],
  templateUrl: './sequence-media.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequenceMediaComponent implements OnInit {

  @Input() src: string;
  @Input() clientCode: string;
  @Input() sequenceId: number;
  @Input() mediaId: number;

  isMediaLoading = true;

  mediaRetryStrategy: MediaRetryStrategy;

  constructor(
    private readonly store: Store,
  ) {
    this.mediaRetryStrategy = new MediaRetryStrategy(
      () => {
        this.store.dispatch(sequencesActions.updateSequenceMedia({
          clientCode: this.clientCode,
          sequenceId: this.sequenceId,
          mediaId: this.mediaId,
        }));
      },
    );
  }

  ngOnInit(): void {
    if (!this.src) {
      this.mediaRetryStrategy.startRetry();
    }
  }

  mediaLoaded(): void {
    this.isMediaLoading = false;
    this.mediaRetryStrategy.stopRetry();
  }

  loadingError(): void {
    this.mediaRetryStrategy.startRetry();
  }

}
