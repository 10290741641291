@if (collection$ | async; as collection) {
  <div class="flex flex-col gap-3">
    <h1 tuiTitle="l">Mes télésuivis</h1>
    <pxc-filter-tabs class="animate-in fade-in"></pxc-filter-tabs>
    <pxc-filter-bar
      searchPlaceholder="Rechercher un télésuivi par nom, date ou patient ..."
      data-testid="filter-bar"
      class="animate-in fade-in"
    ></pxc-filter-bar>
    @if ((isDataLoaded$ | async) === true) {
      <pxc-telemonitoring-list
        [telemonitorings]="collection.data"
        [actions]="actions"
        [isPreviousPageLoading]="collection.context.isPreviousPageLoading"
        [isNextPageLoading]="collection.context?.isNextPageLoading"
        (loadPrevious)="loadPreviousPage()"
        (loadNext)="loadNextPage()"
        data-testid="tlm-list"
      ></pxc-telemonitoring-list>
    } @else {
      <ng-container [ngTemplateOutlet]="loading"></ng-container>
    }
  </div>
} @else {
  <ng-container [ngTemplateOutlet]="loading"></ng-container>
}

<ng-template #loading>
  <pxc-loader
    data-testid="loading"
    class="my-5"
  >
    Chargement des télésuivis ...
  </pxc-loader>
</ng-template>
