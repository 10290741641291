import { createReducer, on } from '@ngrx/store';
import { ClientData } from '../../models/base/client-data';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { clientsActions } from './clients.actions';
import { initialClientsState } from './clients.state';

export const clientsReducer = createReducer(
  initialClientsState,

  on(lifeCycleActions.resetState, () => initialClientsState),

  on(clientsActions.updateClients, (state, { clients }) => ({
    ...state,
    clients: clients.reduce((acc, client) => ({
      ...acc,
      [client.code]: {
        ...client,
      },
    }), {} as { [clientCode: string]: ClientData }),
  })),

  on(clientsActions.updateDataConsumption,
    (state, { clientCode, sequence }) => ({
      ...state,
      clients: {
        ...state.clients,
        [clientCode]: {
          ...state.clients[clientCode],
          clientDataConsumption: {
            ...state.clients[clientCode].clientDataConsumption,
            used: state.clients[clientCode].clientDataConsumption.used + sequence.medias
              .map((media) => media.length).reduce((l1, l2) => l1 + l2, 0),
          },
        },
      },
    }),
  ),
);
