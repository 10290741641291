<div class="group relative aspect-square w-full overflow-hidden rounded-lg object-cover">
  @if (showDelete) {
    <button
      type="button"
      tuiButtonClose
      tuiIconButton
      class="!absolute right-2 top-1 z-10 opacity-0 transition-all hover:!opacity-100 hover:brightness-110 group-hover:top-2 group-hover:opacity-90"
      (click)="delete.emit()"
      data-testid="delete-button"
    ></button>
  }
  @if (caption) {
    <div
      class="absolute -bottom-1 w-full bg-gradient-to-t from-primary/80 via-primary/50 via-60% p-2 pt-8 opacity-0 transition-all group-hover:bottom-0 group-hover:opacity-100"
    >
      <p class="line-clamp-2 break-all text-xs text-base-alt">{{ caption }}</p>
    </div>
  }
  <ng-content></ng-content>
</div>
