import { Directive, Input, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IsMobileService } from '../services/is-mobile.service';

@UntilDestroy()
@Directive({
  selector: '[showIfDesktop]',
  standalone: true,
})
export class ShowIfDesktopDirective {

  private readonly isMobile = inject(IsMobileService);
  private readonly container = inject(ViewContainerRef);
  private readonly templateRef = inject(TemplateRef);

  @Input() set showIfDesktop({
    maxWidth = 1024,
    elseTemplate = null,
  }: {
    maxWidth?: number;
    elseTemplate?: TemplateRef<any>;
  }) {

    this.isMobile.maxWidth(maxWidth)
      .pipe(untilDestroyed(this))
      .subscribe((isMobile) => {
        this.container.clear();
        if (isMobile && elseTemplate) {
          this.container.createEmbeddedView(elseTemplate);
        } else {
          this.container.createEmbeddedView(this.templateRef);
        }
      });

  }

}
