import Konva from 'konva';
import { Command } from 'src/app/shared/models/helpers/command';
import { StudioCanvas } from '../canvas/studio-canvas';
import { DrawConfig } from '../config/studio-interfaces';
import { EventBus } from '../event-bus/event-bus';
import { EventTopic } from '../event-bus/event-bus.interfaces';

export class DrawRectangleCommand implements Command {

  receiver: StudioCanvas;
  eventBus: EventBus;
  drawConfig: DrawConfig;
  constructor(receiver: StudioCanvas, eventBus: EventBus, drawConfig: DrawConfig) {
    this.receiver = receiver;
    this.eventBus = eventBus;
    this.drawConfig = drawConfig;
  }

  execute(): void {
    let isUserDrawing = false;

    const drawingRectangle: Konva.Rect = new Konva.Rect({
      visible: false,
      strokeScaleEnabled: false,
    });
    this.receiver.addTempShape(drawingRectangle);

    let x1: number;
    let y1: number;
    let x2: number;
    let y2: number;

    this.receiver.stage.on('mousedown touchstart', (e) => {
      drawingRectangle.stroke(this.drawConfig.color.code);
      drawingRectangle.strokeWidth(this.drawConfig.width);
      if (e.currentTarget !== this.receiver.stage) {
        return;
      }
      x1 = this.receiver.layer.getRelativePointerPosition().x;
      y1 = this.receiver.layer.getRelativePointerPosition().y;
      isUserDrawing = true;
      drawingRectangle.width(0);
      drawingRectangle.height(0);
    });

    this.receiver.stage.on('mousemove touchmove', (e) => {
      if (!isUserDrawing) {
        return;
      }
      if (!drawingRectangle.isVisible()) {
        drawingRectangle.visible(true);
      }
      x2 = this.receiver.layer.getRelativePointerPosition().x;
      y2 = this.receiver.layer.getRelativePointerPosition().y;

      if (e.evt.shiftKey && !e.evt.altKey) {
        drawingRectangle.setAttrs({
          x: x1,
          y: y1,
          width: Math.max(Math.abs(y2 - y1), Math.abs(x2 - x1)),
          height: Math.max(Math.abs(y2 - y1), Math.abs(x2 - x1)),
        });

        const offsetX = drawingRectangle.getAttr('width');
        const offsetY = drawingRectangle.getAttr('height');
        drawingRectangle.offsetX((x2 < x1) ? offsetX : 0);
        drawingRectangle.offsetY((y2 < y1) ? offsetY : 0);

      } else {
        drawingRectangle.setAttrs({
          x: Math.min(x1, x2),
          y: Math.min(y1, y2),
          width: Math.abs(x2 - x1),
          height: Math.abs(y2 - y1),
        });
      }

      if (e.evt.altKey) {
        drawingRectangle.setAttrs({
          x: x1,
          y: y1,
          width: Math.abs(x2 - x1) * 2,
          height: Math.abs(y2 - y1) * 2,
        });
        if (e.evt.shiftKey) {
          drawingRectangle.setAttrs({
            width: Math.max(Math.abs(y2 - y1), Math.abs(x2 - x1) * 2),
            height: Math.max(Math.abs(y2 - y1), Math.abs(x2 - x1) * 2),
          });
        }
        drawingRectangle.offsetX(drawingRectangle.getAttr('width') / 2);
        drawingRectangle.offsetY(drawingRectangle.getAttr('height') / 2);
      }

    });

    this.receiver.stage.on('mouseup touchend', () => {
      isUserDrawing = false;
      if (!drawingRectangle.visible()) {
        return;
      }
      drawingRectangle.visible(false);

      // Draw rectangle
      const rectangle = new Konva.Rect({
        x: drawingRectangle.getAttr('x'),
        y: drawingRectangle.getAttr('y'),
        width: drawingRectangle.getAttr('width'),
        height: drawingRectangle.getAttr('height'),
        stroke: this.drawConfig.color.code,
        strokeWidth: this.drawConfig.width,
        offsetX: drawingRectangle.offsetX(),
        offsetY: drawingRectangle.offsetY(),
      });

      this.receiver.addShape(rectangle);
      drawingRectangle.destroy();
      this.eventBus.publish(EventTopic.AUTO_SELECT, { shapes: [rectangle] });
    });

  }

}
