import { PagedCollection } from './paged-collection';

export const defaultPagedCollection: PagedCollection = {
  data: {},
  navigation: undefined,
  totalCount: undefined,
  context: {
    isPreviousPageLoading: false,
    isNextPageLoading: false,
    countPerPage: undefined,
    currentPages: undefined,
    hasNextPage: undefined,
    hasPreviousPage: undefined,
    lastPage: undefined,
  },
  sorted: [],
};
