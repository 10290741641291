import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Channel, SearchedUser } from '@pixacare/pxc-ts-core';
import { CrossBadgeComponent } from 'src/app/shared/components/cross-badge/cross-badge.component';
import { UserCardComponent } from 'src/app/shared/components/user-card/user-card.component';
import { ChannelPreviewComponent } from '../channel-preview/channel-preview.component';

@Component({
  selector: 'pxc-receiver-list',
  standalone: true,
  imports: [
    CommonModule,
    UserCardComponent,
    ChannelPreviewComponent,
    CrossBadgeComponent,
  ],
  templateUrl: './receiver-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceiverListComponent {

  @Input() removable = true;
  @Input() channels: Channel[];
  @Input() users: SearchedUser[];
  @Input() mailAddresses: string[];
  @Output() removeChannel = new EventEmitter<Channel>();
  @Output() removeUser = new EventEmitter<SearchedUser>();
  @Output() removeMailAddress = new EventEmitter<string>();

}
