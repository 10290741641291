import {
  ChangeDetectionStrategy, Component, Inject, OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SadmClient, SadmEntity, SadmTypeLabel } from '@pixacare/pxc-ts-core';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { DialogService } from 'src/app/services/dialog.service';
import { PatientTab } from 'src/app/shared/models/enums/patient-tabs.enum';
import { PluralizePipe } from 'src/app/shared/pipes/pluralize.pipe';
import { sadmActions } from 'src/app/shared/store/sadm/sadm.actions';
import { selectSadmClient, selectSadmClientPatientEntities } from 'src/app/shared/store/sadm/sadm.selectors';

@Component({
  templateUrl: './sadm-entity-merge.component.html',
  providers: [PluralizePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SadmEntityMergeComponent implements OnInit {

  sadmEntity: SadmEntity = this.context.data.sadmEntity;

  mergeToEntityId: number;
  sadmEntities$: Observable<SadmEntity[]>;
  sadmClient$: Observable<SadmClient>;
  sadms$: Observable<{ entities: SadmEntity[]; client: SadmClient }>;

  SadmTypeLabel = SadmTypeLabel;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly pluralizePipe: PluralizePipe,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<void, { sadmEntity: SadmEntity }>,
    private readonly dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.sadmEntities$ = this.store.select(
      selectSadmClientPatientEntities(
        this.sadmEntity.sadmClientId, this.sadmEntity.clientCode, this.sadmEntity.patientId,
      ),
    ).pipe(map((sadmEntities) =>
      sadmEntities.filter((sadmEntity) => sadmEntity.id !== this.sadmEntity.id),
    ));

    this.sadmClient$ = this.store.select(selectSadmClient(this.sadmEntity.sadmClientId));

    this.sadms$ = combineLatest([this.sadmEntities$, this.sadmClient$])
      .pipe(map(([entities, client]) => ({ entities, client })));
  }

  cancel(): void {
    this.context.completeWith();
  }

  merge(): void {

    combineLatest([
      this.sadmClient$,
      this.sadmEntities$,
    ]).pipe(first()).subscribe(([sadmClient, sadmEntities]) => {

      const mergeFromEntityIndex = this.sadmEntity.patientEntityIndex;
      const mergeToEntityIndex = this.findSadmEntity(sadmEntities, this.mergeToEntityId).patientEntityIndex;
      const sadmTypeLabel = SadmTypeLabel[sadmClient.sadmType];

      // eslint-disable-next-line max-len
      const content = ` Les analyses de la ${sadmTypeLabel} n°${mergeFromEntityIndex} seront déplacées dans la ${sadmTypeLabel} n°${mergeToEntityIndex}. La ${sadmTypeLabel} n°${mergeFromEntityIndex} sera ensuite supprimée.`;

      this.dialogService.openConfirm(
        `Etes-vous sûr de vouloir fusionner ces ${this.pluralizePipe.transform(sadmTypeLabel)} ?`,
        {
          content,
          yes: 'Fusionner',
          no: 'Annuler',
        },
      ).subscribe((confirm) => {
        if (confirm) {
          this.store.dispatch(sadmActions.mergeSadmEntity({
            fromSadmEntityId: this.sadmEntity.id,
            toSadmEntityId: this.mergeToEntityId,
          }));
          this.context.completeWith();
          this.router.navigate(['/dashboard/patients/', this.sadmEntity.patientId],
            { queryParamsHandling: 'merge', queryParams: { tab: PatientTab.ANALYSES } });
        }
      });

    });
  }

  findSadmEntity(sadmEntities: SadmEntity[], id: number): SadmEntity {
    return sadmEntities.find((sadmEntity) => sadmEntity.id === id);
  }

}
