import { SadmClient, SadmEntity } from '@pixacare/pxc-ts-core';
import { SadmDashboard } from '../../models/sadms/sadm-dashboard';

export interface SadmState {
  clients: {
    [sadmClientId: number]: SadmClient;
  };
  entities: {
    [sadmEntityId: number]: SadmEntity;
  };
  dashboards: {
    [sadmEntityId: number]: SadmDashboard;
  };
  userSadmClients: number[];
  loadState: { [clientCode: string]: { [patientId: number]: boolean } };
}

export const initialSadmState: SadmState = {
  clients: {},
  entities: {},
  dashboards: {},
  userSadmClients: [],
  loadState: {},
};
