@if (!!src && !mediaRetryStrategy.hasReachedMaxRetries) {
  <img
    class="aspect-square w-[inherit] rounded"
    [hidden]="isMediaLoading"
    (load)="mediaLoaded()"
    (error)="loadingError()"
    [src]="src"
    data-testid="image"
  />
} @else {
  <pxc-image-broken class="aspect-square h-[inherit] w-[inherit] rounded"></pxc-image-broken>
}

@if (src && isMediaLoading && !mediaRetryStrategy.hasReachedMaxRetries) {
  <pxc-image-loading
    data-testid="spinner"
    class="aspect-square h-[inherit] w-[inherit] rounded"
  ></pxc-image-loading>
}
