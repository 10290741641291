import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PatientForm } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DepositHttpService {

  constructor(
    private readonly httpClient: HttpClient,
  ) { }

  getForms(): Observable<PatientForm[]> {
    return this.httpClient.get<PatientForm[]>(`${environment.apiBaseUrl}/Deposit/forms?forms=true`);
  }

}
