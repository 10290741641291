@if (dataConsumptionState$ | async; as dataConsumptionState) {
  @if (activeClientDataConsumption$ | async; as clientDataConsumption) {
    <ng-container *tuiLet="(clientDataConsumption.used * 100) / clientDataConsumption.total as percentUsed">
      @if (detailedMode) {
        <div class="my-2 flex flex-col gap-4">
          <div class="my-2 flex flex-col items-start gap-5 lg:flex-row">
            <tui-icon
              [icon]="dataConsumptionState.icon"
              data-testid="icon"
              class="!text-2xl"
            ></tui-icon>
            <div class="flex flex-col gap-2">
              <h5
                tuiTitle="m"
                data-testid="title"
              >
                {{
                  dataConsumptionState.title(
                    clientDataConsumption.total - clientDataConsumption.used | formatGoDataUnit
                  )
                }}
              </h5>
              <div
                class="flex h-6 w-full overflow-hidden rounded-full"
                [ngClass]="dataConsumptionState.styles.containerClass"
                data-testid="progress"
              >
                <div
                  [ngStyle]="{ width: percentUsed + '%' }"
                  [ngClass]="dataConsumptionState.styles.progressClass"
                ></div>
              </div>
              <p
                class="text-sm"
                [ngClass]="dataConsumptionState.styles.hintAppearance === 'error' ? 'text-negative' : 'text-secondary'"
              >
                {{ clientDataConsumption.used | formatGoDataUnit }} consommés sur
                {{ clientDataConsumption.total | formatGoDataUnit }}
              </p>
              <p data-testid="description">
                Vous disposez de
                <span class="font-bold">{{ clientDataConsumption.total | formatGoDataUnit }}</span>
                de stockage dans votre espace sécurisé Pixacare.
              </p>
              <p>Contactez nous pour bénéficier davantage d'espace de stockage.</p>
            </div>
          </div>
          <button
            tuiButton
            (click)="contact()"
            appearance="primary"
            data-testid="contact"
          >
            Demander de l'aide
          </button>
        </div>
      } @else {
        <div
          class="relative h-10 w-full cursor-pointer overflow-hidden rounded-full ring-border-normal/50 transition-all hover:ring"
          [ngClass]="dataConsumptionState.styles.containerClass"
          (click)="manageStorage()"
          [tuiHint]="hint"
          [tuiHintAppearance]="dataConsumptionState.styles.hintAppearance"
          tuiHintDirection="right"
          data-testid="button"
        >
          <div
            class="absolute z-10 flex h-full w-full items-center gap-4 py-2 text-sm"
            [ngClass]="{
              'px-4': !collapsed,
              'px-2': collapsed,
            }"
          >
            <tui-icon [icon]="dataConsumptionState.icon"></tui-icon>
            <span
              class="flex-1 truncate"
              [animateFade]="!collapsed"
              animateDirection="left"
            >
              {{ dataConsumptionState.label }}
            </span>
            <span
              class="truncate font-bold"
              [animateFade]="!collapsed"
              animateDirection="left"
              data-testid="consumption-detail"
            >
              {{ percentUsed | number: '1.0-0' }} %
            </span>
          </div>
          <div
            class="absolute z-0 h-full rounded-full"
            [ngStyle]="{ width: percentUsed + '%' }"
            [ngClass]="dataConsumptionState.styles.progressClass"
            data-testid="progress"
          ></div>
        </div>
      }
      <ng-template #hint>
        {{ clientDataConsumption.used | formatGoDataUnit }} consommés sur
        {{ clientDataConsumption.total | formatGoDataUnit }}
      </ng-template>
    </ng-container>
  }
}
