import { colorizeIcon, icons } from '../../../../assets/icons/icons';
import { getISODate } from '../../utils/utils';

const img = new Image();
const lineGradientColors = {
  start: 'rgba(15, 44, 67, 0.7)',
  stop: 'rgba(15, 44, 67, 0.16)',
};
const baseImageSrc = 'data:image/svg+xml,';
img.src = baseImageSrc + colorizeIcon(icons.form, lineGradientColors.start);

const createGradient = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ctx: any,
  { bottom = 0 }: { bottom?: number },
) => {
  const gradient = ctx.createLinearGradient(0, 0, 0, bottom);
  gradient.addColorStop(0, lineGradientColors.start);
  gradient.addColorStop(1, lineGradientColors.stop);
  return gradient;
};

export const eventLinePlugin = {
  id: 'eventLine',
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  beforeInit: (chart: any): void => {
    const fitValue = chart.legend.fit;
    chart.legend.fit = function fit() {
      fitValue.bind(chart.legend)();
      return this.height += 20;
    };
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  beforeRender: (chart: any): void => {
    chart.config.data.xLines = chart.config.data.events?.map((event) => {
      const date = new Date(getISODate(new Date(event.key)));
      date.setHours(0, 0, 0, 0);
      return Math.round(
        chart.scales.x.getPixelForValue(date.getTime()),
      );
    });
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  beforeDraw: (chart: any): void => {
    const { ctx } = chart;
    ctx.lineWidth = 2;
    ctx.setLineDash([8, 5]);
    const { y } = chart.scales;

    chart.config.data.xLines?.forEach((xPixel) => {
      ctx.strokeStyle = createGradient(ctx, { bottom: y.bottom });
      ctx.drawImage(img, xPixel - 7, y.top - 20, 16, 16);
      ctx.beginPath();
      ctx.moveTo(xPixel, y.top);
      ctx.lineTo(xPixel, y.bottom);
      ctx.stroke();
    });
  },
};
