import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ExportsEffects } from './exports.effects';
import { exportsReducer } from './exports.reducer';
import { EXPORTS_FEATURE_KEY } from './exports.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(EXPORTS_FEATURE_KEY, exportsReducer),
    EffectsModule.forFeature([ExportsEffects]),
  ],
  declarations: [],
})
export class ExportStoreModule { }
