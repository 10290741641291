import { Injectable } from '@angular/core';
import { AdministrationUser } from 'src/app/shared/models/administration-user';
import { UserAdminState } from 'src/app/shared/models/enums/user-admin-state.enum';

@Injectable()
export class UsersAdminSortService {

  sortUserByState(userA: AdministrationUser, userB: AdministrationUser): number {
    const stateOrder = {
      [UserAdminState.ENABLED]: 0,
      [UserAdminState.DISABLED]: 1,
      [UserAdminState.INVITED]: 2,
    };

    const undefinedStateIdx = Object.keys(stateOrder).length;
    const stateA = stateOrder[userA.state] ?? undefinedStateIdx;
    const stateB = stateOrder[userB.state] ?? undefinedStateIdx;

    return stateA - stateB;
  }

  sortUserByAdmin(userA: AdministrationUser, userB: AdministrationUser): number {
    return +(userB.isAdmin) - +(userA.isAdmin);
  }

}
