import { MenuAction } from '../menu-actions/menu-action';
import { MenuActionId } from '../menu-actions/menu-action-id.enum';

export abstract class ActionsService {

  actions: MenuAction[] = [];

  getAction(id: MenuActionId): MenuAction {
    return this.actions.find((action) => action.id === id);
  }

  getActions(): MenuAction[] {
    return this.actions;
  }

}
