import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { HelpActionsService } from 'src/app/services/help-actions.service';
import { SidebarService } from '../sidebar/sidebar.service';
@Component({
  selector: 'pxc-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    HelpActionsService,
  ],
})
export class HeaderComponent {

  helpActions = inject(HelpActionsService).getActions();
  private readonly sidebarService = inject(SidebarService);

  toggleSidebarCollapsed(): void {
    this.sidebarService.collapsed = !this.sidebarService.collapsed;
  }

  showSidebar(): void {
    this.sidebarService.shown = true;
  }

}
