import {
  Component, EventEmitter, Inject, Input, OnInit, Optional, Output,
} from '@angular/core';
import {
  AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Patient } from '@pixacare/pxc-ts-core';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import {
  debounceTime, distinctUntilChanged, first, shareReplay, startWith, tap,
} from 'rxjs/operators';
import { CloseConfirmableDialogComponent } from 'src/app/modules/shared/close-confirmable-dialog/close-confirmable-dialog.component';
import { PatientHttpService } from 'src/app/services/http/patient.http.service';
import { formValidationErrors } from 'src/app/shared/models/form-validation/form-validation-error.config';
import { FormValidationError } from 'src/app/shared/models/form-validation/form-validation-error.enum';
import { selectIsGamEnabled } from 'src/app/shared/store/clients/clients.selectors';
import { comparePatients } from 'src/app/shared/utils/patient-utils';
import { PatientEditService } from './patient-edit.service';

@UntilDestroy()
@Component({
  selector: 'pxc-patient-edit',
  templateUrl: './patient-edit.component.html',
  providers: [PatientEditService],
})
export class PatientEditComponent extends CloseConfirmableDialogComponent<void> implements OnInit {

  @Input() patient: Patient = this.context?.data.patient;
  @Input() clientCode: string = this.context?.data.clientCode;
  @Input() displayActionButtons = this.context?.data.displayActionButtons || true;
  @Output() patientChange = new EventEmitter<Patient>();
  @Output() isCheckingFormValidityChange = new EventEmitter<boolean>();
  @Output() formValidityUpdate = new EventEmitter<boolean>();

  basePatient: Patient;
  patientEditForm: UntypedFormGroup;
  selectedPatient: Patient = null;
  displayPatientsList = false;
  isPatientAnonymous = false;

  selectIsGamEnabled$: Observable<boolean>;
  searchedPatients$: Observable<Patient[]>;
  isCheckingFormValidity$ = new ReplaySubject<boolean>(1);

  requiredFormFieldNames = ['lastName', 'firstName', 'birthDate'];
  FormValidationError = FormValidationError;

  constructor(
    private readonly store: Store,
    private readonly patientService: PatientHttpService,
    private readonly patientEditService: PatientEditService,
    @Optional() @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<void, {
      patient: Patient;
      clientCode: string;
      displayActionButtons?: boolean;
      shouldAskConfirmation$?: Subject<boolean>;
    }>,
  ) {
    super(context);
  }

  ngOnInit() {
    this.basePatient = this.patient;
    this.isPatientAnonymous = this.checkPatientAnonymity(this.patient);

    this.selectIsGamEnabled$ = this.store.select(selectIsGamEnabled(this.clientCode || this.patient?.clientCode));

    this.patientEditForm = new UntypedFormGroup({
      id: new UntypedFormControl(this.patient?.id),
      businessIdentifier: new UntypedFormControl(this.patient?.businessIdentifier),
      firstName: new UntypedFormControl(this.patient?.firstName),
      lastName: new UntypedFormControl(this.patient?.lastName),
      birthName: new UntypedFormControl(this.patient?.birthName),
      birthDate: new UntypedFormControl(this.patient?.birthDate),
      isGamLinked: new UntypedFormControl(this.patient?.isGamLinked),
      clientCode: new UntypedFormControl(this.clientCode || this.patient?.clientCode),
      createdBy: new UntypedFormControl(this.patient?.createdBy),
      lastActivity: new UntypedFormControl(this.patient?.lastActivity),
    }, {
      validators: this.contextualValidators(),
    });

    this.patientEditForm.valueChanges.pipe(
      tap(() => {
        this.shouldCloseConfirmChange(true);
        this.isCheckingFormValidity$.next(true);
        this.isCheckingFormValidityChange.emit(true);
      }),
      distinctUntilChanged(),
      debounceTime(300),
      untilDestroyed(this),
    ).subscribe((values) => {
      this.isPatientAnonymous = this.checkPatientAnonymity(values);
      this.selectedPatient = null;
      this.displayPatientsList = true;
      this.searchedPatients$ = this.patientService.search({ ...values, isArchived: false })
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));

      this.searchedPatients$.subscribe((patients) => {

        const sameIdentifierPatient = patients.find((patient) =>
          patient.businessIdentifier === values.businessIdentifier);
        let errorKey = null;
        let isGamLinked = false;
        if (sameIdentifierPatient) {

          if (!PatientEditService.compare(sameIdentifierPatient, values)) {
            if (sameIdentifierPatient.isGamLinked) {
              errorKey = FormValidationError.OVERRIDEN_GAM_PATIENT;
            } else if (!this.basePatient || this.basePatient.businessIdentifier !== values.businessIdentifier) {
              errorKey = FormValidationError.OVERRIDEN_LOCAL_PATIENT;
            }
          } else {
            isGamLinked = sameIdentifierPatient.isGamLinked;
            this.selectedPatient = sameIdentifierPatient;
          }
        }
        this.patientEditForm.patchValue({ isGamLinked }, { emitEvent: false });
        this.updateFormErrors(errorKey);
        this.formValidityUpdate.next(this.patientEditForm.valid && !this.patientEditForm.errors);
        this.emitChanges();
      });
    });

  }

  checkPatientAnonymity(patient: Patient): boolean {
    return !patient
      || (!patient.businessIdentifier && this.requiredFormFieldNames.every((fieldName) => !patient[fieldName]));
  }

  updateFormErrors(errorKey: FormValidationError): void {
    this.patientEditForm?.setErrors(
      errorKey ? {
        [errorKey]: formValidationErrors[errorKey],
        ...this.patientEditForm?.errors,
      } : this.patientEditForm?.errors,
      { emitEvent: false },
    );
  }

  contextualValidators(): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      let isValid = true;
      this.requiredFormFieldNames.forEach((controlName) => {
        const control = formGroup?.get([controlName]);
        let errors = null;
        if (!control.value && this.areSomeFormFieldsFilled(['businessIdentifier'])) {
          isValid = false;
          errors = { errors: { required: true } };
        }
        control?.setErrors(errors, { emitEvent: false });
      });

      return isValid ? null : { [FormValidationError.INVALID]: formValidationErrors[FormValidationError.INVALID] };
    };
  }

  areSomeFormFieldsFilled(additionalFieldNames: string[] = []): boolean {
    return [...this.requiredFormFieldNames, ...additionalFieldNames]
      .some((formFieldName) => !!this.patientEditForm?.getRawValue()[formFieldName]);
  }

  selectPatient(patient: Patient): void {
    this.patientEditForm.setValue({
      id: patient.id,
      firstName: patient.firstName,
      lastName: patient.lastName,
      birthName: patient.birthName,
      birthDate: patient.birthDate,
      businessIdentifier: patient.businessIdentifier,
      clientCode: patient.clientCode,
      isGamLinked: patient.isGamLinked,
      createdBy: patient.createdBy,
      lastActivity: patient.lastActivity,
    }, { emitEvent: false });
    this.selectedPatient = patient;

    this.formValidityUpdate.next(this.patientEditForm.valid && !this.patientEditForm.errors);

    this.emitChanges();
  }

  emitChanges(): void {
    const isFormValid = this.patientEditForm.valid && !this.patientEditForm.errors;
    this.formValidityUpdate.next(isFormValid);
    this.isCheckingFormValidity$.next(false);
    this.isCheckingFormValidityChange.emit(false);

    this.patientChange.emit({
      ...this.patientEditForm.getRawValue(),
      id: this.patient?.id,
      businessIdentifier: this.patientEditForm.getRawValue()?.businessIdentifier || null,
      firstName: this.patientEditForm.getRawValue()?.firstName || null,
      lastName: this.patientEditForm.getRawValue()?.lastName || null,
      birthName: this.patientEditForm.getRawValue()?.birthName || null,
      birthDate: this.patientEditForm.getRawValue()?.birthDate
        ? new Date(this.patientEditForm.getRawValue()?.birthDate)
        : null,
      createdBy: this.patientEditForm.getRawValue()?.createdBy || null,
      lastActivity: new Date(this.patientEditForm.getRawValue()?.lastActivity) || null,
    });

  }

  save(): void {
    this.isCheckingFormValidity$.pipe(
      startWith(false),
      first(),
    ).subscribe((isChecking) => {
      if (isChecking) {
        return;
      }

      this.isCheckingFormValidity$.next(true);

      if (
        comparePatients(this.patient, this.patientEditForm.value) &&
          this.patient.businessIdentifier === this.patientEditForm.getRawValue()?.businessIdentifier
      ) {

        this.closeEdition();
        this.isCheckingFormValidity$.next(false);

      } else {

        this.patientEditService.updatePatient(
          this.basePatient,
          this.patientEditForm,
        ).pipe(
          first(),
        ).subscribe(() => {
          this.closeEdition();
          this.isCheckingFormValidity$.next(false);
        });

      }

    });
  }

  closeEdition(): void {
    if (this.context) {
      this.context.completeWith();
    }
  }

}
