import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FlattenedTelemonitoring, PagedResponse, PaginationQuery } from '@pixacare/pxc-ts-core';
import { TelemonitoringHttpService } from '@services/http/telemonitoring.http.service';
import { getNextPagedCollection } from '@shared/models/helpers/pagination-helper';
import { PagedCollection } from '@shared/models/pagination/paged-collection';
import { Pagination } from '@shared/pagination';
import { Observable } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TelemonitoringPaginationService extends Pagination<FlattenedTelemonitoring> {

  constructor(
    private readonly telemonitoringService: TelemonitoringHttpService,
  ) {
    super();
  }

  getNextData(
    clientCode: string, { query }: { query: PaginationQuery },
  ): Observable<PagedResponse<FlattenedTelemonitoring>> {
    return this.telemonitoringService.getTelemonitorings({ query });
  }

  getNextCollection({ currentCollection, nextCollection, reset }: {
    currentCollection: PagedCollection<{ [id: number]: FlattenedTelemonitoring }>;
    nextCollection: PagedResponse<FlattenedTelemonitoring>;
    reset: boolean;
  }): PagedCollection<{ [id: number]: FlattenedTelemonitoring }> {

    return getNextPagedCollection({ currentCollection, nextCollection }, {
      reset,
      dataIdentifier: 'telemonitoring.id',
      sortPredicate: (entityId1, entityId2, entities) => (
        entities[entityId2].telemonitoring.lastActivity?.getTime()
        - entities[entityId1].telemonitoring.lastActivity?.getTime()
      ),
    });
  }

}
