import { HttpEvent, HttpEventType, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { convertDateFields } from '@shared/utils/utils';
import { tap } from 'rxjs/operators';

export const responseDateInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => next(req).pipe(
  tap(
    (event: HttpEvent<any>) => {
      if (event.type === HttpEventType.Response) {
        const { body } = event;
        convertDateFields(body);
      }
    },
  ),
);
