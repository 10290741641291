<div class="relative z-0 w-full">
  <div class="absolute z-10 h-full w-full opacity-40 blur-3xl">
    <div
      class="absolute left-8 top-[15%] z-10 h-52 w-52 rotate-12 rounded-full bg-pixablue-2 lg:left-[10%] lg:top-1/4 lg:w-1/4"
    ></div>
    <div
      class="absolute left-24 top-[15%] z-10 h-52 w-52 rounded-full bg-pixapurple-2 mix-blend-color-burn lg:left-1/3 lg:top-1/4 lg:w-1/4"
    ></div>
    <div
      class="absolute left-[40%] top-[20%] z-10 h-52 w-52 rounded-full bg-pixared-2/30 mix-blend-color-burn lg:left-[60%] lg:top-1/4 lg:w-1/4"
    ></div>
  </div>

  <svg
    class="absolute -left-16 opacity-40 mix-blend-soft-light lg:top-2 xl:left-2"
    width="499"
    height="431"
    viewBox="0 0 499 431"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 429.353C29.19 378.978 99.4887 271.917 163.163 246.679C242.755 215.13 311.472 235.484 372.279 226.834C433.086 218.183 530.97 166.79 484.005 28.3849C461.759 -41.3266 388.593 -63.2069 328.775 -213.825C284.778 -324.607 284.447 -338.152 267.474 -339"
      stroke="url(#paint0_linear_1154_179255)"
      stroke-width="4"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1154_179255"
        x1="2"
        y1="-17.5"
        x2="2.00002"
        y2="415"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="white"
          stop-opacity="0"
        />
        <stop
          offset="0.182292"
          stop-color="white"
        />
        <stop
          offset="0.791667"
          stop-color="white"
        />
        <stop
          offset="1"
          stop-color="white"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>

  <svg
    class="absolute right-12 top-12 opacity-40 mix-blend-soft-light max-xl:hidden"
    width="690"
    height="312"
    viewBox="0 0 690 312"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 310.365C71.7054 243.876 278.943 82.2134 437.535 41.913C596.127 1.61259 681.751 -1.89491 694.11 4.55044"
      stroke="url(#paint0_linear_1154_179256)"
      stroke-width="4"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1154_179256"
        x1="42"
        y1="372.5"
        x2="618"
        y2="1.99999"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="white"
          stop-opacity="0"
        />
        <stop
          offset="0.286458"
          stop-color="white"
        />
        <stop
          offset="0.796875"
          stop-color="white"
        />
        <stop
          offset="1"
          stop-color="white"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>

  <div class="container relative z-10 flex h-full py-8">
    <div class="h-full w-full flex-1">
      <router-outlet data-testid="content"></router-outlet>
    </div>
    <router-outlet name="dialog"></router-outlet>
  </div>
</div>
