import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { clientsReducer } from './clients.reducer';
import { CLIENTS_FEATURE_KEY } from './clients.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(CLIENTS_FEATURE_KEY, clientsReducer),
  ],
  declarations: [],
})
export class ClientsStoreModule { }
