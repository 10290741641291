import { CropConfig } from '@pixacare/pxc-ts-core';
import Konva from 'konva';
import { StudioColor } from '../config/studio-interfaces';

export enum EventTopic {
  AUTO_SELECT = 'auto-select',
  SWITCH_TOOL = 'switch-tool',
  REVERT_CROP = 'revert-crop',
  EXECUTE_CROP = 'execute-crop',
  CROP_FINISHED = 'crop-finished',
  ROTATION_FINISHED = 'rotation-finished',
  UPDATE_CURRENT_CROPCONFIG = 'update-current-cropconfig',
  KEY_PRESSED = 'key-pressed',
  MODIFY_ANNOTATIONS = 'modify-annotations',
  SAVE = 'save',
  IMPORT_FINISHED = 'import-finished',
  CLOSE = 'close',
}

export interface EventSubject {
  shapes?: Konva.Shape[];
  keyPressed?: string;
  toolSwitchName?: string;
  color?: StudioColor;
  brushSize?: number;
  cropConfig?: CropConfig;
}

export interface Receiver {
  receive(topic: string, subject: EventSubject);
}

export interface EventBusInterface {
  subscribe(topic: string, receiver: Receiver): void;
  unsubscribe(topic: string, receiver: Receiver): void;

  publish(topic: string, subject: EventSubject): Promise<void>;
}
