<div class="flex items-center gap-6">
  <pxc-client-image
    class="h-14 w-14 rounded-full bg-base !shadow lg:h-24 lg:w-24"
    [imagePath]="clientCdnImagePath"
  ></pxc-client-image>
  <h1 tuiTitle="l">
    {{ title }}
    @if (subtitle) {
      <span tuiSubtitle>{{ subtitle }}</span>
    }
  </h1>
</div>
