<div class="flex flex-col gap-6">
  <h2
    tuiTitle="m"
    class="py-2"
  >
    {{ title }}
    <p
      tuiSubtitle
      class="max-w-prose"
    >
      Afin d'accéder à l'environnement sécurisé Pixacare, merci de valider votre compte avec le code que nous allons
      envoyer par e-mail.
    </p>
  </h2>

  <code-input
    [codeLength]="6"
    (codeCompleted)="onOtpComplete($event)"
    data-testid="code-input"
    [disabled]="!isCodeSent"
    #codeInputComponent
  ></code-input>

  <div class="flex items-center justify-center">
    @if (countdown$ | async; as value) {
      <p class="max-w-prose text-center text-secondary">
        <span class="font-bold">Vous n'avez pas reçu de code ?</span>
        <br />
        Vous pourrez renvoyer un code par email dans {{ value }} secondes.
      </p>
    } @else {
      <button
        tuiButton
        appearance="secondary"
        (click)="sendCode()"
        data-testid="resend-button"
      >
        Renvoyer un code par email
      </button>
    }
  </div>
</div>
