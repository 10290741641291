import { formatDate } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Encounter, PagedResponse, PaginationQuery, Patient, PatientIdentity } from '@pixacare/pxc-ts-core';
import { QueryReportConfig } from '@shared/models/report-config';
import { formatToIso, match } from '@shared/utils/utils';
import { format } from 'date-fns';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PatientHttpService {

  constructor(
    private readonly http: HttpClient,
  ) { }

  createPatient(clientCode: string, patient: PatientIdentity): Observable<Patient> {
    return this.http.post<Patient>(`${environment.apiBaseUrl}/patient/${clientCode}`, {
      ...patient,
      birthDate: format(patient.birthDate, 'yyyy-MM-dd'),
    });
  }

  getPatients(clientCode: string, {
    query = new PaginationQuery({ orderBy: ['last_activity|desc'] }),
  } = {},
  ): Observable<PagedResponse<Patient>> {
    return this.http.get<PagedResponse<Patient>>(
      `${environment.apiBaseUrl}/patient/${clientCode}`,
      {
        headers: { 'X-version': '2.0' },
        params: { paginationOptions: String(query.encodedQuery || query.encode() || '') },
      },
    );
  }

  getPatientsByBusinessIdentifier(
    clientCode: string,
    businessIdentifier: string,
  ): Observable<Patient[]> {
    return this.http.get<Patient[]>(
      `${environment.apiBaseUrl}/patient/${clientCode}?businessIdentifier=${businessIdentifier}`,
    );
  }

  getPatientEncounters(
    clientCode: string,
    patientId: number,
  ): Observable<Encounter[]> {
    return this.http.get<Encounter[]>(
      `${environment.apiBaseUrl}/patient/${patientId}/encounter`,
      { params: { clientCode } },
    );
  }

  mergePatients(
    clientCode: string,
    patientId: number,
    toPatientId: number = null,
  ): Observable<unknown> {
    return this.http.delete(
      `${environment.apiBaseUrl}/patient/${clientCode}/${patientId}?toPatientId=${toPatientId || ''}`,
    );
  }

  deletePatient(
    clientCode: string,
    patientId: number,
  ): Observable<unknown> {
    return this.mergePatients(clientCode, patientId);
  }

  filter(patient: Patient, value: string): boolean {
    const tags = [
      patient.firstName,
      patient.lastName,
      patient.birthName,
      patient.businessIdentifier,
      formatDate(patient.birthDate, 'shortDate', 'fr'),
    ];
    return match(value, tags);
  }

  report(
    clientCode: string,
    patientId: number,
    { query, includeReports, includeCharts }: QueryReportConfig = {
      query: new PaginationQuery({ orderBy: ['created_on|desc'] }),
    },
  ): Observable<HttpResponse<Blob>> {
    return this.http.post(
      `${environment.apiBaseUrl}/patient/${patientId}/report`,
      {},
      {
        params: {
          cc: clientCode,
          paginationOptions: String(query.encodedQuery || query.encode() || ''),
          includeReports,
          includeCharts,
        },
        observe: 'response',
        responseType: 'blob',
      },
    );
  }

  export(
    clientCode: string,
    patientId: number,
    encounterId: string,
    { query, includeReports, includeCharts }: QueryReportConfig = {
      query: new PaginationQuery({ orderBy: ['created_on|desc'] }),
    },
  ): Observable<string> {
    return this.http.post(
      `${environment.apiBaseUrl}/patient/${patientId}/export`,
      { },
      {
        params: {
          cc: clientCode,
          encounterIdentifier: encounterId,
          paginationOptions: String(query.encodedQuery || query.encode() || ''),
          includeReports,
          includeCharts,
        },
        responseType: 'text',
      },
    );
  }

  search({
    businessIdentifier,
    clientCode,
    firstName,
    lastName,
    birthName,
    birthDate,
    isArchived = null,
  }: {
    businessIdentifier: string;
    clientCode?: string;
    firstName?: string;
    lastName?: string;
    birthName?: string;
    birthDate?: Date;
    isArchived?: boolean;
  }): Observable<Patient[]> {
    return this.http.get<Patient[]>(
      `${environment.apiBaseUrl}/patient/`,
      {
        params: {
          ...(businessIdentifier && { businessIdentifier }),
          ...(firstName && { firstName }),
          ...(lastName && { lastName }),
          ...(birthName && { birthName }),
          ...(clientCode && { clientCode }),
          ...(birthDate && { birthDate: formatToIso(birthDate) }),
          ...(isArchived !== null && { isArchived: String(isArchived) }),
          includeLocal: 'true',
        },
      },
    );
  }

  updatePatient(
    clientCode: string,
    patientId: number,
    patient: Patient,
  ): Observable<Patient> {
    return this.http.patch<Patient>(`${environment.apiBaseUrl}/patient/${clientCode}/${patientId}`,
      {
        id: patient.id,
        businessIdentifier: patient.businessIdentifier,
        firstName: patient.firstName,
        lastName: patient.lastName,
        birthName: patient.birthName,
        birthDate: formatToIso(patient.birthDate),
        isGamLinked: patient.isGamLinked,
      });
  }

  teleexpertise(
    clientCode: string,
    patientId: number,
    description: string,
    { query }: QueryReportConfig = { query: new PaginationQuery({ orderBy: ['created_on|desc'] }) },
  ): Observable<string> {
    return this.http.post(
      `${environment.apiBaseUrl}/patient/${patientId}/teleexpertise`,
      { },
      {
        params: {
          cc: clientCode,
          description,
          generateReport: true,
          attachSequenceImages: true,
          paginationOptions: String(query.encodedQuery || query.encode() || ''),
        },
        responseType: 'text',
      },
    );
  }

  archive(clientCode: string, patientId: number): Observable<Patient> {
    return this.http.patch<Patient>(`${environment.apiBaseUrl}/patient/${clientCode}/${patientId}/archive`,
      {},
      {},
    );
  }

  unarchive(clientCode: string, patientId: number): Observable<Patient> {
    return this.http.patch<Patient>(`${environment.apiBaseUrl}/patient/${clientCode}/${patientId}/unarchive`,
      {},
      {},
    );
  }

}
