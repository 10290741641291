import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PaginationDirection, PaginationQuery, Patient } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { BasePagination } from '../shared/base-pagination';
import { PagedCollection } from '../shared/models/pagination/paged-collection';
import { patientPaginationContext } from '../shared/models/pagination/pagination-context.config';
import { patientsActions } from '../shared/store/patients/patients.actions';
import { selectPatients } from '../shared/store/patients/patients.selectors';

@Injectable({
  providedIn: 'root',
})
export class PatientStorePaginationService extends BasePagination<Patient> {

  constructor(
    private readonly store: Store,
  ) {
    super();
  }

  load(clientCode: string, {
    query = new PaginationQuery({ orderBy: ['created_on|desc'], size: patientPaginationContext.countPerPage }),
    direction = PaginationDirection.NEXT,
    reset = false,
  } = {}): void {
    if (direction === PaginationDirection.NEXT) {
      this.store.dispatch(patientsActions.getNextPatients({
        clientCode,
        options: { query, reset },
      }));
    } else {
      console.error('Backward patient pagination is not supported.');
    }
  }

  select({ clientCode }: { clientCode: string }): Observable<PagedCollection<Patient[]>> {
    this.collection$ = this.store.select(selectPatients(clientCode));
    return this.collection$;
  }

}
