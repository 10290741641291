import { Status } from './enums/status.enum';
import { Information } from './information';

export const statusInformations: { [state in Status]: Information } = {
  [Status.BASIC]: {
    title: 'Information',
    icon: '@tui.info',
    color: 'text-tertiary',
  },
  [Status.ERROR]: {
    icon: '@tui.circle-alert',
    title: 'Echec',
    description: 'Un problème est survenu lors de l\'opération. Veuillez réessayer.',
    color: 'text-status-negative',
  },
  [Status.LOADING]: {
    icon: '@tui.loader',
    title: 'Chargement ...',
    description: 'Veuillez patienter quelques instants ...',
    color: 'text-secondary',
  },
  [Status.SUCCESS]: {
    icon: '@tui.circle-check-big',
    title: 'Succès',
    description: 'L\'opération a été réalisée avec succès !',
    color: 'text-status-positive',
  },
  [Status.WARNING]: {
    icon: '@tui.triangle-alert',
    title: 'Avertissement',
    color: 'text-status-warning',
  },
};
