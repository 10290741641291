import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Filter, FilterOperator, FlattenedSequence, PaginationQuery, SadmEntity } from '@pixacare/pxc-ts-core';
import { Observable, map, of, shareReplay } from 'rxjs';
import { SadmHttpService } from 'src/app/services/http/sadm.http.service';
import { defaultPaginationContext } from 'src/app/shared/models/pagination/pagination-context.config';
import { FilterBarService } from '../../filters/filter-bar.service';
import { TelemonitoringPaginationService } from '../../telemonitoring/telemonitoring-pagination.service';
import { TelemonitoringService } from '../../telemonitoring/telemonitoring.service';
import { SequencePaginationService } from '../services/sequence-pagination.service';

@UntilDestroy()
@Injectable()
export class SequencePickerService {

  clientCode: string;
  patientId?: number;
  departmentIds?: number[];
  telemonitoringIds?: number[];

  displayedSequences$ = this.sequencePaginationService.select();
  displayedTelemonitorings$ = this.telemonitoringPaginationService.select();
  displayedSadms$: Observable<SadmEntity[]> = of([]);

  telemonitoringNames$: Observable<{ [id: string]: string }>;
  sadmNames$: Observable<{ [id: string]: string }>;

  constructor(
    private readonly filterBarService: FilterBarService,
    private readonly sequencePaginationService: SequencePaginationService,
    private readonly telemonitoringPaginationService: TelemonitoringPaginationService,
    private readonly sadmHttpService: SadmHttpService,
    private readonly telemonitoringService: TelemonitoringService,
  ) { }

  init({ clientCode, patientId, departmentIds, telemonitoringIds, showSadms, showTelemonitorings }: {
    clientCode: string;
    patientId?: number;
    departmentIds?: number[];
    telemonitoringIds?: number[];
    showSadms: boolean;
    showTelemonitorings: boolean;
  }): void {
    this.clientCode = clientCode;
    this.patientId = patientId;
    this.departmentIds = departmentIds;
    this.telemonitoringIds = telemonitoringIds;

    this.filterBarService.setPersistentFilters(this.getPersistentFilters());

    this.filterBarService.filteredSearch$.pipe(untilDestroyed(this)).subscribe(({ search, filters }) => {
      this.loadSequences(filters, search);
    });

    if (showTelemonitorings) {
      this.displayedSequences$.pipe(untilDestroyed(this)).subscribe((sequences) => {
        this.loadTelemonitorings(sequences.data);
      });

      this.telemonitoringNames$ = this.displayedTelemonitorings$.pipe(
        map((telemonitorings) => telemonitorings.data.reduce((acc, telemonitoring) => ({
          ...acc,
          [telemonitoring.telemonitoring.id]: this.telemonitoringService.getTelemonitoringName(telemonitoring),
        }), {})),
      );
    }

    if (showSadms) {
      this.displayedSequences$.pipe(untilDestroyed(this)).subscribe((sequences) => {
        this.loadSadms(sequences.data);
      });
    }

  }

  loadNextSequences(): void {
    this.sequencePaginationService.loadNextPage(this.clientCode);
  }

  private getPersistentFilters(): Filter[] {

    const filters = [];

    if (this.patientId) {
      filters.push({
        prop: 'patient_id',
        op: FilterOperator.EQUAL,
        val: this.patientId.toString(),
      });
    }

    if (this.departmentIds && this.departmentIds.length > 0) {
      filters.push({
        prop: 'department_id',
        op: FilterOperator.IN,
        val: this.departmentIds.join(','),
      });
    }

    if (this.telemonitoringIds && this.telemonitoringIds.length > 0) {
      filters.push({
        prop: 'telemonitoring_id',
        op: FilterOperator.EQUAL,
        val: this.telemonitoringIds.join(','),
      });
    }

    return filters;

  }

  private loadSequences(filters: Filter[], search: string): void {
    this.sequencePaginationService.load(this.clientCode, {
      query: new PaginationQuery({
        orderBy: ['created_on|desc'],
        filter: filters,
        search,
        size: defaultPaginationContext.countPerPage,
      }),
      reset: true,
    });
  }

  private getSequencesTelemonitoringIds(sequences: FlattenedSequence[]): number[] {
    const ids = sequences.reduce((acc, sequence) => {
      if (sequence.sequenceInstance.telemonitoringId) {
        acc.push(sequence.sequenceInstance.telemonitoringId);
      }
      return acc;
    }, []);

    return [...new Set(ids)];
  }

  private getSequencesSadmIds(sequences: FlattenedSequence[]): number[] {
    const ids = sequences.reduce((acc, sequence) => {
      if (sequence.sequenceInstance.sadmEntityId) {
        acc.push(sequence.sequenceInstance.sadmEntityId);
      }
      return acc;
    }, []);

    return [...new Set(ids)];
  }

  private loadTelemonitorings(sequences: FlattenedSequence[]): void {

    const telemonitoringIds = this.getSequencesTelemonitoringIds(sequences);

    if (telemonitoringIds.length === 0) {
      return;
    }

    this.telemonitoringPaginationService.load(this.clientCode, {
      query: new PaginationQuery({
        filter: [
          {
            prop: 'is_active',
            op: FilterOperator.EQUAL,
            val: '1',
          },
          {
            prop: 'client_code',
            op: FilterOperator.EQUAL,
            val: this.clientCode,
          },
          {
            prop: 'patient_id',
            op: FilterOperator.EQUAL,
            val: this.patientId.toString(),
          },
          {
            prop: 'id',
            op: FilterOperator.IN,
            val: telemonitoringIds.join(','),
          },
        ],
      }),
      reset: true,
    });
  }

  private loadSadms(sequences: FlattenedSequence[]): void {

    const sadmIds = this.getSequencesSadmIds(sequences);

    if (sadmIds.length === 0) {
      return;
    }

    this.displayedSadms$ = this.sadmHttpService.getSadmEntities({
      patientId: this.patientId,
      clientCode: this.clientCode,
      flattened: false,
      includeSadmClients: true,
    }).pipe(shareReplay(1));

    this.sadmNames$ = this.displayedSadms$.pipe(
      map((sadms) => sadms.reduce((acc, sadm) => ({
        ...acc,
        [sadm.id]: sadm.name,
      }), {})),
    );

  }

}
