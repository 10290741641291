import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { labelsActions } from '../labels/labels.actions';
import { licensesActions } from '../licenses/licenses.actions';
import { sadmActions } from '../sadm/sadm.actions';
import { lifeCycleActions } from './life-cycle.actions';

@Injectable()
export class LifeCycleEffects {

  initialize$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(lifeCycleActions.initialize),
      switchMap(() => [
        licensesActions.loadLicenses(),
        labelsActions.getUserLabels(),
        sadmActions.getSadmClients(),
      ]),
    ),
  );

  redirect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(lifeCycleActions.redirect),
      tap(({ url }) =>
        this.router.navigate([url], { queryParamsHandling: 'merge' },
        ),
      ),
    ), { dispatch: false });

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
  ) {}

}
