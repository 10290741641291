@if (clickable) {
  <button
    size="xs"
    tuiChip
    class="tracking-tight text-secondary"
    type="button"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
} @else {
  <tui-chip
    size="xs"
    class="tracking-tight text-secondary"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </tui-chip>
}

<ng-template #content>
  {{ label.word | uppercase }}
  @if (isDeletable) {
    <button
      iconStart="@tui.x"
      tuiIconButton
      type="button"
      (click)="closeEvent.emit()"
    >
      Remove
    </button>
  }
</ng-template>
