import { CropConfig } from '@pixacare/pxc-ts-core';
import Konva from 'konva';
import { Command } from 'src/app/shared/models/helpers/command';
import { StudioCanvas } from '../canvas/studio-canvas';
import { EventBus } from '../event-bus/event-bus';

export class SetBackgroundCommand implements Command {

  receiver: StudioCanvas;
  image: HTMLImageElement;
  eventBus: EventBus;
  margin = 120;
  cropConfig: CropConfig;

  constructor(receiver: StudioCanvas, image: HTMLImageElement, cropConfig: CropConfig, eventBus: EventBus) {
    this.receiver = receiver;
    this.image = image;
    this.cropConfig = cropConfig;
    this.eventBus = eventBus;
  }

  execute(): void {

    this.receiver.stage.setAttrs({
      width: (this.receiver.isVertical() ? window.innerHeight : window.innerWidth) - this.margin,
      height: (this.receiver.isVertical() ? window.innerWidth : window.innerHeight) - this.margin,
    });

    const { width, height } = this.cropConfig.width || this.cropConfig.height ? this.cropConfig : this.image;

    const widthRatio = this.receiver.stage.width() / width;
    const heightRatio = this.receiver.stage.height() / height;

    // Only consider ratio < 1, used to scale down the image to fit container
    let ratio = Math.min(Math.min(widthRatio, 1), Math.min(heightRatio, 1));

    if (ratio === 1) { // Image is smaller or equal to canvas size
      ratio = Math.min(widthRatio, heightRatio);
    }

    const imgWidth = width * ratio;
    const imgHeight = height * ratio;

    if (this.receiver.isVertical()) {
      this.receiver.resize({ width: imgHeight, height: imgWidth });
    } else {
      this.receiver.resize({ width: imgWidth, height: imgHeight });
    }

    const img = new Konva.Image({
      image: this.image,
      x: (this.isVertical() ? imgHeight : imgWidth) / 2,
      y: (this.isVertical() ? imgWidth : imgHeight) / 2,
      offsetX: width / 2,
      offsetY: height / 2,
      scaleX: ratio,
      scaleY: ratio,
      id: 'background-image',
    });

    if (this.cropConfig) {
      img.crop({
        x: this.cropConfig.x,
        y: this.cropConfig.y,
        width: img.width(),
        height: img.height(),
      });
    }
    this.receiver.addBackground(img);

    // rescale drawing layer
    this.receiver.layer.setAttrs({
      x: img.x(),
      y: img.y(),
      offsetX: img.offsetX(),
      offsetY: img.offsetY(),
      scaleX: ratio,
      scaleY: ratio,
    });
    if (this.cropConfig) {
      this.receiver.layer.setAttrs({
        x: img.x() - this.cropConfig.x * ratio,
        y: img.y() - this.cropConfig.y * ratio,
      });
    }

    // updating stage position
    this.receiver.stage.setAttrs({
      offsetX: this.receiver.stage.width() / 2,
      offsetY: this.receiver.stage.height() / 2,
      x: this.receiver.stage.width() / 2,
      y: this.receiver.stage.height() / 2,
    });
  }

  isVertical(): boolean {
    return [90, 270].includes(Math.abs(this.receiver.stage.rotation() % 360));
  }

}
