import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateShare, FlattenedSequence, Share } from '@pixacare/pxc-ts-core';
import { combineLatest, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SequenceHttpService } from './sequence.http.service';

@Injectable({
  providedIn: 'root',
})
export class ShareHttpService {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly sequenceService: SequenceHttpService) {}

  getShareById(shareId: number): Observable<Share> {
    return this.httpClient.get<Share>(`${environment.apiBaseUrl}/share/${shareId}`);
  }

  getSharesToCurrentUser(): Observable<Share[]> {
    return this.httpClient
      .get<Share[]>(`${environment.apiBaseUrl}/share?toMe=true`);
  }

  getSharedSequences(shares: Share[]): Observable<FlattenedSequence[]> {
    return combineLatest(shares.map((share) =>
      this.sequenceService.getSequence(share.clientCode, share.objectId, {
        fillFullSizeUri: true,
        fillThumbnailUri: true,
      })));
  }

  claimShareInvite(token: string): Observable<unknown> {
    return this.httpClient.post(
      `${environment.apiBaseUrl}/Share/ClaimShareInvitation`,
      { token },
    );
  }

  shareObject(
    createShareRequest: CreateShare,
  ): Observable<Share> {
    return this.httpClient.post<Share>(
      `${environment.apiBaseUrl}/share/`, createShareRequest,
    );
  }

}
