import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { SharedModule } from '@modules/shared/shared.module';
import { CreatorType } from '@pixacare/pxc-ts-core';

@Component({
  selector: 'pxc-telemonitoring-request-type',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
  ],
  templateUrl: './telemonitoring-request-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelemonitoringRequestTypeComponent {

  @Output() creatorTypeChange: EventEmitter<CreatorType> = new EventEmitter<CreatorType>();

  byPatient(): void {
    this.creatorTypeChange.emit(CreatorType.BY_PATIENT);
  }

  byUser(): void {
    this.creatorTypeChange.emit(CreatorType.BY_USER);
  }

}
