<div
  class="flex items-start gap-2"
  [ngClass]="{
    'ml-auto': !isIncoming,
    'mt-2': showHeader,
  }"
>
  @if (isIncoming) {
    <pxc-avatar
      [name]="message.senderName"
      [avatarColors]="avatarColors"
      [ngClass]="{
        'h-0 opacity-0': !showHeader,
      }"
    ></pxc-avatar>
  }

  <div
    class="flex cursor-pointer flex-col"
    [ngClass]="{ 'items-start': isIncoming, 'items-end': !isIncoming }"
    (click)="messageClicked.emit()"
  >
    <div
      class="flex w-fit flex-col gap-1 rounded-b-xl p-3 !shadow"
      [ngClass]="{
        'rounded-tr-xl bg-base': isIncoming,
        'rounded-tl-xl bg-neutral-2': !isIncoming,
      }"
    >
      @if (showHeader) {
        @if (isIncoming) {
          <div class="flex justify-between gap-3">
            <p
              class="line-clamp-1 text-sm font-medium"
              [ngStyle]="{
                color: avatarColors.textColor,
              }"
            >
              {{ message.senderName }}
            </p>
            <ng-container *ngTemplateOutlet="messageDate"></ng-container>
          </div>
        } @else {
          <ng-container *ngTemplateOutlet="messageDate"></ng-container>
        }
      }

      <p class="max-w-prose break-words">{{ message.text }}</p>
      <pxc-message-special
        [special]="message.special"
        [userId]="authenticationService.currentUser.id"
      ></pxc-message-special>
    </div>

    <tui-elastic-container>
      @if (shouldShowReaders) {
        <p class="mt-1 text-secondary">
          {{ readers | listMessageReaders: channelUsersCount : authenticationService.currentUser.id }}
        </p>
      }
    </tui-elastic-container>
  </div>

  <ng-template #messageDate>
    <p class="text-right text-xs text-tertiary">
      {{ date | date: 'shortTime' }}
    </p>
  </ng-template>
</div>
