import { Telemonitoring } from '@pixacare/pxc-ts-core';
import { defaultPagedCollection } from '../../models/pagination/default-paged-collection.config';
import { PagedCollection } from '../../models/pagination/paged-collection';

type PagedTelemonitoringsCollection = PagedCollection<{ [telemonitoringId: number]: Telemonitoring }>;

export interface TelemonitoringsState {
  telemonitorings: PagedTelemonitoringsCollection;
  isLoaded: boolean;
}

export const initialTelemonitoringsState: TelemonitoringsState = {
  telemonitorings: {
    ...defaultPagedCollection as PagedTelemonitoringsCollection,
  },
  isLoaded: false,
};
