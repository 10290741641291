import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DepartmentsEffects } from './departments.effects';
import { departmentsReducer } from './departments.reducer';
import { DEPARTMENTS_FEATURE_KEY } from './departments.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(DEPARTMENTS_FEATURE_KEY, departmentsReducer),
    EffectsModule.forFeature([DepartmentsEffects]),
  ],
  declarations: [],
})
export class DepartmentStoreModule { }
