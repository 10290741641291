import { Pipe, type PipeTransform } from '@angular/core';
import { Channel, ChannelType } from '@pixacare/pxc-ts-core';

@Pipe({
  name: 'channelType',
  standalone: true,
})
export class ChannelTypePipe implements PipeTransform {

  transform(value: Channel): string {

    const base = 'Conversation';

    if (!value) {return base;}

    switch (value.type) {

      case ChannelType.DEPARTMENT:
        return base + ' de groupe';

      case ChannelType.PRIVATE:
        if (value.users.length > 1) {
          return base + ' de groupe privée';
        }

        return base + ' privée';

      case ChannelType.TELEMONITORING:
        return base + ' de télésuivi';

      default:
        return base;
    }

  }

}
